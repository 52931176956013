import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { FlexColumnBox } from "../layout";
import { sxEllipsis } from "../global-components/styled/StyledSx";
import timeFormat from "../../utils/timeFormat";
import { createMarkup } from "../atom-components/ParseHtml";
import { grey } from "@mui/material/colors";

/**
 *
 * @param {*} onClick : 해당 referece의 id를 보내줌
 * @returns
 */
function ReferenceCard({
  reference,
  isHovered = false,
  isSelected = false,
  onClick,
}) {
  const {
    referenceId,
    referenceCreatedDate,
    referenceData,
    referenceType /*image*/,
  } = reference;

  return (
    <Card
      elevation={isHovered ? 3 : 0}
      sx={{
        display: "flex",
        backgroundColor: isSelected ? grey[100] : "white",
        // cursor: "pointer",
      }}
    >
      <CardActionArea>
        <CardContent onClick={onClick}>
          <FlexColumnBox gap="0.4rem">
            {/* <Typography
            variant="body1"
            color="info.main"
            sx={{
              ...sxEllipsis,
              WebkitLineClamp: "1",
            }}
          >
            {title}
          </Typography> */}
            <Typography
              variant="body2"
              color="text.secondary"
              component="div" // <p> 태그 안에 <div>를 쓰면 에러남 -> <p> 태그인 Typography를 <div> 태그로 변경
              sx={{
                ...sxEllipsis,
                WebkitLineClamp: "4",
              }}
            >
              <Typography variant="caption">
                {timeFormat(referenceCreatedDate, true)} —
              </Typography>
              <div dangerouslySetInnerHTML={createMarkup(referenceData)}></div>
            </Typography>
          </FlexColumnBox>
        </CardContent>
        {/* <CardMedia
        component="img"
        sx={{
          display: !!image ? "block" : "none",
          width: "25%",
        }}
        image={image}
        alt={title}
      /> */}
      </CardActionArea>
    </Card>
  );
}
export default ReferenceCard;
