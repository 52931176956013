import { FlexColumnBox } from "../layout";
import MyCKEditor from "../global-components/MyCKEditor";
import SubTitle from "../global-components/SubTitle";
import { Paper } from "@mui/material";

/**
 * 저작하기 / 해설
 * @param {*} param0
 */
function WriteQuizCommentary({ inputCommentaryText, setInputCommentaryText }) {
  return (
    <>
      <FlexColumnBox>
        <SubTitle titleName="해설" body="해설을 입력해주세요." />
        <Paper elevation={1}>
          <MyCKEditor
            data={inputCommentaryText}
            onChange={setInputCommentaryText}
            placeholder="해설을 입력해주세요."
            initHeight="200px"
          />
        </Paper>
      </FlexColumnBox>
    </>
  );
}
export default WriteQuizCommentary;
