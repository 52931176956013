import React, { useEffect } from "react";
import useMoveScroll from "../../hooks/useMoveScroll";
import makeRandomWord from "../../utils/makeRandomWord";
import Quiz from "../../pages/Quiz";

function PreviewQuizContainer({ resultQuiz }) {
  //===================실제 퀴즈 컨트롤========================
  /** 현재 사용자가 선택한 선택지의 정답 여부 */
  const [isCorrect, setIsCorrect] = React.useState(false);
  /** 제출 되었는지(확인 눌렀는지) 여부 */
  const [isSubmit, setIsSubmit] = React.useState(false);

  /** OX : 사용자가 선택한 선택지 활성화 */
  const [selectedOX, setSelectedOX] = React.useState("");
  /** OX : 선택한 선택지를 활성화 */
  function handleOXItemClick(event, ox) {
    setSelectedOX(ox);
  }
  /** OX : 사용자가 선택한 선택지가 정답인지 확인하는 함수(선택할 때마다 정답 여부가 갱신되는 것을 방지하고자 함수화를 따로 함) */
  function handleOXIsCorrect(ox) {
    const isCheck = resultQuiz.answer === ox;
    setIsCorrect(isCheck);
  }
  /** 객관식 : 사용자가 선택한 선택지가 정답인지 확인하는 함수(선택할 때마다 정답 여부가 갱신되는 것을 방지하고자 함수화를 따로 함) */
  function handleListIsCorrect(check) {
    setIsCorrect(check);
  }

  /** 퍼즐 : 랜덤 단어 */
  const [randomWord, setRandomWord] = React.useState([]);
  /** 퍼즐 : 랜덤 단어 인덱스(선택 안 된 단어의 인덱스) */
  const [randomWordIndex, setRandomWordIndex] = React.useState([]);
  /** 랜덤으로 섞인 단어 백업(다시 하기 했을 때 되돌리기 위함) */
  const [originalWord, setOriginalWord] = React.useState([]);
  /** 퍼즐 : 랜덤 단어 초기 세팅 */
  React.useEffect(() => {
    // if (isLoading) {
    if (resultQuiz.answerText === undefined) return;
    const word = [...makeRandomWord(resultQuiz.answerText)];
    setRandomWord(word);
    setOriginalWord(word); // 저장해놓기
  }, []);
  /** 퍼즐 : 랜덤 단어 인덱스 초기 세팅 */
  React.useEffect(() => {
    setRandomWordIndex(Array.from(Array(randomWord.length).keys()));
  }, [randomWord]);
  /** 퍼즐 : 선택한 단어 인덱스 (선택한 단어에 참조하기 위해선 예를 들어, randomWord[selectedWord[0]]) */
  const [selectedWord, setSelectedWord] = React.useState([]);
  /** 퍼즐 : 선택한 단어들은 위에서 보여주고, 아래에서 안보여주기 위함 (중복 단어 구분을 위해 index로 구분) */
  function handleSelectedWordInsert(event, index) {
    // if (!isLoading) return;
    /** 선택된 것들에 추가 */
    setSelectedWord([...selectedWord, index]);
    /** 선택 안 된 것들에서 삭제 */
    setRandomWordIndex(randomWordIndex.filter((element) => element !== index));
  }
  /** 퍼즐 : 선택 안 한 단어들은 아래에서 보여주고, 위에서 안보여주기 위함 (중복 단어 구분을 위해 index로 구분) */
  function handleSelectedWordErase(event, selected, index) {
    // if (!isLoading) return;
    /** 선택 안 된 것들에서 삭제 */
    setSelectedWord(selectedWord.filter((element) => element !== selected));
    /** 선택 안 된 것들에 추가 */
    setRandomWordIndex([...randomWordIndex, selected]);
  }
  /** 초기화 */
  function handleOriginalWord() {
    setRandomWord(originalWord);
    setRandomWordIndex(Array.from(Array(originalWord.length).keys()));
    setSelectedWord([]);
  }
  /**
   * 퍼즐 : 현재 선택된 단어의 인덱스(selectedWord)는 map 순환을 위해서 Array 타입이기 때문에
   * Array에 있는 단어의 인덱스들을 랜덤 단어에 참조해서 string으로 변환해주는 함수
   *
   * ex)
   * randomWord = ['률', '나', '다', '법', '마']
   * selectedWord = [3, 0]
   * => "법률" 로 변환
   *
   * @param {Array} arr : string으로 변환하고 싶은 단어의 인덱스 Array
   */
  function handleMakeResultWord(arr) {
    let result = "";
    for (let i = 0; i < arr.length; i++) result += randomWord[arr[i]];
    return result;
  }
  /**
   * 퍼즐 : 현재 선택된 단어가 정답인지 확인하는 함수
   * @param {string} text : 정답인지 확인하고 싶은 string
   */
  function handlePuzzleIsCorrect(text) {
    const isCheck = resultQuiz.answerText === text;
    setIsCorrect(isCheck);
  }

  /** 객관식 : 사용자가 선택한 선택지 활성화 */
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  /** 객관식 : 선택한 선택지를 활성화 & 정답 여부 갱신 */
  function handleListItemClick(event, index) {
    setSelectedIndex(index);
  }

  /** 확인 버튼 : 제출 */
  function handleIsSubmit(event) {
    event.preventDefault();
    setIsSubmit(true);
  }

  /** 해설 나왔을 때, 스크롤이 자동으로 내려가기 위한 ref */
  const commentary = useMoveScroll();
  /** 확인 버튼 누른 후, 해설 나왔을 때 스크롤 자동으로 내려감 */
  useEffect(() => {
    if (!isSubmit) return;
    commentary.onMoveToElement();
  }, [commentary, isSubmit]);

  //==========================다시 하기(새로고침 말고)========================
  function onClickQuizAgain() {
    setIsCorrect(false);
    setIsSubmit(false);
    setSelectedOX("");
    handleOriginalWord();
    setSelectedIndex(-1);
  }

  return (
    <Quiz
      resultQuiz={resultQuiz}
      isSubmit={isSubmit}
      isCorrect={isCorrect}
      isSuccessQuiz={true}
      selectedOX={selectedOX}
      randomWord={randomWord}
      randomWordIndex={randomWordIndex}
      selectedWord={selectedWord}
      selectedIndex={selectedIndex}
      handleOXItemClick={handleOXItemClick}
      handleListItemClick={handleListItemClick}
      handleSelectedWordErase={handleSelectedWordErase}
      handleSelectedWordInsert={handleSelectedWordInsert}
      handleMakeResultWord={handleMakeResultWord}
      handleOXIsCorrect={handleOXIsCorrect}
      handleListIsCorrect={handleListIsCorrect}
      handlePuzzleIsCorrect={handlePuzzleIsCorrect}
      commentary={commentary}
      isPreview={true}
      handleIsSubmit={handleIsSubmit}
      onClickQuizAgain={onClickQuizAgain}
    />
  );
}
export default PreviewQuizContainer;
