import LevelProgressBar from "../global-components/LevelProgressBar";
import {
  FooterBox,
  FooterButton,
  FooterPosition,
} from "../global-components/FooterSx";
import { Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SendIcon from "@mui/icons-material/Send";

/**
 * footer
 * - 건너 뛰기 & 확인
 * - 메인으로 가기 & (다시 풀기) & 다음 문제
 *
 * @param {bool} isSubmit : 제출 여부
 * @param {object} resultQuiz : 퀴즈 데이터
 * @param {string} selectedOX : (OX) OX 중 현재 선택된 버튼
 * @param {object} selectedIndex : (객관식) 사용자가 선택한 선택지
 * @param {Array} selectedWord : (퍼즐) 선택된 단어의 인덱스
 * @param {function} handleIsSubmit : 제출 여부 갱신 함수
 * @param {function} handleOXIsCorrect : (OX) 정답 여부 갱신 함수
 * @param {function} handleListIsCorrect : (객관식) 정답 여부 갱신 함수
 * @param {function} handlePuzzleIsCorrect : (퍼즐) 정답 여부 갱신 함수
 * @param {function} handleMakeResultWord : (퍼즐) 선택된 단어의 인덱스 Array를 string으로 만드는 함수
 */
function Footer({
  isSubmit,
  resultQuiz,
  selectedOX,
  selectedIndex,
  selectedWord,
  handleIsSubmit,
  handleOXIsCorrect,
  handleListIsCorrect,
  handlePuzzleIsCorrect,
  handleMakeResultWord,
  nextSuccess,
  onClickQuizAgain = () => {},
  quizNext = () => {},
  isCorrect,
  upXp,
  handleBackPage = () => {},
  //////////
  isPreview = false,
}) {
  // console.log(nextSuccess);
  return (
    <FooterPosition elevation={3} square>
      <FooterBox>
        {!isPreview ? (
          // (퀴즈)
          !isSubmit ? (
            <FooterButton onClick={quizNext}>건너 뛰기</FooterButton>
          ) : (
            <FooterButton onClick={handleBackPage}>돌아 가기</FooterButton>
          )
        ) : (
          // (미리보기)
          <Box />
        )}
        {!isPreview && isSubmit && isCorrect && (
          <LevelProgressBar
            widthTheme={{ xs: "18rem", sm: "26rem", md: "40rem" }}
            heightTheme={{ xs: "1.3rem", sm: "1.65rem", md: "2.4rem" }}
            upXp={upXp}
          />
        )}
        {
          /**
           * 제출 시,
           * 맞았다면 경험치 업
           * 틀렸다면 다시 풀기 -> 봤던 문제 중에서, API 호출하기 함수
           */
          ((!isPreview && isSubmit && !isCorrect) ||
            (isPreview && isSubmit)) && (
            <FooterButton onClick={onClickQuizAgain}>다시 풀기</FooterButton>
          )
        }
        {!isSubmit ? (
          <FooterButton
            variant="contained"
            /** 현재 사용자가 아무것도 선택하지 않았으면 비활성화(true) */
            disabled={
              (resultQuiz.quizType === "OX" && selectedOX === "") ||
              (resultQuiz.quizType === "MULTIPLE" && selectedIndex === -1) ||
              (resultQuiz.quizType === "PUZZLE" && selectedWord.length <= 0)
            }
            onClick={(event) => {
              handleIsSubmit(event);

              if (resultQuiz.quizType === "OX") {
                handleOXIsCorrect(selectedOX);
              } else if (resultQuiz.quizType === "MULTIPLE") {
                handleListIsCorrect(
                  resultQuiz.examples[selectedIndex].isAnswer
                );
              } else if (resultQuiz.quizType === "PUZZLE") {
                handlePuzzleIsCorrect(handleMakeResultWord(selectedWord));
              }
            }}
          >
            확인
          </FooterButton>
        ) : (
          !isPreview &&
          isCorrect &&
          (nextSuccess ? (
            <FooterButton variant="contained" onClick={quizNext}>
              다음 문제
            </FooterButton>
          ) : (
            <LoadingButton
              variant="outlined"
              disabled
              loading
              loadingPosition="end"
              endIcon={<SendIcon />}
            >
              <span>기록 전송 중...</span>
            </LoadingButton>
          ))
        )}
      </FooterBox>
    </FooterPosition>
  );
}
export default Footer;
