import getLocalStorageItemObj from "./getLocalStorageItemObj";
import checkUserAttendance from "./recordUser/checkUserAttendance";
import checkUserDailyMission from "./recordUser/checkUserDailyMission";
import timeFormat from "./timeFormat";

/**
 * 사용자 정보 기록관리(일일 목표, 출석) - 로컬 스토리지에서 확인
 */
async function checkRecord(userId) {
  /** 로컬 스토리지에 있는 거 가져오기 */
  /** 일일 목표 - 로컬 스토리지 정보 key : userId("userId-보안키") */
  let userGoal = getLocalStorageItemObj(userId);
  // console.log("현재 일일목표는?", userGoal);

  /** 출석 - 로컬 스토리지 정보 key : "userId-보안키-attendance" */
  const userAttendanceId = userId + "-attendance";
  let userAttendance = getLocalStorageItemObj(userAttendanceId);
  // console.log("현재 출석 상태는?", userAttendance);

  /** 현재 한국 날짜와 시간으로 포맷팅 */
  const tmpDate = new Date();
  const date = timeFormat(tmpDate, false);

  /** 일일 목표 체크 */
  await checkUserDailyMission(userId, userGoal, date);
  /** 출석 체크 */
  await checkUserAttendance(userAttendanceId, userAttendance, date);

  //   /**
  //    * 업적 달성
  //    * 1. 문제 풀었을시
  //    * 2. 출석
  //    */
  // }
}
/**
 * 익명 정보 기록관리(일일 목표) - 로컬 스토리지에서 확인
 */
async function checkRecordGuest() {
  const GUEST_ID = "Guest"; // 익명 일일 목표 key

  /** 로컬 스토리지에 있는 거 가져오기 */
  /** 일일 목표 - 로컬 스토리지 정보 key : "Guest" */
  let guestGoalObj = await localStorage.getItem(GUEST_ID);
  let guestGoal = JSON.parse(guestGoalObj);
  // console.log("현재 익명의 일일목표는?", guestGoal);

  /** 현재 한국 날짜와 시간으로 포맷팅 */
  const tmpDate = new Date();
  const date = timeFormat(tmpDate, false);

  /** 일일 목표 체크 */
  await checkUserDailyMission(GUEST_ID, guestGoal, date);
}
export { checkRecord, checkRecordGuest };
