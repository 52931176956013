import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 출석 기록 API
 */
async function recordAttendance() {
  /**
   * "recordStatus": "string",
   * "message": "string",
   * "earnAchievementIdList": [
   *     "string"
   *  ]
   */
  let data;
  try {
    await axios.post(APIURL.api + "record/attendance").then((response) => {
      // console.log("출석 기록 잘 보냄!", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("사용자 풀이 이력 못 보냄..", error);
    switch (error.response.status) {
      case 461:
        // console.log("사용자가 이미 출석체크 했음");
        return error.response;
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default recordAttendance;
