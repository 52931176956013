import { useSelector, useDispatch } from "react-redux";
import {
  onChangeOpen,
  onChangeClose,
  onChangeInput,
} from "../../modules/snackbar";
import {
  GuideSnackbar,
  SnackbarWithIcon,
} from "../atom-components/StyledSnackbars";
/**
 * 알림창
 */
function Snackbars() {
  /** Redux Dispatch */
  const dispatch = useDispatch();

  const { open, severity, message } = useSelector((state) => ({
    open: state.snackbar.open,
    severity: state.snackbar.severity,
    message: state.snackbar.message,
  }));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      // 바깥 클릭해도 안 없어짐.
      return;
    }
    dispatch(onChangeClose());
  };
  //const handleOpen = () => dispatch(onChangeOpen());
  //const handleInput = (data: {id, value}) => dispatch(onChangeInput(data));

  return (
    <>
      {severity === "basic" ? (
        <GuideSnackbar
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
        />
      ) : (
        <SnackbarWithIcon
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}
          message={message}
          alertProps={{ severity: severity /**icon: <CloseIcon />**/ }}
        />
      )}
    </>
  );
}
export default Snackbars;
