import { Card, CardContent, Typography } from "@mui/material";
import useSx from "../../hooks/useSx";
import { FlexColumnBox } from "../layout";

/**
 *
 * @param {*} title : 제목
 * @param {*} component : 컨텐츠
 * @param {*} color : 제목 색
 */
function SettingComponent({ title, component, color }) {
  // 테마 정의
  const { settingBoxGap } = useSx();

  return (
    <FlexColumnBox {...settingBoxGap}>
      <Typography variant="h5" color={color}>
        {title}
      </Typography>
      <Card elevation={2}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2.4rem",
          }}
        >
          {component}
        </CardContent>
      </Card>
    </FlexColumnBox>
  );
}

export default SettingComponent;
