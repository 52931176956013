import * as React from "react";
import Quiz from "../pages/Quiz";
import handleRequestHeader from "../utils/handleRequestHeader";
import useMoveScroll from "../hooks/useMoveScroll";
import recordPlayUser from "../modules/_actions/recordPlayUser";
import { useDispatch, useSelector } from "react-redux";
import { onLoadData } from "../modules/quiz";
import loadQuiz from "../modules/_actions/loadQuiz";
import { handleIdToken } from "../utils/handleIdToken";
import loadQuizGuest from "../modules/_actions/loadQuizGuest";
import URL from "../components/pageUrls";
import { onChangeInput, onChangeOpen } from "../modules/snackbar";
import loadQuizId from "../modules/_actions/loadQuizId";
import loadQuizTopic from "../modules/_actions/loadQuizTopic";
import loadQuizTopicGuest from "../modules/_actions/loadQuizTopicGuest";
import isUserLogin from "../utils/isUserLogin";
import usePage from "../hooks/usePage";
import loadWrongQuiz from "../modules/_actions/loadWrongQuiz";
import loadWrongQuizTopic from "../modules/_actions/loadWrongQuizTopic";
import loadSolveQuiz from "../modules/_actions/loadSolveQuiz";
import loadSolveQuizTopic from "../modules/_actions/loadSolveQuizTopic";
import getUrlParam from "../utils/getUrlParam";
import updateRecordUserDailyMission from "../utils/recordUser/updateRecordUserDailyMission";
import {
  onChangeUserAchievementIdList,
  onInitUser,
  onSetUserStat,
} from "../modules/auth";
import { handleUserStat } from "../utils/handleUserStat";
import {
  onChangeEventAchievement,
  onChangeEventLevelUp,
} from "../modules/event";
import makeRandomWord from "../utils/makeRandomWord";
import { logEvent } from "firebase/analytics";
import { analytics } from "../service/firebase";

// /**
//  * 랜덤 단어 생성
//  * @param {int} word : 정답 단어
//  */
// function makeRandomWord(word) {
//   /**
//    * 정답 단어 길이가 4글자 이하면, 정답 단어 길이 포함 8글자 생성
//    * 초과면 정답 단어 길이 포함 x2 만큼 생성하기 위해, 정답 단어 길이만큼만 생성
//    */
//   const wordLength = word.length;
//   const newWordLength = wordLength <= 4 ? 8 - wordLength : wordLength;

//   const newWord =
//     word +
//     new Array(newWordLength)
//       .fill("")
//       .map(() => String.fromCharCode(44031 + Math.ceil(Math.random() * 11172)))
//       .join("");

//   /** 생성된 문자열을 섞어서 리턴 */
//   return [...newWord].sort(() => Math.random() - 0.5);
// }

/** 퀴즈 페이지 container */
function QuizContainer() {
  /** 페이지 이동 hook */
  const { backPage, quizAgain, quizNext } = usePage();

  /**
   * 현재 url에서 id 뽑아 내기
   * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
   * - topicId : 주제 id
   * - quizId : 퀴즈 id
   */
  const { historyId, topicId, quizId } = getUrlParam();

  /** Redux State */
  const dispatch = useDispatch();

  const {
    resultQuiz,
    open,
    severity,
    message,
    userIdToken,
    userLevel,
    userAchievementIdList,
  } = useSelector((state) => ({
    resultQuiz: state.quiz.resultQuiz,
    open: state.snackbar.open,
    severity: state.snackbar.severity,
    message: state.snackbar.message,
    userIdToken: state.auth.userIdToken,
    userLevel: state.auth.userLevel,
    userAchievementIdList: state.auth.userAchievementIdList,
  }));

  const handleLoadData = (error) => dispatch(onLoadData(error));
  const handleOpen = () => dispatch(onChangeOpen());
  const handleInput = (data) => dispatch(onChangeInput(data));
  /** User Achievement Id List */
  const handleUserAchievementIdList = (achievementIdList) => {
    dispatch(onChangeUserAchievementIdList(achievementIdList));
  };
  /** set user stat */
  const handleSetUserStat = (userStat) => {
    dispatch(onSetUserStat(userStat));
  };
  /** init user stat */
  const handleInitUserStat = () => {
    dispatch(onInitUser());
  };
  /** achievement event open */
  const handleAchievementEventOpen = () => {
    dispatch(onChangeEventAchievement());
  };
  /** level up event open */
  const handleLevelUpEventOpen = () => {
    dispatch(onChangeEventLevelUp());
  };
  ///================================
  /** 현재 로그인한 user의 id token과 더불어 stat 가져오기 : 주의할 점은 서버로부터 stat을 못 받아와도, 로그아웃 될 예정 */
  async function checkUserStat() {
    /** 가져온 user stat이 {} 이면 바로 게스트 후, 리턴 */
    await handleUserStat().then((userStat) => {
      // console.log(userStat);

      if (Object.keys(userStat).length === 0) {
        // console.log("익명이다");
        handleInitUserStat(); // user 아예 초기화
      } else {
        // console.log("회원이다", userStat.userId);
        handleSetUserStat({
          userIdToken: userStat.userId, // userId 보안키
          userNickname: userStat.nickname, // 닉네임
          userLevel: userStat.userLevel, // 레벨
          userLevelXp: userStat.currentXp, // 현재 레벨 XP
          profileImage: userStat.profileImage, // 이미지
          isWritable: userStat.isWritable, //저작 권한
        });
      }
    });
  }

  //=================================

  // 퀴즈 문제 잘 가져왔는지
  const [isSuccessQuiz, setIsSuccessQuiz] = React.useState(false);

  //----------------퀴즈 id를 통해 풀기----------------
  async function loadDataWithId() {
    /** get param */
    let params = {
      quizId: quizId,
    };
    await loadQuizId(params).then((res) => {
      // console.log("퀴즈 ID로 가져왔는지?", res);
      successLoadQuiz(res);
    });
  }

  //----------------main > 퀴즈 주제id 를 통해 풀기----------------
  // 회원
  async function loadDataWithTopicId(params) {
    /** get param */

    if (!isSuccessQuiz) {
      await loadQuizTopic(params).then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        /** 제공할 문제 있으면, 퀴즈 세팅 */
        if (res["resultQuiz"] !== undefined) {
          successLoadQuiz(res);
        } else {
          /** 제공할 문제 없으면, 뒤로가기 */
          // console.log("퀴즈 없음");
          errorLoadQuiz();
        }
      });
    }
  }
  // 익명
  async function loadDataWithTopicIdGuest(params) {
    if (!isSuccessQuiz) {
      await loadQuizTopicGuest(params).then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        successLoadQuiz(res);
      });
    }
  }

  //----------------wrong > 랜덤으로 풀기 (회원만 가능)---------------
  async function loadWrongData() {
    if (!isSuccessQuiz) {
      await loadWrongQuiz().then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        /** 제공할 문제 있으면, 퀴즈 세팅 */
        if (res["resultQuiz"] !== undefined) {
          // console.log(res);
          successLoadQuiz(res);
        } else {
          /** 로드 실패 시, 뒤로가기 */
          // console.log("로드 실패");
          errorLoadQuiz();
        }
      });
    }
  }
  //----------------wrong > 퀴즈 주제id 로 풀기 (회원만 가능)---------------
  async function loadWrongDataWithTopicId(params) {
    if (!isSuccessQuiz) {
      await loadWrongQuizTopic(params).then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        /** 제공할 문제 있으면, 퀴즈 세팅 */
        if (res["resultQuiz"] !== undefined) {
          successLoadQuiz(res);
        } else {
          /** 로드 실패 시, 뒤로가기 */
          // console.log("로드 실패");
          errorLoadQuiz();
        }
      });
    }
  }
  //----------------solve > 랜덤으로 풀기 (회원만 가능)---------------
  async function loadSolveData() {
    if (!isSuccessQuiz) {
      await loadSolveQuiz().then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        /** 제공할 문제 있으면, 퀴즈 세팅 */
        if (res["resultQuiz"] !== undefined) {
          successLoadQuiz(res);
        } else {
          /** 로드 실패 시, 뒤로가기 */
          // console.log("로드 실패");
          errorLoadQuiz();
        }
      });
    }
  }
  //----------------solve > 퀴즈 주제id 로 풀기 (회원만 가능)---------------
  async function loadSolveDataWithTopicId(params) {
    if (!isSuccessQuiz) {
      await loadSolveQuizTopic(params).then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        /** 제공할 문제 있으면, 퀴즈 세팅 */
        if (res["resultQuiz"] !== undefined) {
          successLoadQuiz(res);
        } else {
          /** 로드 실패 시, 뒤로가기 */
          // console.log("로드 실패");
          errorLoadQuiz();
        }
      });
    }
  }

  //----------------랜덤으로 풀기----------------------
  // 회원
  async function loadData() {
    if (!isSuccessQuiz) {
      await loadQuiz().then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        if (res["resultQuiz"] !== undefined) {
          successLoadQuiz(res);
        } else {
          /** 제공할 문제 없으면, 메인으로 */
          // console.log("퀴즈 없음");
          errorLoadQuiz();
        }
      });
    }
  }
  // 익명
  async function loadDataGuest() {
    if (!isSuccessQuiz) {
      await loadQuizGuest().then((res) => {
        // console.log("로드된 퀴즈 데이터 값은?", res);
        setIsSuccessQuiz(true);
        successLoadQuiz(res);
      });
    }
  }

  // 제공할 문제 로드 성공
  function successLoadQuiz(res) {
    setIsSuccessQuiz(true);
    handleLoadData({ id: "resultQuiz", value: res.resultQuiz });
  }

  // 제공할 문제 없어서 로드 실패 시
  function errorLoadQuiz() {
    handleInput({ id: "severity", value: "warning" });
    handleInput({
      id: "message",
      value: "제공한 문제들을 다 풀었습니다.",
    });
    setIsSuccessQuiz(false);
    handleOpen(); // snackbar open
    backPage(); // 뒤로가기
  }
  //===================(로그인 상태 인지 확인 후) API 요청을 위한 준비=========================
  async function prepareRequestApi() {
    let isSuccess = false;
    /** 리퀘스트 헤더에 토큰 담기 */
    await handleRequestHeader().then((res) => {
      if (res) isSuccess = true;
    });
    return isSuccess;
  }

  React.useEffect(() => {
    let l;
    /** 퀴즈 id 있을 때 (로그인, 익명 둘 다 가능) */
    if (quizId !== null) {
      // console.log("퀴즈 id 있을 때");
      l = loadDataWithId();
      return;
    }

    /** 현재 로그인 상태라면 */
    if (isUserLogin()) {
      /** API 요청 준비 */
      if (!prepareRequestApi()) return;

      /** 주제 id 있을 때 */
      if (topicId !== null) {
        let params = {
          topicId: topicId,
        };
        /** main */
        if (historyId === null) {
          // console.log("main 주제 id 있을 때");
          l = loadDataWithTopicId(params);
        } else if (historyId === URL.WRONG_ID) {
          /** wrong */
          // console.log("wrong 주제 id 있을 때");
          l = loadWrongDataWithTopicId(params);
        } else if (historyId === URL.SOLVE_ID) {
          /** solve */
          // console.log("solve 주제 id 있을 때");
          l = loadSolveDataWithTopicId(params);
        }
        return;
      }
      /** 퀴즈 id, 주제 id 없으면, 랜덤으로 풀기 */
      /** main */
      if (historyId === null) {
        // console.log("로그인 퀴즈 id, 주제 id 없으면, 랜덤으로 풀기");
        l = loadData();
      } else if (historyId === URL.WRONG_ID) {
        /** wrong */
        // console.log("wrong 랜덤으로 풀기");
        l = loadWrongData();
      } else if (historyId === URL.SOLVE_ID) {
        /** solve */
        // console.log("solve 랜덤으로 풀기");
        l = loadSolveData();
      }
    } else {
      /** 익명이라면 */
      /** 주제 id 있을 때 */
      if (topicId !== null) {
        let params = {
          topicId: topicId,
        };
        /** main */
        if (historyId === null) {
          l = loadDataWithTopicIdGuest(params);
        }
        return;
      }
      /** 퀴즈 id, 주제 id 없으면, 랜덤으로 풀기 */
      // console.log("익명 퀴즈 id, 주제 id 없으면, 랜덤으로 풀기");
      l = loadDataGuest();
    }
  }, [isSuccessQuiz]);

  //----------------------상태 관리-----------------------

  /** 현재 사용자가 선택한 선택지의 정답 여부 */
  const [isCorrect, setIsCorrect] = React.useState(false);
  /** 제출 되었는지(확인 눌렀는지) 여부 */
  const [isSubmit, setIsSubmit] = React.useState(false);

  /** OX : 사용자가 선택한 선택지 활성화 */
  const [selectedOX, setSelectedOX] = React.useState("");
  /** OX : 선택한 선택지를 활성화 */
  function handleOXItemClick(event, ox) {
    setSelectedOX(ox);
  }
  /** OX : 사용자가 선택한 선택지가 정답인지 확인하는 함수(선택할 때마다 정답 여부가 갱신되는 것을 방지하고자 함수화를 따로 함) */
  function handleOXIsCorrect(ox) {
    const isCheck = resultQuiz.answer === ox;
    setIsCorrect(isCheck);
  }

  /** 객관식 : 사용자가 선택한 선택지 활성화 */
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  /** 객관식 : 선택한 선택지를 활성화 & 정답 여부 갱신 */
  function handleListItemClick(event, index) {
    setSelectedIndex(index);
  }
  /** 객관식 : 사용자가 선택한 선택지가 정답인지 확인하는 함수(선택할 때마다 정답 여부가 갱신되는 것을 방지하고자 함수화를 따로 함) */
  function handleListIsCorrect(check) {
    setIsCorrect(check);
  }

  /** 퍼즐 : 랜덤 단어 */
  const [randomWord, setRandomWord] = React.useState([]);
  /** 퍼즐 : 랜덤 단어 인덱스(선택 안 된 단어의 인덱스) */
  const [randomWordIndex, setRandomWordIndex] = React.useState([]);
  /** 퍼즐 : 랜덤 단어 초기 세팅 */
  React.useEffect(() => {
    if (isSuccessQuiz) {
      if (resultQuiz.answerText === undefined) return;
      setRandomWord([...makeRandomWord(resultQuiz.answerText)]);
    }
  }, [isSuccessQuiz]);
  /** 퍼즐 : 랜덤 단어 인덱스 초기 세팅 */
  React.useEffect(() => {
    setRandomWordIndex(Array.from(Array(randomWord.length).keys()));
  }, [randomWord]);
  /** 퍼즐 : 선택한 단어 인덱스 (선택한 단어에 참조하기 위해선 예를 들어, randomWord[selectedWord[0]]) */
  const [selectedWord, setSelectedWord] = React.useState([]);
  /** 퍼즐 : 선택한 단어들은 위에서 보여주고, 아래에서 안보여주기 위함 (중복 단어 구분을 위해 index로 구분) */
  function handleSelectedWordInsert(event, index) {
    if (!isSuccessQuiz) return;
    /** 선택된 것들에 추가 */
    setSelectedWord([...selectedWord, index]);
    /** 선택 안 된 것들에서 삭제 */
    setRandomWordIndex(randomWordIndex.filter((element) => element !== index));
  }
  /** 퍼즐 : 선택 안 한 단어들은 아래에서 보여주고, 위에서 안보여주기 위함 (중복 단어 구분을 위해 index로 구분) */
  function handleSelectedWordErase(event, selected, index) {
    if (!isSuccessQuiz) return;
    /** 선택 안 된 것들에서 삭제 */
    setSelectedWord(selectedWord.filter((element) => element !== selected));
    /** 선택 안 된 것들에 추가 */
    setRandomWordIndex([...randomWordIndex, selected]);
  }
  /**
   * 퍼즐 : 현재 선택된 단어의 인덱스(selectedWord)는 map 순환을 위해서 Array 타입이기 때문에
   * Array에 있는 단어의 인덱스들을 랜덤 단어에 참조해서 string으로 변환해주는 함수
   *
   * ex)
   * randomWord = ['률', '나', '다', '법', '마']
   * selectedWord = [3, 0]
   * => "법률" 로 변환
   *
   * @param {Array} arr : string으로 변환하고 싶은 단어의 인덱스 Array
   */
  function handleMakeResultWord(arr) {
    let result = "";
    for (let i = 0; i < arr.length; i++) result += randomWord[arr[i]];
    return result;
  }
  /**
   * 퍼즐 : 현재 선택된 단어가 정답인지 확인하는 함수
   * @param {string} text : 정답인지 확인하고 싶은 string
   */
  function handlePuzzleIsCorrect(text) {
    const isCheck = resultQuiz.answerText === text;
    setIsCorrect(isCheck);
  }

  /** 해설 나왔을 때, 스크롤이 자동으로 내려가기 위한 ref */
  const commentary = useMoveScroll();

  //========================== 버튼 ========================================================
  /** 퀴즈 시작 전 레벨로 초기화 해놓고, 갱신되었을 때 레벨 다르면 이벤트 */
  const [quizBeforeLevel, setQuizBeforeLevel] = React.useState(
    Number(localStorage.getItem("login-level"))
  );
  // console.log("quizBeforeLevel", quizBeforeLevel);
  // console.log("userLevel", userLevel);

  async function updateUserLevel() {
    if (userIdToken !== "Guest" && userLevel !== 0) {
      // console.log("현재 레벨은??", userLevel);
      /**
       * 레벨 체크
       * 1. 로컬 스토리지에 없으면, login-level로 level 새로 등록, 리턴
       * 2. 로컬 스토리지에 있으면, 레벨 비교
       * 3. 레벨 같으면, 리턴
       * 4. 레벨 다르면, 이벤트 뿌리고 갱신
       */
      const userLevelId = "login-level";
      const currentUserLevel = Number(await localStorage.getItem(userLevelId));

      if (currentUserLevel === null) {
        await localStorage.setItem(userLevelId, userLevel);
        return;
      }
      // console.log(" 같은가?? ", currentUserLevel === userLevel);
      if (currentUserLevel === userLevel) return;

      await handleLevelUpEventOpen();
      await localStorage.setItem(userLevelId, userLevel);
      await setQuizBeforeLevel(userLevel);
    }
  }

  /** 확인 버튼 : 제출된 것으로 갱신 , server에 유저 풀이 이력 보냄 */
  function handleIsSubmit(event) {
    event.preventDefault();
    setIsSubmit(true);
    handleRecordPlayUser();
  }

  /** 건너뛰기 || 다음 문제 */
  function handleQuizNext(historyId, topicId) {
    // console.log(quizBeforeLevel, userLevel);
    if (quizBeforeLevel !== userLevel && userLevel !== 0) {
      updateUserLevel(); // 레벨 이벤트 보여주기
    } else if (userAchievementIdList.length !== 0) handleAchievementEventOpen();
    /** 이벤트 있으면 이벤트 보여주기 */
    /** 아니면 다음 문제 혹은 건너뛰기 */ else if (
      quizBeforeLevel === userLevel &&
      userAchievementIdList.length === 0
    )
      quizNext(historyId, topicId);
  }
  /** 돌아가기 */
  function handleBackPage() {
    if (quizBeforeLevel !== userLevel && userLevel !== 0) {
      updateUserLevel(); // 레벨 이벤트 보여주기
    } else if (userAchievementIdList.length !== 0) handleAchievementEventOpen();
    /** 이벤트 있으면 이벤트 보여주기 */
    /** 아니면 다음 문제 혹은 건너뛰기 */ else if (
      quizBeforeLevel === userLevel &&
      userAchievementIdList.length === 0
    )
      backPage();
  }

  //=================================================================================

  /** 확인 버튼 누른 후, 해설 나왔을 때 스크롤 자동으로 내려감 */
  React.useEffect(() => {
    if (!isSubmit) return;
    commentary.onMoveToElement();
  }, [commentary, isSubmit]);

  /** 풀이 시작 시간 */
  const [playStartTime, setPlayStartTime] = React.useState(new Date());

  /** 다음 문제로 넘어가도록, 강제 리렌더링할 수 있는 상태인지(사용자 풀이 이력 서버에 잘 갔는지 여부) */
  const [nextSuccess, setNextSuccess] = React.useState(false);

  /** 획득한 XP */
  const [upXp, setUpXp] = React.useState(0);

  /** 익명 ) 정답 여부에 따라 익명에게 10 혹은 0을 주기 위해, isCorrect가 바뀔 때마다 useEffect로 다시 렌더 */
  React.useEffect(() => {
    const l = handleRecordPlayUser();
  }, [isSubmit, isCorrect]);

  /** 확인 버튼 누른 후, 사용자 풀이 이력을 server에 보내는 함수 */
  async function handleRecordPlayUser() {
    const loginToken = await localStorage.getItem("login-token");

    /** =========== 익명인 경우 : 그냥 XP만 올려주기(문제 풀이 이력 안 보냄) ========== */
    if (loginToken === null) {
      await updateRecordUserDailyMission(userIdToken, isCorrect ? 10 : 0);
      await setUpXp(isCorrect ? 10 : 0);
      await setNextSuccess(true);
      return;
    }

    /** =========== 회원인 경우 : 문제 풀이 이력 보내는 API 요청 ========== */

    /** 풀이 끝난 시간 */
    const playEndTime = new Date();
    /** 풀이 시간 (float 초 단위로 셋째자리까지) */
    const spandTime = (playEndTime.getTime() - playStartTime.getTime()) / 1000;

    /** 객관식인 경우 빈 문자열 */
    let userInputText = "";
    /** 퍼즐인 경우 */
    if (resultQuiz.quizType === "PUZZLE") {
      /** 사용자가 선택한 퍼즐 답 */
      userInputText = handleMakeResultWord(selectedWord);
    } else if (resultQuiz.quizType === "OX") {
      /** OX 인 경우*/
      /** 사용자가 선택한 OX 답 */
      userInputText = selectedOX;
    }

    // 서버에 보낼 정보 => body
    let body = {
      quizId: resultQuiz.quizId,
      /** 2019-08-24 14:15:22 형태 */
      playStartTime: playStartTime
        .toISOString()
        .replace("T", " ")
        .replace(/\..*/, ""),
      playEndTime: playEndTime
        .toISOString()
        .replace("T", " ")
        .replace(/\..*/, ""),
      spandTime: spandTime,
      /**
       * selectedExampleID
       * - 객관식 : 선택된 보기에 ID
       * - OX, 퍼즐 : -1
       */
      selectExampleId: selectedIndex,
      /**
       * userInputText
       * OX : 사용자가 선택한 O또는 X
       * 퍼즐형 : 사용자가 퍼즐형 답안으로 입력한 문자열
       * 객관식 : 빈 문자열
       */
      userInputText: userInputText,
      /** 정답 여부 */
      isAnswer: isCorrect,
    };

    /** 위에까진 isCorrect 수정될 때마다 리렌더, 제출 후엔 서버에 보내기 */
    if (!isSubmit) return;

    // console.log("사용자 이력은?", body);

    let isSuccess = false;
    /** 로그인 상태 login-token */
    /**ID 토큰 로컬스토리지에 담기 */
    await handleIdToken("login-token").then((res) => {
      if (res) isSuccess = true;
      else isSuccess = false;
    });

    /** 리퀘스트 헤더에 토큰 담기 */
    await handleRequestHeader().then((res) => {
      if (res) isSuccess = true;
      else isSuccess = false;
    });
    /** 위의 과정을 수행 못 했다면, 바로 리턴 */
    if (!isSuccess) return;

    /** server에 요청 */
    await recordPlayUser(body).then((res) => {
      if (res.recordStatus === "Success") {
        // console.log("사용자 정보 서버에 잘 보냄!", res);
        /**
         * "recordStatus": "string",
         * "earnXp": 0,
         * "earnAchievementIdList": [
         *     "string"
         *  ]
         */
        /** 일일 목표 & 업적 리스트 업데이트 */
        updateRecordUserDailyMission(userIdToken, res.earnXp);
        setUpXp(res.earnXp);
        if (res.earnAchievementIdList.length !== 0) {
          handleUserAchievementIdList(res.earnAchievementIdList);
          // handleAchievementEventOpen();
        }
        setNextSuccess(true);
        checkUserStat();
      } else {
        // console.log("사용자 정보 서버에 못 보냄..", res);
        setNextSuccess(false);
      }
    });

    // 애널리틱스) 문제 푸는 사용자 수 - 제출한 시각, 푸는 데 걸린 시간, 접속 사용자 ID 로그
    await logEvent(analytics, "user_quiz_solve", {
      checkin_user_id: userIdToken,
      checkin_time_solve: body.spandTime, // 푸는 데 걸린 시간
      checkin_time_submit: body.playEndTime, // 제출 시각
    });
  }

  return (
    <Quiz
      resultQuiz={resultQuiz}
      isSubmit={isSubmit}
      isCorrect={isCorrect}
      isSuccessQuiz={isSuccessQuiz}
      selectedOX={selectedOX}
      randomWord={randomWord}
      randomWordIndex={randomWordIndex}
      selectedWord={selectedWord}
      selectedIndex={selectedIndex}
      handleOXItemClick={handleOXItemClick}
      handleListItemClick={handleListItemClick}
      handleSelectedWordErase={handleSelectedWordErase}
      handleSelectedWordInsert={handleSelectedWordInsert}
      handleIsSubmit={handleIsSubmit}
      handleMakeResultWord={handleMakeResultWord}
      handleOXIsCorrect={handleOXIsCorrect}
      handleListIsCorrect={handleListIsCorrect}
      handlePuzzleIsCorrect={handlePuzzleIsCorrect}
      nextSuccess={nextSuccess}
      quizNext={() => handleQuizNext(historyId, topicId)}
      onClickQuizAgain={() => quizAgain(historyId, topicId, resultQuiz.quizId)}
      handleBackPage={handleBackPage}
      commentary={commentary}
      upXp={upXp}
    />
  );
}
export default QuizContainer;
