/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const MAIN_REPRESENT_QUIZ = "main/MAIN_REPRESENT_QUIZ";

/** init State ( 초기 상태 ) */
const initialState = {
  representQuiz: [
    {
      topicId: 0,
      topicName: "",
      quizList: [
        {
          quizId: 0,
          questionText: "",
          quizTopics: [
            {
              topicId: 0,
              topicName: "",
            },
          ],
          playUserCount: 0,
          descriptionImages: "",
          answerRate: 0,
        },
      ],
    },
  ],
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onLoadData = ({ id, value }) => ({
  type: id === "representQuiz" && MAIN_REPRESENT_QUIZ,
  [id]: value,
});

/** reducer정의 */
export default function main(state = initialState, action) {
  switch (action.type) {
    case MAIN_REPRESENT_QUIZ:
      return {
        ...state,
        representQuiz: action.representQuiz,
      };
    default:
      return state;
  }
}
