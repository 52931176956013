import { Box, Button, Paper, Tooltip } from "@mui/material";
import { SubHeader } from "../../components/atom-components/StyledHeaders";
import BreadCrumbsTitle from "../../components/global-components/BreadCrumbsTitle";
import PageTitle from "../../components/global-components/PageTitle";
import { FlexBox, FlexColumnBox } from "../../components/layout";
import { BorderLinearProgress } from "../../components/atom-components/StyledProgressBars";
import PageScreenBox from "../../components/global-components/PageScreenBox";
import { ID_INPUT_REFERENCE_TYPE } from "../../constants/writeReference";
import WriteTab from "../../components/write/WriteTab";

/**
 * Write Reference page
 * @returns
 */
function WriteReference({
  onClickSubmitReference,
  isCanSubmit,
  writeProgressValue,
  inputReferenceType,
  onChangeWriteReferenceTabs,
  tabReferenceTypeData,
  onClickBackPage,
  writeReferencePageTitle,
  isEditMode,
  isLoading,
}) {
  return (
    <>
      <SubHeader>
        <FlexColumnBox
          sx={{
            width: 1,
            maxWidth: 1128,
            gap: "0.4rem",
            p: "1.6rem 0.75rem 0.8rem",
          }}
        >
          <BreadCrumbsTitle />
          <FlexBox
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <PageTitle
              titleName={`${writeReferencePageTitle} 하기`}
              pageBack={true}
              onClick={onClickBackPage}
            />
            <FlexBox gap="1.2rem" sx={{ alignItems: "center" }}>
              <Tooltip title={`${writeReferencePageTitle} 완료`}>
                <div>
                  <Button
                    variant="contained"
                    onClick={onClickSubmitReference}
                    disabled={!isCanSubmit()}
                  >
                    {`${writeReferencePageTitle} 완료`}
                  </Button>
                </div>
              </Tooltip>
            </FlexBox>
          </FlexBox>
        </FlexColumnBox>
        <Box sx={{ width: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={writeProgressValue}
          />
        </Box>
      </SubHeader>
      <PageScreenBox sx={{ pt: "20rem", pb: "10rem" }}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "primary.tonal",
            borderRadius: "16px",
            p: "2.4rem 4.8rem",
          }}
        >
          <FlexColumnBox gap="1.6rem">
            {isLoading ? (
              <></>
            ) : (
              <WriteTab
                id={ID_INPUT_REFERENCE_TYPE}
                subTitleName="유형"
                subTitleBody={`${
                  isEditMode
                    ? "관련자료 유형은 수정할 수 없습니다."
                    : "관련자료 유형을 선택해주세요."
                }`}
                inputTab={inputReferenceType}
                onChangeInputTab={onChangeWriteReferenceTabs}
                tabData={tabReferenceTypeData}
                disabled={isEditMode}
              />
            )}
            {isLoading ? (
              <></>
            ) : (
              tabReferenceTypeData.map((data) => {
                const { label, value, content } = data;

                return (
                  <FlexColumnBox
                    key={`reference-type-content-id-${label}`}
                    sx={{
                      display: inputReferenceType === value ? "block" : "none",
                    }}
                  >
                    {content}
                  </FlexColumnBox>
                );
              })
            )}
          </FlexColumnBox>
        </Paper>
      </PageScreenBox>
    </>
  );
}
export default WriteReference;
