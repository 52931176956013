import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 사용자 이미지 업데이트
 * @param {object} dataToSubmit : 서버에 보낼 정보
 */
async function postProfileImage(dataToSubmit = {}) {
  const formData = new FormData(); // 서버에 보내기 위한 form

  if (dataToSubmit.profileImage !== null) {
    let values = dataToSubmit.profileImage.values();
    for (const pair of values) {
      formData.append("profile_image", pair);
    }
  }

  let data;
  try {
    await axios
      .post(APIURL.api + "user/profile/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // 파일 등록을 위함
        },
      })
      .then((response) => {
        //console.log("등록 완 : 이미지 업데이트 ID", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    //console.log("업데이트 실패...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default postProfileImage;
