import { Box } from "@mui/material";
import { FlexColumnBox } from "../components/layout";
import Result from "../components/quiz/Result";
import QuizDescription from "../components/quiz/QuizDescription";
import Answer from "../components/quiz/Answer";
import Example from "../components/quiz/Example";
import Puzzle from "../components/quiz/Puzzle";
import QuizInfo from "../components/quiz/QuizInfo";
import Footer from "../components/quiz/Footer";
import MyLoading from "../components/global-components/MyLoading";
import QuizScreenBox from "../components/global-components/QuizScreenBox";

/**
 * 퀴즈 페이지
 */
function Quiz({
  resultQuiz,
  isSubmit,
  isCorrect,
  isSuccessQuiz,
  selectedOX,
  randomWord,
  randomWordIndex,
  selectedWord,
  selectedIndex,
  handleOXItemClick,
  handleListItemClick,
  handleSelectedWordErase,
  handleSelectedWordInsert,
  handleIsSubmit,
  handleMakeResultWord,
  handleOXIsCorrect,
  handleListIsCorrect,
  handlePuzzleIsCorrect,
  nextSuccess,
  onClickQuizAgain,
  quizNext,
  commentary,
  upXp,
  handleBackPage,
  ////////////////////////////
  isPreview = false, // 미리보기
}) {
  // console.log(resultQuiz);
  return (
    <>
      {!isSuccessQuiz && <MyLoading />}
      {isSuccessQuiz && (
        <>
          <QuizScreenBox isSubmit={isSubmit}>
            <FlexColumnBox
              /** 퀴즈 문제 + 예문 + 정답 선택지 감싸는 레이아웃 */
              sx={{
                gap: (theme) => theme.spacing(6),
                width: 1,
              }}
            >
              <QuizInfo /** 퀴즈에 대한 정보 */ resultQuiz={resultQuiz} />
              <Box>
                <QuizDescription
                  /** 퀴즈 예문 */
                  resultQuiz={resultQuiz}
                />
              </Box>
              {resultQuiz.quizType === "OX" && (
                /** OX */
                // DB엔 "O"&"X" | 보낼 때만 true & false
                // 항상 "O"&"X"로 관리하기 !!!
                <Answer
                  isSubmit={isSubmit}
                  selectedOX={selectedOX}
                  handleOXItemClick={handleOXItemClick}
                />
              )}
              {resultQuiz.quizType === "MULTIPLE" && (
                /** 다지선다 */
                // DB 보낼 때만 exampleId 삭제
                // 항상 exampleId 있음 !!!
                <Example
                  isSubmit={isSubmit}
                  resultQuiz={resultQuiz}
                  selectedIndex={selectedIndex}
                  handleListItemClick={handleListItemClick}
                />
              )}
              {resultQuiz.quizType === "PUZZLE" && (
                /** 퍼즐 */
                <Puzzle
                  isSubmit={isSubmit}
                  resultQuiz={resultQuiz}
                  randomWord={randomWord}
                  randomWordIndex={randomWordIndex}
                  selectedWord={selectedWord}
                  handleSelectedWordErase={handleSelectedWordErase}
                  handleSelectedWordInsert={handleSelectedWordInsert}
                />
              )}
            </FlexColumnBox>
            <Footer
              /** footer : 메인으로 가기 & 확인... */
              isSubmit={isSubmit}
              resultQuiz={resultQuiz}
              selectedOX={selectedOX}
              selectedIndex={selectedIndex}
              selectedWord={selectedWord}
              handleIsSubmit={handleIsSubmit}
              handleMakeResultWord={handleMakeResultWord}
              handleOXIsCorrect={handleOXIsCorrect}
              handleListIsCorrect={handleListIsCorrect}
              handlePuzzleIsCorrect={handlePuzzleIsCorrect}
              nextSuccess={nextSuccess}
              onClickQuizAgain={onClickQuizAgain}
              quizNext={quizNext}
              isCorrect={isCorrect}
              upXp={upXp}
              handleBackPage={handleBackPage}
              isPreview={isPreview}
            />
          </QuizScreenBox>
          {
            /** 제출했을 경우 */
            isSubmit && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                }}
                ref={commentary.element}
              >
                <Result
                  /** 정답 여부를 보내주며 결과와 해설창 보여줌 */
                  resultQuiz={resultQuiz}
                  isCorrect={isCorrect}
                />
              </Box>
            )
          }
        </>
      )}
    </>
  );
}
export default Quiz;
