import { TonalTab, TonalTabs } from "../atom-components/StyledTabs";
import SubTitle from "../global-components/SubTitle";
import { FlexColumnBox } from "../layout";

/**
 *
 * @param {*} param0
 * tabData | * require field : label, value
 */
function WriteTab({
  id,
  subTitleName,
  subTitleBody,
  inputTab,
  onChangeInputTab,
  tabData,
  disabled,
}) {
  return (
    <FlexColumnBox>
      <SubTitle titleName={subTitleName} body={subTitleBody} />
      <TonalTabs value={inputTab} onChange={onChangeInputTab}>
        {tabData.map((data) => {
          const { label, value } = data;

          return (
            <TonalTab
              id={id}
              key={`${id}-${label}`}
              label={label}
              value={value}
              disabled={disabled}
            />
          );
        })}
      </TonalTabs>
    </FlexColumnBox>
  );
}
export default WriteTab;
