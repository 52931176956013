import { useEffect, useState } from "react";
import useMySnackbar from "../../hooks/useMySnackbar";
import usePage from "../../hooks/usePage";
import DetailReference from "../../pages/reference/DetailReference";
import getUrlParam from "../../utils/getUrlParam";
import {
  handleDeleteReferenceWrite,
  handleGetReferenceWrite,
} from "../../utils/actions/handleUserReference";

function DetailReferenceContainer() {
  const {
    loginPage,
    referencePage,
    referenceUpdatePage,
    referencePageRefresh,
  } = usePage();
  const { openSnackbarHere } = useMySnackbar();

  /**
   * 현재 url에서 저작한 관련자료 id 뽑아 내기
   */
  const { createId } = getUrlParam();

  const [reference, setReference] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  //--------------저작한 관련자료 id를 통해 풀기----------------
  async function loadDataWithId() {
    await setIsLoading(true);

    // get login token
    const loginToken = await localStorage.getItem("login-token");
    if (loginToken === null) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
      return;
    }

    /** get param */
    let params = {
      referenceId: createId,
    };
    const response = await handleGetReferenceWrite(loginToken, params);
    await setReference(response);
    await setIsLoading(false);
  }

  //==========================관련자료 삭제 버튼===========================
  const [warningModal, setWarningModal] = useState(false); // 삭제 재확인 모달창
  function onCLickDeleteReference() {
    setWarningModal(true);
  }
  // 취소 버튼
  function onClickCancle() {
    setWarningModal(false);
  }
  // 관련자료 삭제
  async function onCLickCheckDeleteReference() {
    let param = { referenceId: createId };

    // get login token
    const loginToken = await localStorage.getItem("login-token");
    if (loginToken === null) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
      return;
    }

    const response = await handleDeleteReferenceWrite(loginToken, param);
    if (!!response) {
      //   console.log("관련자료 삭제 성공!");
      referencePage(); // 저작 메인 페이지로(삭제 된 후에 가야하니까 새로고침)
      openSnackbarHere("success", "해당 관련자료가 삭제되었습니다.");
    } else {
      openSnackbarHere(
        "error",
        "해당 관련자료를 삭제하는 데 실패했습니다. 다시 시도해주세요."
      );
    }
  }

  function onClickUpdateReference() {
    referenceUpdatePage(createId);
  }

  useEffect(() => {
    const l = loadDataWithId();
  }, []);

  return (
    <>
      {/* {!isLoading && <MyLoading />}
      {isLoading && ( */}
      <DetailReference
        referencePageRefresh={referencePageRefresh}
        onClickUpdateReference={onClickUpdateReference}
        onCLickDeleteReference={onCLickDeleteReference}
        onClickCancle={onClickCancle}
        onCLickCheckDeleteReference={onCLickCheckDeleteReference}
        isLoading={isLoading}
        reference={reference}
        warningModal={warningModal}
      />
      {/* )} */}
    </>
  );
}
export default DetailReferenceContainer;
