import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

/** Level Progress Bar */
const LevelBorderLinearProgress = styled(LinearProgress)(
  ({ theme, barcolor, usercurrpercent }) => ({
    borderRadius: "100rem",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[300],
      boxShadow: "0 1px 2px #999 inset, 0 1px #ffffff",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "100rem",
      backgroundColor: barcolor.light,
      boxShadow: `0px -3px 0px 9px ${barcolor.bar} inset`,
      /** xp에 따라 얘를 조절하기 (일일 목표XP가 30 이라면, 분자 분모에 각각 100/30 곱해서 100분율로 맞추기) */
      width: usercurrpercent >= 100 ? "100%" : `${usercurrpercent}%`,
    },
  })
);

/**
 * 레벨 바 & 일일 목표 바
 * @param {*} widthTheme : width 길이 (기본값은 100%)
 * @param {*} heightTheme : height 길이 (기본값은 "2.4rem")
 * @param {*} upXp : 획득 XP (퀴즈 정답일 때만 쓰임 -> 기본값 null)
 * @param {*} isLevel : 레벨 바인지 여부 (기본값 false : 일일 목표, true : 레벨)
 * @param {*} isProfile : 프로필인지 여부 (기본값 false : 일일 목표, 이벤트, true : 프로필 페이지)
 */
function LevelProgressBar({
  widthTheme = "100%",
  heightTheme = "2.4rem",
  upXp = null,
  isLevel = false,
  isProfile = false,
}) {
  const { userIdToken, userLevel, userLevelXp } = useSelector((state) => ({
    userIdToken: state.auth.userIdToken,
    userLevel: state.auth.userLevel, // 레벨
    userLevelXp: state.auth.userLevelXp, // 현재 레벨 XP
  }));

  /** =========================== (기본값) ================================== */
  const [barcolor, setBarcolor] = React.useState({
    bar: "#76D22D",
    light: "#C8F2A7",
  }); //bar 색
  const [userCurrXp, setUserCurrXp] = React.useState(0); // => 실제 XP 값
  const [usercurrpercent, setUsercurrpercent] = React.useState(0); // => XP 를 퍼센테이지
  const [barWithXp, setBarWithXp] = React.useState(
    <>
      <Grid item xs>
        <LevelBorderLinearProgress
          variant="determinate"
          value={100}
          sx={{ height: heightTheme }}
          barcolor={barcolor}
          usercurrpercent={usercurrpercent}
        />
      </Grid>
      <Grid
        item
        sx={{
          ml: "0.8rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            color: "text.secondary",
          }}
        >
          <Typography variant="subtitle2">{0} / 30 XP</Typography>
        </Box>
      </Grid>
    </>
  );
  // const [userGoal, setUserGoal] = React.useState({ currentXp: -1 });

  // React.useEffect(() => {

  // }, [usercurrpercent]);

  React.useEffect(() => {
    /** 일일 목표 */
    if (!isLevel) {
      if (upXp !== null && upXp === 0) return; // 기본 점수는 분명히 있기 때문에

      /** 현재 일일 목표 XP */
      const userGoal = JSON.parse(localStorage.getItem(userIdToken)); // 현재 존재하는 user의 XP가져오기

      if (userGoal === null) return;

      setUserCurrXp(userGoal.currentXp);
      setUsercurrpercent(Math.ceil(userCurrXp * (100 / 30)));
      // /** 획득한 XP가 있는 경우 */
      // if (upXp !== null && userCurrXp !== upXp + userGoal.currentXp)
      //   setUserGoal(JSON.parse(localStorage.getItem(userIdToken)));

      // console.log(userGoal.currentXp);
      // console.log(upXp);

      setBarcolor({
        bar: "#76D22D",
        light: "#C8F2A7",
      }); // 초록색

      setBarWithXp(
        <>
          <Grid item xs>
            <LevelBorderLinearProgress
              variant="determinate"
              value={100}
              sx={{ height: heightTheme }}
              barcolor={barcolor}
              usercurrpercent={usercurrpercent}
            />
          </Grid>
          <Grid
            item
            sx={{
              ml: "0.8rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                color: "text.secondary",
              }}
            >
              <Typography variant="subtitle2">{userCurrXp} / 30 XP</Typography>
            </Box>
          </Grid>
        </>
      );
    } else {
      /** Level Progress Bar */
      // const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      //   borderRadius: "100rem",
      //   [`&.${linearProgressClasses.colorPrimary}`]: {
      //     backgroundColor: theme.palette.grey[300],
      //     boxShadow: "0 1px 2px #999 inset, 0 1px #ffffff",
      //   },
      //   [`& .${linearProgressClasses.bar}`]: {
      //     borderRadius: "100rem",
      //     backgroundColor: barcolor.light,
      //     boxShadow: `0px -3px 0px 9px ${barcolor.bar} inset`,
      //     /** xp에 따라 얘를 조절하기 (일일 목표XP가 30 이라면, 분자 분모에 각각 100/30 곱해서 100분율로 맞추기) */
      //     width: usercurrpercent >= 100 ? "100%" : `${usercurrpercent}%`,
      //   },
      // }));
      /** 레벨바 1레벨 당 50XP 씩 올림 */
      const LEVEL_XP = 50;
      setBarcolor({ bar: "#fdd835", light: "#fff176" }); // 노란색
      setUserCurrXp(userLevelXp);
      setUsercurrpercent(
        Math.ceil(userCurrXp * (100 / (userLevel * LEVEL_XP)))
      );

      /** 프로필인 경우 */
      if (isProfile) {
        setBarWithXp(
          <>
            <Grid
              container
              sx={{
                alignItems: "center",
              }}
            >
              <Grid item xs>
                <Typography variant="h6">레벨 {userLevel}</Typography>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    color: "text.secondary",
                  }}
                >
                  <Typography variant="subtitle1">
                    {userCurrXp} / {userLevel * LEVEL_XP} XP
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: "1.5rem" }}>
              <Grid item xs>
                <LevelBorderLinearProgress
                  variant="determinate"
                  value={100}
                  sx={{ height: heightTheme }}
                  barcolor={barcolor}
                  usercurrpercent={usercurrpercent}
                />
              </Grid>
            </Grid>
          </>
        );
      } else {
        setBarWithXp(
          <>
            <Grid
              container
              sx={{
                margin: {
                  xs: "3rem 5rem 2rem",
                  sm: "3rem 12rem 2rem",
                  md: "3rem 20rem 2rem",
                },
                alignItems: "center",
              }}
            >
              <Grid item xs>
                <Typography variant="h6">레벨 {userLevel}</Typography>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    color: "text.secondary",
                  }}
                >
                  <Typography variant="h6">
                    {userCurrXp} / {userLevel * LEVEL_XP} XP
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{ marginX: { xs: "5rem", sm: "12rem", md: "20rem" } }}
            >
              <Grid item xs>
                <LevelBorderLinearProgress
                  variant="determinate"
                  value={100}
                  sx={{ height: heightTheme }}
                  barcolor={barcolor}
                  usercurrpercent={usercurrpercent}
                />
              </Grid>
            </Grid>
          </>
        );
      }
    }
    // setUsercurrpercent(Math.ceil(userCurrXp * (100 / 30))); // => XP 를 퍼센테이지
  }, [upXp, userCurrXp, usercurrpercent, userIdToken]);

  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        width: widthTheme,
      }}
    >
      {upXp !== null && (
        <Grid
          item
          sx={{
            mr: "0.8rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              color: "success.light",
            }}
          >
            <Typography variant="subtitle2">+ {upXp} XP</Typography>
          </Box>
        </Grid>
      )}
      {barWithXp}
    </Grid>
  );
}
export default LevelProgressBar;
