import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 저작 권한 요청 post
 * @param {object} dataToSubmit : 서버에 보낼 정보
 */
async function postWriteAccount(dataToSubmit = {}) {
  let data;

  try {
    await axios
      .post(APIURL.api + "account/request/write", dataToSubmit)
      .then((response) => {
        // console.log("요청 완료!", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    switch (error.response.status) {
      case 461: // 현재 검토중인 요청이 존재함
        // console.log(error);
        return error.response.data;
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default postWriteAccount;
