import axios from "axios";
import APIURL from "../../apiUrls";

/**
 * 이미지 url 반환 api
 * @param {object} dataToSubmit : 서버에 보낼 정보
 * @param {array} exampleImageBody : 서버에 보낼 보기 이미지 정보
 */
async function postQuizWriteImageUpload(userId, dataToSubmit = {}) {
  const { image } = dataToSubmit;

  const formData = new FormData(); // 서버에 보내기 위한 form
  formData.append("image", image);

  try {
    const response = await axios.post(
      APIURL.api + `quiz/write/image/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${userId}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return false;
    // switch (error.response.status) {
    //   case 502:
    //     window.location.href = URL.ERROR;
    //     return;
    //   case 503:
    //     window.location.href = URL.ERROR;
    //     return;
    //   default:
    //     return;
    // }
  }
}

export default postQuizWriteImageUpload;
