import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { onInitUser } from "../modules/auth";
import Header from "../components/global-components/Header";
import { logout } from "../utils/logout";

export default function HeaderContainer() {
  /** Redux State */
  const dispatch = useDispatch();

  const { loginSuccess } = useSelector((state) => ({
    loginSuccess: state.auth.loginSuccess,
  }));

  /** init user stat */
  const handleInitUserStat = () => {
    dispatch(onInitUser());
  };

  /** 로그아웃 버튼 */
  async function headerLogout() {
    let isSuccess = false;
    await logout().then((res) => {
      if (res) isSuccess = true;
    });
    if (isSuccess) await handleInitUserStat();
  }
  return <Header loginSuccess={loginSuccess} logout={headerLogout} />;
}
