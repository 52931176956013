import { Box, Typography } from "@mui/material";
/**
 * 퀴즈 데이터 : 문제 유형, 풀이수, 정답률, 생성일, 상태 나열
 * 상태 점검중임을 나타내는 isError(기본값 false)
 */
function QuizState({ quizData, isError = false }) {
  const textStyle = isError
    ? { type: "error.main", data: "error.main" }
    : { type: "text.disabled", data: "black" };

  return (
    <Box sx={{ mr: "1.6rem" }}>
      <Typography variant="caption" color="text.disabled">
        {quizData.type}
        <Box component="span" sx={{ display: "inline-block", mx: "0.4rem" }}>
          |
        </Box>
      </Typography>
      <Typography
        variant="caption"
        color={textStyle.data}
        sx={{ fontWeight: "bold" }}
      >
        {quizData.data} {!!quizData.unit && quizData.unit}
      </Typography>
    </Box>
  );
}
export default QuizState;
