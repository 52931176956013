import { DragDropContext, Droppable } from "react-beautiful-dnd";

/**
 * draggable list container
 *
 * 움직일 범위 지정해주기
 *
 * - Droppable의 droppableId 와 provided를 리턴하는 html element의 className 일치시켜주기
 * - provided를 리턴해주는 element는 컴포넌트가 아니라 html element로 작성해주어야 함.
 *
 * - 컴포넌트 이동시 자리 배열이 알맞게 위치하도록 placeholder 함수가 필요하다.
 *   (만약 넣지 않으면, placeholder가 없다는 에러 메세지가 뜬다.)
 */
function DraggableListContainer({ onDragEnd, draggableListItems }) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div
            className="droppable-list"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {draggableListItems}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export { DraggableListContainer };
