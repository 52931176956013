import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 저작한 퀴즈 삭제
 * @param {array} params : 어떤 퀴즈 지울 지 id 담겨있는 인자더미
 */
async function deleteQuizWrite(userId, params = {}) {
  let data;
  try {
    await axios
      .delete(APIURL.api + `quiz/write/${params.quizId}`, {
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      })
      .then((response) => {
        data = response.data;
      });
    return data;
  } catch (error) {
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default deleteQuizWrite;
