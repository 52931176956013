import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 저작한 관련자료 삭제
 * @param {array} params : 어떤 관련자료 지울 지 id 담겨있는 인자더미
 */
async function deleteReferenceWrite(userId, params = {}) {
  try {
    const response = await axios.delete(
      APIURL.api +
        `quiz/write/references?referenceId=${params.referenceId}`,
      {
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default deleteReferenceWrite;
