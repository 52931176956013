import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import React from "react";
import StyledScrollbar from "../../atom-components/StyledScrollbar";
import { FlexColumnBox } from "../../layout";

function ScrollableCard({ subheader, children }) {
  return (
    <Grid item xs={1}>
      <Card>
        <CardHeader subheader={subheader} sx={{ bgcolor: "primary.tonal" }} />
        <CardContent
          sx={{
            p: 2,
            "&:last-child": { pb: 2 },
          }}
        >
          <StyledScrollbar>
            <FlexColumnBox gap={2} sx={{ maxHeight: "640px", width: "100%" }}>
              {children}
            </FlexColumnBox>
          </StyledScrollbar>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default React.memo(ScrollableCard);
