import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Password from "../../components/setting/Password";
import useMySnackbar from "../../hooks/useMySnackbar";
import { setAuthError } from "../../modules/error";
import { onLoadingFalse } from "../../modules/header";
import {
  auth,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "../../service/firebase";

// 비밀번호 변경
function PasswordContainer() {
  /** Redux Dispatch */
  const dispatch = useDispatch();

  const { openSnackbarHere } = useMySnackbar();

  /** Redux State */
  const { authError } = useSelector((state) => ({
    authError: state.error.authError,
  }));

  /** authError : 에러 메세지 */
  const handleAuthError = (error) => dispatch(setAuthError(error));

  // id
  const CURRENT_PASSWORD = "currentPassword";
  const NEW_PASSWORD = "newPassword";
  const NEW_PASSWORD_CONFIRM = "newPasswordConfirm";

  // input 변수
  const [inputs, setInputs] = React.useState({
    currentPassword: "", // 현재 비밀번호
    newPassword: "", // 새 비밀번호
    newPasswordConfirm: "", // 새 비밀번호 확인
  });
  const { currentPassword, newPassword, newPasswordConfirm } = inputs; // 비구조화 할당을 통해 값 추출

  // 비밀번호 입력
  const handlePasswordInput = (event) => {
    const { id, value } = event.target;

    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [id]: value, // name 키를 가진 값을 value 로 설정
    });
  };

  // 로그
  const [currentPasswordLog, setCurrentPasswordLog] = React.useState(false);
  const [newPasswordLog, setNewPasswordLog] = React.useState(false);
  const [newPasswordConfirmLog, setNewPasswordConfirmLog] =
    React.useState(false);

  /** 비밀번호 일치하는지 검사 */
  function validatePassword() {
    let isValid = true;
    if (
      newPassword !== "" &&
      newPasswordConfirm !== "" &&
      newPassword !== newPasswordConfirm
    ) {
      isValid = false;
      handleAuthError("invalid-password");
    }
    /** 일치하지 않을 경우, 비밀번호 재차 확인을 위해 error강조 */
    setNewPasswordConfirmLog(!isValid);
    return isValid;
  }

  /** 비밀번호 폼 제출 함수 */
  async function handleSubmit(event) {
    event.preventDefault();
    setCurrentPasswordLog(false);
    setNewPasswordLog(false);
    setNewPasswordConfirmLog(false);

    let isSuccess = false;

    const user = auth.currentUser;
    // 보안이 민감하기 때문에 최근에 로그인해야 함. 아래와 같은 절차 진행
    // 01. 현재 사용자가 입력한 현재 비밀번호로부터 credential 발급
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    // 02. 발급받은 credential로 재로그인
    // => 예외처리 ) 만약 사용자가 입력한 현재 비밀번호가 틀렸다면? 에러 메세지
    await reauthenticateWithCredential(user, credential)
      .then(() => {
        isSuccess = true;
      })
      .catch((error) => {
        // console.log("error", error);
        isSuccess = false;
        handleAuthError(error.code);
        switch (error.code) {
          case "auth/wrong-password": {
            return setCurrentPasswordLog(true);
          }
        }
      });
    if (!isSuccess) return;

    // 03. 새 비밀번호, 확인 검사
    if (!validatePassword()) return;

    // 04. 비밀번호 변경 요청
    await updatePassword(user, newPassword)
      .then(() => {
        isSuccess = true;
        // console.log("변경 성공");
      })
      .catch((error) => {
        // console.log("실패!", error);
        isSuccess = false;
        handleAuthError(error.code);
        switch (error.code) {
          case "auth/weak-password": {
            return setNewPasswordLog(true);
          }
        }
      });

    // 05. 변경 상태 스낵바
    if (!isSuccess) {
      openSnackbarHere("error", "다시 시도해주세요.");
      return;
    }
    openSnackbarHere("success", "비밀번호 변경이 완료되었습니다.");
  }

  // 변경하기 버튼 활성화
  const [isDisabled, setIsDisabled] = React.useState(true);
  React.useEffect(() => {
    if (
      currentPassword === "" ||
      newPassword === "" ||
      newPasswordConfirm === ""
    )
      setIsDisabled(true);
    else setIsDisabled(false);
  }, [currentPassword, newPassword, newPasswordConfirm]);

  const [isLoading, setIsLoading] = React.useState(true);
  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  React.useEffect(() => {
    setIsLoading(true);
    handleLoadingFalse();
    setIsLoading(false);
  }, []);

  return (
    <Password
      isLoading={isLoading}
      CURRENT_PASSWORD={CURRENT_PASSWORD}
      currentPassword={currentPassword}
      handlePasswordInput={handlePasswordInput}
      currentPasswordLog={currentPasswordLog}
      authError={authError}
      NEW_PASSWORD={NEW_PASSWORD}
      newPassword={newPassword}
      newPasswordLog={newPasswordLog}
      NEW_PASSWORD_CONFIRM={NEW_PASSWORD_CONFIRM}
      newPasswordConfirm={newPasswordConfirm}
      newPasswordConfirmLog={newPasswordConfirmLog}
      handleSubmit={handleSubmit}
      isDisabled={isDisabled}
    />
  );
}
export default PasswordContainer;
