import REFERENCE_INFO from "../constants/referenceInfo";
import timeFormat from "./timeFormat";

function changeReferenceState(referenceData) {
  const { referenceType, referenceCreatedDate } = referenceData;

  let result = [
    {
      type: "관련자료 유형",
      data: REFERENCE_INFO[referenceType]?.title,
    },
    {
      type: "생성일",
      data: timeFormat(referenceCreatedDate, true),
    },
  ];

  return result;
}
export default changeReferenceState;
