import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/App";
import { createGlobalStyle } from "styled-components";
import { ThemeComponent } from "./components/theme";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import rootReducer from "./modules/index";

// rootReducer 를 가진 Store 생성
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const GlobalStyles = createGlobalStyle` 
  html, body {
    padding : 0;
    margin : 0;
    scroll-behavior: smooth;
  }
  html {
    font-size: 62.5%; 
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <GlobalStyles />
      <ThemeComponent component={<App />} />
    </Provider>
  </React.Fragment>
);
