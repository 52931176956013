import * as React from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  Divider,
  Modal,
} from "@mui/material";
import { FlexColumnBox } from "../components/layout";
import ErrorIcon from "@mui/icons-material/Error";
import URL from "../components/pageUrls";
import useSx from "../hooks/useSx";
import PasswordInput from "../components/global-components/PasswordInput";

/**
 * 회원가입 페이지
 */
function SignUp({
  email,
  password,
  confirmPassword,
  nickName,
  authError,
  emailLog,
  passwordLog,
  confirmPasswordLog,
  isChecked,
  handleIsChecked,
  isDisabled,
  isNickNameDisabled,
  isOpenNickNameModal,
  handleOnChangeInput,
  handleSubmit,
  handleNickNameSubmit,
  handleGoogleAuthProvider,
  loginPageRePlace,
}) {
  const { modalStyle } = useSx();

  /** 닉네임 최대 길이 */
  const NICKNAME_LIMIT = 12;

  // //================스타일=================
  // const modalStyle = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: "background.paper",
  //   border: "1px solid #0000000D",
  //   boxShadow: 24,
  //   pt: 2,
  //   px: 4,
  //   pb: 3,
  // };

  return (
    <Container maxWidth="xs" sx={{ pt: "8rem" }}>
      <Card variant="outlined">
        <CardContent>
          <FlexColumnBox
            sx={{
              mt: 8,
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              회원가입
            </Typography>
            <Box
              /** 이메일 & 패스워드 감싸는 레이아웃 */
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                error={emailLog}
                margin="normal"
                required
                fullWidth
                label="이메일"
                value={email}
                onChange={handleOnChangeInput}
                /** <input> 태그의 autocomplete 속성 : 자동 완성 기능 */
                autoComplete="email"
                autoFocus
                id="email"
              />
              {/* 비밀번호 입력 */}
              <PasswordInput
                passwordLog={passwordLog}
                password={password}
                handleOnChangeInput={handleOnChangeInput}
              />
              {/* 비밀번호 입력 */}
              <PasswordInput
                passwordLog={confirmPasswordLog}
                password={confirmPassword}
                handleOnChangeInput={handleOnChangeInput}
                inputId="confirmPassword"
              />
              <Grid container>
                <Grid item>
                  {authError !== "" && (
                    <Typography variant="body2" color="error">
                      <ErrorIcon fontSize="small" /> {authError}
                    </Typography>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleIsChecked}
                        color="primary"
                      />
                    }
                    label="개인정보 수집·이용에 동의합니다."
                  />
                </Grid>
              </Grid>
              <Button
                disabled={isDisabled}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 10 }}
                // onClick={handleSubmit}
              >
                회원가입 하기
              </Button>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" sx={{ mr: 4 }}>
                    계정이 이미 있으신가요?
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    component="button"
                    onClick={loginPageRePlace}
                    variant="body2"
                  >
                    로그인 하기
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </FlexColumnBox>
        </CardContent>
      </Card>
      <Grid container alignItems="center" sx={{ my: 8 }}>
        <Grid item xs>
          <Divider />
        </Grid>
        <Typography variant="body2" sx={{ mx: 8 }}>
          또는
        </Typography>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="outlined"
        sx={{ color: "text.secondary", borderColor: "text.disabled" }}
        onClick={handleGoogleAuthProvider}
      >
        <img src="svgs/btn_google_light_normal_ios.svg" />
        Google 계정으로 가입
      </Button>
      <Modal
        /** 닉네임 작성 */
        open={isOpenNickNameModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={modalStyle}
          component="form"
          onSubmit={handleNickNameSubmit}
          noValidate
        >
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            닉네임
          </Typography>
          <p id="parent-modal-description">
            닉네임을 입력해주세요. (최소 2자, 최대 12자)
          </p>
          <TextField
            margin="normal"
            required
            fullWidth
            label="닉네임"
            value={nickName}
            onChange={handleOnChangeInput}
            /** <input> 태그의 autocomplete 속성 : 자동 완성 기능 */
            autoComplete="name"
            autoFocus
            id="nickName"
            type="text"
            inputProps={{
              maxLength: NICKNAME_LIMIT,
            }}
            helperText={`${nickName.length}/${NICKNAME_LIMIT}`}
          />
          <Button
            disabled={isNickNameDisabled}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 10 }}
          >
            입력 완료
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}
export default SignUp;
