import { Paper, Typography } from "@mui/material";
import SubTitle from "../global-components/SubTitle";
import { DraggableListContainer } from "../atom-components/StyledDraggableLists";
import WriteQuizExampleDraggableListItem from "./WriteQuizExampleDraggableListItem";
import { FlexBox } from "../layout";
import { PopIconButton } from "../atom-components/StyledButtons";
import AddIcon from "@mui/icons-material/Add";

/**
 * 다지선다 입력
 * @param {} param0
 * @returns
 */
function WriteQuizQuestionMultiple({
  exampleItems,
  handleDragEndExampleItems,
  handleClickRemoveExampleItem,
  handleClickSetExampleItemIsAnswer,
  handleChangeExampleItemText,
  handleClickAddExampleItem,
}) {
  return (
    <>
      {exampleItems && (
        <>
          <SubTitle
            titleName="보기"
            body="보기를 2개 이상 5개 이하로 추가하고, 정답을 1개 이상 체크해주세요."
          />
          <Paper
            sx={{
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <DraggableListContainer
              // drag and drop 보기
              onDragEnd={handleDragEndExampleItems}
              draggableListItems={exampleItems.map((item, index) => {
                if (item.exampleId === undefined) return;
                const exampleIdString = item.exampleId.toString();

                return (
                  <WriteQuizExampleDraggableListItem
                    item={item}
                    index={index}
                    key={exampleIdString}
                    keyId={exampleIdString}
                    onClickRemoveItem={handleClickRemoveExampleItem}
                    onClickSetExampleItemIsAnswer={
                      handleClickSetExampleItemIsAnswer
                    }
                    onChangeExampleItemText={handleChangeExampleItemText}
                  />
                );
              })}
            />
            <FlexBox
              gap="0.8rem"
              sx={{
                alignItems: "center",
                p: "1.2rem 2.4rem",
                "&:hover": {
                  backgroundColor: (theme) => theme.palette.action.hover,
                  cursor: "pointer",
                },
              }}
              onClick={handleClickAddExampleItem}
            >
              <PopIconButton>
                <AddIcon />
              </PopIconButton>
              <Typography color="text.secondary">보기 추가</Typography>
            </FlexBox>
          </Paper>
        </>
      )}
    </>
  );
}
export default WriteQuizQuestionMultiple;
