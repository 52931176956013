import axios from "axios";
import URL from "../../../components/pageUrls";
import { logout } from "../../../utils/logout";
import APIURL from "../../apiUrls";

/** 유저의 프로필 데이터 get */
async function getUserProfile() {
  let data;
  try {
    await axios.get(APIURL.api + "user/profile").then((response) => {
      // console.log("stat 받았습니다", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("stat 못 받음...", error);
    logout(); // 바로 로그인 토큰 없애버리고 로그아웃
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getUserProfile;
