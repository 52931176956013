import { Typography } from "@mui/material";
import { FlexColumnBox } from "../layout";

/**
 *
 * @param {*} titleName : 타이틀 이름
 * @param {*} body : 설명
 * @returns
 */
function SubTitle({ titleName, body = "" }) {
  return (
    <FlexColumnBox gap="0.4rem" sx={{ marginBottom: "0.8rem" }}>
      <Typography
        variant="h3"
        color="text.secondary"
        sx={{
          fontWeight: (theme) => theme.typography.fontWeightMedium,
        }}
      >
        {titleName}
      </Typography>
      {!!body && (
        <Typography variant="body2" color="text.disabled">
          {body}
        </Typography>
      )}
    </FlexColumnBox>
  );
}
export default SubTitle;
