import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 틀린 - 주제의 퀴즈 문제들 제공 API
 */
async function loadWrongQuizTopic(params = {}) {
  let data;
  try {
    await axios
      .get(APIURL.api + `quiz/user/wrong/${params.topicId}/play`, params)
      .then((response) => {
        // console.log("틀린 - 주제 퀴즈들 로드 잘 해옴~", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // console.log("로드 문제!!", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      //   case 404:
      /** 풀이할 문제가 없음 */
      case 461:
        return error.response.data;
      default:
        return;
    }
  }
}
export default loadWrongQuizTopic;
