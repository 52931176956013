/**
 * 각 페이지 url
 */
const URL = {
  // 메인 문제 화면
  MAIN: "/main",
  // 틀린 문제 화면
  WRONGQUIZ: "/wrong-quiz",
  WRONG_ID: "wrong-quiz",
  // 봤던 문제 화면
  SOLVEQUIZ: "/solve-quiz",
  SOLVE_ID: "solve-quiz",
  // 퀴즈 화면
  QUIZ: "/quiz",
  // 로그인 화면
  LOGIN: "/login",
  // 회원 가입 화면
  SIGNUP: "/sign-up",
  /** 401 오류 페이지 */
  UNAUTHORIZED: "/unauthorized",
  /** 점검 중 페이지 */
  ERROR: "/error",
  // 퀴즈 저작 메인
  CREATE: "/create",

  // 프로필 화면
  PROFILE: "/profile",
  // 설정 화면
  SETTING: "/setting",

  // 관련자료 메인
  REFERENCE: "/reference",

  // 저작하기 화면
  WRITE: "/write",

  //============변수================
  /** 주제 id */
  TOPIC_ID: "topic_id",
  /** 퀴즈 id */
  QUIZ_ID: "quiz_id",
  /** 틀린? 봤던? 이력 */
  HISTORY_ID: "history_id",
  /** 저작한 퀴즈 id 혹은 저작한 관련자료 id */
  CREATE_ID: "create_id",

  // 설정 - 계정 화면 id
  ACCOUNT_ID: "account",
  // 설정 - 저작 권한 화면 id
  WRITE_AUTHORITY_ID: "write-authority",
  // 설정 - 비밀번호 변경 화면 id
  PASSWORD_ID: "password",
};
export default URL;
