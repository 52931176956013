import React from "react";
import { Paper } from "@mui/material";
import MyCKEditor from "../global-components/MyCKEditor";
import SubTitle from "../global-components/SubTitle";
import { FlexColumnBox } from "../layout";

/**
 * common Write CKEditor
 * @param {} param0
 * @returns
 */
function WriteCKEditor({
  subTitleName,
  subTitleBody,
  inputText,
  onChangeInputText,
  placeholder,
  initHeight = "0px",
}) {
  return (
    <FlexColumnBox>
      <SubTitle titleName={subTitleName} body={subTitleBody} />
      <Paper elevation={1}>
        <MyCKEditor
          data={inputText}
          onChange={onChangeInputText}
          placeholder={placeholder}
          initHeight={initHeight}
        />
      </Paper>
    </FlexColumnBox>
  );
}
export default React.memo(WriteCKEditor);
