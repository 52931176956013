/**
 * change example form (delete id)
 * @param {*} exampleItems
 * @returns
 */
function changeExamplesForm(exampleItems) {
  if (exampleItems === undefined) return [];

  let result = [];
  let exampleItemsTemp = [...exampleItems]; // example copy

  exampleItemsTemp.map((item) => {
    delete item.exampleId;
    result.push(item);
  });

  return result;
}
export default changeExamplesForm;
