import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  onChangeUserAchievementIdList,
  onInitUser,
  onInitUserAchievementIdList,
  onSetUserStat,
} from "../modules/auth";
import { handleIdToken } from "../utils/handleIdToken";
import AppRouter from "../Router";
import Snackbars from "../components/global-components/Snackbars";
import URL from "../components/pageUrls";
import { checkRecord, checkRecordGuest } from "../utils/checkRecord";
import getLocalStorageItemObj from "../utils/getLocalStorageItemObj";
import Event from "./Event";
import { handleUserStat } from "../utils/handleUserStat";
import {
  onChangeEventAchievement,
  onChangeEventLevelUp,
  onChangeEventOpen,
} from "../modules/event";
import APIURL from "../modules/apiUrls";
import { analytics, logEvent } from "../service/firebase";

function App() {
  /** Redux State */
  const dispatch = useDispatch();

  const {
    loginSuccess,
    userIdToken,
    open,
    userAchievementIdList,
    eventOpen,
    userLevel,
    profileImage,
  } = useSelector((state) => ({
    loginSuccess: state.auth.loginSuccess,
    userIdToken: state.auth.userIdToken,
    open: state.snackbar.open,
    userAchievementIdList: state.auth.userAchievementIdList,
    eventOpen: state.event.eventOpen,
    userLevel: state.auth.userLevel, // 레벨
    profileImage: state.auth.profileImage, // 이미지
  }));

  /** User Achievement Id List */
  const handleUserAchievementIdList = (achievementIdList) => {
    dispatch(onChangeUserAchievementIdList(achievementIdList));
  };
  /** User Achievement Id List Init */
  const handleUserAchievementIdListInit = () => {
    dispatch(onInitUserAchievementIdList());
  };
  /** set user stat */
  const handleSetUserStat = (userStat) => {
    dispatch(onSetUserStat(userStat));
  };
  /** init user stat */
  const handleInitUserStat = () => {
    dispatch(onInitUser());
  };
  /** level up event open */
  const handleLevelUpEventOpen = () => {
    dispatch(onChangeEventLevelUp());
  };
  /** achievement event open */
  const handleAchievementEventOpen = () => {
    dispatch(onChangeEventAchievement());
  };

  // /** 레벨 바뀌었다? 그럼 event 열기 */
  // React.useEffect(() => {

  //   if()
  //   handleLevelUpEventOpen();
  // }, [currentLevel]);

  // //console.log("userIdToken 상황 : ", userIdToken);

  /** 현재 로그인한 user의 id token과 더불어 stat 가져오기 : 주의할 점은 서버로부터 stat을 못 받아와도, 로그아웃 될 예정 */
  async function checkUserStat() {
    /** 가져온 user stat이 {} 이면 바로 게스트 후, 리턴 */
    await handleUserStat().then((userStat) => {
      // console.log(userStat);

      if (Object.keys(userStat).length === 0) {
        // //console.log("익명이다");
        handleInitUserStat(); // user 아예 초기화
      } else {
        //console.log(userStat);
        //console.log("회원이다", userStat);
        handleSetUserStat({
          userIdToken: userStat.userId, // userId 보안키
          userNickname: userStat.nickname, // 닉네임
          userLevel: userStat.userLevel, // 레벨
          userLevelXp: userStat.currentXp, // 현재 레벨 XP
          profileImage: userStat.profileImage, // 이미지
          isWritable: userStat.isWritable, //저작 권한
        });

        // logEvent(analytics, "test", {
        //   id: userStat.userId,
        //   stat: [
        //     { name: userStat.nickname },
        //     { level: userStat.userLevel },
        //     { xp: userStat.currentXp },
        //   ],
        // });
      }
    });
  }

  async function updateUserLevel() {
    // //console.log("현재 레벨은??", userLevel);
    /**
     * 레벨 체크
     * 1. 로컬 스토리지에 없으면, login-level로 level 새로 등록, 리턴
     * 2. 로컬 스토리지에 있으면, 레벨 비교
     * 3. 레벨 같으면, 리턴
     * 4. 레벨 다르면, 이벤트 뿌리고 갱신
     */
    const userLevelId = "login-level";
    const currentUserLevel = await localStorage.getItem(userLevelId);

    /** APP에선 초기화만 해주기 */
    if (currentUserLevel === null) {
      await localStorage.setItem(userLevelId, userLevel);
      return;
    }

    // if (Number(currentUserLevel) === userLevel) return;

    // await handleLevelUpEventOpen();
    // await localStorage.setItem(userLevelId, userLevel);
  }

  /** 익명 Auth 관리 */
  async function updateGuest() {
    // //console.log("비회원이다");
    /** login 안 되어 있음 & userIdToken 이 "Guest" & userAchievementIdList 이 빈 배열로 */
    if (loginSuccess || userIdToken !== "Guest" || userAchievementIdList !== [])
      await handleInitUserStat();
    await checkRecordGuest(); // => 일일 목표
  }

  /** 회원 Auth 관리 */
  async function updateUser() {
    // //console.log("회원이다");
    /** login 되어 있음 & userIdToken 이 "user-id-토큰" & userAchievementIdList 이 빈 배열로 */
    if (!loginSuccess || userIdToken === "Guest") {
      await checkUserStat(); //=> stat 잘 받아올 시, handleSetUserStat 수행
    }
    /** 로컬 스토리지 확인을 위해 stat API 요청해서, user Id 얻어옴 */
    if (loginSuccess && userIdToken !== "Guest") {
      /** 일일 목표, 출석 */
      await checkRecord(userIdToken);

      // /** 로컬 스토리지에 달성한 출석 관련 업적이 있을테니, 그걸로 추가 해주기, 그리고 바로 초기화 */
      // if (userAchievementIdList !== []) {
      //// 만약 출석 관련 업적이 들어왔다? 그럼 여기서 바로~~~ handleAchievementEventOpen 이벤트 열어주기
      //   await handleUserAchievementIdList(
      //     getLocalStorageItemObj(userIdToken + "-attendance")
      //       .earnAchievementIdList
      //   );
      //   await handleUserAchievementIdListInit();
      // }

      /** 로컬 스토리지에 달성한 출석 관련 업적이 있을테니, 그걸로 추가 해주기, 그리고 바로 초기화 */

      // 만약 출석 관련 업적이 들어왔다? 그럼 여기서 바로~~~ handleAchievementEventOpen 이벤트 열어주기
      const achievementIdList = await getLocalStorageItemObj(
        userIdToken + "-attendance"
      ).earnAchievementIdList;
      const achievementData = await getLocalStorageItemObj(
        userIdToken + "-attendance"
      );

      if (achievementIdList.length !== 0 && achievementIdList !== null) {
        // //console.log("achievementIdList???", achievementIdList);
        await handleUserAchievementIdList(achievementIdList);
        await handleAchievementEventOpen(); // 이벤트 실행
        // await handleUserAchievementIdListInit();
        /** 로컬 스토리지 초기화 */
        await localStorage.setItem(
          userIdToken + "-attendance",
          JSON.stringify({
            ...achievementData,
            earnAchievementIdList: [],
          })
        );
      }
    }
  }

  async function checkAuthState() {
    /** 로그인 했는지 확인 */
    const loginToken = await localStorage.getItem("login-token");

    /** 익명 */
    if (loginToken === null) {
      await updateGuest(); // 일일목표 update
      return;
    }
    /**
     * 회원 로그인 상태
     * 회원 가입 페이지에선 안 부름
     * 회원가입 시 자동 로그인되었을 때, API 막음(닉네임을 적어야만 DB에 들어가니까)
     */
    if (loginToken !== null && window.location.pathname !== URL.SIGNUP) {
      await checkUserStat(); // stat setting
      await updateUser(); // 일일목표 & 출석 update
    }
    /** 퀴즈 페이지에서는 레벨 비교하지 말기 */
    if (
      loginToken !== null &&
      userLevel !== 0
      // && window.location.pathname !== URL.QUIZ
    ) {
      await updateUserLevel();
    }
  }
  // //console.log(window.location.pathname);
  /** startupQT 처음 렌더 될 때, 현재 로그인 되어있는지 확인 */
  useEffect(() => {
    // //console.log("현재 로그인??");
    // //console.log("현재 로그인??");
    // if (
    //   !(loginSuccess && userIdToken !== "Guest") ||
    //   !(!loginSuccess && userIdToken === "Guest")
    // )
    //   return;
    const c = checkAuthState();
  }, [loginSuccess, userIdToken, userLevel]);

  /**
   * 토큰 만료 시간이 1시간임
   * - firebase는 auto refresh 해주기 때문에
   * - 50 분마다 로컬 스토리지에 담아놓은 id Token만 갱신해주면 됨.
   */
  async function refreshToken() {
    // //console.log("리프레시!");
    const loginToken = await localStorage.getItem("login-token");
    /** 익명 */
    if (loginToken === null) {
      await updateGuest();
      return;
    }

    /** 회원 로그인 상태 */
    if (loginToken !== null) {
      /** 새로운 토큰 발급 받기 */
      await handleIdToken("login-token");
      // await checkUserStat(); // stat setting
      await updateUser();
    }
  }

  /** 50분마다 토큰 요청하여 리프레시 된 토큰 받아서 로컬 스토리지에 담기 */
  React.useEffect(() => {
    const refresh = setInterval(() => {
      const l = refreshToken();
    }, 1000 * 60 * 50); // 50 분
    return () => clearInterval(refresh);
    /** 현재, 자정일 땐 무조건 리프레시 해주기! */
  }, []);

  return (
    <div className="App">
      <Snackbars />
      {/* {(loginSuccess && userIdToken !== "Guest") ||
        (!loginSuccess && userIdToken === "Guest" && <AppRouter />)} */}
      <AppRouter />
    </div>
  );
}

export default App;
