import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  ButtonBase,
} from "@mui/material";
import CategoryTag from "../atom-components/CategoryTag";
import { styled } from "@mui/material/styles";
import QuizState from "./QuizState";
import usePage from "../../hooks/usePage";
import changeQuizState from "../../utils/changeQuizState";

/**
 * 문제 저작 메인 페이지에서 보이는
 * 문제 카드들
 * @param {*} quizData 
 *   {
    quizId: 0,
    questionText: "string",
    quizTopics: [
      {
        topicId: 0,
        topicName: "string",
      },
    ],
    correctAnswerRate: 0,
    verifyStatus: "string",
    playUserCount: 0,
    createdDate: "string",
  },
 */
function CreateQuizCard({ quizData }) {
  const { handleCreateIdPage } = usePage();

  /** 상태 | 점검중... 인 퀴즈 카드 */
  const DisabledQuizCard = styled(Card)(({ theme }) => ({
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[300],
  }));
  function QuizCardContent({ content, isVerifyed }) {
    // isVerifyed : true==점검 완료, false==점검 중
    return (
      <CardContent sx={{ width: "100%", "&:last-child": { pb: "1.6rem" } }}>
        <Grid container>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mb: "0.8rem",
            }}
          >
            <Typography
              /** 문제 */
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                height: { xs: "4.4rem", sm: "5rem", md: "5.4rem" },
                mb: "0.8rem",
                // 두 줄 이상으로 해야 하니까 고치기
                whiteSpace: "nowrap",
              }}
              align="left"
              variant="subtitle1"
            >
              {content.questionText}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          /** 문제 정보들 */
          item
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            mb: "0.8rem",
          }}
        >
          {
            // 점검 완료된 카드 내용 : 문제 유형, 풀이수, 정답률, 생성일
            // 점검 중인 카드 내용 : 문제 유형, 생성일, 상태
            changeQuizState(isVerifyed, quizData).map((comp, index) => (
              <QuizState key={index} quizData={comp} />
            ))
          }
          {!isVerifyed && (
            <QuizState quizData={{ type: "상태", data: "점검중" }} isError />
          )}
        </Grid>
        <Box
          /** 퀴즈 주제들 */
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            mb: "0.8rem",
          }}
        >
          {content.quizTopics.map((topic, index) => (
            <CategoryTag key={topic.topicId} text={topic.topicName} />
          ))}
        </Box>
        <Grid container sx={{ alignItems: "center" }}></Grid>
      </CardContent>
    );
  }

  const cardXs = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  };

  return (
    <ButtonBase
      onClick={() => handleCreateIdPage(quizData.quizId)}
      type="button"
      sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
    >
      {quizData.verifyStatus === "검증 전" ? (
        /** 상태 | 점검중... */
        <DisabledQuizCard
          /** 한 문제 레이아웃 */ key="isVerifyed-false"
          elevation={2}
          sx={cardXs}
        >
          <QuizCardContent content={quizData} isVerifyed={false} />
        </DisabledQuizCard>
      ) : (
        /** 점검 완료된 제작된 문제 카드 */
        <Card
          /** 한 문제 레이아웃 */ key="isVerifyed-true"
          elevation={2}
          sx={cardXs}
        >
          <QuizCardContent content={quizData} isVerifyed={true} />
        </Card>
      )}
    </ButtonBase>
  );
}
export default CreateQuizCard;
