import { Box, Button, Paper, Tooltip } from "@mui/material";
import React from "react";
import BreadCrumbsTitle from "../components/global-components/BreadCrumbsTitle";
import PageScreenBox from "../components/global-components/PageScreenBox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { FlexBox, FlexColumnBox } from "../components/layout";
import PageTitle from "../components/global-components/PageTitle";
import {
  ID_SELECT_QUIZ_INFO,
  VALUE_QUIZ_TYPE_MULTIPLE,
  VALUE_QUIZ_TYPE_OX,
  VALUE_QUIZ_TYPE_PUZZLE,
} from "../constants/writeQuiz";
import { OutlinedIconButton } from "../components/atom-components/StyledButtons";
import WriteQuizQuestion from "../components/write/WriteQuizQuestion";
import WriteQuizCommentary from "../components/write/WriteQuizCommentary";
import {
  FloatingTabs,
  FloatingTab,
} from "../components/atom-components/StyledTabs";
import { BorderLinearProgress } from "../components/atom-components/StyledProgressBars";
import WriteQuizQuestionMultiple from "../components/write/WriteQuizQuestionMultiple";
import WriteQuizQuestionPuzzle from "../components/write/WriteQuizQuestionPuzzle";
import WriteQuizQuestionOX from "../components/write/WriteQuizQuestionOX";
import PreviewQuizContainer from "../containers/create/PreviewQuizContainer";
import { SubHeader } from "../components/atom-components/StyledHeaders";
import WriteQuizReference from "../components/write/WriteQuizReference";

/** 퀴즈 저작 컴포넌트 */
function WriteQuiz({
  isEditMode,
  resultQuiz,
  previewResultQuizState,
  onClickPreview,
  onClickEdit,
  inputQuizType,
  inputQuestionText,
  inputDescriptionText,
  inputAnswer,
  inputAnswerText,
  inputCommentaryText,
  exampleItems,
  onChangeWriteQuizInputs,
  onChangeWriteQuizSelects,
  onChangeWriteQuizTabs,
  selectQuizInfo,
  handleDragEndExampleItems,
  handleClickAddExampleItem,
  handleClickRemoveExampleItem,
  handleClickSetExampleItemIsAnswer,
  handleChangeExampleItemText,
  handleChangeInputDescriptionText,
  handleChangeInputCommentaryText,
  handleClickSubmitWrite,
  selectedTopics,
  handleSubTopicToggle,
  writeProgressValue,
  isCanSubmit,
  writeQuizPageTitle, // 저작 or 수정
  onClickBackPage,
  selectedReferencesItems, // 관련자료
  onClickSelectReference,
  handleDragEndSelectedReferencesItems,
}) {
  // tab quiz type data : 다지선다 / 퍼즐 / OX
  const tabQuizTypeData = [
    {
      quizType: "다지선다",
      value: VALUE_QUIZ_TYPE_MULTIPLE,
      content: (
        <WriteQuizQuestionMultiple
          exampleItems={exampleItems}
          handleDragEndExampleItems={handleDragEndExampleItems}
          handleClickRemoveExampleItem={handleClickRemoveExampleItem}
          handleClickSetExampleItemIsAnswer={handleClickSetExampleItemIsAnswer}
          handleChangeExampleItemText={handleChangeExampleItemText}
          handleClickAddExampleItem={handleClickAddExampleItem}
        />
      ),
    },
    {
      quizType: "퍼즐",
      value: VALUE_QUIZ_TYPE_PUZZLE,
      content: (
        <WriteQuizQuestionPuzzle
          inputAnswerText={inputAnswerText}
          onChangeWriteQuizInputs={onChangeWriteQuizInputs}
        />
      ),
    },
    {
      quizType: "OX",
      value: VALUE_QUIZ_TYPE_OX,
      content: (
        <WriteQuizQuestionOX
          inputAnswer={inputAnswer}
          onChangeWriteQuizSelects={onChangeWriteQuizSelects}
        />
      ),
    },
  ];
  const tabQuizInfoData = [
    {
      label: "문제",
      content: (
        <WriteQuizQuestion
          isEditMode={isEditMode}
          inputQuizType={inputQuizType}
          onChangeWriteQuizTabs={onChangeWriteQuizTabs}
          tabQuizTypeData={tabQuizTypeData}
          inputQuestionText={inputQuestionText}
          onChangeWriteQuizInputs={onChangeWriteQuizInputs}
          inputDescriptionText={inputDescriptionText}
          setInputDescriptionText={handleChangeInputDescriptionText}
          selectedTopics={selectedTopics}
          handleSubTopicToggle={handleSubTopicToggle}
        />
      ),
    },
    {
      label: "해설",
      content: (
        <WriteQuizCommentary
          inputCommentaryText={inputCommentaryText}
          setInputCommentaryText={handleChangeInputCommentaryText}
        />
      ),
    },
    {
      label: "관련자료",
      content: (
        <WriteQuizReference
          selectedReferencesItems={selectedReferencesItems}
          handleDragEndSelectedReferencesItems={
            handleDragEndSelectedReferencesItems
          }
          onClickSelectReference={onClickSelectReference}
        />
      ),
    },
  ];

  return (
    <>
      <SubHeader>
        <FlexColumnBox
          sx={{
            width: 1,
            maxWidth: 1128,
            gap: "0.4rem",
            p: "1.6rem 0.75rem 0.8rem",
          }}
        >
          <BreadCrumbsTitle />
          <FlexBox
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <PageTitle
              titleName={`${writeQuizPageTitle} 하기`}
              pageBack={true}
              onClick={onClickBackPage}
            />
            <FloatingTabs
              value={selectQuizInfo}
              onChange={onChangeWriteQuizTabs}
              variant="scrollable"
            >
              {!previewResultQuizState &&
                tabQuizInfoData.map((data) => {
                  const { label } = data;

                  return (
                    <FloatingTab
                      id={ID_SELECT_QUIZ_INFO}
                      key={"tab-quiz-info-id" + label}
                      label={label}
                    />
                  );
                })}
            </FloatingTabs>
            <FlexBox gap="1.2rem" sx={{ alignItems: "center" }}>
              {!previewResultQuizState ? (
                <Tooltip title="미리 보기">
                  <div>
                    <OutlinedIconButton onClick={onClickPreview}>
                      <VisibilityIcon />
                    </OutlinedIconButton>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="편집 하기">
                  <div>
                    <OutlinedIconButton onClick={onClickEdit}>
                      <EditIcon />
                    </OutlinedIconButton>
                  </div>
                </Tooltip>
              )}
              <Tooltip title={`${writeQuizPageTitle} 완료`}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleClickSubmitWrite}
                    disabled={!isCanSubmit}
                  >
                    {`${writeQuizPageTitle} 완료`}
                  </Button>
                </div>
              </Tooltip>
            </FlexBox>
          </FlexBox>
        </FlexColumnBox>
        <Box sx={{ width: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={writeProgressValue}
          />
        </Box>
      </SubHeader>
      {!previewResultQuizState ? (
        <PageScreenBox sx={{ pt: "20rem", pb: "10rem" }}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: "primary.tonal",
              borderRadius: "16px",
              p: "2.4rem 4.8rem",
            }}
          >
            {tabQuizInfoData.map((data, index) => {
              const { label, content } = data;

              return (
                <FlexColumnBox key={"quiz-type-info-id" + label} gap="1.6rem">
                  {selectQuizInfo === index && content}
                </FlexColumnBox>
              );
            })}
          </Paper>
        </PageScreenBox>
      ) : (
        <Box sx={{ pt: "12rem", width: "100%" }}>
          <PreviewQuizContainer resultQuiz={resultQuiz} />
        </Box>
      )}
    </>
  );
}
export default WriteQuiz;
