import Masonry from "@mui/lab/Masonry";
import QuizCard from "../global-components/QuizCard";

function QuizTopicCards({ quizList, isFavorites }) {
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={10}>
      {quizList.map((quizData) => (
        <QuizCard
          key={quizData.quizId}
          quiz={quizData}
          isFavorites={isFavorites}
        />
      ))}
    </Masonry>
  );
}
export default QuizTopicCards;
