import { FlexBox, FlexColumnBox } from "../layout";
import { Typography } from "@mui/material";
import { People } from "@mui/icons-material";
import CategoryTag from "../atom-components/CategoryTag";
import { Box } from "@mui/system";

/**
 * 스타일 정의한 '|'
 */
const divider = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "0.8rem", transform: "scale(0.8)" }}
  >
    |
  </Box>
);

/**
 * 퀴즈에 대한 정보
 * - 문제
 * - 태그
 * - 풀이한 사람 수
 * - 정답률
 * @param {object} resultQuiz : 퀴즈 데이터
 * @returns
 */
function QuizInfo({ resultQuiz }) {
  return (
    <FlexColumnBox
      sx={{
        gap: (theme) => theme.spacing(2),
      }}
    >
      <Typography /** 퀴즈 문제 */ variant="h5" gutterBottom>
        {resultQuiz.questionText}
      </Typography>
      <FlexBox
        /** 주제들 감싸는 레이아웃 */
        sx={{
          gap: (theme) => theme.spacing(2),
        }}
      >
        {resultQuiz.quizTopics.map((topic) => (
          <CategoryTag key={topic.topicId} text={topic.topicName} />
        ))}
      </FlexBox>
      <FlexBox
        /** 풀이한 사람 수 + 정답률 감싸는 레이아웃 */
        sx={{
          gap: (theme) => theme.spacing(1),
          color: "text.secondary",
          alignItems: "center",
        }}
      >
        <People sx={{ transform: "scale(0.8)" }} />
        <Typography /** 풀이한 사람 수 / 정답률 */ variant="overline">
          {resultQuiz.playUserCount}명{divider}정답률 {resultQuiz.answerRate}%
        </Typography>
      </FlexBox>
    </FlexColumnBox>
  );
}
export default QuizInfo;
