import { Typography, Grid, Card, CardContent } from "@mui/material";
import { Masonry } from "@mui/lab";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";

/** 통계 카드 */
function StatisticsCard({
  totalPlayTime,
  totalPlayCount,
  correctAnswerRate,
  maxComboCount,
}) {
  const statisticsData = [
    {
      info: "누적 풀이 시간",
      unit: "시간",
      icon: <AccessTimeIcon fontSize="large" />,
      userData: totalPlayTime,
    },
    {
      info: "누적 풀이 문제 수",
      unit: "개",
      icon: <LightbulbOutlinedIcon fontSize="large" />,
      userData: totalPlayCount,
    },
    {
      info: "정답률",
      unit: "%",
      icon: <AutoGraphIcon fontSize="large" />,
      userData: correctAnswerRate,
    },
    {
      info: "최대 콤보 횟수",
      unit: "COMBO",
      icon: <CelebrationOutlinedIcon fontSize="large" />,
      userData: maxComboCount,
    },
  ];

  return (
    <Masonry columns={{ xs: 2, sm: 2, md: 4 }} spacing={6}>
      {statisticsData.map((data, index) => (
        <Card /** 한 문제 레이아웃 */ elevation={1} key={index}>
          <CardContent
            sx={{
              p: "1.6rem 1.6rem 0 2.4rem",
              "&:last-child": { pb: "1.6rem" },
            }}
          >
            <Grid container sx={{ alignItems: "center", gap: "1.6rem" }}>
              <Grid item color="secondary.dark">
                {data.icon}
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  variant="subtitle1"
                >
                  {data["userData"] === undefined ? 0 : data.userData}{" "}
                  {data.unit}
                </Typography>
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  variant="subtitle2"
                  color="text.secondary"
                >
                  {data.info}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
    </Masonry>
  );
}
export default StatisticsCard;
