import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 일일 목표 기록 API
 * @param {object} dataToSubmit : 서버에 보낼 정보
 */
async function recordDailyMission(dataToSubmit = {}) {
  /**
   * "goalXp": 0,
   * "isSuccess": false,
   * "successDate": "2022-10-16T10:10:40.919Z"
   */
  let data;
  try {
    await axios
      .post(APIURL.api + "record/daily-mission", dataToSubmit)
      .then((response) => {
        // console.log("일일 목표 기록 잘 보냄!", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // console.log("사용자 풀이 이력 못 보냄..", error);
    switch (error.response.status) {
      case 461:
        // console.log("일일목표 에러! 사용자가 이미 일일 목표를 달성했습니다.");
        return;
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default recordDailyMission;
