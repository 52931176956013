import changeQuizTypeInfo from "./changeQuizTypeInfo";
import timeFormat from "./timeFormat";

function changeQuizState(isCheck, quizData) {
  let result = [];

  if (isCheck) {
    result = [
      {
        type: "문제 유형",
        data: changeQuizTypeInfo(quizData.quizType),
      },
      {
        type: "풀이수",
        data: String(quizData.playUserCount),
        unit: "명",
      },
      {
        type: "정답률",
        data: String(quizData.answerRate),
        unit: "%",
      },
      {
        type: "생성일",
        data: timeFormat(quizData.createdDate, true),
      },
    ];
  } else {
    result = [
      {
        type: "문제 유형",
        data: changeQuizTypeInfo(quizData.quizType),
      },
      {
        type: "생성일",
        data: timeFormat(quizData.createdDate, true),
      },
    ];
  }

  return result;
}
export default changeQuizState;
