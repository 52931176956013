import { useState } from "react";

/**
 * useState를 이용한, 입력 상태 관리 함수 컴포넌트
 * - ckeditor에는 id를 부여할 수 없기 때문에, input 하나씩 관리
 * @param {string} initialValue 입력 초기값
 *
 */
function useEditorInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  // 입력 event
  function onChange(event, editor) {
    const data = editor.getData();
    setValue(data);
  }

  return { value, setValue, onChange };
}
export default useEditorInput;
