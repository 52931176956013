/**
 * 오브젝트 타입의 로컬 스토리지 아이템 가져오기
 * 로컬 스토리지엔 문자열인 키와 값만 지원하기 때문에,
 * JSON.stringify()으로 저장
 * JSON.parse()으로 접근해야 함
 */
function getLocalStorageItemObj(key) {
  const itemObj = localStorage.getItem(key);
  return JSON.parse(itemObj);
}
export default getLocalStorageItemObj;
