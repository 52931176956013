import deleteQuizWrite from "../modules/_actions/create/deleteQuizWrite";
import postQuizWrite from "../modules/_actions/create/postQuizWrite";
import postQuizWriteImageUpload from "../modules/_actions/create/postQuizWriteImageUpload";
import putQuizWrite from "../modules/_actions/create/putQuizWrite";

/**
 * post QuizWrite
 * @param {*} idToken
 */
async function handlePostQuizWrite(idToken, body) {
  try {
    const response = await postQuizWrite(idToken, body);
    if (response.hasOwnProperty("quizId")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * post QuizWrite image url upload
 * @param {*} idToken
 */
async function handlePostQuizWriteImageUpload(idToken, body) {
  try {
    const response = await postQuizWriteImageUpload(idToken, body);
    if (!!response) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * delete Quiz
 * @param {*} idToken
 */
async function handleDeleteQuizWrite(idToken, param) {
  try {
    const response = await deleteQuizWrite(idToken, param);
    if (response.hasOwnProperty("message")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * put QuizWrite
 * @param {*} idToken
 */
async function handlePutQuizWrite(idToken, body, param) {
  try {
    const response = await putQuizWrite(idToken, body, param);
    if (response.hasOwnProperty("quizId")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

export {
  handlePostQuizWrite,
  handlePostQuizWriteImageUpload,
  handleDeleteQuizWrite,
  handlePutQuizWrite,
};
