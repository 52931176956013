import React from "react";
import { useDispatch, useSelector } from "react-redux";
import getQuizTopic from "../modules/_actions/getQuizTopic";
import getQuizTopicGuest from "../modules/_actions/getQuizTopicGuest";
import { onLoadData } from "../modules/topicQuiz";
import handleRequestHeader from "../utils/handleRequestHeader";
import QuizList from "../components/quizList/QuizList";
import usePage from "../hooks/usePage";
import MyLoading from "../components/global-components/MyLoading";

function TopicQuizContainer() {
  /** 페이지 이동 hook */
  const { mainTopicQuizPage: quizPage } = usePage();

  /**
   * 현재 url에서 id 뽑아서 해당 퀴즈 제공
   * - topic_id : 주제 id
   * - quiz_id : 퀴즈 id
   */
  let param = new URLSearchParams(window.location.search);
  let topic_id = param.get("topic_id");
  const [isSuccessQuiz, setIsSuccessQuiz] = React.useState(false);

  /** Redux State */
  const dispatch = useDispatch();

  const { topicId, topicName, quizList, currentPageNum, maxPageNum } =
    useSelector((state) => ({
      topicId: state.topicQuiz.topicId,
      topicName: state.topicQuiz.topicName,
      quizList: state.topicQuiz.quizList,
      currentPageNum: state.topicQuiz.currentPageNum,
      maxPageNum: state.topicQuiz.maxPageNum,
    }));

  const handleLoadData = (data) => dispatch(onLoadData(data));

  /** 현재 pagination 페이지 */
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  /** 대표 문제 제공 API */
  async function loadData() {
    /** get param */
    let params = {
      topicId: topic_id,
      pageNum: page,
      pagingSize: 9,
    };

    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      if (isSuccess) {
        await getQuizTopic(params).then((res) => {
          // console.log("우선 여기까지 잘 왔음", res);
          setIsSuccessQuiz(true);
          handleLoadData({
            id: "topicQuizData",
            value: res,
          });
        });
      }
    } else {
      /** 비회원 퀴즈 문제 제공 */
      await getQuizTopicGuest(params).then((res) => {
        // console.log("게스트는 왔남??", res);
        setIsSuccessQuiz(true);
        handleLoadData({
          id: "topicQuizData",
          value: res,
        });
      });
    }
  }
  React.useEffect(() => {
    const l = loadData();
  }, [page]);

  return (
    <>
      {!isSuccessQuiz && <MyLoading />}
      {isSuccessQuiz && (
        <QuizList
          topicId={topicId}
          topicName={topicName}
          quizList={quizList}
          currentPageNum={currentPageNum}
          maxPageNum={maxPageNum}
          page={page}
          handlePageChange={handlePageChange}
          isTopicPage={true}
          isFavorites={false}
          /** 현재 주제 중에서 랜덤 퀴즈 : topic_id를 url로 보냄 */
          quizPage={() => quizPage(topic_id)}
        />
      )}
    </>
  );
}
export default TopicQuizContainer;
