/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const TOPIC_WRONG_QUIZ_DATA = "topicWrongQuiz/TOPIC_WRONG_QUIZ_DATA";

/** init State ( 초기 상태 ) */
const initialState = {
  topicId: 0,
  topicName: "",
  quizList: [
    {
      quizId: 0,
      questionText: "",
      quizTopics: [
        {
          topicId: 0,
          topicName: "",
        },
      ],
      playUserCount: 0,
      descriptionImages: "",
      answerRate: 0,
    },
  ],
  currentPageNum: 0,
  maxPageNum: 0,
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onLoadData = ({ id, value }) => ({
  type: id === "topicWrongQuizData" && TOPIC_WRONG_QUIZ_DATA,
  [id]: value,
});

/** reducer정의 */
export default function topicWrongQuiz(state = initialState, action) {
  const data = action.topicWrongQuizData;

  switch (action.type) {
    case TOPIC_WRONG_QUIZ_DATA:
      return {
        ...state,
        topicId: data.topicId,
        topicName: data.topicName,
        quizList: data.quizList,
        currentPageNum: data.currentPageNum,
        maxPageNum: data.maxPageNum,
      };
    default:
      return state;
  }
}
