import { Typography, Grid, Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useGameGraphic from "../../hooks/useGameGraphic";
import { onInitUserAchievementIdList } from "../../modules/auth";
import { onChangeEventAchievement } from "../../modules/event";
import StyledScrollbar from "../atom-components/StyledScrollbar";
import { FlexBox } from "../layout";

/** 배지 획득 이벤트 시, 보여지는 업적 바 */
function AchievmentBar({ achievmentData }) {
  // /** Redux State */
  // const dispatch = useDispatch();
  /** 그래픽 적용을 위한 함수 */
  const { handleAchievementData, getAchievmentIcon } = useGameGraphic();

  // /** 달성한 업적 id 리스트 */
  // const [achievmentData, setAchievmentData] = React.useState([]);

  // const { userAchievementIdList } = useSelector((state) => ({
  //   userAchievementIdList: state.auth.userAchievementIdList,
  // }));

  // /** 여기서 다 보여주고, 초기화 시키기 */
  // /** User Achievement Id List Init */
  // const handleUserAchievementIdListInit = () => {
  //   dispatch(onInitUserAchievementIdList());
  // };
  // /** achievement event open */
  // const handleAchievementEventOpen = () => {
  //   dispatch(onChangeEventAchievement());
  // };
  // console.log("userAchievementIdList", userAchievementIdList);
  // console.log("achievmentData", achievmentData);

  // async function handleUserAchievementEvent() {
  //   if (userAchievementIdList.length === 0) return;
  //   // 만약 업적이 들어왔다? 그럼 여기서 바로 handleAchievementEventOpen 이벤트 열어주기

  //   /** 채워주기 */
  //   if (achievmentData.length === 0) {
  //     await setAchievmentData(handleAchievementData(userAchievementIdList));
  //     // await handleAchievementEventOpen(); // 이벤트 실행
  //   }
  // }

  // React.useEffect(() => {
  //   /** 로컬 스토리지에 달성한 출석 관련 업적 & 퀴즈 업적으로 데이터 들어오면, 그걸로 추가 해주기, 그리고 바로 초기화 */
  //   if (userAchievementIdList.length !== 0 && achievmentData.length !== 0) {
  //     /** 잘 채워졌다면 초기화 해주기 */
  //     handleUserAchievementIdListInit();
  //     console.log("여기 왔나?11111");
  //   }
  //   console.log("여기 2222");
  //   // if (achievmentData.length === 0) {
  //   //   /** 비워주기 */
  //   //   handleUserAchievementIdListInit();
  //   // }
  //   const l = handleUserAchievementEvent();
  // }, [userAchievementIdList, achievmentData]);

  /** 업적 바 컴포넌트 */
  function AchievementComponent({ data }) {
    // console.log("과연?", data);
    return (
      <Grid
        container
        sx={{
          width: "100%",
          alignItems: "center",
        }}
      >
        <Grid item xs>
          <Grid item>
            <Typography variant="h6">{data.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">{data.description}</Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ color: "#fdd835" }}>
          {getAchievmentIcon(data.target)}
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {achievmentData.length !== 0 && (
        <FlexBox
          sx={{
            maxHeight: { xs: 300, sm: 250, md: 200 },
            paddingX: {
              xs: "5rem",
              sm: "12rem",
              md: "24rem",
            },
            width: "100%",
          }}
        >
          <StyledScrollbar>
            <Box
              sx={{
                maxHeight: { xs: 300, sm: 250, md: 200 },
                width: "100%",
              }}
            >
              {achievmentData.map((data) => (
                <AchievementComponent key={data.achievement_id} data={data} />
              ))}
            </Box>
          </StyledScrollbar>
        </FlexBox>
      )}
    </>
  );
}
export default AchievmentBar;
