import { IconButton, styled } from "@mui/material";
import { grey, red } from "@mui/material/colors";

const ICON_BUTTON_SIZE = "3.2rem";
/**
 * styled pop icon button
 */
const StyledPopIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: "8px",
  boxShadow: theme.shadows[3],
  width: ICON_BUTTON_SIZE,
  height: ICON_BUTTON_SIZE,
}));
/**
 * pop icon button
 */
const PopIconButton = styled((props) => <StyledPopIconButton {...props} />)(
  ({ theme }) => ({
    backgroundColor: "white",
    "&:disabled": { backgroundColor: grey[500] },
  })
);
/**
 * pop icon error button
 */
const PopErrorIconButton = styled((props) => (
  <StyledPopIconButton {...props} />
))(({ theme }) => ({
  backgroundColor: red[500],
  "&:hover": { backgroundColor: red[600] },
  "&:disabled": { backgroundColor: grey[500] },
}));

/**
 * styled outlined icon button
 */
const OutlinedIconButton = styled(({ isError, ...props }) => (
  <IconButton {...props} />
))(({ theme, isError }) => ({
  border: "1px solid",
  borderRadius: "6px",
  borderColor: isError ? theme.palette.error.main : theme.palette.divider,
  width: ICON_BUTTON_SIZE,
  height: ICON_BUTTON_SIZE,
}));

export { PopErrorIconButton, PopIconButton, OutlinedIconButton };
