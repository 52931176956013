// 일정 line 이상 넘어가면 ... (말 줄임표)
// 일정 line은 추가 => WebkitLineClamp: "2",
const sxEllipsis = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
};

export { sxEllipsis };
