import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// 테마 정의
function useSx() {
  const theme = useTheme();
  /** is window md down? */
  const isWindowMdDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  /** is window sm down? */
  const isWindowSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  /** is window lg down? */
  const isWindowLgDown = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  /** is window xl down? */
  const isWindowXlDown = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  //=======main
  /**랜덤 풀기 아이콘 크기 */
  const randomSx = {
    [theme.breakpoints.down("sm")]: {
      width: "2.4rem",
      height: "2.4rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "2.8rem",
      height: "2.8rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "3.2rem",
      height: "3.2rem",
    },
  };

  //==========Write===============
  const gridWidth = { xs: "7.2rem", sm: "9rem", md: "11.2rem" };
  const subtitleFont = {
    typography: isWindowMdDown ? "body1" : "h6",
    fontWeight: "regular",
  };

  //==========Setting===============
  const settingSubtitleFont = {
    typography: "subtitle1",
    fontWeight: "bold",
  };
  const settingLayout = { spacing: 12, sx: { width: 1 } };
  const settingBoxGap = { gap: "1.6rem" };

  //============Modal==============
  const modalStyle = {
    borderRadius: "0.4rem",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "1px solid #0000000D",
    boxShadow: 24,
    pt: 10,
    px: 8,
    pb: 3,
  };

  return {
    isWindowMdDown,
    isWindowSmDown,
    isWindowLgDown,
    isWindowXlDown,
    randomSx,
    gridWidth,
    subtitleFont,
    modalStyle,
    settingSubtitleFont,
    settingLayout,
    settingBoxGap,
  };
}
export default useSx;
