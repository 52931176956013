import * as React from "react";
import SignUp from "../pages/SignUp";
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
  googleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  linkWithCredential,
  getAdditionalUserInfo,
} from "../service/firebase";
import { useSelector, useDispatch } from "react-redux";
import { onChangeInput } from "../modules/signUp";
import { setAuthError } from "../modules/error";
import usePreventLeave from "../hooks/usePreventLeave";
import getUserStat from "../modules/_actions/getUserStat";

import { handleIdToken } from "../utils/handleIdToken";
import { onChangeSuccess, onInitUser, onSetUserStat } from "../modules/auth";
import handleRequestHeader from "../utils/handleRequestHeader";
import deleteAuth from "../utils/deleteAuth";
import usePage from "../hooks/usePage";
import { handleUserStat } from "../utils/handleUserStat";
import { logout } from "../utils/logout";
import { checkRecord } from "../utils/checkRecord";

function SignUpContainer() {
  /** 페이지 이동 hook */
  const { mainPage, loginPageRePlace } = usePage();
  /** Redux Dispatch */
  const dispatch = useDispatch();

  /** 닉네임 안 쓰고 윈도우 창 나갈 때 경고문 */
  const { enablePrevent, disablePrevent } = usePreventLeave(
    withoutNickNameDeleteAuth
  );

  /** 입력 에러창 로그 */
  const [emailLog, setEmailLog] = React.useState(false);
  const [passwordLog, setPasswordLog] = React.useState(false);
  const [confirmPasswordLog, setConfirmPasswordLog] = React.useState(false);

  /** 정보 수집 동의 체크박스 */
  const [isChecked, setIsChecked] = React.useState(false);
  function handleIsChecked(event) {
    setIsChecked(event.target.checked);
  }

  /** 회원가입 하기 버튼 활성화 여부 */
  const [isDisabled, setIsDisabled] = React.useState(true);

  /** 닉네임 버튼 활성화 여부 */
  const [isNickNameDisabled, setIsNickNameDisabled] = React.useState(true);

  /** 닉네임 모달창 */
  const [isOpenNickNameModal, setIsOpenNickNameModal] = React.useState(false);
  function handleOpenNickNameModal() {
    setIsOpenNickNameModal(true);
  }
  function handleCloseNickNameModal() {
    setIsOpenNickNameModal(false);
  }

  /** Redux State */
  const {
    email,
    password,
    confirmPassword,
    nickName,
    authError,
    loginSuccess,
    userIdToken,
  } = useSelector((state) => ({
    email: state.signUp.email,
    password: state.signUp.password,
    confirmPassword: state.signUp.confirmPassword,
    nickName: state.signUp.nickName,
    authError: state.error.authError,
    loginSuccess: state.auth.loginSuccess,
    userIdToken: state.auth.userIdToken,
  }));

  // 혹시 user Id 가진 유저(로그인 한 유저)가 회원가입 페이지에 왔다면, 뒤로가기로 보내주기
  React.useEffect(() => {
    if (userIdToken !== "Guest") mainPage();
  }, [userIdToken]);

  /** email, password, confirmPassword, nickName */
  const handleOnChangeInput = (e) => dispatch(onChangeInput(e.target));
  /** authError : 에러 메세지 */
  const handleAuthError = (error) => dispatch(setAuthError(error));
  /** 로그인 성공 여부 */
  const handleOnChangeSuccess = (data) => dispatch(onChangeSuccess(data));

  //==========로그인 시, user 정보 setting==========
  /** set user stat */
  const handleSetUserStat = (userStat) => {
    dispatch(onSetUserStat(userStat));
  };
  /** init user stat */
  const handleInitUserStat = () => {
    dispatch(onInitUser());
  };
  /** 현재 로그인한 user의 id token과 더불어 stat 가져오기 : 주의할 점은 서버로부터 stat을 못 받아와도, 로그아웃 될 예정 */
  async function checkUserStat() {
    /** 가져온 user stat이 {} 이면 바로 게스트 후, 리턴 */
    await handleUserStat().then((userStat) => {
      // console.log(userStat);
      if (Object.keys(userStat).length === 0) {
        // console.log("익명이다");
        handleInitUserStat(); // user 아예 초기화
      } else {
        // console.log("회원이다", userStat.userId);
        handleSetUserStat({
          userIdToken: userStat.userId, // userId 보안키
          userNickname: userStat.nickname, // 닉네임
          userLevel: userStat.userLevel, // 레벨
          userLevelXp: userStat.currentXp, // 현재 레벨 XP
          profileImage: userStat.profileImage, // 이미지
          isWritable: userStat.isWritable, //저작 권한
        });
      }
    });
  }

  /** 회원가입 버튼 활성화 여부 */
  React.useEffect(() => {
    if (
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      !isChecked
    ) {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [email, password, confirmPassword, isChecked]);

  /** 닉네임 버튼 활성화 여부 */
  React.useEffect(() => {
    const len = nickName.length;
    if (2 <= len && len <= 16) {
      setIsNickNameDisabled(false);
    } else setIsNickNameDisabled(true);
  }, [nickName]);

  /** 닉네임 창 떠있을 때만, 닉네임 안 쓰면 경고창 띄우기 */
  React.useEffect(() => {
    if (isOpenNickNameModal) {
      enablePrevent(); /** 윈도우 창 나가면 경고창 띄움 */
    }
    return () => {
      disablePrevent(); /** 윈도우 창 이벤트 리스너 제거 */
    };
  }, [isOpenNickNameModal]);

  /** 비밀번호 맞는지 검사 */
  function validatePassword() {
    let isValid = true;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        isValid = false;
        handleAuthError("invalid-password");
      }
    }
    /** 맞지 않았을 경우, 비밀번호 재차 확인을 위해 error강조 */
    setConfirmPasswordLog(!isValid);
    return isValid;
  }

  /**
   * (닉네임 안 쓰고 윈도우 창 나갈 때)
   * firebase 계정, 로컬 스토리지 ID 토큰 삭제
   */
  async function withoutNickNameDeleteAuth(event) {
    event.preventDefault();
    if (await deleteAuth()) {
      await localStorage.removeItem("login-token");
    }
  }

  /** 회원가입 폼 제출 함수 */
  async function handleSubmit(event) {
    event.preventDefault();
    setEmailLog(false);
    setPasswordLog(false);

    let isSuccess = false;
    /** 비밀번호 검사 */
    if (!validatePassword()) return;
    await createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        // console.log("회원가입 성공!", res);
        isSuccess = true;
        localStorage.setItem("login-token", res.user.accessToken);
        // 아직 갱신해주지 말기
        // handleOnChangeSuccess({ id: "login", state: true });
      })
      .catch((err) => {
        /** 에러 */
        isSuccess = false;
        handleAuthError(err.code);
        switch (err.code) {
          case "auth/email-already-in-use": {
            return setEmailLog(true);
          }
          case "auth/invalid-email": {
            return setEmailLog(true);
          }
          case "auth/weak-password": {
            return setPasswordLog(true);
          }
        }
      });
    if (!isSuccess) {
      // await handleOnChangeSuccess({ id: "login", state: false });
      return;
    }
    /**
     * 닉네임 입력 안 하고 닫아버릴 수 있으니(어차피 계정 삭제되지만) displayName 임의로 넣어주기
     * display name update
     */
    if (!handleDisplayName("Guest")) {
      return; // 업데이트 실패 시, 바로 리턴
    }
    /** ID 토큰 로컬스토리지에 담기 */
    if (!(await handleIdToken("login-token"))) {
      return; // 못 저장하면, 바로 리턴
    }
    /**
     * 닉네임 작성하기 위해 모달창 띄우기
     * => handleNickNameSubmit
     */
    await handleOpenNickNameModal();
    // await handleOnChangeSuccess({ id: "login", state: true });
  }

  /** 닉네임 폼 제출 함수 */
  async function handleNickNameSubmit(event) {
    event.preventDefault();
    /**
     * 사용자가 작성한 display name update
     * => 업데이트 성공시, 계정 연동
     */
    if (!(await handleDisplayName(nickName))) {
      // await handleOnChangeSuccess({ id: "login", state: false });
      return;
    }
    /** 주의! display name 갱신 후 다시 login-token 갱신해주기 */
    await handleIdToken("login-token");
    /** 닉네임 작성 모달창 닫고 */
    await handleCloseNickNameModal();

    /** 리퀘스트 헤더에 토큰 담기 */
    if (!(await handleRequestHeader())) {
      return; // 못 담으면, 바로 리턴
    }
    /** stat API 호출해서 사용자 정보 넣기 */
    await handleGetUserStat();

    /** 이제서야 로그인 완료! => user setting */
    await checkUserStat();
    /** 뒤로가기 */
    await mainPage();
  }

  /** 닉네임 display name update 함수 */
  async function handleDisplayName(name) {
    let isUpdated = false;
    // console.log("닉네임 잘 들어왔나>", name);
    await updateProfile(auth.currentUser, {
      displayName: name,
    })
      .then(() => {
        /** 닉네임 업데이트 성공 */
        isUpdated = true;
      })
      .catch((error) => {
        isUpdated = false;
      });
    return isUpdated;
  }

  /** 사용자 Stat API 요청 - 닉네임 post */
  async function handleGetUserStat() {
    let isSuccess = false;
    await getUserStat()
      .then((res) => {
        isSuccess = true;
        // console.log("사용자 stat 받았어용 stat 볼까?", res);
      })
      .catch((err) => {
        isSuccess = false;
        // console.log("사용자 stat 못 받았어용", err);
      });
    return isSuccess;
  }

  /** 구글로 최초 로그인 */
  /** 구글의 display name 그대로 받기 */
  async function handleGoogleAuthProvider(event) {
    event.preventDefault();

    let isSuccess = false;
    await signInWithPopup(auth, googleAuthProvider)
      .then((res) => {
        // console.log("구글로 최초 로그인~~", res);

        /** 기존 이메일로 회원가입한 유저 -> 구글 회원가입 연결 */
        const credential = OAuthProvider.credentialFromResult(res);
        linkWithCredential(auth.currentUser, credential)
          .then((usercred) => {
            const user = usercred.user;
            // console.log("Account linking success", user);
            // localStorage.setItem("login-token", user.accessToken);
            // handleOnChangeSuccess({ id: "login", state: true });
          })
          .catch((err) => {
            /** 에러 */
            // console.log("Account linking error", error);
            handleAuthError(err.code);
            switch (err.code) {
              case "auth/provider-already-linked": {
                return setEmailLog(true);
              }
            }
          });

        /** 이미 회원가입한 계정인지 확인 */
        const { isNewUser } = getAdditionalUserInfo(res);
        // 존재하는 계정
        if (!isNewUser) {
          logout(false)
            .then(() => {
              // console.log("이미 있는 계정입니다.");
              handleAuthError("auth/email-already-in-use");
              setEmailLog(true);
            })
            .catch((error) => {
              // console.log("삭제 오류", error);
            });
        }
        // 회원가입 안 한 계정이니까, 회원가입 진행
        else {
          isSuccess = true;
          // console.log("회원가입 성공!");
          //  localStorage.setItem("login-token", res.user.accessToken);
          // checkUserStat();
          // if (userIdToken !== "Guest") checkRecord(userIdToken);
          /**
           * 로그인 페이지에선 안 부름
           * 항상 APP에서 감시하니까
           */
          // handleOnChangeSuccess({ id: "login", state: true });
          /** 로그인 성공 시, 뒤로 가기 (로그인 페이지는 뒤로 가기로 못 감) */
          //  mainPageRefresh();
        }
      })
      .catch((err) => {
        // console.log("구글로 최초 로그인 에러~~", err);
      });

    if (!isSuccess) {
      // await handleOnChangeSuccess({ id: "login", state: false });
      return;
    }
    /** Google displayname 16 자 이하로 자르기 */
    let displayName = "";
    const user = auth.currentUser;
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      displayName = user.displayName;
    }
    // console.log("현재 displayName : ", displayName);
    if (displayName.length > 16) displayName = displayName.substring(0, 16);
    // console.log("바뀐 displayName : ", displayName);

    /**
     * 사용자가 작성한 display name update
     * => 업데이트 성공시, 계정 연동
     */
    if (!(await handleDisplayName(displayName))) {
      await handleOnChangeSuccess({ id: "login", state: false });
      return;
    }

    /** ID 토큰 로컬스토리지에 담기 */
    if (!(await handleIdToken("login-token"))) {
      return; // 못 저장하면, 바로 리턴
    }

    /** 리퀘스트 헤더에 토큰 담기 */
    if (!(await handleRequestHeader())) {
      return; // 못 담으면, 바로 리턴
    }
    /** stat API 호출해서 사용자 정보 넣기 */
    await handleGetUserStat();

    // /** 이제서야 로그인 완료! => user setting */
    await checkUserStat();
    if (userIdToken !== "Guest") await checkRecord(userIdToken);
    /** 로그인 성공 시, 뒤로 가기 (로그인 페이지는 뒤로 가기로 못 감) */
    await mainPage();
  }

  return (
    <SignUp
      email={email}
      password={password}
      confirmPassword={confirmPassword}
      nickName={nickName}
      authError={authError}
      emailLog={emailLog}
      passwordLog={passwordLog}
      confirmPasswordLog={confirmPasswordLog}
      isChecked={isChecked}
      handleIsChecked={handleIsChecked}
      isDisabled={isDisabled}
      isNickNameDisabled={isNickNameDisabled}
      isOpenNickNameModal={isOpenNickNameModal}
      handleOnChangeInput={handleOnChangeInput}
      handleSubmit={handleSubmit}
      handleNickNameSubmit={handleNickNameSubmit}
      handleGoogleAuthProvider={handleGoogleAuthProvider}
      enablePrevent={enablePrevent}
      disablePrevent={disablePrevent}
      loginPageRePlace={loginPageRePlace}
    />
  );
}
export default SignUpContainer;
