import { Typography } from "@mui/material";
import { FlexBox, FlexColumnBox } from "../layout";
import { CheckCircle, Cancel } from "@mui/icons-material";
import ParseHtml from "../atom-components/ParseHtml";
import useSx from "../../hooks/useSx";
import { lightGreen, red } from "@mui/material/colors";

/**
 * 퀴즈 결과 컴포넌트
 * @param {object} resultQuiz : 퀴즈 데이터
 * @param {bool} isCorrect : 정답 여부 - 정답(true), 오답(false)
 * @param {bool} isPreviewImage : 미리보기 이미지인지 여부 ( true : 미리보기 이미지 => media 안붙여도 됨 , false : (기본값) 서버에서 받아온 이미지)
 */
function Result({ resultQuiz, isCorrect, isPreviewImage }) {
  const { isWindowMdDown } = useSx();

  /** 해설 사용자 정의 테마 */
  const commentriesTheme = {
    /** 정답 */
    right: {
      color: "success.main",
      backgroundColor: lightGreen[50],
      text: "정답입니다 !",
      icon: <CheckCircle fontSize="large" color="success" />,
    },
    /** 오답 */
    wrong: {
      color: "error.main",
      backgroundColor: red[50],
      text: "오답입니다 !",
      icon: <Cancel fontSize="large" color="error" />,
    },
  };
  /** 정답 여부에 따라 사용자 테마 정의 입힘 */
  const commentrayTheme = isCorrect
    ? commentriesTheme.right
    : commentriesTheme.wrong;

  return (
    <FlexBox
      sx={{
        width: "100%",
        backgroundColor: `${commentrayTheme.backgroundColor}`,
        justifyContent: "center",
        alignItems: "flex-start",
        marginBottom: isWindowMdDown ? "5.6rem" : "8.0rem",
        minWidth: 328,
      }}
    >
      <FlexColumnBox
        sx={{
          display: "flex",
          width: "100%",
          // justifyContent: "flex-start",
          margin: "0 auto",
          // padding: "10rem 0 30rem",
          // maxWidth: 1128,
          // alignItems: "flex-start",
          gap: (theme) => theme.spacing(6),
          padding: "2.4rem 3.2rem",
          maxWidth: 936,
        }}
      >
        <FlexBox
          sx={{
            gap: (theme) => theme.spacing(4),
            alignItems: "center",
          }}
        >
          {commentrayTheme.icon}
          <Typography variant="h4" color={commentrayTheme.color}>
            {commentrayTheme.text}
          </Typography>
        </FlexBox>
        <FlexBox sx={{ m: "0 4.4rem" }}>
          {
            /** 해설 텍스트 있으면 렌더 */
            !!resultQuiz.commentaryText && (
              <ParseHtml htmlCodes={resultQuiz.commentaryText} />
            )
          }
        </FlexBox>
      </FlexColumnBox>
    </FlexBox>
  );
}
export default Result;
