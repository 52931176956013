import React, { useEffect, useState } from "react";
import DetailReferenceContainer from "./DetailReferenceContainer";
import getUrlParam from "../../utils/getUrlParam";
import Reference from "../../pages/reference/Reference";
import WriteReferenceContainer from "./WriteReferenceContainer";
import { useDispatch } from "react-redux";
import { onLoadingFalse, onLoadingTrue } from "../../modules/header";
import usePage from "../../hooks/usePage";
import useSx from "../../hooks/useSx";
import useMySnackbar from "../../hooks/useMySnackbar";
import { handleGetManageReferenceList } from "../../utils/actions/handleUserReference";

function ReferenceContainer() {
  /**
   * 현재 url에서 저작한 관련자료 id 뽑아 내기
   */
  const { createId, isWrite } = getUrlParam();

  //   const { loginPage, writePage, backPage } = usePage();
  //   const { openSnackbar } = useMySnackbar();
  //   /** Redux State */
  const dispatch = useDispatch();

  const { loginPage } = usePage();
  const { openSnackbarHere } = useMySnackbar();

  // 관련자료 | 요청
  const [referenceList, setReferenceList] = useState([]);
  const [isGetReferencesLoading, setIsGetReferencesLoading] = useState(true);
  function isReferenceListEmpty() {
    return referenceList.length === 0;
  }
  // get references
  async function handleGetReferences() {
    const response = await handleGetManageReferenceList(
      setIsGetReferencesLoading,
      setReferenceList
    );
    if (!response) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
    }
  }

  useEffect(() => {
    handleGetReferences();
  }, []);

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  useEffect(() => {
    if (isGetReferencesLoading) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isGetReferencesLoading]);

  return (
    <>
      {isWrite ? (
        <WriteReferenceContainer />
      ) : createId !== null ? (
        <DetailReferenceContainer />
      ) : (
        <>
          <Reference
            isGetReferencesLoading={isGetReferencesLoading}
            isReferenceListEmpty={isReferenceListEmpty}
            referenceList={referenceList}
          />
        </>
      )}
    </>
  );
}
export default ReferenceContainer;
