const LOADING_TRUE = "header/LOADING_TRUE";
const LOADING_FALSE = "header/LOADING_FALSE";

// init State ( 초기 상태 )
const initialState = {
  isLoading: true,
};
// Action Creator Function ( 액션 생성 함수 )
export const onLoadingTrue = () => ({ type: LOADING_TRUE });
export const onLoadingFalse = () => ({ type: LOADING_FALSE });

export default function header(state = initialState, action) {
  switch (action.type) {
    case LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
