import { Divider } from "@mui/material";
import WriteQuizReferenceListItem from "./WriteQuizReferenceListItem";
import { FlexColumnBox } from "../layout";

function WriteQuizReferenceList({
  referenceList,
  isSelectedReference,
  onClickSelectReference,
  onClickDetailReference,
}) {
  function isLast(index) {
    return index !== referenceList.length - 1;
  }

  return (
    <>
      {referenceList.map((reference, index) => {
        const { referenceId } = reference;
        return (
          <FlexColumnBox key={referenceId} gap={2}>
            <WriteQuizReferenceListItem
              reference={reference}
              isSelectedReference={isSelectedReference}
              onClickSelectReference={onClickSelectReference}
              onClickDetailReference={onClickDetailReference}
            />
            {isLast(index) ? <Divider /> : <></>}
          </FlexColumnBox>
        );
      })}
    </>
  );
}
export default WriteQuizReferenceList;
