import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

async function getReferenceList(userId) {
  try {
    const response = await axios.get(APIURL.api + "quiz/write/references", {
      headers: {
        Authorization: `Bearer ${userId}`,
      },
    });
    return response.data;
  } catch (error) {
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getReferenceList;
