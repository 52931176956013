import { auth, signOut } from "../service/firebase";

/** 로그아웃 */
export async function logout(isReload = true) {
  let isSuccess = false;
  await signOut(auth)
    .then(() => {
      isSuccess = true;
      /** 로그아웃 성공 시, login-token 로컬 스토리지 지우고, 익명 로그인 */
      localStorage.removeItem("login-token");
      localStorage.removeItem("login-level");
      /** 새로고침 */
      if (isReload) window.location.reload();
    })
    .catch((error) => {
      isSuccess = false;
      // console.log("로그아웃 에러", error);
    });

  return isSuccess;
}
