/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";

const USER_STAT_INIT = "auth/USER_STAT_INIT";
const USER_STAT_SET = "auth/USER_STAT_SET";

// const USER_ID_TOKEN = "auth/USER_ID_TOKEN";
const USER_ACHIEVEMENT_ID_LIST = "auth/USER_ACHIEVEMENT_ID_LIST";
const USER_ACHIEVEMENT_ID_LIST_INIT = "auth/USER_ACHIEVEMENT_ID_LIST_INIT";

const USER_NICK_NAME = "auth/USER_NICK_NAME"; // 닉네임
const USER_PROFILE_IMAGE = "auth/USER_PROFILE_IMAGE"; // 프로필 이미지

// const USER_LEVEL = "auth/USER_LEVEL";
// const USER_LEVEL_XP = "auth/USER_LEVEL_XP";

//프로필 세팅
const USER_PROFILE_SET = "auth/USER_PROFILE_SET";

//달성한 업적
const USER_ACHIEVEMENT_LIST = "auth/USER_ACHIEVEMENT_LIST";

/** init State ( 초기 상태 ) */
const initialState = {
  loginSuccess: false, // 로그인 여부
  userIdToken: "Guest", // userId 보안키
  userAchievementIdList: [], // 달성한 업적 (이벤트를 위함)
  userNickname: " ", // 닉네임
  userLevel: 0, // 레벨
  userLevelXp: 0, // 현재 레벨 XP
  //프로필
  profileImage: { url: "", file: null }, // 이미지{
  //   url: createImageUrl(file),
  //   file: formData, => null 이면 새로운 파일 추가 X => 즉, 프로필 변경 안 했다는 소리
  // }

  totalPlayTime: "", // 누적 풀이 시간
  totalPlayCount: "", // 누적 풀이 문제 수
  correctAnswerRate: 0, // 정답률
  maxComboCount: 0, // 최대 콤보 횟수
  achievementsList: [
    // 달성한 업적 (배지 보여주기 위함)
    // {
    //   achievementsId: "",
    //   isClear: false, => 달성 여부, true를 우선순위
    // },
  ],
  achievementsListMaxPageNum: 1,
  achievementsListCurrentPageNum: 1,
  isWritable: false, // 저작 권한 여부
  // isActivate: false, // 계정 활성화 여부 (굳이 저장할 필요는 없을 듯! 어차피 true여야만 로그인 가능하니까)
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeSuccess = ({ id, state }) => ({
  type: id === "login" && LOGIN_SUCCESS,
  payload: state,
});

/** 추가하는 형태이기 때문에, 추가할 리스트만 인자로 보내주면 됨 */
export const onChangeUserAchievementIdList = (achievementIdList) => ({
  type: USER_ACHIEVEMENT_ID_LIST,
  achievementIdList,
});
/** 달성한 업적 초기화 */
export const onInitUserAchievementIdList = () => ({
  type: USER_ACHIEVEMENT_ID_LIST_INIT,
});

//////
/** 인자로 들어온 stat으로 초기화 */
export const onSetUserStat = (userStat) => ({
  type: USER_STAT_SET,
  userStat,
});
/** 익명일 시, 아예 초기화 */
export const onInitUser = () => ({
  type: USER_STAT_INIT,
});

/// 프로필
export const onSetUserProfile = (userData) => ({
  type: USER_PROFILE_SET,
  userData,
});

// 닉네임 하나 수정
export const onSetUserNickName = ({ id, value }) => ({
  type: id === "userNickname" && USER_NICK_NAME,
  [id]: value,
});
// 프로필 이미지 하나 수정
export const onSetUserProfileImage = (value) => ({
  type: USER_PROFILE_IMAGE,
  value,
});

// 달성한 업적 (프로필)
export const onSetAchievementsList = (value) => ({
  type: USER_ACHIEVEMENT_LIST,
  value,
});

/** reducer정의 */
export default function auth(state = initialState, action) {
  // console.log("action: ", action);
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: true,
      };
    case USER_STAT_SET: // 로그인 성공, 인자로 들어온 stat으로 초기화
      return {
        ...state,
        loginSuccess: true,
        userIdToken: action.userStat.userIdToken, // userId 보안키
        userNickname: action.userStat.userNickname, // 닉네임
        userLevel: action.userStat.userLevel, // 레벨
        userLevelXp: action.userStat.userLevelXp, // 현재 레벨 XP
        profileImage: action.userStat.profileImage, // 이미지
        isWritable: action.userStat.isWritable, // 저작권한
      };
    case USER_STAT_INIT: // 로그인 안 되어 있을 시, 초기화
      return {
        loginSuccess: false,
        userIdToken: "Guest",
        userAchievementIdList: [],
        userNickname: "",
        userLevel: 0,
        userLevelXp: 0,
        profileImage: {}, // 이미지
        totalPlayTime: "", // 누적 풀이 시간
        totalPlayCount: "", // 누적 풀이 문제 수
        correctAnswerRate: 0, // 정답률
        maxComboCount: 0, // 콤보횟수
        isWritable: false,
      };
    case USER_ACHIEVEMENT_ID_LIST:
      return {
        ...state,
        userAchievementIdList: action.achievementIdList,
      };
    case USER_ACHIEVEMENT_ID_LIST_INIT:
      return {
        ...state,
        userAchievementIdList: [],
      };
    case USER_PROFILE_SET:
      return {
        ...state,
        loginSuccess: true,
        userIdToken: action.userData.userIdToken, // userId 보안키
        userNickname: action.userData.userNickname, // 닉네임
        userLevel: action.userData.userLevel, // 레벨
        userLevelXp: action.userData.userLevelXp, // 현재 레벨 XP
        profileImage: action.userData.profileImage, // 이미지
        totalPlayTime: action.userData.totalPlayTime, // 누적 풀이 시간
        totalPlayCount: action.userData.totalPlayCount, // 누적 풀이 문제 수
        correctAnswerRate: action.userData.correctAnswerRate, // 정답률
        maxComboCount: action.userData.maxComboCount, // 콤보횟수
      };
    case USER_NICK_NAME:
      return {
        ...state,
        userNickname: action.userNickname,
      };

    case USER_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.value,
      };
    case USER_ACHIEVEMENT_LIST:
      return {
        ...state,
        achievementsListMaxPageNum: action.value.maxPageNum,
        achievementsListCurrentPageNum: action.value.currentPageNum,
        achievementsList: action.value.achievementsList,
      };

    default:
      return state;
  }
}
