import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  deleteUser,
  signInAnonymously,
  setPersistence,
  browserSessionPersistence,
  signInWithCustomToken,
  OAuthProvider,
  linkWithCredential,
  signInWithCredential,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

/** 프로젝트 config */
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// google analytics
const analytics = getAnalytics(app);

// Authentication SDK 추가 및 초기화
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

/** google auth provider */
const googleAuthProvider = new GoogleAuthProvider();

export {
  auth,
  analytics,
  logEvent,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  googleAuthProvider,
  signInWithPopup,
  updateProfile,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  deleteUser,
  signInAnonymously,
  setPersistence,
  browserSessionPersistence,
  signInWithCustomToken,
  OAuthProvider,
  linkWithCredential,
  signInWithCredential,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
};
