import React from "react";
import MyLoading from "../../components/global-components/MyLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  onSetUserNickName,
  onSetUserProfile,
  onSetUserProfileImage,
} from "../../modules/auth";
import { auth } from "../../service/firebase";
import handleRequestHeader from "../../utils/handleRequestHeader";
import getUserProfile from "../../modules/_actions/profile/getUserProfile";
import usePage from "../../hooks/usePage";
import APIURL from "../../modules/apiUrls";
import postProfileImage from "../../modules/_actions/profile/postProfileImage";
import putProfileNickname from "../../modules/_actions/profile/putProfileNickname";
import deleteProfileImage from "../../modules/_actions/profile/deleteProfileImage";
import useMySnackbar from "../../hooks/useMySnackbar";
import postDeleteAccount from "../../modules/_actions/request/postDeleteAccount";
import { handleIdToken } from "../../utils/handleIdToken";
import Account from "../../components/setting/Account";
import { onLoadingFalse, onLoadingTrue } from "../../modules/header";

/** 설정 -계정 */
function AccountContainer({}) {
  const { loginPage } = usePage();

  /** Redux State */
  const dispatch = useDispatch();

  /** 스낵바 */
  const { openSnackbarHere } = useMySnackbar();

  const { userNickname, profileImage } = useSelector((state) => ({
    userNickname: state.auth.userNickname, // 닉네임
    profileImage: state.auth.profileImage, // 이미지
  }));

  /** 유저 데이터 세팅 */
  const handleOnSetUserProfile = (value) => dispatch(onSetUserProfile(value));
  /** 닉네임 수정 */
  const handleOnSetUserNickName = (event) =>
    dispatch(onSetUserNickName(event.target));
  /** 이미지 수정, url과 파일 둘 다 저장하기 */
  const handleOnSetUserProfileImage = (value) =>
    dispatch(onSetUserProfileImage(value));

  const [isEdit, setIsEdit] = React.useState(false); // 프로필 편집 모드인지
  /** ================프로필 이미지================= */
  /**
   * Ref를 사용해서 input태그 참조
   * -> 이미지 추가 누르면, 사진 선택 가능
   */
  const imageInput = React.useRef(null);
  function changeImageSrc() {
    imageInput.current.click();
  }

  // 기본 이미지로 변경
  function changeDefaultProfileImage() {
    handleOnSetUserProfileImage({
      url: "",
      file: null,
    }); // 이미지 url
  }
  /**===============URL API를 이용한 이미지 URL 생성========================= */
  function createImageUrl(fileBlob) {
    return URL.createObjectURL(fileBlob); // url 생성
  }

  /** + 이미지 추가 버튼 눌렀을 때 ref에서 일어나는 일 */
  function onAddImageChange(event) {
    event.preventDefault();

    // 파일이 있는 경우
    if (event.target.files.length !== 0) {
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append("profileImage", file);
      handleOnSetUserProfileImage({
        url: createImageUrl(file),
        file: formData,
      }); // 이미지 url
    }
  }

  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false); //업데이트 로딩
  /** 프로필 저장 -> 이미지, 닉네임 업데이트 */
  function submitProfileUpdate() {
    setIsUpdateLoading(true);
    // 기본 이미지면 삭제 API 호출
    if (isEdit && profileImage.file === null && profileImage.url === "") {
      deleteUserProfileImage();
    }
    // 이미지 변경사항 있으면 API 호출
    if (isEdit && profileImage.file !== null) {
      postUserProfileImage();
    }
    // 닉네임 변경 사항 API 호출
    putUserProfileNickname();
    setIsEdit(false);
    setIsUpdateLoading(false);

    // 수정 완료 성공
    openSnackbarHere("success", "성공적으로 계정 수정을 하였습니다.");
  }

  /** 이미지 삭제 */
  async function deleteUserProfileImage() {
    const loginToken = await localStorage.getItem("login-token");

    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;

      if (isSuccess) {
        await deleteProfileImage().then((res) => {
          //console.log("res:", res);
        });
      }
    } else {
      /** 로그인 하러 가기 */
      await loginPage();
      return;
    }
  }

  /** 이미지 업데이트 */
  async function postUserProfileImage() {
    let body = {
      profileImage: profileImage.file,
    };

    const loginToken = await localStorage.getItem("login-token");

    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;

      if (isSuccess) {
        await postProfileImage(body).then((res) => {
          //console.log("res:", res);
          handleOnSetUserProfileImage({
            url: APIURL.media + res.newImage, // 이미지 url
            file: null,
          });
        });
      }
    } else {
      /** 로그인 하러 가기 */
      await loginPage();
      return;
    }
  }

  /** ================닉네임================= */
  const [isNicknameActive, setIsNicknameActive] = React.useState(false);
  /** 글자 수 */
  React.useEffect(() => {
    if (userNickname.length >= 2 && userNickname.length <= 12)
      setIsNicknameActive(false);
    else setIsNicknameActive(true);
  }, [userNickname]);

  /** 닉네임 최대 길이 */
  const NICKNAME_LIMIT = 12;
  const USER_NICK_NAME = "userNickname";

  function handleEditOpen() {
    setIsEdit(true);
  }
  function handleEditClose() {
    setIsEdit(false);
  }

  /** 닉네임 업데이트 */
  async function putUserProfileNickname() {
    let body = {
      nickname: userNickname,
    };

    const loginToken = await localStorage.getItem("login-token");

    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;

      if (isSuccess) {
        await putProfileNickname(body).then((res) => {
          // //console.log("res:", res);
          handleOnSetUserNickName({
            target: { id: USER_NICK_NAME, value: res.changeNickname },
          });
        });
      }
    } else {
      /** 로그인 하러 가기 */
      await loginPage();
      return;
    }
  }

  /**============== 사용자 프로필 정보 로딩 */
  const [isLoading, setIsLoading] = React.useState(true);
  async function loadUserProfile() {
    await setIsLoading(true);
    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;

      if (isSuccess) {
        await getUserProfile().then((res) => {
          // //console.log("res:", res);
          handleOnSetUserProfile({
            userIdToken: res.userId, // userId 보안키
            userNickname: res.nickname, // 닉네임
            userLevel: res.userLevel, // 레벨
            userLevelXp: res.currentXp, // 현재 레벨 XP
            profileImage: {
              url:
                res.profileImage === "" ? "" : APIURL.media + res.profileImage, // 여기서 바로 media 붙여서 갱신해주기
              file: null, // 만약 null 이면 새로운 파일 추가 된 거 아니니까 API호출 X
            }, // 이미지
            totalPlayTime: res.totalPlayTime, // 누적 풀이 시간
            totalPlayCount: res.totalPlayCount, // 누적 풀이 문제 수
            correctAnswerRate: res.correctAnswerRate, // 정답률
            maxComboCount: res.maxComboCount, // 콤보
          });
        });
      }
    } else {
      /** 로그인 하러 가기 */
      await loginPage();
    }
    // 이메일 로드
    await loadUserEmail();
    // await setIsLoading(false);
  }

  /** 프로필 사진 기본이미지, 이미지 선택 팝업 */
  const [popUpOpen, setPopUpOpen] = React.useState(false);
  const handleClick = () => {
    setPopUpOpen((prev) => !prev);
  };
  const handleClickAway = () => {
    setPopUpOpen(false);
  };

  /** sm : 팝업 열고 닫기 */
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setPopUpOpen(open);
  };

  // 유저 이메일
  const [userEmail, setUserEmail] = React.useState(null);
  async function loadUserEmail() {
    await auth.onAuthStateChanged((res) => {
      setUserEmail(res.email);
      // 로딩 풀기
      setIsLoading(false);
    });
  }

  /** ========================계정 탈퇴============================ */
  const [warningModal, setWarningModal] = React.useState(false); // 탈퇴 재확인 모달창
  function onClickDeleteAccount() {
    setWarningModal(true);
  }
  // 취소 버튼
  function onClickCancle() {
    setWarningModal(false);
  }
  // 진짜 삭제 버튼
  async function onClickCheckDeleteAccount() {
    let isSuccess = false;
    /** 로그인 상태 login-token */
    /**ID 토큰 로컬스토리지에 담기 */
    await handleIdToken("login-token").then((res) => {
      if (res) isSuccess = true;
      else isSuccess = false;
    });

    /** 리퀘스트 헤더에 토큰 담기 */
    await handleRequestHeader().then((res) => {
      if (res) isSuccess = true;
      else isSuccess = false;
    });
    /** 위의 과정을 수행 못 했다면, 바로 리턴 */
    if (!isSuccess) return;

    /** server에 요청 */
    await postDeleteAccount().then((res) => {
      if (res["tobeDeletedDate"] !== undefined) {
        openSnackbarHere("success", res.message);
        setWarningModal(false);
      }
    });
  }

  React.useEffect(() => {
    const l = loadUserProfile();
  }, []);

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  React.useEffect(() => {
    if (isLoading || userEmail === null) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isLoading, userEmail]);

  return (
    <>
      {(isLoading || userEmail === null) && <MyLoading />}
      {!isLoading && userEmail !== null && (
        <Account
          changeImageSrc={changeImageSrc}
          changeDefaultProfileImage={changeDefaultProfileImage}
          isEdit={isEdit}
          profileImage={profileImage}
          imageInput={imageInput}
          onAddImageChange={onAddImageChange}
          USER_NICK_NAME={USER_NICK_NAME}
          NICKNAME_LIMIT={NICKNAME_LIMIT}
          userNickname={userNickname}
          handleOnSetUserNickName={handleOnSetUserNickName}
          submitProfileUpdate={submitProfileUpdate}
          popUpOpen={popUpOpen}
          toggleDrawer={toggleDrawer}
          handleClick={handleClick}
          handleClickAway={handleClickAway}
          isNicknameActive={isNicknameActive}
          isUpdateLoading={isUpdateLoading}
          handleEditOpen={handleEditOpen}
          // handleEditClose={handleEditClose}
          userEmail={userEmail}
          // 계정 탈퇴
          onClickDeleteAccount={onClickDeleteAccount}
          warningModal={warningModal}
          onClickCancle={onClickCancle}
          onClickCheckDeleteAccount={onClickCheckDeleteAccount}
        />
      )}
    </>
  );
}
export default AccountContainer;
