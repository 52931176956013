import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function BasicPagination({ count, page, handlePageChange }) {
  // console.log("현재 페이지:", page);
  return (
    <Stack spacing={2} sx={{ py: "4rem" }}>
      <Pagination
        count={count}
        page={page}
        onChange={handlePageChange}
        color="primary"
      />
    </Stack>
  );
}
export default BasicPagination;
