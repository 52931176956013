import URL from "../components/pageUrls";

/**
 * 현재 url에서 id 뽑아 내기
 * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
 * - topicId : 주제 id
 * - quizId : 퀴즈 id
 * - createId : 저작한 퀴즈 id
 */
function getUrlParam() {
  let param = new URLSearchParams(window.location.search);
  let historyId = param.get(URL.HISTORY_ID);
  let topicId = param.get(URL.TOPIC_ID);
  let quizId = param.get(URL.QUIZ_ID);
  let createId = param.get(URL.CREATE_ID);

  /** 저작 메인 페이지인지 구분 */
  let isCreate = window.location.pathname.includes(URL.CREATE);
  /** 관련자료 메인 페이지인지 구분 */
  let isReference = window.location.pathname.includes(URL.REFERENCE);
  /** 저작 페이지인지 구분 */
  let isWrite = window.location.pathname.includes(URL.WRITE);
  /** 설정 페이지 구분 */
  let isSetting = window.location.pathname.includes(URL.SETTING);

  // console.log(
  //   URL.HISTORY_ID,
  //   historyId,
  //   URL.TOPIC_ID,
  //   topicId,
  //   URL.QUIZ_ID,
  //   quizId
  // );

  return {
    historyId,
    topicId,
    quizId,
    isCreate,
    createId,
    isWrite,
    isSetting,
    isReference,
  };
}
export default getUrlParam;
