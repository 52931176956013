import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/** (익명) 주제 문제 리스트 제공 API */
async function getQuizTopicGuest(params = {}) {
  let data;
  try {
    await axios
      .get(
        APIURL.api +
          `quiz/topic/${params.topicId}/guest?pageNum=${params.pageNum}&&pagingSize=${params.pagingSize}`
      )
      .then((response) => {
        // console.log("주제 문제 리스트 받음", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // console.log("주제 문제 리스트 못 받음...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getQuizTopicGuest;
