import { Box, Divider, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { OutlinedIconButton } from "../../components/atom-components/StyledButtons";
import BreadCrumbsTitle from "../../components/global-components/BreadCrumbsTitle";
import DetailReferenceInfo from "../../components/reference/DetailReferenceInfo";
import PageScreenBox from "../../components/global-components/PageScreenBox";
import { SubHeader } from "../../components/atom-components/StyledHeaders";
import ModalWarning from "../../components/global-components/ModalWarning";
import PageTitle from "../../components/global-components/PageTitle";
import QuizState from "../../components/global-components/QuizState";
import { FlexBox, FlexColumnBox } from "../../components/layout";
import changeReferenceState from "../../utils/changeReferenceState";

function DetailReference({
  referencePageRefresh,
  onClickUpdateReference,
  onCLickDeleteReference,
  onClickCancle,
  onCLickCheckDeleteReference,
  isLoading,
  reference,
  warningModal,
}) {
  return (
    <>
      <SubHeader>
        <FlexColumnBox
          sx={{
            width: 1,
            maxWidth: 1128,
            gap: "0.4rem",
            p: "1.6rem 0.75rem",
          }}
        >
          <BreadCrumbsTitle />
          <FlexBox
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageTitle
              titleName="상세 보기"
              pageBack
              onClick={referencePageRefresh}
            />
            <FlexBox gap="1.2rem">
              <Tooltip title="수정 하기">
                <div>
                  <OutlinedIconButton onClick={onClickUpdateReference}>
                    <EditIcon />
                  </OutlinedIconButton>
                </div>
              </Tooltip>
              <Tooltip title="삭제 하기">
                <div>
                  <OutlinedIconButton
                    onClick={onCLickDeleteReference}
                    isError
                    color="error"
                  >
                    <DeleteIcon />
                  </OutlinedIconButton>
                </div>
              </Tooltip>
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              mt: "1.2rem",
              flexWrap: "wrap",
            }}
          >
            {isLoading ? (
              <></>
            ) : (
              changeReferenceState(reference).map((comp, index) => (
                <QuizState key={index} quizData={comp} />
              ))
            )}
          </FlexBox>
        </FlexColumnBox>
        <Box sx={{ width: 1 }}>
          <Divider />
        </Box>
      </SubHeader>
      <PageScreenBox sx={{ pt: "22rem" }}>
        {isLoading ? <></> : <DetailReferenceInfo reference={reference} />}
      </PageScreenBox>
      {/* 문제 삭제하기 주의 모달창 */}
      <ModalWarning
        title="삭제하시겠습니까?"
        content="저작한 관련자료를 삭제하시면 다시 복구할 수 없습니다. 그래도 정말 삭제하시겠습니까?"
        buttonHandleName="삭제"
        modalWarningOpen={warningModal}
        onClickModalCancle={onClickCancle}
        onClickModalHandle={onCLickCheckDeleteReference}
      />
    </>
  );
}
export default DetailReference;
