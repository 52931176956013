import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { PopIconButton } from "../atom-components/StyledButtons";
import ReferenceCard from "../reference/ReferenceCard";
import useHover from "../../hooks/useHover";

function WriteQuizReferenceListItem({
  reference,
  isSelectedReference,
  onClickDetailReference,
  onClickSelectReference,
}) {
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover(false);
  const { referenceId } = reference;

  function isSelected() {
    return isSelectedReference(referenceId);
  }

  return (
    <div onMouseOver={handleMouseEnter} onMouseOut={handleMouseLeave}>
      <div style={{ position: "relative", margin: "0px 4px", zIndex: 1 }}>
        <PopIconButton
          sx={{
            m: "12px 4px",
            right: 0,
            position: "absolute",
            // selected => show | no selected => when hovered, show & no hovered, no show
            opacity: isSelected() ? 1 : isHovered ? 0.95 : 0,
            transition: (theme) =>
              theme.transitions.create(["opacity"], {
                duration: theme.transitions.duration.shorter,
              }),
          }}
          onClick={(event) => onClickSelectReference(event, referenceId)}
        >
          {isSelected() ? <CheckIcon /> : <AddIcon />}
        </PopIconButton>
      </div>
      <ReferenceCard
        reference={reference}
        isSelected={isSelected()}
        onClick={(event) => onClickDetailReference(event, reference)}
      />
    </div>
  );
}
export default WriteQuizReferenceListItem;
