import {
  VALUE_QUIZ_TYPE_MULTIPLE,
  VALUE_QUIZ_TYPE_OX,
  VALUE_QUIZ_TYPE_PUZZLE,
} from "../../constants/writeQuiz";

/** 다지선다형 : 1개 이상 정답이 체크되었는지 확인 */
function checkMultipleExampleAnswer(exampleItems) {
  let cnt = 0;
  exampleItems.forEach((item) => {
    item.isAnswer && cnt++;
  });
  return cnt >= 1;
}

/** 다지선다형 : 입력 다 했는지 확인 */
function checkMultipleExampleInput(exampleItems) {
  for (let i = 0; i < exampleItems.length; i++) {
    if (exampleItems[i].exampleText === "") {
      return false;
    }
  }
  return true;
}

/** 제출 조건에 맞는 지 확인 */
function checkSubmitCondition(
  selectedTopics,
  inputQuestionText,
  inputQuizType,
  inputAnswer,
  inputAnswerText,
  exampleItems,
  inputCommentaryText
) {
  // handleWriteError("init"); // 우선 초기화

  /** 1. 주제 1개 이상, 5개 이하 */
  if (0 >= selectedTopics.length || selectedTopics.length > 5) {
    // handleWriteError(ERROR_TOPIC);
    // console.log("주제 1개 이상 선택");
    return false;
  }

  // 2. check 질문
  if (inputQuestionText === "") {
    // handleWriteError(ERROR_QUESTION);
    // console.log("질문 작성 요망");
    return false;
  }

  // 3. check 예문 (선택)

  // 4. check quiz type
  // 4-1. OX
  if (inputQuizType === VALUE_QUIZ_TYPE_OX && inputAnswer === null) {
    // console.log("OX : 선택 안 함");
    // handleWriteError(TYPE_OX);
    return false;
  }
  // 4-2. PUZZLE
  if (inputQuizType === VALUE_QUIZ_TYPE_PUZZLE && inputAnswerText === "") {
    // console.log("PUZZLE : 정답 작성 안 함");
    // handleWriteError(TYPE_PUZZLE);
    return false;
  }
  // 4-3. MULTIPLE
  if (inputQuizType === VALUE_QUIZ_TYPE_MULTIPLE) {
    // 보기 2 ~ 5개여야 함.
    if (1 >= exampleItems.length || exampleItems.length > 5) {
      // handleWriteError(MULTIPLE_EXAMPLE_COUNT);
      // console.log("MULTIPLE : 보기 2~5개여야 함");
      return false;
    }

    // 1개 이상 정답이 체크되어야 함.(복수 정답 가능)
    if (!checkMultipleExampleAnswer(exampleItems)) {
      // console.log("MULTIPLE : 1개 이상 정답이 체크되어야 함");
      // handleWriteError(MULTIPLE_EXAMPLE_ANSWER);
      return false;
    }

    // 각각 보기 입력 다 해야 함.
    if (!checkMultipleExampleInput(exampleItems)) {
      //   handleWriteError(MULTIPLE_EXAMPLE_INPUT);
      // console.log("MULTIPLE : 각각 보기 입력 다 해야 함");
      return false;
    }
  }

  // 5. check commentary
  if (inputCommentaryText === "") {
    // console.log("해설 작성 요망");
    // handleWriteError(ERROR_COMMENTARY);
    return false;
  }
  return true;
}

function checkWriteProgressValue(
  selectedTopics,
  inputQuestionText,
  inputQuizType,
  inputAnswer,
  inputAnswerText,
  exampleItems,
  inputCommentaryText
) {
  let value = 0;
  const PROGRESS = 100 / 4;
  //1
  if (selectedTopics.length !== 0) {
    value += PROGRESS;
  }
  //2
  if (inputQuestionText !== "") {
    value += PROGRESS;
  }
  //3
  if (inputCommentaryText !== "") {
    value += PROGRESS;
  }
  //4
  if (inputQuizType === VALUE_QUIZ_TYPE_OX && inputAnswer !== null) {
    value += PROGRESS;
  }
  if (inputQuizType === VALUE_QUIZ_TYPE_PUZZLE && inputAnswerText !== "") {
    value += PROGRESS;
  }
  if (inputQuizType === VALUE_QUIZ_TYPE_MULTIPLE) {
    if (
      1 < exampleItems.length &&
      exampleItems.length <= 5 &&
      checkMultipleExampleAnswer(exampleItems) &&
      checkMultipleExampleInput(exampleItems)
    ) {
      value += PROGRESS;
    }
  }
  return value;
}
export { checkSubmitCondition, checkWriteProgressValue };
