import React from "react";
import AccountContainer from "./AccountContainer";
import RequestWriteContainer from "./RequestWriteContainer";
import { useParams } from "react-router-dom";
import URL from "../../components/pageUrls";
import usePage from "../../hooks/usePage";
import PasswordContainer from "./PasswordContainer";
import Setting from "../../pages/Setting";

/** 설정 */
function SettingContainer({}) {
  const { settingAccountPage, settingWriteAuthorityPage, settingPasswordPage } =
    usePage();

  // 탭
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // 현재 탭 메뉴
  const { menu } = useParams();

  // 탭 메뉴에 따른, 콘텐츠 컴포넌트
  const tabList = [
    {
      menuId: URL.ACCOUNT_ID,
      label: "계정",
      panel: <AccountContainer />,
      pageFunction: settingAccountPage,
    },
    {
      menuId: URL.WRITE_AUTHORITY_ID,
      label: "저작 권한",
      panel: <RequestWriteContainer />,
      pageFunction: settingWriteAuthorityPage,
    },
    {
      menuId: URL.PASSWORD_ID,
      label: "비밀번호",
      panel: <PasswordContainer />,
      pageFunction: settingPasswordPage,
    },
  ];

  // url에 따른 탭 메뉴 렌더
  React.useEffect(() => {
    tabList.map((list, index) => {
      if (menu === list.menuId) {
        // 현재 url의 menu 변수 보고, 그에 맞는 페이지 렌더
        list.pageFunction(); // url navigate 실행
        setValue(index); // tab의 value도 맞춰주기
        return;
      }
    });
  }, [value, menu]);

  return (
    <Setting value={value} handleChange={handleChange} tabList={tabList} />
  );
}
export default SettingContainer;
