import {
  Button,
  Box,
  Typography,
  Chip,
  Stack,
  Grid,
  useMediaQuery,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import DoneIcon from "@mui/icons-material/Done";
import React from "react";
import QuizCards from "./QuizCards";
import QuizTopicCards from "./QuizTopicCards";
import MyHistoryDrawer from "../global-components/MyHistoryDrawer";
import BasicPagination from "../global-components/BasicPagination";
import PageScreenBox from "../global-components/PageScreenBox";
import BreadCrumbsTitle from "../global-components/BreadCrumbsTitle";
import FabPageTop from "../global-components/FabPageTop";
import useSx from "../../hooks/useSx";
import PageFooter from "../global-components/PageFooter";

function QuizList({
  quizPage,
  topicQuizPage,
  isSelectedAll,
  handleAllTopic,
  representQuiz,
  isSelectedTopic,
  handleTopicClick,
  handleMoreClick,
  quizList,
  maxPageNum,
  currentPageNum,
  handlePageChange,
  topicName,
  isTopicPage,
  isFavorites,
}) {
  const { randomSx } = useSx();

  /** 현재 window 사이즈 sm down인지 */
  const isWindowSmDown = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // console.log("isTopicPage:", isTopicPage, "isFavorites:", isFavorites);
  /**
   * isTopicPage => 현재 메인페이지인지? 틀린&봤던 문제 페이지인지?
   * false : main
   * true : 틀린 문제 & 봤던 문제
   */
  /**
   * isFavorites => 각 주제마다의 더보기 페이지인지?
   * false : 각 주제마다 버튼 존재
   * true : 주제 버튼 없이, 페이지네이션
   */

  //=====================컴포넌트 정의=======================

  /** 각 주제마다 버튼, 칩 존재 */
  const QuizListPage = (
    <>
      {!isTopicPage && (
        <Stack
          /** 카테고리 선택 버튼 & 문제들 묶음 */
          spacing={12}
          sx={{ width: 1 }}
        >
          <Box
            /** 전체 & 카테고리 선택 버튼 나열 */
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 3,
            }}
          >
            <Chip
              variant={isSelectedAll ? "filled" : "outlined"}
              icon={isSelectedAll.toString() === "true" ? <DoneIcon /> : null}
              label="전체"
              onClick={handleAllTopic}
            />
            {representQuiz.map((topic) => (
              <Chip
                key={topic.topicId}
                sx={{
                  backgroundColor:
                    isSelectedTopic.get(topic.topicName).isSelected &&
                    "#00639b1a",
                }}
                variant={
                  isSelectedTopic.get(topic.topicName).isSelected
                    ? "filled"
                    : "outlined"
                }
                icon={
                  isSelectedTopic.get(topic.topicName).isSelected.toString() ===
                  "true" ? (
                    <DoneIcon />
                  ) : null
                }
                label={topic.topicName}
                onClick={() => handleTopicClick(topic.topicName)}
              />
            ))}
          </Box>
          <Box
            /** 각 카테고리별 문제 나열한 거 묶음 */
            sx={{ width: 1, display: "flex", justifyContent: "center" }}
          >
            {/* isFavorites : main 페이지인지? (main)즐겨찾기 버튼 x : (틀린, 봤던)즐겨찾기 버튼 o */}
            <QuizCards
              topicQuizPage={topicQuizPage}
              representQuiz={representQuiz}
              isSelectedTopic={isSelectedTopic}
              handleMoreClick={handleMoreClick}
              isFavorites={isFavorites}
            />
          </Box>
        </Stack>
      )}
    </>
  );

  /** 각 주제마다의 페이지(Pagination) */
  const QuizTopicListPage = (
    <>
      {isTopicPage && maxPageNum === 0 ? (
        <Typography variant="h4" color="text.secondary">
          풀 문제가 없습니다. ;(
        </Typography>
      ) : (
        <Box
          /** 각 카테고리별 문제 나열한 거 묶음 */
          sx={{
            width: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "4rem",
          }}
        >
          <QuizTopicCards quizList={quizList} isFavorites={isFavorites} />
          <BasicPagination
            count={maxPageNum}
            page={currentPageNum}
            handlePageChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
  /** 01. 페이지 바깥 레이아웃 */
  const PageLayout = (
    <Stack /** 문제, 일일목표 묶음 */ direction="row" sx={{ width: 1 }}>
      <Stack spacing={4} sx={{ width: 1 }}>
        <Grid container>
          <Grid item xs>
            <Grid item>
              {/* 흔적 인자로 보내주기 <PageBreadcrumbs /> */}
              {/* 각 주제별 주제 이름은 여기서 렌더할 예정 */}
              <BreadCrumbsTitle topicName={topicName} />
            </Grid>
            {isWindowSmDown && (
              <Grid item sx={{ mt: "2.5rem" }}>
                <MyHistoryDrawer
                /** 일일 목표 */
                />
              </Grid>
            )}
            <Grid
              item
              sx={{
                height: { xs: "fit-content", sm: "fit-content", md: "100%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  py: { xs: "3rem", sm: "4rem", md: "0rem" },
                }}
              >
                <Button
                  sx={{
                    p: "2rem 2.4rem 2rem 2rem",
                    borderRadius: "100rem",
                    maxWidth: "fit-content",
                  }}
                  variant="contained"
                  onClick={quizPage}
                  startIcon={<PlayArrowIcon sx={randomSx} />}
                >
                  <Typography variant="h5">전체 랜덤으로 풀기</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
          {!isWindowSmDown && (
            <Grid item>
              <MyHistoryDrawer
              /** 일일 목표 */
              />
            </Grid>
          )}
        </Grid>
        {/* 주제 페이지? 주제 버튼 없이, 페이지네이션 : 각 주제마다 랜덤 풀기 버튼 */}
        <>{isTopicPage ? QuizTopicListPage : QuizListPage}</>
      </Stack>
    </Stack>
  );

  return (
    <>
      <PageScreenBox /**제일 바깥 스크린 레이아웃  */>
        {PageLayout}
        <FabPageTop />
      </PageScreenBox>
      <PageFooter />
    </>
  );
}

export default QuizList;
