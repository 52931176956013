import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** 너비 정의 */
const WidthSize = {
  lg: [72, 168, 264, 360, 456, 552, 648, 744, 840, 936, 1032, 1128],
};

/** flex box */
const FlexBox = styled(Box)`
  display: flex;
`;

/** column flex box */
const FlexColumnBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export { WidthSize, FlexBox, FlexColumnBox };
