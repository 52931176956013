import deleteReferenceWrite from "../../modules/_actions/reference/deleteReferenceWrite";
import getReferenceList from "../../modules/_actions/reference/getReferenceList";
import getReferenceWrite from "../../modules/_actions/reference/getReferenceWrite";
import postReferenceWrite from "../../modules/_actions/reference/postReferenceWrite";
import putReferenceWrite from "../../modules/_actions/reference/putReferenceWrite";

/**
 * post Reference
 * @param {*} idToken
 */
async function handlePostReferenceWrite(idToken, body) {
  try {
    const response = await postReferenceWrite(idToken, body);
    if (response.hasOwnProperty("referenceId")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}
/**
 * delete Reference
 * @param {*} idToken
 */
async function handleDeleteReferenceWrite(idToken, param) {
  try {
    const response = await deleteReferenceWrite(idToken, param);
    if (response.hasOwnProperty("message")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * put Reference Write
 * @param {*} idToken
 */
async function handleUpdateReferenceWrite(idToken, body) {
  try {
    const response = await putReferenceWrite(idToken, body);
    if (response.hasOwnProperty("referenceId")) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * get Reference List
 * @param {*} idToken
 */
async function handleGetReferenceList(idToken) {
  try {
    const response = await getReferenceList(idToken);
    if (!!response) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

/**
 * handle get reference
 */
async function handleGetManageReferenceList(setIsLoading, setReferenceList) {
  await setIsLoading(true);

  // let body = {
  //   quizType: inputQuizType,
  //   difficultyValue: 1,
  // };

  // get login token
  const loginToken = await localStorage.getItem("login-token");
  if (loginToken === null) return false;

  const response = await handleGetReferenceList(loginToken);
  await setReferenceList(response);
  await setIsLoading(false);
  return true;
}

/**
 * get Reference
 * @param {*} idToken
 */
async function handleGetReferenceWrite(idToken, param) {
  try {
    const response = await getReferenceWrite(idToken, param);
    if (!!response) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    return error.code;
  }
}

export {
  handlePostReferenceWrite,
  handleDeleteReferenceWrite,
  handleUpdateReferenceWrite,
  handleGetReferenceList,
  handleGetManageReferenceList,
  handleGetReferenceWrite,
};
