import PageScreenBox from "../components/global-components/PageScreenBox";
import BreadCrumbsTitle from "../components/global-components/BreadCrumbsTitle";
import { Button, Stack, Grid, Box, Chip, Typography } from "@mui/material";
import CreateQuizCard from "../components/global-components/CreateQuizCard";
import { Masonry } from "@mui/lab";
import BasicPagination from "../components/global-components/BasicPagination";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import useSx from "../hooks/useSx";

/** 퀴즈 제작 페이지 */
function CreateQuiz({
  createId,
  isSelectedAll,
  handleChangeAll,
  isSelectedState,
  isSelectedFalse,
  handleChangeIsSelected,
  handlePageChange,
  quizList,
  currentPageNum,
  maxPageCount,
  writePage,
}) {
  const { randomSx } = useSx();

  const makeButton = (
    <Grid item>
      {/* <Button
        variant="contained"
        sx={{ minWidth: "fit-content", mr: "1.2rem" }}
      >
        대량 저작
      </Button> */}
      {/* <Button
        variant="contained"
        sx={{ minWidth: "fit-content" }}
        onClick={writePage}
        startIcon={<EditOutlinedIcon />}
      >
        퀴즈 저작 하기
      </Button> */}
      <Button
        sx={{
          p: "2rem 2.4rem 2rem 2rem",
          borderRadius: "100rem",
          minWidth: "fit-content",
          // color: "#68587A",
        }}
        variant="contained"
        onClick={writePage}
        // color="secondary"
        startIcon={<EditOutlinedIcon sx={randomSx} />}
      >
        <Typography variant="h5">퀴즈 저작 하기</Typography>
      </Button>
    </Grid>
  );

  return (
    <PageScreenBox /**제일 바깥 스크린 레이아웃  */>
      <Stack spacing={6} sx={{ width: 1 }}>
        <BreadCrumbsTitle />
        <Grid container sx={{ w: 1, justifyContent: "center" }}>
          {/*
          검색 기능
          <Grid item xs>
            {isWindowSmDown && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: "1.2rem",
                }}
              >
                {makeButton}
              </Box>
            )}
            <SearchBar placeholder="검색하실 문제를 입력해주세요." />
          </Grid> */}
          {/* {!isWindowSmDown && <Box  sx={{ ml: "1.2rem" }}>{makeButton}</Box>} */}
          <Box sx={{ mb: "1.2rem" }}>{makeButton}</Box>
        </Grid>
        <Box
          /** 전체 & 카테고리 선택 버튼 나열 */
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 3,
          }}
        >
          <Chip
            variant={isSelectedAll ? "filled" : "outlined"}
            icon={String(isSelectedAll) === "true" ? <DoneIcon /> : null}
            label="전체"
            onClick={handleChangeAll}
          />
          {isSelectedState.map((data) => (
            <Chip
              key={data.state}
              sx={{
                backgroundColor: data.isSelected && "#00639b1a",
              }}
              variant={data.isSelected ? "filled" : "outlined"}
              icon={String(data.isSelected) === "true" ? <DoneIcon /> : null}
              label={data.state}
              onClick={() => handleChangeIsSelected(data.state)}
            />
          ))}
        </Box>
        <Box
          /** 각 카테고리별 문제 나열한 거 묶음 */
          sx={{
            width: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "4rem",
          }}
        >
          {quizList.length === 0 ||
          quizList === undefined ||
          isSelectedFalse ? (
            <Typography variant="h4" color="text.secondary">
              저작한 문제가 없습니다. ;(
            </Typography>
          ) : (
            <>
              <Masonry columns={{ xs: 1, sm: 1, md: 2 }} spacing={10}>
                {quizList.map((quiz) => (
                  <CreateQuizCard key={quiz.quizId} quizData={quiz} />
                ))}
              </Masonry>
            </>
          )}
          <BasicPagination
            count={maxPageCount}
            page={currentPageNum}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Stack>
    </PageScreenBox>
  );
}
export default CreateQuiz;
