import * as React from "react";
import { Breadcrumbs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import getUrlParam from "../../utils/getUrlParam";
import URL from "../pageUrls";
import usePage from "../../hooks/usePage";
/**
 * 각 url로 판단하여, 바로가기 생성
 * ex. 틀린 문제의 각 주제별 문제 리스트 페이지
 * => 문제 풀기 > 틀린 문제 > 주제
 */
/**
 * @param {string} topicName : 주제 이름 (기본값 "", 아예 안 들어올 수 있음)
 */
function BreadCrumbsTitle({ topicName = "" }) {
  /**
   * 현재 url에서 id 뽑아 내기
   * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
   * - topicId : 주제 id
   * - quizId : 퀴즈 id
   * - isCreate : 현재 저작 페이지인지 여부
   * - createId : 제작한 퀴즈 id
   */
  const {
    historyId,
    topicId,
    quizId,
    isCreate,
    createId,
    isWrite,
    isReference,
  } = getUrlParam();
  const {
    mainBread,
    wrongPage,
    solvePage,
    createPageRefresh,
    createIdPage,
    referencePageRefresh,
    referenceCreateIdPage,
  } = usePage();

  /** 커스텀 링크 버튼 */
  function ButtonLink({ handleClick, title }) {
    return (
      <Link
        underline="hover"
        color="inherit"
        onClick={handleClick}
        fontSize="inherit"
        variant="caption"
      >
        {title}
      </Link>
    );
  }
  /** 커스텀 타이포그래피 */
  function CustomTypography({ title }) {
    return (
      <Typography
        fontSize="inherit"
        color="black"
        variant="caption"
        sx={{ fontWeight: "bold" }}
      >
        {title}
      </Typography>
    );
  }

  const com = {
    main: [<CustomTypography key="main" title="문제 풀기" />],
    mainTopic: [
      <ButtonLink
        key="mainTopic-main"
        handleClick={mainBread}
        title="문제 풀기"
      />,
      <CustomTypography key="mainTopic-main>topic" title={topicName} />,
    ],
    wrong: [
      <ButtonLink key="wrong-main" handleClick={mainBread} title="문제 풀기" />,
      <CustomTypography key="wrong-main>wrong" title="틀린 문제" />,
    ],
    solve: [
      <ButtonLink key="solve-main" handleClick={mainBread} title="문제 풀기" />,
      <CustomTypography key="solve-main>solve" title="풀었던 문제" />,
    ],
    wrongTopic: [
      <ButtonLink
        key="wrongTopic-main"
        handleClick={mainBread}
        title="문제 풀기"
      />,
      <ButtonLink
        key="wrongTopic-main>wrong"
        handleClick={wrongPage}
        title="틀린 문제"
      />,
      <CustomTypography key="wrongTopic-main>wrong>topic" title={topicName} />,
    ],
    solveTopic: [
      <ButtonLink
        key="solveTopic-main"
        handleClick={mainBread}
        title="문제 풀기"
      />,
      <ButtonLink
        key="solveTopic-main>solve"
        handleClick={solvePage}
        title="풀었던 문제"
      />,
      <CustomTypography key="solveTopic-main>solve>topic" title={topicName} />,
    ],
    create: [<CustomTypography key="create" title="퀴즈 저작" />],
    createDetail: [
      <ButtonLink
        key="createDetail-create"
        handleClick={createPageRefresh}
        title="퀴즈 저작"
      />,
      <CustomTypography key="createDetail-create>detail" title="상세 보기" />,
    ],
    createWrite: [
      <ButtonLink
        key="createDetail-create"
        handleClick={createPageRefresh}
        title="퀴즈 저작"
      />,
      <CustomTypography key="createWrite-create>write" title="저작 하기" />,
    ],
    createUpdate: [
      <ButtonLink
        key="createDetail-create"
        handleClick={createPageRefresh}
        title="퀴즈 저작"
      />,
      <ButtonLink
        key="createDetail-create>detail"
        handleClick={() => createIdPage(createId)}
        title="상세 보기"
      />,
      <CustomTypography
        key="createWrite-create>detail>write"
        title="수정 하기"
      />,
    ],
    reference: [<CustomTypography key="reference" title="관련자료 저작" />],
    referenceDetail: [
      <ButtonLink
        key="referenceDetail-create"
        handleClick={referencePageRefresh}
        title="관련자료 저작"
      />,
      <CustomTypography
        key="referenceDetail-create>detail"
        title="상세 보기"
      />,
    ],
    referenceWrite: [
      <ButtonLink
        key="referenceDetail-create"
        handleClick={referencePageRefresh}
        title="관련자료 저작"
      />,
      <CustomTypography key="referenceWrite-create>write" title="저작 하기" />,
    ],
    referenceUpdate: [
      <ButtonLink
        key="referenceDetail-create"
        handleClick={referencePageRefresh}
        title="관련자료 저작"
      />,
      <ButtonLink
        key="referenceDetail-create>detail"
        handleClick={() => referenceCreateIdPage(createId)}
        title="상세 보기"
      />,
      <CustomTypography
        key="referenceDetail-create>detail>write"
        title="수정 하기"
      />,
    ],
  };

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ width: "1.6rem", height: "1.6rem" }} />
      }
    >
      {
        isCreate
          ? isWrite
            ? (createId !== null && com.createUpdate) || // 수정 페이지
              (createId === null && com.createWrite) // 저작 페이지
            : (createId === null && com.create) || // 저작 메인 페이지
              (createId !== null && com.createDetail) // 저작 상세 페이지
          : isReference
          ? isWrite
            ? (createId !== null && com.referenceUpdate) || // 수정 페이지
              (createId === null && com.referenceWrite) // 관련자료 페이지
            : (createId === null && com.reference) || // 관련자료 메인 페이지
              (createId !== null && com.referenceDetail) // 관련자료 상세 페이지
          : (historyId === null && topicId === null && com.main) || // 메인 페이지
            (historyId === null && topicId !== null && com.mainTopic) || // 메인 주제 페이지
            (historyId === URL.WRONG_ID && topicId === null && com.wrong) || // 틀린 문제 페이지
            (historyId === URL.WRONG_ID &&
              topicId !== null &&
              com.wrongTopic) || // 틀린 문제 주제 페이지
            (historyId === URL.SOLVE_ID && topicId === null && com.solve) || // 풀었던 문제 페이지
            (historyId === URL.SOLVE_ID && topicId !== null && com.solveTopic) // 풀었던 문제 주제 페이지)
      }
    </Breadcrumbs>
  );
}
export default BreadCrumbsTitle;
