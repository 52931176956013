import { Draggable } from "react-beautiful-dnd";
import { grey } from "@mui/material/colors";
import DragHandler from "../global-components/draggable-list/DragHandler";
import useHover from "../../hooks/useHover";
import ReferenceCard from "../reference/ReferenceCard";
import RemoveIcon from "@mui/icons-material/Remove";

import { PopErrorIconButton } from "../atom-components/StyledButtons";

function WriteQuizReferenceDraggableListItem({
  item,
  index,
  onClickDetailReference,
  onClickSelectReference,
}) {
  const {
    referenceId,
    referenceCreatedDate,
    referenceData,
    referenceType /*image*/,
  } = item;

  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  return (
    <Draggable
      draggableId={referenceId} // dnd에선 Id는 number 안 됨 >> string
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          onMouseOver={handleMouseEnter}
          onMouseOut={handleMouseLeave}
        >
          <div>
            <div
              {...provided.dragHandleProps}
              style={{ position: "relative", margin: "0px 4px", zIndex: 1 }}
            >
              <DragHandler
                outlined
                isVisible={isHovered || snapshot.isDragging}
                sx={{ m: "12px 0", color: grey[600] }}
              />
            </div>
            <div style={{ position: "relative", margin: "0px 4px", zIndex: 1 }}>
              <div style={{ position: "absolute", right: 0 }}>
                <PopErrorIconButton
                  sx={{
                    m: "12px 0",
                    opacity: snapshot.isDragging ? 0 : isHovered ? 0.95 : 0,
                    transition: (theme) =>
                      theme.transitions.create(["opacity"], {
                        duration: theme.transitions.duration.shorter,
                      }),
                  }}
                  onClick={(event) =>
                    onClickSelectReference(event, referenceId)
                  }
                >
                  <RemoveIcon sx={{ color: "white" }} />
                </PopErrorIconButton>
              </div>
            </div>
            <ReferenceCard
              reference={item}
              isHovered={snapshot.isDragging}
              onClick={(event) => onClickDetailReference(event, item)}
            />
          </div>
        </div>
      )}
    </Draggable>
  );
}
export default WriteQuizReferenceDraggableListItem;
