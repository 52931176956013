import { Link } from "@mui/icons-material";

const REFERENCE_INFO = {
  URL: {
    title: "URL",
    icon: Link,
    type: "URL",
  },
  TEXT: {
    title: "텍스트",
    type: "TEXT",
  },
};

export default REFERENCE_INFO;
