import React from "react";
import handleRequestHeader from "../utils/handleRequestHeader";
import { useDispatch, useSelector } from "react-redux";
import { onLoadData } from "../modules/topicSolveQuiz";
import getUserSolveTopic from "../modules/_actions/getUserSolveTopic";
import QuizList from "../components/quizList/QuizList";
import usePage from "../hooks/usePage";
import getUrlParam from "../utils/getUrlParam";
import MyLoading from "../components/global-components/MyLoading";
import { onLoadingFalse, onLoadingTrue } from "../modules/header";

function TopicSolveQuizContainer() {
  /** 페이지 이동 hook */
  const { solveTopicQuizPage: quizPage, loginPage } = usePage();

  /**
   * 현재 url에서 id 뽑아 내기
   * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
   * - topicId : 주제 id
   * - quizId : 퀴즈 id
   */
  const { historyId, topicId: topic_id, quizId } = getUrlParam();
  const [isSuccessQuiz, setIsSuccessQuiz] = React.useState(false);

  /** Redux State */
  const dispatch = useDispatch();

  const { topicId, topicName, quizList, currentPageNum, maxPageNum } =
    useSelector((state) => ({
      topicId: state.topicSolveQuiz.topicId,
      topicName: state.topicSolveQuiz.topicName,
      quizList: state.topicSolveQuiz.quizList,
      currentPageNum: state.topicSolveQuiz.currentPageNum,
      maxPageNum: state.topicSolveQuiz.maxPageNum,
    }));

  const handleLoadData = (data) => dispatch(onLoadData(data));

  /** 현재 pagination 페이지 */
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  /** 대표 문제 제공 API */
  async function loadData() {
    /** get param */
    let params = {
      topicId: topic_id,
      pageNum: page,
      pagingSize: 9,
    };

    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      if (isSuccess) {
        await getUserSolveTopic(params).then((res) => {
          // console.log("우선 여기까지 잘 왔음", res);
          setIsSuccessQuiz(true);
          handleLoadData({
            id: "topicSolveQuizData",
            value: res,
          });
        });
      }
    } else {
      /** 로그인 하러 가기 */
      loginPage();
    }
  }
  React.useEffect(() => {
    const l = loadData();
  }, [page]);

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  React.useEffect(() => {
    if (!isSuccessQuiz) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isSuccessQuiz]);

  return (
    <>
      {!isSuccessQuiz && <MyLoading />}
      {isSuccessQuiz && (
        <QuizList
          quizPage={() => quizPage(topic_id)}
          topicId={topicId}
          topicName={topicName}
          quizList={quizList}
          currentPageNum={currentPageNum}
          maxPageNum={maxPageNum}
          page={page}
          handlePageChange={handlePageChange}
          isTopicPage={true}
          isFavorites={true}
        />
      )}
    </>
  );
}
export default TopicSolveQuizContainer;
