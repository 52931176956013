import { useDispatch, useSelector } from "react-redux";
import {
  onChangeScrollPage,
  onLoadTopicList,
  onSelectQuizTopic,
} from "../../modules/writeQuiz";
import {
  Typography,
  Grid,
  Paper,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  // Collapse,
  ListItemIcon,
  Checkbox,
  Tooltip,
  // Divider,
} from "@mui/material";
import useSx from "../../hooks/useSx";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Chip from "@mui/material/Chip";
import getTopicList from "../../modules/_actions/create/getTopicList";
import handleRequestHeader from "../../utils/handleRequestHeader";
import usePage from "../../hooks/usePage";
// import { changeJsonArrToObjectArr } from "../../utils/write/changeTopicToList";
import URL from "../pageUrls";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { ResponsiveDialog } from "../atom-components/StyledDialogs";
import { useEffect, useState } from "react";
import StyledScrollbar from "../atom-components/StyledScrollbar";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TagChip } from "../atom-components/StyledChips";

// // constant data
// const topicsTempData = [
//   {
//     mainTopic: "데이터",
//     subTopics: [
//       "피드백, 인사이트 도출 체계",
//       "지표 대시보드",
//       "핵심 KPI",
//       "코호트 분석",
//       "임팩트 KPI",
//       "CAC, CLV",
//       "OMTM",
//       "퍼널분석",
//     ],
//     toggle: false,
//   },
//   {
//     mainTopic: "사회적 가치",
//     subTopics: [
//       "SDGs",
//       "Mission Statement",
//       "기존 솔루션 탐색",
//       "고객, 수혜자 Alignment",
//       "임팩트 로직모델",
//       "수혜자의 규모",
//       "문제 정의",
//       "정관 등 명문화",
//     ],
//     toggle: false,
//   },
// ];

const ITEM_PADDING_TOP = 8; // 각 아이템 패딩 top
const ITEM_HEIGHT = 72; // 각 아이템 높이

// function getStyles(topic, quizTopics, theme) {
//   const topicNames = changeJsonArrToObjectArr(quizTopics, false, true); // 이름만 추출해오기
//   return {
//     fontWeight:
//       topicNames.indexOf(topic) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

// is selected topic
function isSelectedTopic(selectedTopics, topicId) {
  return selectedTopics.findIndex((topic) => topic.topicId === topicId) !== -1;
}

// 선택된 주제 dialog
function SelectedTopicsDialog({ topics, handleSubTopicToggle }) {
  return (
    <Box
      sx={{
        p: "1.2rem",
        m: "1rem",
        backgroundColor: "primary.tonal",
        borderRadius: "8px",
      }}
    >
      <StyledScrollbar>
        <Grid
          container
          spacing={2}
          sx={{
            height: "8rem",
          }}
        >
          {topics.map((topic) => {
            const { topicId, topicName } = topic;
            return (
              <Grid key={topicId} item>
                <TagChip
                  label={topicName}
                  onClick={() => handleSubTopicToggle(topic)}
                  onDelete={() => handleSubTopicToggle(topic)}
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      display: "none",
                    },
                    "&:hover": {
                      "& .MuiChip-deleteIcon": {
                        display: "block",
                      },
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </StyledScrollbar>
    </Box>
  );
}

// /**
//  * 계층 주제 선택 dialog
//  * @param {*} param0
//  * @returns
//  */
// function SelectTopicDialog({
//   topics,
//   topicsToggle,
//   handleToggle,
//   selectedTopics,
//   handleSubTopicToggle,
// }) {
//   /**
//    * 대분류 toggle handle
//    * @param {*} mainTopic
//    * @param {*} subTopics
//    * @param {*} mainIndex
//    * @param {*} toggle
//    * @returns
//    */
//   function TopicToggleHandler(mainTopic, subTopics, mainIndex, toggle) {
//     return (
//       <div key={"main-id" + mainTopic}>
//         <ListItem disablePadding>
//           <ListItemButton onClick={() => handleToggle(mainIndex)}>
//             <ListItemText primary={mainTopic} />
//             {toggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
//           </ListItemButton>
//         </ListItem>
//         <Collapse in={toggle} timeout="auto" unmountOnExit>
//           {subTopics.map((topic) => (
//             <ListItem key={topic} disablePadding>
//               <ListItemButton
//                 sx={{ pl: (theme) => theme.spacing(20) }}
//                 onClick={() => handleSubTopicToggle(topic)}
//               >
//                 <ListItemIcon>
//                   <Checkbox
//                     edge="start"
//                     checked={selectedTopics.indexOf(topic) !== -1}
//                     tabIndex={-1}
//                     disableRipple
//                   />
//                 </ListItemIcon>
//                 <ListItemText primary={topic} />
//               </ListItemButton>
//             </ListItem>
//           ))}
//         </Collapse>
//       </div>
//     );
//   }

//   return (
//     <StyledScrollbar>
//       <Box sx={{ width: "100%" }}>
//         {topics.map((topic, mainTopicIndex) => {
//           const { mainTopic, subTopics } = topic;

//           return TopicToggleHandler(
//             mainTopic,
//             subTopics,
//             mainTopicIndex,
//             topicsToggle[mainTopicIndex].toggle
//           );
//         })}
//       </Box>
//     </StyledScrollbar>
//   );
// }

/**
 * 주제 선택 dialog (임시)
 * @param {*} param0
 */
function SelectTopicDialog({ topics, selectedTopics, handleSubTopicToggle }) {
  const { isWindowMdDown } = useSx();
  return (
    <StyledScrollbar>
      <Box sx={{ width: isWindowMdDown ? "100%" : "64rem" }}>
        {topics.map((topic) => {
          const { topicId, topicName } = topic;
          return (
            <ListItem key={topicId} disablePadding>
              <ListItemButton onClick={() => handleSubTopicToggle(topic)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    // checked={selectedTopics.indexOf(topic) !== -1}
                    checked={isSelectedTopic(selectedTopics, topicId)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={topicName} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </Box>
    </StyledScrollbar>
  );
}

/** 주제 선택 */
function TopicSelect({ selectedTopics, handleSubTopicToggle }) {
  const { loginPage } = usePage();

  /** Redux State */
  const dispatch = useDispatch();

  const {
    isError,
    quizTopics /** 주제 ID, name로 저장*/,
    topicList, // topics
    writeError,
    scrollPage,
  } = useSelector((state) => ({
    isError: state.error.isError,
    quizTopics: state.writeQuiz.quizTopics,
    topicList: state.writeQuiz.topicList,
    writeError: state.error.writeError, // 입력 제어
    scrollPage: state.writeQuiz.scrollPage,
  }));

  /** 퀴즈 전체 주제 리스트 로드 */
  const handleOnLoadTopicList = (value) => dispatch(onLoadTopicList(value));
  /** 퀴즈 주제 선택 */
  const handleOnSelectTopic = (value) => dispatch(onSelectQuizTopic(value));

  /////============더 이상 갱신 안 해도 될 때 로그===================
  const [isMore, setIsMore] = useState(true);
  /** 주제 스크롤 */
  const setScrollPage = (value) => {
    dispatch(onChangeScrollPage(value));
  };

  const theme = useTheme();
  // const [quizTopics, handleOnSelectTopic] = useState([]);

  /** 사용자가 선택한 주제들 추가된 리스트 */
  const handleChange = (event) => {
    handleOnSelectTopic(event.target.value); // ( 현재, json 형태 ) 이미 menuItem 의 value는 {id, name} 있기 때문에 바로 갱신!
    // // On autofill we get a stringified value.
    // typeof value === "string" ? value.split(",") : value
  };

  /** 서버로부터 가져온 보기 추가 */
  function addTopicList(res) {
    // console.log(res);
    // 이미 있는 내용이면 갱신하지 말기
    handleOnLoadTopicList(
      [...new Set([...topicList, ...res].map(JSON.stringify))].map(JSON.parse)
    ); // 중복 없이 갱신
    // handleOnLoadTopicList([...topicList, ...res]); // 전체 리스트 ID, 이름 갱신
  }

  /** 현재 스크롤 페이지 */
  // const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true); //로딩 중,.
  /** 주제 리스트 로딩... */
  async function loadTopicList() {
    setLoading(true);
    // if (page >= scrollPage) {
    //   setPage(scrollPage);
    //   return;
    // }
    // console.log("true");

    /** get param */
    let params = {
      pageNum: scrollPage,
      pagingSize: 10,
    };

    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      if (isSuccess) {
        await getTopicList(params).then((res) => {
          // console.log("우선 여기까지 잘 왔음", res);
          if (res !== undefined) {
            if (res.topicList.length === 0) setIsMore(false); // 이제 더 이상 없을 때 로그 찍기
            addTopicList(res.topicList);
            // setHasMore() // 길이가 더 남았다?? maxNum과 비교해서 갱신해주기
            setLoading(false);
          }
        });
      }
    } else {
      /** 로그인 하러 가기 */
      loginPage();
    }
  }

  /** 스크롤 페이지 변할 때마다 불러오기! */
  useEffect(() => {
    if (!window.location.pathname.includes(URL.WRITE)) return; // 편집하는 곳에서만 로딩
    const l = loadTopicList();
    // addList();
  }, [scrollPage]);

  // const HEIGHT = 240;
  function loadMoreItems(event) {
    /** Select 패널 맨 밑으로 내렸을 시, item 가져와서 개수 늘려가면서 스크롤 무한대로 구현 */
    const targetScroll =
      event.target.scrollTop + ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP;

    /** 처음 불러오는 거면 */
    if (scrollPage === 1 && isMore && event.target.scrollHeight === 0) {
      setScrollPage(scrollPage + 1);
    }

    if (isMore && event.target.scrollHeight === targetScroll) {
      setScrollPage(scrollPage + 1);
    }
  }

  // topic select modal state
  const [isOpen, setIsOpen] = useState(false);
  function handleClickOpen() {
    setIsOpen(true);
  }

  return (
    <>
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid",
          borderColor: "divider",
          boxShadow: (theme) => theme.shadows[1],
          padding: "0.4rem 0rem",
        }}
      >
        <FormControl
          sx={{
            padding: "0rem 1.6rem",
            display: "flex",
            flexDirection: "row",
            WebkitLineClamp: "1", // 원하는 만큼의 줄
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {selectedTopics.length === 0 && (
            <Typography sx={{ color: "text.disabled" }}>
              옆 아이콘을 눌러 주제를 선택해주세요.
            </Typography>
          )}
          {selectedTopics.map((item) => {
            const { topicId, topicName } = item;
            return <TagChip key={topicId} size="small" label={topicName} />;
          })}
        </FormControl>
        <Tooltip title="주제 추가">
          <div>
            <IconButton sx={{ p: "0.8rem" }} onClick={handleClickOpen}>
              <ZoomInIcon />
            </IconButton>
          </div>
        </Tooltip>
      </Paper>
      <ResponsiveDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dialogTitleText="선택된 주제들"
      >
        <SelectedTopicsDialog
          topics={selectedTopics}
          handleSubTopicToggle={handleSubTopicToggle}
        />
        {/* <SelectTopicDialog
      topics={topicsTempData}
      topicsToggle={topicsToggle}
      handleToggle={handleToggle}
      selectedTopics={selectedTopics}
      handleSubTopicToggle={handleSubTopicToggle}
    /> */}
        <SelectTopicDialog
          topics={topicList}
          selectedTopics={selectedTopics}
          handleSubTopicToggle={handleSubTopicToggle}
        />
      </ResponsiveDialog>
    </>
  );
}
export default TopicSelect;
