import React, { useEffect } from "react";
import handleRequestHeader from "../utils/handleRequestHeader";
import getQuizRepresent from "../modules/_actions/getQuizRepresent";
import getQuizRepresentGuest from "../modules/_actions/getQuizRepresentGuest";
import { useDispatch, useSelector } from "react-redux";
import { onLoadData } from "../modules/main";
import TopicQuizContainer from "./TopicQuizContainer";
import deleteTopicWithoutQuiz from "../utils/deleteTopicWithoutQuiz";
import QuizList from "../components/quizList/QuizList";
import usePage from "../hooks/usePage";
import getUrlParam from "../utils/getUrlParam";
import SolveQuizContainer from "./SolveQuizContainer";
import WrongQuizContainer from "./WrongQuizContainer";
import TopicSolveQuizContainer from "./TopicSolveQuizContainer";
import TopicWrongQuizContainer from "./TopicWrongQuizContainer";
import URL from "../components/pageUrls";
import MyLoading from "../components/global-components/MyLoading";
import { onLoadingFalse, onLoadingTrue } from "../modules/header";

function MainContainer() {
  /** 페이지 이동 hook */
  const {
    handleMainMoreClick: handleMoreClick,
    mainQuizPage: quizPage,
    mainTopicQuizPage: topicQuizPage,
    mainPage,
  } = usePage();

  /** 만약 "/" 이라면 바로 main으로 보내주기 */
  React.useEffect(() => {
    if (window.location.pathname === "/") {
      mainPage();
    }
  }, []);

  /**
   * 현재 url에서 id 뽑아 내기
   * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
   * - topicId : 주제 id
   * - quizId : 퀴즈 id
   */
  const { historyId, topicId, quizId } = getUrlParam();

  /** Redux State */
  const dispatch = useDispatch();

  const { representQuiz, isLoading } = useSelector((state) => ({
    representQuiz: state.main.representQuiz,
    isLoading: state.header.isLoading,
  }));

  const handleLoadData = (data) => dispatch(onLoadData(data));
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());

  // 퀴즈 문제 잘 가져왔는지
  const [isSuccessQuiz, setIsSuccessQuiz] = React.useState(false);

  /** 대표 문제 제공 API */
  async function loadData() {
    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      if (isSuccess && !isSuccessQuiz) {
        await getQuizRepresent().then((res) => {
          // console.log("우선 여기까지 잘 왔음", res);
          setIsSuccessQuiz(true);
          handleLoadData({
            id: "representQuiz",
            value: deleteTopicWithoutQuiz(res.representQuiz),
          });
        });
      }
    } else {
      /** 비회원 퀴즈 문제 제공 */
      if (!isSuccessQuiz) {
        await getQuizRepresentGuest().then((res) => {
          // console.log("게스트는 왔남??", res);
          setIsSuccessQuiz(true);
          handleLoadData({
            id: "representQuiz",
            value: deleteTopicWithoutQuiz(res.representQuiz),
          });
        });
      }
    }
  }

  React.useEffect(() => {
    /** main 그냥 문제들 제공하는 페이지가 아니라면 리턴 */
    if (historyId !== null && topicId !== null) return;

    const l = loadData();
  }, [isSuccessQuiz]);

  /** 문제 선택 여부 잘 세팅했는지 */
  const [isSuccess, setIsSuccess] = React.useState(false);
  /** 전체 주제 버튼 세팅 */
  const [isSelectedAll, setIsSelectedAll] = React.useState(true);
  /**
   * 주제 리스트 세팅
   *
   * { key: "topicName", value: { isSelected(선택 여부): true, more(더보기 여부) : false }
   * => 접근 : isSelectedTopic.get("법").isSelected
   */
  const [isSelectedTopic, setIsSelectedTopic] = React.useState(new Map());
  /** 선택 여부 업데이트 */
  const updateIsSelected = (key, value) => {
    setIsSelectedTopic((prev) => new Map(prev).set(key, value));
  };
  /** 주제 리스트 처음 세팅 */
  React.useEffect(() => {
    if (!isSuccessQuiz) return;
    representQuiz.filter((topic) => {
      updateIsSelected(topic.topicName, { isSelected: true });
    });
    setIsSuccess(true);
  }, [isSuccessQuiz]);
  /** 주제 하나씩 선택 & 취소 */
  function handleTopicClick(topicName) {
    updateIsSelected(topicName, {
      isSelected: !isSelectedTopic.get(topicName).isSelected,
    });
  }
  /** 전체 선택 & 취소 */
  function handleAllTopic() {
    /** 주제 중 하나라도 체크 안 되어 있으면, outlined */
    if (!isSelectedAll) {
      representQuiz.filter((topic) => {
        updateIsSelected(topic.topicName, {
          isSelected: true,
        });
      });
      setIsSelectedAll(true);
    } else {
      /** 모든 주제가 체크 되어 있으면, filled */
      representQuiz.filter((topic) => {
        updateIsSelected(topic.topicName, {
          isSelected: false,
        });
      });
      setIsSelectedAll(false);
    }
  }

  /** 체크 안 되어 있는 거 하나라도 찾아서 전체 버튼 활성화 */
  React.useEffect(() => {
    if (!isSuccessQuiz) return;
    /**
     * 주제 중 하나라도 체크 안 되어 있으면, outlined
     * map에서 value만 뽑아서 array로 만듦
     */
    const check = [...isSelectedTopic.values()].some((elem) => {
      return elem.isSelected === false;
    });
    if (check) {
      setIsSelectedAll(false);
    } else {
      setIsSelectedAll(true);
    }
  }, [isSelectedTopic]);

  /** 일일 목표 */
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    if (!isSuccess || !isSuccessQuiz) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isSuccess, isSuccessQuiz]);

  return (
    <>
      {
        /** 틀린&봤던 문제들 페이지 */
        (historyId !== null &&
          /** 주제별 문제들 페이지 */
          (topicId !== null
            ? /** 틀린 문제들 */
              (historyId === URL.WRONG_ID && <TopicWrongQuizContainer />) ||
              /** 봤던 문제들 */
              (historyId === URL.SOLVE_ID && <TopicSolveQuizContainer />)
            : /** 그냥 문제들 페이지 */
              /** 틀린 문제들 */
              (historyId === URL.WRONG_ID && <WrongQuizContainer />) ||
              /** 봤던 문제들 */
              (historyId === URL.SOLVE_ID && <SolveQuizContainer />))) ||
          /** 메인 문제들 페이지 */
          (historyId === null &&
            /** 주제별 문제들 페이지 */
            (topicId !== null ? (
              <TopicQuizContainer />
            ) : (
              /** 그냥 문제들 페이지 */
              ((!isSuccess || !isSuccessQuiz) && <MyLoading />) ||
              (isSuccess && isSuccessQuiz && (
                <QuizList
                  quizPage={quizPage}
                  topicQuizPage={topicQuizPage}
                  isSelectedAll={isSelectedAll}
                  handleAllTopic={handleAllTopic}
                  representQuiz={representQuiz}
                  isSelectedTopic={isSelectedTopic}
                  handleTopicClick={handleTopicClick}
                  handleMoreClick={handleMoreClick}
                  isTopicPage={false}
                  isFavorites={false}
                />
              ))
            )))
      }
    </>
  );
}
export default MainContainer;
