import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 사용자의 봤던 문제 API
 */
async function getUserSolve() {
  let data;
  try {
    await axios.get(APIURL.api + "quiz/user/played").then((response) => {
      // console.log("퀴즈 로드 잘 해옴~", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("로드 문제!!", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      //   case 404:
      /** 풀이할 문제가 없음 */
      default:
        return;
    }
  }
}
export default getUserSolve;
