import StartupqtMasterData from "../json/StartupqtMasterData.json";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import SchoolIcon from "@mui/icons-material/School";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

/** 게임적 요소 그래픽 반환하는 hook */
function useGameGraphic() {
  /** 레벨에 맞는 뱃지 반환 */
  function getLevelBadge(level) {
    /**
     * 1~20 레벨은 level_001_020.png 반환
     * 21레벨 -> (21-1)/20 => 1 => level_021_040.png 반환
     *
     */
    const badgeLevel = Math.floor((level - 1) / 20);

    const baseSrc = "/assets/level/";

    switch (badgeLevel) {
      case 0:
        return `${baseSrc}level_001_020.png`;
      case 1:
        return `${baseSrc}level_021_040.png`;
      case 2:
        return `${baseSrc}level_041_060.png`;
      case 3:
        return `${baseSrc}level_061_080.png`;
      case 4:
        return `${baseSrc}level_081_100.png`;
      case 5:
        return `${baseSrc}level_101_120.png`;
      case 6:
        return `${baseSrc}level_121_140.png`;
      case 7:
        return `${baseSrc}level_141_160.png`;
      case 8:
        return `${baseSrc}level_161_180.png`;
      case 9:
        return `${baseSrc}level_181_200.png`;
      default:
        return `${baseSrc}level_181_200.png`;
    }
  }

  /**
   * index  |   achievement_id  |       name        |   description                |   target          |	count
   *    1   |    ACH-0001      |   누적 3일 출석    |   StartupQT에 누적 3일 접속  |   ATTENDANCE       |        3
   *
   * 업적 id만 날라오기 때문에, master data에 아래와 같이 접근
   * StartupqtMasterData.achievment.find((element) => {
   *    return element.achievement_id === "ACH-0036";
   * })
   *
   */
  function getAchievmentData(achievementId) {
    return StartupqtMasterData.achievment.find((element) => {
      return element.achievement_id === achievementId;
    });
  }

  /** 업적들을 리스트로 */
  function handleAchievementData(userAchievementIdList) {
    let result = [];
    userAchievementIdList.map((achievementId) => {
      result.push(getAchievmentData(achievementId));
    });
    return result;
  }
  /** 획득 못한 업적들을 리스트로 */
  function handleNoAchievementData(userAchievementIdList) {
    let result = [...StartupqtMasterData.achievment]; // id에 매칭해서 아닌 것들만 남기기
    userAchievementIdList.map((achievementId) => {
      const idx = result.findIndex((item) => {
        return item.achievement_id === achievementId;
      });
      result.splice(idx, 1);
    });
    return result;
  }

  const iconSize = { xs: "8rem", sm: "10rem", md: "12rem" };
  /** 업적 target 에 따라 아이콘 리턴 */
  function getAchievmentIcon(
    achievementTarget,
    iconSx = { width: iconSize, height: iconSize }
  ) {
    switch (achievementTarget) {
      // 누적 출석
      case "ATTENDANCE":
        return <MilitaryTechIcon sx={iconSx} />;
      // 누적 풀이 문제 수
      case "TOT_PLAY_COUNT":
        return <SchoolIcon sx={iconSx} />;
      // 연속 정답
      case "MAX_COMBO":
        return <LocalFireDepartmentIcon sx={iconSx} />;
      // 연속 출석
      case "SEQ_ATTENDANCE":
        return <EmojiEventsIcon sx={iconSx} />;
      // 총 풀이 시간
      case "TOT_PLAY_TIME":
        return <HourglassBottomIcon sx={iconSx} />;
    }
  }

  return {
    getLevelBadge,
    getAchievmentData,
    handleAchievementData,
    getAchievmentIcon,
    handleNoAchievementData,
  };
}
export default useGameGraphic;
