import { Masonry } from "@mui/lab";
import { Stack } from "@mui/material";
import React from "react";
import AchievementCard from "../components/global-components/AchievementCard";
import PageScreenBox from "../components/global-components/PageScreenBox";

function Test() {
  return (
    <PageScreenBox>
      <Stack spacing={4} sx={{ width: 1 }}>
        <h1>업적</h1>
        <h1>업적</h1>
        <Masonry columns={{ xs: 5, sm: 5, md: 6 }} spacing={6}>
          <AchievementCard />
          <AchievementCard />
          <AchievementCard />
          <AchievementCard />
        </Masonry>
      </Stack>
    </PageScreenBox>
  );
}
export default Test;
