import React from "react";
import { FlexColumnBox } from "../../layout";

function EmptyGuide({ children }) {
  return (
    <FlexColumnBox
      sx={{
        alignItems: "center",
        p: 10,
        gap: 4,
      }}
    >
      {children}
    </FlexColumnBox>
  );
}
export default React.memo(EmptyGuide);
