import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Login from "../pages/Login";
import {
  auth,
  analytics,
  signInWithEmailAndPassword,
  googleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
} from "../service/firebase";
import { onChangeInput } from "../modules/login";
import { setAuthError } from "../modules/error";
import { onChangeSuccess, onInitUser, onSetUserStat } from "../modules/auth";
import usePage from "../hooks/usePage";
import { handleUserStat } from "../utils/handleUserStat";
import { checkRecord } from "../utils/checkRecord";
import deleteAuth from "../utils/deleteAuth";
import useMySnackbar from "../hooks/useMySnackbar";

export default function LoginContainer() {
  /** 페이지 이동 hook */
  const { mainPage, signUpPageRePlace } = usePage();
  const { openSnackbarHere } = useMySnackbar();
  /** Redux State */
  const dispatch = useDispatch();

  const { email, password, authError, loginSuccess, userIdToken } = useSelector(
    (state) => ({
      email: state.login.email,
      password: state.login.password,
      authError: state.error.authError,
      loginSuccess: state.auth.loginSuccess,
      userIdToken: state.auth.userIdToken,
    })
  );
  // 혹시 user Id 가진 유저(로그인 한 유저)가 회원가입 페이지에 왔다면, 뒤로가기로 보내주기
  React.useEffect(() => {
    if (userIdToken !== "Guest") mainPage();
  }, [userIdToken]);

  /** authError : 에러 메세지 */
  const handleAuthError = (error) => dispatch(setAuthError(error));

  /** email, password */
  const handleOnChangeInput = (e) => dispatch(onChangeInput(e.target));

  /** 회원 로그인 성공 여부 */
  const handleOnChangeSuccess = (data) => dispatch(onChangeSuccess(data));

  /** 입력 에러창 로그 */
  const [emailLog, setEmailLog] = React.useState(false);
  const [passwordLog, setPasswordLog] = React.useState(false);

  /** 로그인 하기 버튼 활성화 여부 */
  const [isDisabled, setIsDisabled] = React.useState(true);
  /** 로그인 버튼 활성화 여부 */
  React.useEffect(() => {
    if (email === "" || password === "") {
      setIsDisabled(true);
    } else setIsDisabled(false);
  }, [email, password]);

  //==========로그인 시, user 정보 setting==========
  /** set user stat */
  const handleSetUserStat = (userStat) => {
    dispatch(onSetUserStat(userStat));
  };
  /** init user stat */
  const handleInitUserStat = () => {
    dispatch(onInitUser());
  };
  /** 현재 로그인한 user의 id token과 더불어 stat 가져오기 : 주의할 점은 서버로부터 stat을 못 받아와도, 로그아웃 될 예정 */
  async function checkUserStat() {
    /** 가져온 user stat이 {} 이면 바로 게스트 후, 리턴 */
    await handleUserStat().then((userStat) => {
      // console.log(userStat);
      if (Object.keys(userStat).length === 0) {
        // console.log("익명이다");
        handleInitUserStat(); // user 아예 초기화
      } else {
        // console.log("회원이다", userStat.userId);
        handleSetUserStat({
          userIdToken: userStat.userId, // userId 보안키
          userNickname: userStat.nickname, // 닉네임
          userLevel: userStat.userLevel, // 레벨
          userLevelXp: userStat.currentXp, // 현재 레벨 XP
          profileImage: userStat.profileImage, // 이미지
          isWritable: userStat.isWritable, //저작 권한
        });
      }
    });
  }

  /** 로그인 폼 제출 함수 */
  async function handleLoginSubmit(event) {
    event.preventDefault();
    setEmailLog(false);
    setPasswordLog(false);

    /** 로그인 */
    await signInWithEmailAndPassword(auth, email, password)
      .then((res) => {
        // console.log("로그인 성공!", res);
        localStorage.setItem("login-token", res.user.accessToken);
        checkUserStat();
        if (userIdToken !== "Guest") checkRecord(userIdToken);
        /**
         * 로그인 페이지에선 안 부름
         * 항상 APP에서 감시하니까
         */
        // handleOnChangeSuccess({ id: "login", state: true });
        /** 로그인 성공 시, 뒤로 가기 (로그인 페이지는 뒤로 가기로 못 감) */
        mainPage();
      })
      .catch((err) => {
        /** 에러 */
        handleAuthError(err.code);
        switch (err.code) {
          case "auth/user-not-found": {
            return setEmailLog(true);
          }
          case "auth/invalid-email": {
            return setEmailLog(true);
          }
          case "auth/wrong-password": {
            return setPasswordLog(true);
          }
        }
      });
  }
  /** 구글 로그인 */
  async function handleGoogleAuthProvider(event) {
    event.preventDefault();
    await signInWithPopup(auth, googleAuthProvider)
      .then((res) => {
        // 새로운 유저인지 확인
        const { isNewUser } = getAdditionalUserInfo(res);
        // 존재하지 않는 계정
        if (isNewUser) {
          deleteAuth()
            .then(() => {
              // console.log("없는 계정입니다.");
              handleAuthError("auth/user-not-found");
            })
            .catch((error) => {
              // console.log("삭제 오류", error);
            });
        }
        // 회원가입한 계정이니까, 로그인 진행
        else {
          // console.log("로그인 성공!");
          localStorage.setItem("login-token", res.user.accessToken);
          checkUserStat();
          if (userIdToken !== "Guest") checkRecord(userIdToken);
          /**
           * 로그인 페이지에선 안 부름
           * 항상 APP에서 감시하니까
           */
          // handleOnChangeSuccess({ id: "login", state: true });
          /** 로그인 성공 시, 뒤로 가기 (로그인 페이지는 뒤로 가기로 못 감) */
          mainPage();
        }
      })
      .catch((error) => {
        // console.log("구글 로그인 에러 : ", error);
      });
  }

  // 비밀번호 찾기 이메일 입력 내용
  const [findPasswordValue, setFindPasswordValue] = React.useState("");
  function handleOnChangeFindPasswordValue(event) {
    setFindPasswordValue(event.target.value);
  }
  const [findPasswordButton, setFindPasswordButton] = React.useState(false);
  function handleOpenFindPasswordButton() {
    setFindPasswordButton(true);
  }
  function handleCloseFindPasswordButton() {
    setFindPasswordButton(false);
  }

  /** 비밀번호 찾기(비밀번호 재설정) */
  async function handleFindPassword(event) {
    event.preventDefault();

    await sendPasswordResetEmail(auth, findPasswordValue)
      .then((res) => {
        openSnackbarHere(
          "info",
          "해당 이메일로 메일이 전송되었습니다. 비밀번호를 재설정해주세요."
        );
      })
      .catch((error) => {});
    handleCloseFindPasswordButton();
  }

  return (
    <Login
      email={email}
      password={password}
      authError={authError}
      emailLog={emailLog}
      passwordLog={passwordLog}
      isDisabled={isDisabled}
      handleOnChangeInput={handleOnChangeInput}
      handleLoginSubmit={handleLoginSubmit}
      handleGoogleAuthProvider={handleGoogleAuthProvider}
      signUpPageRePlace={signUpPageRePlace}
      findPasswordValue={findPasswordValue}
      handleOnChangeFindPasswordValue={handleOnChangeFindPasswordValue}
      handleFindPassword={handleFindPassword}
      findPasswordButton={findPasswordButton}
      handleOpenFindPasswordButton={handleOpenFindPasswordButton}
      handleCloseFindPasswordButton={handleCloseFindPasswordButton}
    />
  );
}
