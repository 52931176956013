import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 현재 저작 권한 상태 get
 */
async function getWriteAccount() {
  let data;

  try {
    await axios.get(APIURL.api + "account/request").then((response) => {
      // console.log("권한 상태 가져옴!", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("error!!", error);
    switch (error.response.status) {
      case 461: // 저작권한을 요청하지 않은 사용자입니다.
        return {
          requestDate: "",
          status: error.response.data.detail,
        };
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default getWriteAccount;
