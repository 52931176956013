import { Box, Checkbox, Divider, ListItem } from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import { Draggable } from "react-beautiful-dnd";
import { PopErrorIconButton } from "../atom-components/StyledButtons";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RemoveIcon from "@mui/icons-material/Remove";
import MyCKEditor from "../global-components/MyCKEditor";
import { FlexBox } from "../layout";

/**
 * write quiz example item component
 * @param {*} param0
 * @returns
 */
function WriteQuizExampleDraggableListItem({
  item,
  index,
  onClickRemoveItem,
  onClickSetExampleItemIsAnswer,
  onChangeExampleItemText,
  keyId,
}) {
  const { exampleId, exampleText, isAnswer } = item;

  /**
   * - Draggable 의 draggableId와 key가 같아야 한다.
   * - Droppable과 마찬가지로 html elemnent를 이용해 프롭스를 넘겨주어야한다.
   */
  // console.log(exampleId.toString());
  // console.log(typeof exampleId.toString());
  // Draggable requires a [string] draggableId. Provided: [type: number]
  return (
    <Draggable
      // key={exampleIdString}
      draggableId={keyId} // dnd에선 Id는 number x, string
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ListItem
            disablePadding
            sx={{ backgroundColor: snapshot.isDragging ? "surface.1" : "" }}
          >
            <DragIndicatorIcon
              fontSize="small"
              color="disabled"
              sx={{ m: "0 0.8rem" }}
            />
            <Divider orientation="vertical" flexItem />
            <FlexBox sx={{ width: "100%" }}>
              <Checkbox
                // 정답 체크
                checked
                sx={{
                  height: "fit-content",
                  "&.Mui-checked": {
                    color: isAnswer ? "primary.main" : blueGrey[100],
                  },
                }}
                onChange={(event) =>
                  onClickSetExampleItemIsAnswer(event, exampleId)
                }
              />
              <Box sx={{ width: "100%" }}>
                <MyCKEditor
                  placeholder={`보기 ${index + 1}`}
                  data={exampleText}
                  onChange={(event, editor) => {
                    onChangeExampleItemText(event, editor, exampleId);
                  }}
                />
              </Box>
              <PopErrorIconButton
                // 삭제
                onClick={() => onClickRemoveItem(index)}
                sx={{ m: "0.8rem" }}
              >
                <RemoveIcon sx={{ color: "white" }} />
              </PopErrorIconButton>
            </FlexBox>
          </ListItem>
          <Divider />
        </div>
      )}
    </Draggable>
  );
}
export default WriteQuizExampleDraggableListItem;
