import useSx from "../../hooks/useSx";
import MyLoading from "../global-components/MyLoading";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SettingComponent from "./SettingComponent";
import { FlexColumnBox } from "../layout";

// 저작 권한 컴포넌트
function RequestWrite({
  isLoading,
  requestStateList,
  userRequestWrite,
  requestValue,
  handleSelectChange,
  requestValueList,
  ETC,
  etcRequestValue,
  handleTextFieldChange,
  onClickRequestSubmit,
  isDisabled,
}) {
  // 테마 정의
  const { settingSubtitleFont, settingLayout, settingBoxGap } = useSx();

  // 저작 권한 상태 컴포넌트
  function UserRequestWriteState() {
    return (
      <>
        <Grid container>
          <Typography color="text.secondary">
            현재 저작 권한 상태입니다.
          </Typography>
        </Grid>

        {requestStateList.map(
          (state) =>
            // 현재 저작 권한 상태에 맞는 상태만 렌더
            userRequestWrite.status === state.state && (
              <div key={state.state}>
                <Grid container sx={{ alignItems: "center", gap: "0.4rem" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {state.state}
                  </Typography>
                  <Typography>{state.icon}</Typography>
                </Grid>
                <Grid container>
                  <Typography>
                    {userRequestWrite.requestDate === ""
                      ? "아래에서 저작 권한을 요청해주세요."
                      : "마지막 요청 일자 : " +
                        userRequestWrite.requestDate
                          .replace("T", " ")
                          .replace(/\..*/, "")}
                  </Typography>
                </Grid>
              </div>
            )
        )}
      </>
    );
  }

  return (
    <>
      {isLoading && <MyLoading />}
      {!isLoading && (
        <Stack {...settingLayout}>
          <SettingComponent
            title="저작 권한 상태"
            component={<UserRequestWriteState />}
          />
          <FlexColumnBox {...settingBoxGap}>
            <Typography variant="h5">저작 권한 요청</Typography>
            <Card elevation={2}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.4rem",
                }}
              >
                <Grid container>
                  <Typography color="text.secondary">
                    퀴즈 저작을 할 수 있도록 저작 권한을 요청할 수 있습니다.
                    저작 권한을 요청하려면 다음 내용을 작성해주세요.
                  </Typography>
                </Grid>
                <Grid container sx={{ gap: "0.8rem", maxWidth: "56rem" }}>
                  <Grid container>
                    <Typography sx={settingSubtitleFont}>요청 사유</Typography>
                  </Grid>
                  <Grid container>
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        value={requestValue}
                        onChange={handleSelectChange}
                        displayEmpty
                      >
                        <MenuItem disabled value="">
                          요청 사유를 선택해주세요. *
                        </MenuItem>
                        {requestValueList.map((data) => (
                          <MenuItem key={data} value={data}>
                            {data}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: "0.8rem", maxWidth: "56rem" }}>
                  <Grid container>
                    <Typography sx={settingSubtitleFont}>기타 사유</Typography>
                  </Grid>
                  <Grid container>
                    <TextField
                      size="small"
                      fullWidth
                      placeholder="기타 선택 시, 작성해주세요."
                      variant="outlined"
                      disabled={requestValue !== ETC}
                      value={etcRequestValue}
                      onChange={handleTextFieldChange}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Button
                    variant="contained"
                    onClick={onClickRequestSubmit}
                    disabled={isDisabled}
                  >
                    작성 완료
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </FlexColumnBox>
        </Stack>
      )}
    </>
  );
}
export default RequestWrite;
