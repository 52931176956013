/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const EVENT_OPEN = "event/EVENT_OPEN";
const EVENT_INIT = "event/EVENT_INIT";

const EVENT_CLOSE = "event/EVENT_CLOSE";
const EVENT_LEVEL_UP = "event/EVENT_LEVEL_UP";
const EVENT_ACHIEVEMENT = "event/EVENT_ACHIEVEMENT";

/** init State ( 초기 상태 ) */
const initialState = {
  eventOpen: false,
  eventType: "",
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeEventOpen = () => ({ type: EVENT_OPEN });
export const onChangeEventInit = () => ({ type: EVENT_INIT });

export const onChangeEventClose = () => ({ type: EVENT_CLOSE });
export const onChangeEventLevelUp = () => ({ type: EVENT_LEVEL_UP });
export const onChangeEventAchievement = () => ({ type: EVENT_ACHIEVEMENT });

/** reducer정의 */
export default function event(state = initialState, action) {
  switch (action.type) {
    // case EVENT_OPEN: // 무슨 종류의 event로 열리는지까지
    //   return {
    //     ...state,
    //     eventOpen: true,
    //     eventType
    //   };
    case EVENT_CLOSE:
      return {
        eventOpen: false,
        eventType: "",
      };
    case EVENT_LEVEL_UP: // 레벨 업 이벤트
      return {
        eventOpen: true,
        eventType: "levelUp",
      };
    case EVENT_ACHIEVEMENT: // 업적 달성 이벤트
      return {
        eventOpen: true,
        eventType: "achievement",
      };
    default:
      return state;
  }
}
