import APIURL from "../modules/apiUrls";
import getUserStat from "../modules/_actions/getUserStat";
import handleRequestHeader from "./handleRequestHeader";

/**
 * 현재 로그인한 user 의 stat 가져오기
 */
export async function handleUserStat() {
  let isSuccess = false;
  /** 리퀘스트 헤더에 토큰 담기 */
  await handleRequestHeader().then((res) => {
    if (res) isSuccess = true;
  });
  if (!isSuccess) return;

  /** 사용자의 userId, nickname, userLevel, currentXp를 얻기 위해 stat API 요청 */
  let userStat = {};
  await getUserStat().then((res) => {
    // console.log("사용자의 stat은?: ", res);

    /** isActivate 계정 활성화 안 되어 있으면 그냥 빈 걸로 주기 */
    if (res["userId"] === undefined || !res.isActivate) {
      userStat = {};
    } else {
      /** stat 잘 받아옴 */
      userStat = {
        userId: res.userId,
        nickname: res.nickname,
        userLevel: res.userLevel,
        currentXp: res.currentXp,
        profileImage: {
          url: res.profileImage === "" ? "" : APIURL.media + res.profileImage,
          file: null,
        },
        isWritable: res.isWritable,
      };
    }
  });

  return userStat;
}
