import { Box, Modal, Typography, CardActions, Button } from "@mui/material";
import useSx from "../../hooks/useSx";
import { FlexColumnBox } from "../layout";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
// 경고 모달창
function ModalWarning({
  title,
  content,
  buttonHandleName,
  modalWarningOpen,
  onClickModalCancle,
  onClickModalHandle,
}) {
  const { modalStyle } = useSx();

  return (
    <Modal open={modalWarningOpen}>
      <Box
        sx={{
          ...modalStyle,
          width: { xs: "28rem", sm: "38rem", md: "44rem" },
        }}
      >
        <FlexColumnBox
          sx={{
            display: "flex",
            gap: "1.2rem",
            mb: "1.2rem",
            alignItems: "center",
          }}
        >
          <WarningAmberIcon
            color="error"
            sx={{
              fontSize: 40,
            }}
          />
          <Typography
            variant="h5"
            color="error"
            sx={{ fontWeight: "bold", mb: "1.2rem" }}
          >
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {content}
          </Typography>
        </FlexColumnBox>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button color="inherit" onClick={onClickModalCancle}>
            취소
          </Button>
          <Button
            color="error"
            variant="contained"
            onClick={onClickModalHandle}
          >
            {buttonHandleName}
          </Button>
        </CardActions>
      </Box>
    </Modal>
  );
}
export default ModalWarning;
