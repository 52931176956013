import { FloatingTextField } from "../atom-components/StyledTextFields";
import SubTitle from "../global-components/SubTitle";
import { FlexColumnBox } from "../layout";

/**
 * Write TextField component
 * @param {*} param0
 */
function WriteTextField({ id, subTitleName, subTitleBody, ...props }) {
  return (
    <FlexColumnBox>
      <SubTitle titleName={subTitleName} body={subTitleBody} />
      <FloatingTextField id={id} {...props} />
    </FlexColumnBox>
  );
}
export default WriteTextField;
