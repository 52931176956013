import styled from "@emotion/styled";
import { Box, Button, Divider } from "@mui/material";
import { FlexBox, FlexColumnBox } from "../layout";

/** 위에 있는(선택한 단어들)버튼을 지정한 색으로 정의한 컴포넌트 */
const StyledButtonPuzzle = styled(Button)`
  background-color: #00639b1a;
`;

/**
 * 퍼즐 정답 선택지
 *
 * @param {bool} isSubmit : 제출 여부
 * @param {Array} selectedWord : 선택된 단어의 인덱스
 * @param {function} handleSelectedWordErase : 선택된 단어를 클릭하면 선택 안 한 단어로 바꾸는 함수
 * @param {Array} randomWord : 퍼즐 초기에 세팅되는 랜덤 단어들
 * @param {object} resultQuiz : 퀴즈 데이터(정답에 참조하기 위함)
 * @param {Array} randomWordIndex : 선택 안 된 단어의 인덱스
 * @param {function} handleSelectedWordInsert : 선택 안 한 단어를 클릭하면 선택한 단어로 바꾸는 함수
 *
 */
function Puzzle({
  isSubmit,
  selectedWord,
  handleSelectedWordErase,
  randomWord,
  resultQuiz,
  randomWordIndex,
  handleSelectedWordInsert,
}) {
  /** 퍼즐 전체를 감싸는 레이아웃 사용자 정의 테마 */
  const puzzleBoxSx = {
    justifyContent: "center",
    my: "1.6rem",
  };
  /** 퍼즐 단어 버튼 사용자 정의 테마 */
  const puzzleSx = {
    p: 0,
    m: 0,
    minWidth: 0,
    width: "4.0rem",
    height: "4.0rem",
    typography: "body1",
  };

  return (
    <FlexColumnBox
      sx={puzzleBoxSx}
      style={{ pointerEvents: isSubmit && "none" }}
    >
      <FlexBox sx={puzzleBoxSx}>
        <Box
          sx={{
            display: "flex",
            height: "4.5rem",
            gap: (theme) => theme.spacing(2),
          }}
        >
          {selectedWord.map((selected, index) => (
            <StyledButtonPuzzle
              key={index}
              variant="outlined"
              sx={puzzleSx}
              onClick={(event) =>
                handleSelectedWordErase(event, selected, index)
              }
            >
              {randomWord[selected]}
            </StyledButtonPuzzle>
          ))}
        </Box>
      </FlexBox>
      <Divider variant="fullWidth" />
      <FlexBox sx={puzzleBoxSx}>
        <Box
          /** 블록 개수에 맞게 너비 조정 */
          sx={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: `calc(${
              (resultQuiz.answerText.length <= 4
                ? 2
                : resultQuiz.answerText.length / 2) * 90
            }px)`,
            gap: (theme) => theme.spacing(2),
          }}
        >
          {randomWord.map((word, index) => (
            <Button
              key={index}
              variant="outlined"
              sx={puzzleSx}
              style={{
                visibility: !randomWordIndex.includes(index)
                  ? "hidden"
                  : "visible",
              }}
              onClick={(event) => handleSelectedWordInsert(event, index)}
            >
              {word}
            </Button>
          ))}
        </Box>
      </FlexBox>
    </FlexColumnBox>
  );
}
export default Puzzle;
