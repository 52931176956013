import { FlexColumnBox } from "../layout";
import {
  ID_INPUT_QUESTION_TEXT,
  ID_INPUT_QUIZ_TYPE,
} from "../../constants/writeQuiz";
import TopicSelect from "./TopicSelect";
import { FloatingTextField } from "../atom-components/StyledTextFields";
import MyCKEditor from "../global-components/MyCKEditor";
import { TonalTab, TonalTabs } from "../atom-components/StyledTabs";
import SubTitle from "../global-components/SubTitle";
import { Paper } from "@mui/material";

/**
 * 저작하기 / 문제
 * @param {*} param0
 */
function WriteQuizQuestion({
  isEditMode,
  inputQuizType,
  onChangeWriteQuizTabs,
  tabQuizTypeData,
  inputQuestionText,
  onChangeWriteQuizInputs,
  inputDescriptionText,
  setInputDescriptionText,
  selectedTopics,
  handleSubTopicToggle,
  currentIdToken,
}) {
  return (
    <>
      <FlexColumnBox>
        <SubTitle
          titleName="유형"
          body={`${
            isEditMode
              ? "퀴즈 유형은 수정할 수 없습니다."
              : "퀴즈 유형을 선택해주세요."
          }`}
        />
        <TonalTabs value={inputQuizType} onChange={onChangeWriteQuizTabs}>
          {tabQuizTypeData.map((data) => {
            const { quizType, value } = data;

            return (
              <TonalTab
                disabled={isEditMode}
                id={ID_INPUT_QUIZ_TYPE}
                key={"tab-quiz-type-id" + quizType}
                label={quizType}
                value={value}
              />
            );
          })}
        </TonalTabs>
      </FlexColumnBox>
      <FlexColumnBox>
        <SubTitle titleName="주제" body="주제를 1개 이상 선택해주세요." />
        <TopicSelect
          selectedTopics={selectedTopics}
          handleSubTopicToggle={handleSubTopicToggle}
        />
      </FlexColumnBox>
      <FlexColumnBox>
        <SubTitle titleName="질문" body="질문을 입력해주세요." />
        <FloatingTextField
          id={ID_INPUT_QUESTION_TEXT}
          value={inputQuestionText}
          onChange={onChangeWriteQuizInputs}
          placeholder="질문을 입력해주세요."
        />
      </FlexColumnBox>
      <FlexColumnBox>
        <SubTitle titleName="예문" body="예문을 입력해주세요. (선택)" />
        <Paper elevation={1}>
          <MyCKEditor
            data={inputDescriptionText}
            onChange={setInputDescriptionText}
            placeholder="예문을 입력해주세요."
            idToken={currentIdToken}
            initHeight="200px"
          />
        </Paper>
      </FlexColumnBox>
      <FlexColumnBox>
        {
          // 다지선다 | 퍼즐 | OX
          tabQuizTypeData.map((data) => {
            const { quizType, value, content } = data;

            return (
              <FlexColumnBox key={"quiz-type-content-id" + quizType}>
                {inputQuizType === value && content}
              </FlexColumnBox>
            );
          })
        }
      </FlexColumnBox>
    </>
  );
}
export default WriteQuizQuestion;
