import { Typography } from "@mui/material";
import { FlexBox } from "../layout";
import PageScreenBox from "./PageScreenBox";
import { blueGrey } from "@mui/material/colors";

function PageFooter() {
  return (
    <FlexBox
      sx={{
        width: "100%",
        backgroundColor: blueGrey[900],
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <PageScreenBox sx={{ pt: "6.4rem", width: "100%" }}>
        <Typography variant="subtitle1" color="white">
          The Innovators
        </Typography>
      </PageScreenBox>
    </FlexBox>
  );
}
export default PageFooter;
