import { List, ListItem, ListItemButton, Typography } from "@mui/material";
import ParseHtml from "../atom-components/ParseHtml";

/**
 * 객관식 퀴즈 정답 선택지
 * @param {object} resultQuiz : 퀴즈 데이터 (선택지)
 * @param {object} selectedIndex : 사용자가 선택한 선택지
 * @param {Function} handleListItemClick : 사용자가 선택한 선택지 이벤트
 */
function Example({ resultQuiz, selectedIndex, handleListItemClick, isSubmit }) {
  return (
    <nav aria-label="multiple" style={{ pointerEvents: isSubmit && "none" }}>
      {!!resultQuiz.examples && (
        <List
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: (theme) => theme.spacing(2),
          }}
        >
          {resultQuiz.examples.map((example, index) => (
            // 텍스트 렌더
            <ListItem
              disablePadding
              key={example.exampleId}
              alignItems="flex-start"
            >
              <Typography
                onClick={(event) => handleListItemClick(event, index)}
                sx={{ pt: "1.2rem", pr: "0.8rem", cursor: "pointer" }}
              >
                {index + 1 + ". "}
              </Typography>
              <ListItemButton
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
                sx={{
                  borderRadius: "0.8rem",
                }}
              >
                <ParseHtml htmlCodes={example.exampleText} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </nav>
  );
}
export default Example;
