import { Box, styled } from "@mui/material";

/** 퀴즈 페이지 너비 (제출 여부에 따라 padding bottom 변화) */
const QuizScreenBox = styled(({ isSubmit, ...props }) => {
  return <Box {...props} />;
})(({ theme, isSubmit }) => ({
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  padding: "2.4rem 3.2rem 0rem",
  paddingTop: "9rem",
  maxWidth: 936,
  minWidth: 328,
  [theme.breakpoints.down("md")]: {
    paddingBottom: isSubmit ? 0 : "6.0rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingBottom: isSubmit ? 0 : "8.8rem",
  },
}));

export default QuizScreenBox;
