/**
 * change answer
 * @param {*} exampleItems
 * @returns
 */
function changeAnswer(ox) {
  // DB에 보낼 땐 true, false
  if (ox === "O") return true;
  else if (ox === "X") return false;
}
export default changeAnswer;
