import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import WriteQuizReferenceDraggableListItem from "./WriteQuizReferenceDraggableListItem";
import DraggableListContainer from "../global-components/draggable-list/DraggableListContainer";
import { FlexColumnBox } from "../layout";

function WriteQuizReferenceDraggableList({
  referencesItems,
  onClickDetailReference,
  onClickSelectReference,
  handleDragEndSelectedReferencesItems,
}) {
  function onDragEndItem({ source, destination }) {
    // dropped outside the list
    if (!destination) return;

    // reorder된 아이템들
    handleDragEndSelectedReferencesItems({ source, destination });
  }
  function isLast(index) {
    return index !== referencesItems.length - 1;
  }

  /**
   * requestAnimationFrame이 호출된 이후에 DND 컴포넌트를 렌더링 해야 함.
   * 브라우저가 부드러운 애니메이션을 렌더링할 준비되고 앱을 실행하기 위함.
   */
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }
  // --- requestAnimationFrame 초기화 END

  return (
    <DraggableListContainer
      // drag & drop
      droppableId="write-reference-draggable-list"
      onDragEnd={onDragEndItem}
      draggableListItems={referencesItems.map((item, index) => {
        const {
          referenceId,
          referenceCreatedDate,
          referenceData,
          referenceType /*image*/,
        } = item;

        return (
          <FlexColumnBox key={referenceId} gap={2}>
            <WriteQuizReferenceDraggableListItem
              item={item}
              index={index}
              onClickDetailReference={onClickDetailReference}
              onClickSelectReference={onClickSelectReference}
            />
            {isLast(index) ? <Divider /> : <></>}
          </FlexColumnBox>
        );
      })}
    />
  );
}
export default React.memo(WriteQuizReferenceDraggableList);
