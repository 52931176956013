import * as React from "react";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import URL from "../pageUrls";
import { useSelector } from "react-redux";
import { AppBar, IconButton } from "@mui/material";
import usePage from "../../hooks/usePage";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { amber } from "@mui/material/colors";
import PopupList from "./PopupList";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EditIcon from "@mui/icons-material/Edit";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import useSx from "../../hooks/useSx";
import LogoHeader from "../atom-components/LogoHeader";
import { FlexBox } from "../layout";
import ContentPasteSearchTwoToneIcon from "@mui/icons-material/ContentPasteSearchTwoTone";

import MenuIcon from "@mui/icons-material/Menu";
function Header({ loginSuccess, logout }) {
  const { isLoading, userNickname, profileImage } = useSelector((state) => ({
    isLoading: state.header.isLoading,
    userNickname: state.auth.userNickname, // 닉네임
    profileImage: state.auth.profileImage, // 이미지
  }));

  const {
    mainPage,
    loginPage,
    signUpPage,
    createPage,
    profilePage,
    // settingPage,
    writePage,
    wrongPage,
    solvePage,
    settingAccountPage,
    settingWriteAuthorityPage,
    // settingPasswordPage,
    referencePage,
    referenceWritePage,
  } = usePage();

  /** 헤더 로고 크기 sm down? 58 rem : 112 rem */
  const { isWindowMdDown } = useSx();
  // =================저작===================
  const writeList = [
    [
      {
        name: "저작하기",
        icon: <AddIcon />,
        onClickFuntion: writePage,
      },
      {
        name: "저작한 문제",
        icon: <FormatListBulletedIcon />,
        onClickFuntion: createPage,
      },
      {
        name: "저작 권한 요청",
        icon: <AssignmentIndIcon />,
        onClickFuntion: settingWriteAuthorityPage,
      },
    ],
  ];

  // 관련자료
  const referenceList = [
    [
      {
        name: "저작하기",
        icon: <AddIcon />,
        onClickFuntion: referenceWritePage,
      },
      {
        name: "관련자료 목록",
        icon: <FormatListBulletedIcon />,
        onClickFuntion: referencePage,
      },
    ],
  ];

  // 내 이력
  const myHistoryList = [
    [
      {
        name: "틀린 문제",
        icon: <PlaylistRemoveIcon />,
        onClickFuntion: wrongPage,
      },
      {
        name: "풀었던 문제",
        icon: <PlaylistAddCheckIcon />,
        onClickFuntion: solvePage,
      },
    ],
  ];

  //==================내 계정==================
  // 내 계정 팝업 리스트
  const myAccountList = [
    [
      {
        name: "프로필",
        icon: <AccountCircleOutlinedIcon />,
        onClickFuntion: profilePage,
      },
      {
        name: "설정",
        icon: <SettingsOutlinedIcon />,
        onClickFuntion: settingAccountPage,
      },
    ],
    [
      {
        name: "로그아웃",
        icon: <LogoutOutlinedIcon />,
        onClickFuntion: logout,
      },
    ],
  ];
  // 내 계정 리스트 외 컴포넌트
  const userInfoComponent = (
    <Box
      sx={{ display: "flex", gap: "1rem", p: "1.6rem", alignItems: "center" }}
    >
      <Avatar src={profileImage.url} />
      <Typography variant="body1" color="black">
        {
          // 닉네임
          userNickname
        }
      </Typography>
    </Box>
  );

  // ================헤더에 렌더할 팝업 컴포넌트 리스트==================
  const HeaderPopupLists = [
    // 저작
    {
      isMd: true,
      popupIcon: <EditIcon />,
      popupTitle: "퀴즈 저작",
      popupListData: writeList,
      popupContent: null,
    },
    // 관련자료
    {
      isMd: true,
      popupIcon: <ContentPasteSearchTwoToneIcon />,
      popupTitle: "관련자료",
      popupListData: referenceList,
      popupContent: null,
    },
    // 내 이력
    {
      isMd: isWindowMdDown,
      popupIcon: <FolderIcon />,
      popupTitle: "내 이력",
      popupListData: myHistoryList,
      popupContent: null,
    },
    // 내 계정
    {
      isMd: true,
      popupIcon: <Avatar src={profileImage.url} />,
      popupTitle: "내 계정",
      popupListData: myAccountList,
      popupContent: userInfoComponent,
    },
  ];

  /** 시험용 서비스 */
  function Test({}) {
    return (
      <Box
        sx={{
          position: "fixed",
          // zIndex: -1,
          pt: { xs: "5.4rem", sm: "6rem" },
          width: "100%",
          display: "flex",
          // alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body1" color="text.disabled">
          본 서비스는 과제 시험용 서비스입니다. 문의 사항은 옆 아이콘을 통해
          오픈 채팅방을 이용해주세요.
        </Typography>
        <IconButton
          sx={{ color: amber[700] }}
          onClick={() => window.open("https://open.kakao.com/o/gIs600Me")}
        >
          <ContactSupportOutlinedIcon />
        </IconButton>
      </Box>
    );
  }

  /** 헤더 콘텐츠들 sx */
  const headerContentSx = {
    alignItems: "center",
    gap: "1.2rem",
  };

  return (
    <>
      <Test />
      <AppBar
        elevation={1}
        position="fixed"
        sx={{
          backgroundColor: "white",
        }}
      >
        <Toolbar
          sx={{
            width: 1,
            gap: "1.2rem",
            justifyContent: "space-between",
          }}
        >
          {/* 메인 퀴즈 리스트 페이지로 가는 로고 */}
          <FlexBox sx={headerContentSx}>
            <IconButton
              disableRipple
              variant="filledTonal"
              sx={{ borderRadius: "6px" }}
            >
              <MenuIcon />
            </IconButton>
            <LogoHeader pageUrl={mainPage} />
          </FlexBox>
          <FlexBox sx={headerContentSx}>
            {
              // 로그인, 회원가입, 퀴즈 페이지 제외하고 팝업 렌더
              window.location.pathname.includes(URL.LOGIN) ||
              window.location.pathname.includes(URL.SIGNUP) ||
              window.location.pathname.includes(URL.QUIZ) ? (
                <></>
              ) : (
                <>
                  {
                    // 로딩 중...
                    isLoading && (
                      <>
                        <Skeleton width="4rem" height="4rem" />
                        <Skeleton width="4rem" height="4rem" />
                        <Skeleton width="4rem" height="4rem" />
                      </>
                    )
                  }
                  {
                    // 로그인 안 함
                    !isLoading && !loginSuccess && (
                      <>
                        <Button variant="outlined" onClick={loginPage}>
                          로그인
                        </Button>
                        <Button variant="contained" onClick={signUpPage}>
                          회원가입
                        </Button>
                      </>
                    )
                  }
                  {
                    // 로그인 함 -  팝업 렌더
                    !isLoading &&
                      loginSuccess &&
                      HeaderPopupLists.map(
                        (popupData, index) =>
                          popupData.isMd && (
                            <PopupList
                              key={index}
                              popupIcon={popupData.popupIcon}
                              popupTitle={popupData.popupTitle}
                              popupListData={popupData.popupListData}
                              popupContent={popupData.popupContent}
                            />
                          )
                      )
                  }
                </>
              )
            }
          </FlexBox>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
