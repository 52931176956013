import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

/**
 * 테마 정의
 * - 고유색, 글꼴 등
 */
let theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#00639B",
      tonal: "#F2F6FB",
      container: "#D5E4F7",
    },
    secondary: {
      main: "#D3BFE6",
    },
    divider: "#DEE3EB",
    surface: {
      1: "#EFF4FA",
    },
    linear: {
      main: "linear-gradient(98.18deg, #81C4EA 2.53%, #00639B 73.02%)",
    },
  },
  shape: {
    borderRadius: 8,
  },

  shadows: [
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1
        ? "0px 1px 12px 1px rgba(0, 0, 0, 0.08)"
        : i === 2
        ? "0px 4px 12px rgba(0, 0, 0, 0.08)"
        : i === 3
        ? "0px 4px 12px rgba(0, 0, 0, 0.16)"
        : i === 4
        ? "0px 4px 16px rgba(0, 0, 0, 0.24)"
        : shadow
    ),
  ],
  customShadows: {
    tag: "0px 2px 5px 1px #00639B26",
  },

  typography: {
    fontFamily: "Noto Sans KR",
    htmlFontSize: 10,
    h3: {
      fontSize: "1.875rem",
    },
  },
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1040,
      xl: 1920,
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`,

  components: {
    /** Button */
    MuiButton: {
      variants: [
        /** variant: "filledTonal" 버튼 변수 추가 */
        {
          props: { variant: "filledTonal" },
          style: {
            backgroundColor: "#F2F6FB",
            color: "#00639B",
          },
        },
        {
          props: { variant: "filledTonal", color: "secondary" },
          style: {
            backgroundColor: "#F9ECFF",
            color: "#D3BFE6",
          },
        },
      ],
    },
    /** IconButton */
    MuiIconButton: {
      variants: [
        {
          props: { variant: "filledTonal" },
          style: {
            backgroundColor: "#F2F6FB",
            color: "#00639B",
          },
        },
        {
          props: { variant: "filledTonal", color: "secondary" },
          style: {
            backgroundColor: "#F9ECFF",
            color: "#D3BFE6",
          },
        },
      ],
    },
  },
});

/**
 * (반응형) 사용자가 정의한 theme의 글꼴 크기가 화면 크기에 맞게 변해야 함.
 * 따라서, responsiveFontSizes로 theme를 감싸줌.
 */
theme = responsiveFontSizes(theme);

/**
 * 반응형을 위해 사용자가 정의한 theme를 컴포넌트에 적용하기 위해선 ThemeProvider로 감싸줘야 함.
 * 따라서, ThemeProvider로 컴포넌트를 감싸주는 함수
 *
 * @param {Function} component : 사용자가 정의한 theme를 적용할 컴포넌트
 */
function ThemeComponent({ component }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {component}
    </ThemeProvider>
  );
}
export { theme, ThemeComponent };
