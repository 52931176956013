import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 틀린 문제 중에서 주제 ID로 문제 제공API
 */
async function getUserWrongTopic(params = {}) {
  let data;
  try {
    await axios
      .get(
        APIURL.api +
          `quiz/user/wrong/${params.topicId}?pageNum=${params.pageNum}&&pagingSize=${params.pagingSize}`
      )
      .then((response) => {
        // console.log("틀린 문제 중에서 퀴즈 로드 잘 해옴~", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // console.log("로드 문제!!", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      //   case 404:
      /** 풀이할 문제가 없음 */
      default:
        return;
    }
  }
}
export default getUserWrongTopic;
