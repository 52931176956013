import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 사용자 풀이 이력 - 서버에 요청
 * @param {object} dataToSubmit : 서버에 보낼 정보
 */
async function recordPlayUser(dataToSubmit = {}) {
  let data;
  try {
    await axios
      .post(APIURL.api + "record/play-log", dataToSubmit)
      .then((response) => {
        // console.log("사용자 풀이 이력 기록 잘 보냄!", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // console.log("사용자 풀이 이력 못 보냄..", error);
    switch (error.statusCode) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default recordPlayUser;
