import MyLoading from "../global-components/MyLoading";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useSx from "../../hooks/useSx";
import { FlexColumnBox } from "../layout";

// 비밀번호 컴포넌트
function Password({
  isLoading,
  CURRENT_PASSWORD,
  currentPassword,
  handlePasswordInput,
  currentPasswordLog,
  authError,
  NEW_PASSWORD,
  newPassword,
  newPasswordLog,
  NEW_PASSWORD_CONFIRM,
  newPasswordConfirm,
  newPasswordConfirmLog,
  handleSubmit,
  isDisabled,
}) {
  const { settingLayout, settingBoxGap, settingSubtitleFont } = useSx();

  return (
    <>
      {isLoading && <MyLoading />}
      {!isLoading && (
        <Stack {...settingLayout}>
          <FlexColumnBox {...settingBoxGap}>
            <Typography variant="h5">비밀번호 변경</Typography>
            <Card elevation={2}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2.4rem",
                }}
              >
                <Grid container sx={{ gap: "0.8rem", maxWidth: "56rem" }}>
                  <Grid container>
                    <Typography sx={settingSubtitleFont}>
                      현재 비밀번호
                    </Typography>
                  </Grid>
                  <Grid container>
                    <TextField
                      type="password"
                      id={CURRENT_PASSWORD}
                      size="small"
                      fullWidth
                      placeholder="현재 비밀번호를 입력해주세요."
                      variant="outlined"
                      value={currentPassword}
                      onChange={handlePasswordInput}
                      helperText={currentPasswordLog && authError}
                      error={currentPasswordLog}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: "0.8rem", maxWidth: "56rem" }}>
                  <Grid container>
                    <Typography sx={settingSubtitleFont}>
                      새 비밀번호
                    </Typography>
                  </Grid>
                  <Grid container>
                    <TextField
                      type="password"
                      id={NEW_PASSWORD}
                      size="small"
                      fullWidth
                      placeholder="새 비밀번호를 입력해주세요."
                      variant="outlined"
                      value={newPassword}
                      onChange={handlePasswordInput}
                      helperText={newPasswordLog && authError}
                      error={newPasswordLog}
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: "0.8rem", maxWidth: "56rem" }}>
                  <Grid container>
                    <Typography sx={settingSubtitleFont}>
                      새 비밀번호 확인
                    </Typography>
                  </Grid>
                  <Grid container>
                    <TextField
                      type="password"
                      id={NEW_PASSWORD_CONFIRM}
                      size="small"
                      fullWidth
                      placeholder="새 비밀번호를 다시 입력해주세요."
                      variant="outlined"
                      value={newPasswordConfirm}
                      onChange={handlePasswordInput}
                      helperText={newPasswordConfirmLog && authError}
                      error={newPasswordConfirmLog}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={isDisabled}
                  >
                    변경하기
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </FlexColumnBox>
        </Stack>
      )}
    </>
  );
}
export default Password;
