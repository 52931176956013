import { Button } from "@mui/material";
import { FlexBox } from "../layout";
import PanoramaFishEyeRoundedIcon from "@mui/icons-material/PanoramaFishEyeRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTheme, styled } from "@mui/material/styles";

/** OX 버튼 눌렀을 때 지정한 색으로 정의한 컴포넌트 */
const StyledButtonAnswer = styled(Button)`
  background-color: ${({ backcolor }) => backcolor};
`;

/**
 * OX 버튼
 *
 * @param {object} answerTheme : OX 버튼 사용자 정의 테마
 * @param {function} handleOXItemClick : OX 버튼 누르면 활성화 해주는 함수
 * @param {string} selectedOX : OX 중 현재 선택된 버튼
 * @param {string} answerItem : "O" 버튼인지, "X" 버튼인지
 */
function ButtonAnswer({
  answerTheme,
  handleOXItemClick,
  selectedOX,
  answerItem,
}) {
  const theme = useTheme();
  /**
   * OX 버튼 크기
   */
  const answerSx = {
    // [theme.breakpoints.down("sm")]: {
    //   width: "6.4rem",
    //   height: "6.4rem",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   width: "7.2rem",
    //   height: "7.2rem",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "7.6rem",
      height: "7.6rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "8.4rem",
      height: "8.4rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "9.6rem",
      height: "9.6rem",
    },
    borderRadius: "100%",
  };
  return (
    <StyledButtonAnswer
      variant="contained"
      backcolor={selectedOX === answerItem ? answerTheme.selectedColor : null}
      color={answerTheme.color}
      size="large"
      sx={answerSx}
      onClick={(event) => handleOXItemClick(event, answerItem)}
    >
      {answerTheme.icon}
    </StyledButtonAnswer>
  );
}
/**
 * OX 정답 선택지
 *
 * @param {function} handleOXItemClick : OX 버튼 누르면 활성화 해주는 함수
 * @param {string} selectedOX : OX 중 현재 선택된 버튼
 * @param {bool} isSubmit : 제출 여부
 */
function Answer({ handleOXItemClick, selectedOX, isSubmit }) {
  /**
   * (반응형) breakPoints에 따라 HTML에 적용하는 스타일을 전환하도록 함.
   *
   * useTheme : 현재 정의한 테마를 리턴하는 hook
   * - 현재 정의한 breakPoints에 접근 가능
   */
  const theme = useTheme();
  /**
   * OX 아이콘 크기
   */
  const answerIconSx = {
    // [theme.breakpoints.down("sm")]: {
    //   width: "3.6rem",
    //   height: "3.6rem",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   width: "4.4rem",
    //   height: "4.4rem",
    // },
    [theme.breakpoints.down("sm")]: {
      width: "4.8rem",
      height: "4.8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "5.6rem",
      height: "5.6rem",
    },
    [theme.breakpoints.up("md")]: {
      width: "6.4rem",
      height: "6.4rem",
    },
  };
  /**
   * OX 버튼 테마
   */
  const answerTheme = {
    /** O */
    right: {
      color: "info",
      selectedColor: "#013863",
      icon: <PanoramaFishEyeRoundedIcon sx={answerIconSx} />,
    },
    /** X */
    wrong: {
      color: "error",
      selectedColor: "#700c0c",
      icon: <CloseRoundedIcon sx={answerIconSx} />,
    },
  };
  /** OX 감싸는 레이아웃 */
  const answerBoxSx = {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      gap: (theme) => theme.spacing(56),
    },
    [theme.breakpoints.up("sm")]: {
      gap: (theme) => theme.spacing(84),
    },
    my: "4.0rem",
  };
  // console.log(selectedOX);
  return (
    <FlexBox sx={answerBoxSx} style={{ pointerEvents: isSubmit && "none" }}>
      <ButtonAnswer
        answerTheme={answerTheme.right}
        handleOXItemClick={handleOXItemClick}
        selectedOX={selectedOX}
        answerItem="O"
      />
      <ButtonAnswer
        answerTheme={answerTheme.wrong}
        handleOXItemClick={handleOXItemClick}
        selectedOX={selectedOX}
        answerItem="X"
      />
    </FlexBox>
  );
}
export default Answer;
