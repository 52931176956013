import { auth } from "../service/firebase";

/**
 * firebase : 사용자 ID 토큰 받아서 로컬 스토리지에 담기
 * @param {string} tokenKey : 로컬 스토리지에 담을 id token key 이름
 */
export async function handleIdToken(tokenKey) {
  let isSuccess = false;
  await auth.currentUser
    .getIdToken(/* forceRefresh */ true)
    .then((idToken) => {
      /** 로컬스토리지에 토큰 담아놓기 */
      isSuccess = true;
      localStorage.setItem(tokenKey, idToken);
      // console.log("id토큰 받아와서 로컬 스토리지에 저장 성공!", idToken);
    })
    .catch((error) => {
      /** 실패 */
      isSuccess = false;
      // console.log("id토큰 받아와서 로컬 스토리지에 저장 실패", error);
    });
  return isSuccess;
}
