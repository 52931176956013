import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { styled } from "@mui/material/styles";

const DragHandler = styled(({ isVisible, outlined, ...props }) => (
  <DragIndicatorIcon {...props} />
))(({ isVisible, outlined, theme }) => ({
  position: "absolute",
  left: 0,
  cursor: "move",
  margin: "24px",
  zIndex: 1900,

  border: outlined ? "1px solid" : "",
  borderRadius: outlined ? 4 : 0,
  // padding: outlined ? 1.2 : 0,
  backgroundColor: outlined ? "white" : "",

  opacity: isVisible ? 0.95 : 0,
  transition: theme.transitions.create(["opacity"], {
    duration: theme.transitions.duration.shorter,
  }),

  boxShadow: theme.shadows[3],
  width: "24px",
  height: "28px",
}));

export default DragHandler;
