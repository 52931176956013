import { Stack, Avatar, Typography, Box } from "@mui/material";
import PageScreenBox from "../components/global-components/PageScreenBox";
import { yellow } from "@mui/material/colors";
import LevelProgressBar from "../components/global-components/LevelProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
  FooterBox,
  FooterButton,
  FooterPosition,
} from "../components/global-components/FooterSx";
import usePage from "../hooks/usePage";
import { onChangeEventAchievement, onChangeEventClose } from "../modules/event";
import { styled } from "@mui/material/styles";
import useGameGraphic from "../hooks/useGameGraphic";
import AchievmentBar from "../components/global-components/AchievmentBar";
import React from "react";
import { onInitUserAchievementIdList } from "../modules/auth";

/** 레벨 업 & 업적 달성 시 이벤트 페이지 */
function Event() {
  /** Redux State */
  const dispatch = useDispatch();
  /** 그래픽 적용을 위한 함수 */
  const { handleAchievementData, getAchievmentData, getAchievmentIcon } =
    useGameGraphic();
  /** 페이징 */
  const { mainPageImme } = usePage();

  /** 달성한 업적 id 리스트 */
  const [achievmentData, setAchievmentData] = React.useState([]);

  const { eventOpen, eventType, userNickname, userAchievementIdList } =
    useSelector((state) => ({
      eventOpen: state.event.eventOpen,
      eventType: state.event.eventType,
      userNickname: state.auth.userNickname, // 닉네임
      userAchievementIdList: state.auth.userAchievementIdList,
    }));

  React.useEffect(() => {
    /** close면 렌더 x */
    if (!eventOpen) return;

    /** open이면 렌더 */

    /** 로컬 스토리지에 달성한 출석 관련 업적 & 퀴즈 업적으로 데이터 들어오면, 그걸로 추가 해주기, 그리고 바로 초기화 */
    if (userAchievementIdList.length !== 0 && achievmentData.length !== 0) {
      /** 잘 채워졌다면 초기화 해주기 */
      handleUserAchievementIdListInit();
      // console.log("여기 왔나?11111");
    }
    // console.log("여기 2222");
    // if (achievmentData.length === 0) {
    //   /** 비워주기 */
    //   handleUserAchievementIdListInit();
    // }
    const l = handleUserAchievementEvent();
  }, [eventOpen, userAchievementIdList, achievmentData]);

  /** 여기서 다 보여주고, 초기화 시키기 */
  /** User Achievement Id List Init */
  const handleUserAchievementIdListInit = () => {
    dispatch(onInitUserAchievementIdList());
  };
  /** achievement event open */
  const handleAchievementEventOpen = () => {
    dispatch(onChangeEventAchievement());
  };
  /** event page close */
  const handleEventClose = () => {
    dispatch(onChangeEventClose());
  };

  // console.log("userAchievementIdList", userAchievementIdList);
  // console.log("achievmentData", achievmentData);

  async function handleUserAchievementEvent() {
    if (userAchievementIdList.length === 0) return;
    // 만약 업적이 들어왔다? 그럼 여기서 바로 handleAchievementEventOpen 이벤트 열어주기

    // console.log("여기인가");
    /** 채워주기 */
    if (achievmentData.length === 0) {
      await setAchievmentData(handleAchievementData(userAchievementIdList));
      // await handleAchievementEventOpen(); // 이벤트 실행
    }
  }

  // React.useEffect(() => {
  //   /** 로컬 스토리지에 달성한 출석 관련 업적 & 퀴즈 업적으로 데이터 들어오면, 그걸로 추가 해주기, 그리고 바로 초기화 */
  //   if (userAchievementIdList.length !== 0 && achievmentData.length !== 0) {
  //     /** 잘 채워졌다면 초기화 해주기 */
  //     handleUserAchievementIdListInit();
  //     console.log("여기 왔나?11111");
  //   }
  //   console.log("여기 2222");
  //   // if (achievmentData.length === 0) {
  //   //   /** 비워주기 */
  //   //   handleUserAchievementIdListInit();
  //   // }
  //   const l = handleUserAchievementEvent();
  // }, [userAchievementIdList, achievmentData]);

  /////////////////////////////////////////

  /** 확인 버튼 */
  function onClickCheck() {
    handleEventClose(); // 이벤드 창 닫고
    // if (eventType === "achievement") handleUserAchievementIdListInit(); // 만약 업적 달성
  }
  /** 메인으로 가기 버튼 */
  function onClickMain() {
    handleEventClose(); // 이벤드 창 닫고
    mainPageImme();
    // if (eventType === "achievement") handleUserAchievementIdListInit(); // 만약 업적 달성
  }

  /** 프로필 크기 */
  const profileSize = { xs: 80, sm: 92, md: 104, lg: 112 };

  /** page 제일 바깥 스크린 크기 레이아웃 */
  const FixBox = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: 0,
    bottom: 0,
    zIndex: theme.zIndex.tooltip + 1,
    width: "100%",
    backgroundColor: "white",
  }));

  let eventPresent =
    (eventType === "levelUp" && {
      title: "레벨 업!",
      show: <LevelProgressBar isLevel={true} />,
    }) ||
    (eventType === "achievement" && {
      title: "배지 획득!",
      show: <AchievmentBar achievmentData={achievmentData} />,
    });

  return (
    <>
      {eventOpen && (
        <FixBox>
          <PageScreenBox
            /**제일 바깥 스크린 레이아웃  */ sx={{
              pt: "23rem",
              backgroundColor: "white",
            }}
          >
            <Stack /** 제목 & 프로필 & (레벨 바 || 배지) 묶음 */
              sx={{
                width: 1,
                alignItems: "center",
              }}
              spacing={10}
            >
              <Typography variant="h3" sx={{ color: yellow[600] }}>
                {eventPresent.title}
              </Typography>
              <Avatar
                src="/broken-image.jpg"
                color="primary.main"
                sx={{
                  width: profileSize,
                  height: profileSize,
                }}
              />
              <Typography variant="h5">{userNickname}</Typography>
              {eventPresent.show}
            </Stack>
          </PageScreenBox>
          <FooterPosition elevation={3} square>
            <FooterBox>
              <FooterButton onClick={onClickMain}>메인으로 가기</FooterButton>
              <FooterButton variant="contained" onClick={onClickCheck}>
                확인
              </FooterButton>
            </FooterBox>
          </FooterPosition>
        </FixBox>
      )}
    </>
  );
}
export default Event;
