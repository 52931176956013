/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const WRITE_QUIZ_TYPE = "writeQuiz/WRITE_QUIZ_TYPE"; //quizType

const WRITE_QUIZ_TOPICS = "writeQuiz/WRITE_QUIZ_TOPICS"; //quizTopics

const WRITE_QUESTION_TEXT = "writeQuiz/WRITE_QUESTION_TEXT"; //questionText
const WRITE_DESCRIPTION_TEXT = "writeQuiz/WRITE_DESCRIPTION_TEXT"; //descriptionText
const WRITE_COMMENTARY_TEXT = "writeQuiz/WRITE_COMMENTARY_TEXT"; //commentaryText

const WRITE_EXAMPLES = "writeQuiz/WRITE_EXAMPLES"; //examples
const WRITE_EXAMPLES_ITEM = "writeQuiz/WRITE_EXAMPLES_ITEM"; //examples_item
const WRITE_EXAMPLES_ITEM_ISANSWER = "writeQuiz/WRITE_EXAMPLES_ITEM_ISANSWER"; //examples_item_isAnswer
/**
 * + ) example의 이미지 관리
 * 
 * <미리보기>
 *   examples: [
     {
       exampleText: "",
       exampleImage: URL(미리보기를 위해), 왜..? Example 에서 URL을 examples.exampleImage로 바로 접근해갖고..
       isAnswer: false,
     },
     ...] 와 함께
    [FormData, FormData, ...]를 저장하다가

     <API post>

     [FormData, FormData, ...]에서 해당 index의 이름 뽑아서
     examples: [
     {
       exampleText: "",
       exampleImage: "이미지 이름"(서버에 보내기 위해), 
       isAnswer: false,
     },
     ...] 를 만들고,
    [FormData, FormData, ...]를 함께 서버에 보낸다.
 * 
 */
const WRITE_EXAMPLES_ITEM_IMAGE = "writeQuiz/WRITE_EXAMPLES_ITEM_IMAGE"; //exampleImage 이름
const WRITE_EXAMPLES_IMAGES = "writeQuiz/WRITE_EXAMPLES_IMAGES"; //exampleImages [formData, formData, formData, ...] : 서버에 보내기 위함

const WRITE_ANSWER = "writeQuiz/WRITE_ANSWER"; //answer
const WRITE_ANSWER_TEXT = "writeQuiz/WRITE_ANSWER_TEXT"; //answerText

const WRITE_TOPIC_LIST = "writeQuiz/WRITE_TOPIC_LIST"; //topicList

const WRITE_DESCRIPTION_IMAGES = "writeQuiz/WRITE_DESCRIPTION_IMAGES"; //descriptionImages [formData, formData, formData, ...] : 서버에 보내기 위함
const WRITE_DESCRIPTION_URL = "writeQuiz/WRITE_DESCRIPTION_URL"; //descriptionUrl [url, url, ...] : 미리보기를 위함

const WRITE_COMMENTARY_IMAGES = "writeQuiz/WRITE_COMMENTARY_IMAGES"; //commentaryImages [formData, formData, formData, ...] : 서버에 보내기 위함
const WRITE_COMMENTARY_URL = "writeQuiz/WRITE_COMMENTARY_URL"; //commentaryUrl [url, url, ...] : 미리보기를 위함

const WRITE_INIT = "writeQuiz/WRITE_INIT"; // 초기화

const SCROLL_PAGE = "writeQuiz/SCROLL_PAGE"; // 스크롤 페이지

/** init State ( 초기 상태 ) */
const initialState = {
  //===========텍스트=============
  quizType: "",
  difficultyValue: 1,
  questionText: "",
  descriptionText: "",

  /**
   *
   * 0: "{\"topicId\":6,\"topicName\":\"마바\"}"
   * 1: "{\"topicId\":7,\"topicName\":\"사아\"}" 처럼 Array마다 JSON으로 저장했다가,
   * 서버 요청 할 땐 Object로 변환하여 보냄
   */
  quizTopics: [],

  commentaryText: "",
  examples: [
    // {
    //   exampleText: "",
    //   exampleImage: "", // [주의] 보기 이미지는 따로 안 보내고 같이 보냄!!!
    //   isAnswer: false,
    // },
  ],
  answerText: "",
  answer: null,
  //===========이미지=============
  exampleImages: [
    // 보기
    // images, images, images... File 객체를 FormData 객체로 바꿔 배열로 저장
  ],
  /**
   * 서버에 보내는 건, 이미지 !!! File !!! 이고, 미리보기로 보여주는 건 url이 필요하기 때문에 아래와 같이 따로 정의
   * descriptionImages : 이미지 File array
   * descriptionUrl : 이미지 url array
   */
  descriptionImages: [
    // 예문 DESCRIPTION
    // images, images, images... File 객체를 FormData 객체로 바꿔 배열로 저장
  ],
  descriptionUrl: [] /** 렌더하기 위한 image url : 실제론 안 보냄 */,

  commentaryImages: [
    // 해설 COMMENTARY
    // images, images, images... File 객체를 FormData 객체로 바꿔 배열로 저장
  ],
  commentaryUrl: [] /** 렌더하기 위한 image url : 실제론 안 보냄 */,
  //========================
  /** 주제 리스트 */
  topicList: [],
  // 주제 불러오는 스크롤 상태
  scrollPage: 1,
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeInit = () => ({
  // 초기화
  type: WRITE_INIT,
});
export const onChangeScrollPage = (value) => ({
  type: SCROLL_PAGE,
  value,
});
export const onChangeInput = ({ id, value }) => ({
  type:
    (id === "questionText" && WRITE_QUESTION_TEXT) ||
    (id === "descriptionText" && WRITE_DESCRIPTION_TEXT) ||
    (id === "commentaryText" && WRITE_COMMENTARY_TEXT) ||
    (id === "answerText" && WRITE_ANSWER_TEXT),
  [id]: value,
});
export const onSelectQuizType = (value) => ({
  //유형
  type: WRITE_QUIZ_TYPE,
  value,
});
export const onSelectQuizTopic = (value) => ({
  //주제
  type: WRITE_QUIZ_TOPICS,
  value,
});
// ==================================객관식=======================================
export const onMakeExamples = (value) => ({
  //객관식 보기
  type: WRITE_EXAMPLES,
  value,
});
export const onMakeExamplesItem = (value, index) => ({
  //객관식 보기 텍스트 하나씩 바꾸기
  type: WRITE_EXAMPLES_ITEM,
  value,
  index,
});
export const onMakeExamplesItemIsAnswer = (value, index) => ({
  //객관식 정답 여부 하나씩 바꾸기
  type: WRITE_EXAMPLES_ITEM_ISANSWER,
  value,
  index,
});
export const onMakeExamplesItemImage = (value, index) => ({
  //객관식 이미지 하나씩 바꾸기
  type: WRITE_EXAMPLES_ITEM_IMAGE,
  value,
  index,
});
// export const onMakeExamplesItemImageFile = (value, index) => ({
//   //객관식 보기의 이미지 FILE의 하나씩 바꾸기
//   type: WRITE_EXAMPLES_ITEM_IMAGE_FILE,
//   value,
//   index,
// });

export const onSelectAnswer = (value) => ({
  //정답 OX
  type: WRITE_ANSWER,
  value,
});
//=======
export const onLoadTopicList = (value) => ({
  //주제 선택하기 위한, 주제 리스트
  type: WRITE_TOPIC_LIST,
  value,
});
//========예문 이미지============
export const onMakeDescriptionImages = (value) => ({
  // 예문 이미지 전체 수정
  type: WRITE_DESCRIPTION_IMAGES,
  value,
});
//========예문 이미지 Url============
export const onMakeDescriptionUrl = (value) => ({
  // 예문 이미지 url 전체 수정
  type: WRITE_DESCRIPTION_URL,
  value,
});

//========해설 이미지============
export const onMakeCommentaryImages = (value) => ({
  // 해설 이미지 전체 수정
  type: WRITE_COMMENTARY_IMAGES,
  value,
});
//========해설 이미지 Url============
export const onMakeCommentaryUrl = (value) => ({
  // 해설 이미지 url 전체 수정
  type: WRITE_COMMENTARY_URL,
  value,
});

//========보기 이미지============
export const onMakeExampleImages = (value) => ({
  // 보기 이미지 전체 수정
  type: WRITE_EXAMPLES_IMAGES,
  value,
});

/** reducer정의 */
export default function writeQuiz(state = initialState, action) {
  // console.log(state.scrollPage);
  // console.log(action);
  switch (action.type) {
    case WRITE_INIT:
      return {
        quizType: "",
        difficultyValue: 1,
        questionText: "",
        descriptionText: "",
        quizTopics: [],
        commentaryText: "",
        examples: [],
        answerText: "",
        answer: null,
        exampleImages: [],
        descriptionImages: [],
        descriptionUrl: [],
        commentaryImages: [],
        commentaryUrl: [],
        topicList: [],
      };
    case WRITE_QUIZ_TYPE:
      return {
        ...state,
        quizType: action.value,
      };
    case WRITE_QUESTION_TEXT:
      return {
        ...state,
        questionText: action.questionText,
      };
    case WRITE_DESCRIPTION_TEXT:
      return {
        ...state,
        descriptionText: action.descriptionText,
      };
    case WRITE_QUIZ_TOPICS:
      return {
        ...state,
        quizTopics: action.value,
      };
    case WRITE_COMMENTARY_TEXT:
      return {
        ...state,
        commentaryText: action.commentaryText,
      };

    case WRITE_EXAMPLES: // 보기: 전체 바꾸기 (추가, 삭제)
      return {
        ...state,
        examples: action.value,
      };
    case WRITE_EXAMPLES_ITEM: // 보기: 텍스트 하나씩 바꾸기
      return {
        ...state,
        examples: state.examples.map((item, idx) =>
          idx === action.index
            ? { ...item, exampleText: action.value }
            : { ...item }
        ),
      };
    case WRITE_EXAMPLES_ITEM_ISANSWER: // 보기: 정답 여부 하나씩 바꾸기
      return {
        ...state,
        examples: state.examples.map((item, idx) =>
          idx === action.index
            ? { ...item, isAnswer: action.value }
            : { ...item }
        ),
      };
    case WRITE_EXAMPLES_ITEM_IMAGE: // 보기: 이미지 하나씩 바꾸기
      return {
        ...state,
        examples: state.examples.map((item, idx) =>
          idx === action.index
            ? { ...item, exampleImage: action.value }
            : { ...item }
        ),
      };

    case WRITE_ANSWER:
      return {
        ...state,
        answer: action.value,
      };
    case WRITE_ANSWER_TEXT:
      return {
        ...state,
        answerText: action.answerText,
      };

    case WRITE_TOPIC_LIST: // 주제 리스트
      return {
        ...state,
        topicList: action.value,
      };

    case WRITE_EXAMPLES_IMAGES: // 보기 이미지: File 하나 바꾸기 (추가, 삭제)
      return {
        ...state,
        exampleImages: action.value,
      };

    case WRITE_DESCRIPTION_IMAGES: // 예문 이미지: 전체 바꾸기 (추가, 삭제)
      return {
        ...state,
        descriptionImages: action.value,
      };
    case WRITE_DESCRIPTION_URL: // 예문 url 이미지: 전체 바꾸기 (추가, 삭제)
      return {
        ...state,
        descriptionUrl: action.value,
      };

    case WRITE_COMMENTARY_IMAGES: // 해설 이미지: 전체 바꾸기 (추가, 삭제)
      return {
        ...state,
        commentaryImages: action.value,
      };
    case WRITE_COMMENTARY_URL: // 해설 url 이미지: 전체 바꾸기 (추가, 삭제)
      return {
        ...state,
        commentaryUrl: action.value,
      };
    case SCROLL_PAGE:
      return {
        ...state,
        scrollPage: action.value,
      };

    default:
      return state;
  }
}
