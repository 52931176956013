import React from "react";
import {
  Typography,
  Stack,
  Avatar,
  Box,
  Button,
  Badge,
  Grid,
  ButtonBase,
  TextField,
  ClickAwayListener,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Modal,
  Card,
  CardContent,
} from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import useSx from "../../hooks/useSx";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import ModalWarning from "../global-components/ModalWarning";
import SettingComponent from "./SettingComponent";
import { FlexColumnBox } from "../layout";

/** 계정 수정, 계정 탈퇴 */
function Account({
  changeImageSrc,
  changeDefaultProfileImage,
  isEdit,
  profileImage,
  imageInput,
  onAddImageChange,
  USER_NICK_NAME,
  NICKNAME_LIMIT,
  userNickname,
  handleOnSetUserNickName,
  submitProfileUpdate,
  popUpOpen,
  toggleDrawer,
  handleClick,
  handleClickAway,
  isNicknameActive,
  isUpdateLoading,
  handleEditOpen,
  userEmail,
  onClickDeleteAccount,
  warningModal,
  onClickCancle,
  onClickCheckDeleteAccount,
}) {
  const profileSize = { xs: "8rem", sm: "10rem", md: "12rem" };
  const EditSize = { xs: "2.6rem", sm: "3.2rem", md: "3.6rem" };
  const {
    isWindowSmDown,
    modalStyle,
    settingSubtitleFont,
    settingLayout,
    settingBoxGap,
  } = useSx();

  /** 프로필 선택 팝업 스타일 */
  const popupLayoutStyles = {
    position: "absolute",
    zIndex: 2000,
    bgcolor: "white",
  };
  const popupStyles = {
    position: "relative",
  };

  /** 프로필 사진 선택 목록 */
  const listData = [
    {
      name: "사진 선택",
      onClickFuntion: changeImageSrc,
    },
    {
      name: "기본 이미지로 변경",
      onClickFuntion: changeDefaultProfileImage,
    },
  ];

  const StyledListItem = ({ data }) => {
    return (
      <ListItem disablePadding>
        <ListItemButton
          onClick={data.onClickFuntion}
          sx={{
            paddingY: "1.2rem",
            paddingLeft: "2.0rem",
            paddingRight: "8rem",
          }}
        >
          <ListItemText primary={data.name} sx={{ ml: "1.6rem" }} />
        </ListItemButton>
      </ListItem>
    );
  };
  //   sx={{ paddingY: "1.2rem", paddingX: "1.6rem" }}
  const compontList = (
    <>
      {listData.map((data) => (
        <StyledListItem key={data.name} data={data} />
      ))}
    </>
  );

  /** sm down에서의 popup 컴포넌트 */
  const SmPopup = () => {
    return (
      <Modal open={popUpOpen} onClose={toggleDrawer(false)}>
        <Box sx={modalStyle} noValidate>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1.3rem" }}>
            프로필 사진
          </Typography>
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {compontList}
          </Box>
        </Box>
      </Modal>
    );
  };
  /** md up에서의 popup 컴포넌트 */
  const MdPopup = () => {
    return (
      <Paper sx={popupLayoutStyles} elevation={3}>
        <Box sx={popupStyles}>
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {compontList}
          </Box>
        </Box>
      </Paper>
    );
  };

  // subtitle - content gap
  const contentGap = { gap: "0.8rem" };

  // 프로필 사진
  function AvatarProfile() {
    return (
      <Grid container sx={contentGap}>
        <Grid container>
          <Typography sx={settingSubtitleFont}>프로필 사진</Typography>
        </Grid>
        <Grid container>
          <Grid item>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClickAway}
            >
              <Box>
                <ButtonBase
                  onClick={handleClick}
                  disableRipple
                  disabled={!isEdit}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={
                      isEdit && (
                        <Avatar
                          sx={{
                            bgcolor: lightBlue[600],
                            width: EditSize,
                            height: EditSize,
                          }}
                        >
                          <EditIcon
                            fontSize={isWindowSmDown ? "small" : "medium"}
                          />
                        </Avatar>
                      )
                    }
                  >
                    <Avatar
                      src={profileImage.url || ""} // 링크
                      sx={{ width: profileSize, height: profileSize }}
                    />
                  </Badge>
                </ButtonBase>
                {isWindowSmDown ? <SmPopup /> : popUpOpen ? <MdPopup /> : null}
              </Box>
            </ClickAwayListener>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  // 계정 탈퇴 콘텐츠
  function AccountDelete() {
    return (
      <>
        <Grid container>
          <Typography variant="subtitle1" color="text.secondary">
            탈퇴 시, 계정 복구는 불가능 합니다.
          </Typography>
        </Grid>
        <Grid container>
          <Button
            variant="outlined"
            color="error"
            onClick={onClickDeleteAccount}
          >
            탈퇴 하기
          </Button>
        </Grid>
      </>
    );
  }

  return (
    <Stack {...settingLayout}>
      <FlexColumnBox {...settingBoxGap}>
        <Typography variant="h5">계정 수정</Typography>
        <Card elevation={2}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2.4rem",
            }}
          >
            <Grid container sx={{ gap: "2.4rem" }}>
              <Grid item xs>
                <Grid container sx={{ gap: "1.6rem" }}>
                  {isWindowSmDown && <AvatarProfile />}
                  <Grid container sx={contentGap}>
                    <Grid container>
                      <Typography sx={settingSubtitleFont}>닉네임</Typography>
                    </Grid>
                    <Grid container>
                      {!isEdit && (
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="닉네임"
                          variant="outlined"
                          value={userNickname}
                          disabled
                        />
                      )}
                      {isEdit && (
                        <TextField
                          hiddenLabel
                          size="small"
                          fullWidth
                          id={USER_NICK_NAME}
                          variant="outlined"
                          inputProps={{
                            maxLength: NICKNAME_LIMIT,
                          }}
                          value={userNickname}
                          helperText={`${userNickname.length}/${NICKNAME_LIMIT}`}
                          onChange={handleOnSetUserNickName}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={contentGap}>
                    <Grid container>
                      <Typography sx={settingSubtitleFont}>이메일</Typography>
                    </Grid>
                    <Grid container>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="이메일"
                        variant="outlined"
                        disabled
                        value={userEmail}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!isWindowSmDown && (
                <Grid item xs>
                  <AvatarProfile />
                </Grid>
              )}
            </Grid>
            <Grid container>
              <Grid item>
                {!isEdit && (
                  <Button variant="contained" onClick={handleEditOpen}>
                    수정하기
                  </Button>
                )}
                {isEdit && (
                  <>
                    {isUpdateLoading && (
                      <LoadingButton loading variant="contained" />
                    )}
                    {!isUpdateLoading && (
                      <>
                        <Button
                          variant="contained"
                          onClick={submitProfileUpdate}
                          disabled={isNicknameActive}
                        >
                          변경 사항 저장
                        </Button>
                        {/* <Button
                            variant="outlined"
                            onClick={handleEditClose}
                            sx={{ ml: "1.6rem" }}
                          >
                            취소
                          </Button> */}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </FlexColumnBox>

      <SettingComponent
        title="계정 탈퇴"
        component={<AccountDelete />}
        color="error"
      />
      {/* 계정 탈퇴하기 주의 모달창 */}
      <ModalWarning
        title="탈퇴하시겠습니까?"
        content="계정을 탈퇴하시면 다시 복구할 수 없습니다. 그래도 정말 탈퇴하시겠습니까?"
        buttonHandleName="탈퇴"
        modalWarningOpen={warningModal}
        onClickModalCancle={onClickCancle}
        onClickModalHandle={onClickCheckDeleteAccount}
      />
      <input
        type="file" // input 타입을 file로 지정하면 파일을 가져올 수 있는 형태의 input이 생성됨.
        accept="image/*"
        style={{ display: "none" }}
        ref={imageInput}
        onChange={onAddImageChange}
      />
    </Stack>
  );
}
export default Account;
