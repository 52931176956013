import { useState } from "react";
import reorder from "../utils/reorder";

/**
 * drag hook
 * @param {*} initialItems
 * @returns
 */
function useDragList(initialItems) {
  const [items, setItems] = useState(initialItems);

  // add list item form you want
  function onAddItem(form) {
    setItems([...items, form]);
  }

  // remove list item
  function onRemoveItem(index) {
    let tmp = [...items]; // 현재 값 가져오기
    tmp.splice(index, 1);
    setItems(tmp);
  }

  // drag end control
  function onDragEnd({ source, destination }) {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(items, source.index, destination.index);

    setItems(newItems);
  }

  return { items, setItems, onDragEnd, onAddItem, onRemoveItem };
}
export default useDragList;
