import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 사용자 닉네임 업데이트
 * @param {object} dataToSubmit : 서버에 보낼 정보
 */
async function putProfileNickname(dataToSubmit = {}) {
  let data;
  try {
    await axios
      .put(APIURL.api + "user/profile/nickname", dataToSubmit)
      .then((response) => {
        //console.log("등록 완 : 닉네임 업데이트 ID", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    //console.log("업데이트 실패...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default putProfileNickname;
