import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

/**
 * 게스트 문제 제공 - 서버에 요청
 */
async function loadQuizGuest() {
  let data;
  try {
    await axios.get(APIURL.api + "quiz/play/guest").then((response) => {
      //console.log("퀴즈 로드 잘 해옴~", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("로드 문제!!", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default loadQuizGuest;
