import SubTitle from "../global-components/SubTitle";
import { ID_INPUT_ANSWER } from "../../constants/writeQuiz";
import { Box, Radio, RadioGroup } from "@mui/material";
import { FormControlCard } from "../atom-components/StyledFormControlLabels";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";

/**
 * OX
 * @param {} param0
 * @returns
 */
function WriteQuizQuestionOX({ inputAnswer, onChangeWriteQuizSelects }) {
  // form OX data
  const fontSize = "5.4rem";
  const formQuizAnswerData = [
    {
      value: "O", // control value
      label: "O", // show value
      content: (
        <CircleOutlinedIcon
          color="info"
          sx={{ width: fontSize, height: fontSize }}
        />
      ),
    },
    {
      value: "X",
      label: "X",
      content: (
        <ClearIcon color="error" sx={{ width: fontSize, height: fontSize }} />
      ),
    },
  ];

  return (
    <>
      <SubTitle titleName="정답" body="정답에 맞게 O 혹은 X를 선택해주세요." />
      <RadioGroup
        row
        name={ID_INPUT_ANSWER}
        defaultValue={formQuizAnswerData[0].value}
        value={inputAnswer}
        onChange={onChangeWriteQuizSelects}
      >
        {formQuizAnswerData.map((data) => {
          const { value, label, content } = data;

          return (
            <FormControlCard
              key={value}
              value={value}
              label={label}
              content={
                <Box sx={{ margin: "20px 4px 16px 24px" }}>{content}</Box>
              }
              control={<Radio />}
            />
          );
        })}
      </RadioGroup>
    </>
  );
}
export default WriteQuizQuestionOX;
