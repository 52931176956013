import { Typography, Box } from "@mui/material";

/**
 * 주제를 태그 컴포넌트로 만드는 함수
 *
 * @param {string} text : 주제
 */
function CategoryTag({ text, color = "primary.main" }) {
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: color,
        color: "primary.contrastText",
        px: "1.0rem",
        borderRadius: "100rem",
      }}
    >
      <Typography variant="caption">{text}</Typography>
    </Box>
  );
}
export default CategoryTag;
