import UpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Zoom, Fab } from "@mui/material";
import useScrollEventListener from "../../hooks/useScrollEventListener";

/** 제일 위쪽으로 올라가는 FAB */
function FabPageTop() {
  const { isScrollEventActive, scrollInit } = useScrollEventListener();

  /** FAB sx */
  const fabStyle = {
    position: "fixed",
    bottom: 16,
    right: 16,
  };

  return (
    <Zoom in={isScrollEventActive}>
      <Fab
        color="secondary"
        sx={fabStyle}
        component="button"
        onClick={scrollInit}
      >
        <UpIcon sx={{ color: "#68587A" }} />
      </Fab>
    </Zoom>
  );
}
export default FabPageTop;
