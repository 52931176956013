import { auth, deleteUser } from "../service/firebase";
/**
 * firebase 계정 삭제
 */
async function deleteAuth() {
  let isSuccess = false;
  const user = auth.currentUser;
  await deleteUser(user)
    .then((res) => {
      isSuccess = true;
      // console.log("계정 삭제", res);
    })
    .catch((error) => {
      isSuccess = false;
      // console.log("계정 삭제 실패", error);
    });
  return isSuccess;
}
export default deleteAuth;
