import axios from "axios";

/** 요청 header Authorization 에 토큰 담기 */
async function handleRequestHeader() {
  let isSuccess = false;

  /** 로그인 토큰을 헤더에 담음 */
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("login-token")}`;
  const idToken = axios.defaults.headers.common["Authorization"];

  // console.log("요청 헤더의 상태는?", idToken);

  /** 요청 헤더에 토큰 잘 담겼는지 확인, 안 담겼으면 바로 false 리턴 */
  if (idToken === undefined || idToken === null) return isSuccess;
  /** 토큰 담기 성공 */
  isSuccess = true;

  return isSuccess;
}
export default handleRequestHeader;
