/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const SNACKBAR_OPEN = "snackbar/SNACKBAR_OPEN";
const SNACKBAR_CLOSE = "snackbar/SNACKBAR_CLOSE";
const SNACKBAR_SEVERITY = "snackbar/SNACKBAR_SEVERITY";
const SNACKBAR_MESSAGE = "snackbar/SNACKBAR_MESSAGE";

/** init State ( 초기 상태 ) */
const initialState = {
  open: false,
  severity: "success" /** [error, warning, info, success] */,
  message: "알림",
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeOpen = () => ({ type: SNACKBAR_OPEN });
export const onChangeClose = () => ({ type: SNACKBAR_CLOSE });
export const onChangeInput = ({ id, value }) => ({
  type:
    (id === "severity" && SNACKBAR_SEVERITY) ||
    (id === "message" && SNACKBAR_MESSAGE),
  [id]: value,
});

/** reducer정의 */
export default function snackbar(state = initialState, action) {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        open: true,
      };
    case SNACKBAR_CLOSE:
      return {
        ...state,
        open: false,
      };
    case SNACKBAR_SEVERITY:
      return {
        ...state,
        severity: action.severity,
      };
    case SNACKBAR_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}
