import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// tonal
const TONAL_TAB_HEIGHT = "3.2rem";
const TONAL_TABS_HEIGHT = "3.6rem";
const TonalTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  minHeight: TONAL_TABS_HEIGHT,
  height: TONAL_TABS_HEIGHT,
  alignItems: "center",
  width: "fit-content",
  background: theme.palette.surface[1],
  borderRadius: "8px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  boxShadow: theme.shadows[1],

  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
}));
const TonalTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minHeight: TONAL_TAB_HEIGHT,
  height: TONAL_TAB_HEIGHT,
  margin: "0.2rem",

  // 선택 안 됨
  fontWeight: theme.typography.fontWeightRegular,
  color: "text.disabled",

  // hover, 선택 됨
  "&:hover, &.Mui-selected": {
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: "6px",
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.container,
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// floating
const FLOATING_TAB_HEIGHT = "3.6rem";
const FloatingTabs = styled((props) => <Tabs {...props} />)({
  alignItems: "center",
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
});
const FloatingTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minHeight: FLOATING_TAB_HEIGHT,
  height: FLOATING_TAB_HEIGHT,
  margin: "0.4rem 0.8rem",
  width: "11.2rem",

  // 선택 안 됨
  fontWeight: theme.typography.fontWeightRegular,
  color: "text.disabled",

  // hover, 선택 됨
  "&:hover, &.Mui-selected": {
    color: "black",
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: "6px",
    backgroundColor: "white",
  },
  "&.Mui-selected": {
    border: "1px solid",
    borderColor: theme.palette.divider,
    boxShadow: theme.shadows[1],
  },
  "&.Mui-selected:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export { TonalTabs, TonalTab, FloatingTabs, FloatingTab };
