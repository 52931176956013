import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React from "react";

/**
 * 비밀번호 입력 창
 * @param {bool} passwordLog: 에러 여부
 * @param {bool} password: 입력값
 * @param {bool} handleOnChangeInput: 입력 제어 함수
 * @param {bool} inputId: 입력창 id=> id에 따라 입력 제어 함수가 입력값을 제어하기 때문, 기본값 password
 */
function PasswordInput({
  passwordLog,
  password,
  handleOnChangeInput,
  inputId = "password",
}) {
  const [showPassword, setShowPassword] = React.useState(); // 비밀번호 보기
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function EndAdornment() {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    );
  }

  const inputLabel = inputId !== "password" ? "비밀번호 확인" : "비밀번호";

  return (
    <FormControl fullWidth margin="normal" variant="outlined" required>
      <InputLabel htmlFor="outlined-adornment-password">
        {inputLabel}
      </InputLabel>
      <OutlinedInput
        error={passwordLog}
        required
        label={inputLabel}
        value={password}
        onChange={handleOnChangeInput}
        type={showPassword ? "text" : "password"}
        endAdornment={<EndAdornment />}
        /** <input> 태그의 autocomplete 속성 : 현재 사용자가 입력한 비밀번호 */
        autoComplete="current-password"
        id={inputId} // id는 고정
      />
    </FormControl>
  );
}
export default PasswordInput;
