import { styled } from "@mui/material/styles";
import { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const StyledFormControlCard = styled((props) => {
  const { content } = props;
  return <FormControlLabel {...props} labelPlacement="start" label={content} />;
})(({ theme, checked }) => ({
  userSelect: "none",
  width: "fit-content",
  padding: checked ? "0.8px" : "2px",
  margin: "0 2.4rem 0 0",
  border: "1px solid",
  borderRadius: "8px",
  borderColor: checked ? theme.palette.primary.main : theme.palette.divider,
  borderWidth: checked ? "2px" : "1px",
  boxShadow: checked ? "0 0 0 4px rgba(30, 144, 255, 0.3)" : theme.shadows[1],
  background: checked ? "rgba(30, 144, 255, 0.04)" : "white",
  "&:active": {
    borderColor: checked ? "" : "#bbb",
  },
  alignItems: "flex-start",
}));

/**
 * form control card component
 * @param {*} props
 * @returns
 */
function FormControlCard(props) {
  const { value, content } = props;
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === value;
  }

  return (
    <StyledFormControlCard {...props} content={content} checked={checked} />
  );
}

export { FormControlCard };
