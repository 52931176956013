import React from "react";
import handleRequestHeader from "../utils/handleRequestHeader";
import { useDispatch, useSelector } from "react-redux";
import { onLoadData } from "../modules/solveQuiz";
import deleteTopicWithoutQuiz from "../utils/deleteTopicWithoutQuiz";
import getUserSolve from "../modules/_actions/getUserSolve";
import TopicSolveQuizContainer from "./TopicSolveQuizContainer";
import QuizList from "../components/quizList/QuizList";
import usePage from "../hooks/usePage";
import MyLoading from "../components/global-components/MyLoading";
import { onLoadingFalse, onLoadingTrue } from "../modules/header";

/** 봤던 문제들 제공 */
function SolveQuizContainer() {
  /** 페이지 이동 hook */
  const {
    handleSolveMoreClick: handleMoreClick,
    solveQuizPage: quizPage,
    solveTopicQuizPage: topicQuizPage,
    loginPage,
  } = usePage();
  /**
   * 현재 url에서 id 뽑아서 해당 퀴즈 제공
   * - topic_id : 주제 id
   */
  let param = new URLSearchParams(window.location.search);
  let topic_id = param.get("topic_id");

  /** Redux State */
  const dispatch = useDispatch();

  const { representQuiz } = useSelector((state) => ({
    representQuiz: state.solveQuiz.representQuiz,
  }));

  const handleLoadData = (data) => dispatch(onLoadData(data));

  // 퀴즈 문제 잘 가져왔는지
  const [isSuccessQuiz, setIsSuccessQuiz] = React.useState(false);

  /** 대표 문제 제공 API */
  async function loadData() {
    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      if (isSuccess && !isSuccessQuiz) {
        await getUserSolve().then((res) => {
          // console.log("우선 여기까지 잘 왔음", res);
          setIsSuccessQuiz(true);
          handleLoadData({
            id: "representQuiz",
            value: deleteTopicWithoutQuiz(res.representQuiz),
          });
        });
      }
    } else {
      /** 로그인 하러 가기 */
      loginPage();
    }
  }

  React.useEffect(() => {
    /** 현재 페이지가 주제별 퀴즈 문제 제공하는 페이지가 아니라면 바로 리턴 */
    if (topic_id !== null) return;
    const l = loadData();
  }, [isSuccessQuiz]);

  /** 문제 선택 여부 잘 세팅했는지 */
  const [isSuccess, setIsSuccess] = React.useState(false);
  /** 전체 주제 버튼 세팅 */
  const [isSelectedAll, setIsSelectedAll] = React.useState(true);
  /**
   * 주제 리스트 세팅
   *
   * { key: "topicName", value: { isSelected(선택 여부): true, more(더보기 여부) : false }
   * => 접근 : isSelectedTopic.get("법").isSelected
   */
  const [isSelectedTopic, setIsSelectedTopic] = React.useState(new Map());
  /** 선택 여부 업데이트 */
  const updateIsSelected = (key, value) => {
    setIsSelectedTopic((prev) => new Map(prev).set(key, value));
  };
  /** 주제 리스트 처음 세팅 */
  React.useEffect(() => {
    if (!isSuccessQuiz) return;
    representQuiz.filter((topic) => {
      updateIsSelected(topic.topicName, { isSelected: true });
    });
    // console.log(isSelectedTopic);
    setIsSuccess(true);
  }, [isSuccessQuiz]);
  /** 주제 하나씩 선택 & 취소 */
  function handleTopicClick(topicName) {
    updateIsSelected(topicName, {
      isSelected: !isSelectedTopic.get(topicName).isSelected,
    });
  }
  /** 전체 선택 & 취소 */
  function handleAllTopic() {
    /** 주제 중 하나라도 체크 안 되어 있으면, outlined */
    if (!isSelectedAll) {
      representQuiz.filter((topic) => {
        updateIsSelected(topic.topicName, {
          isSelected: true,
        });
      });
      setIsSelectedAll(true);
    } else {
      /** 모든 주제가 체크 되어 있으면, filled */
      representQuiz.filter((topic) => {
        updateIsSelected(topic.topicName, {
          isSelected: false,
        });
      });
      setIsSelectedAll(false);
    }
  }

  /** 체크 안 되어 있는 거 하나라도 찾아서 전체 버튼 활성화 */
  React.useEffect(() => {
    if (!isSuccessQuiz) return;
    /**
     * 주제 중 하나라도 체크 안 되어 있으면, outlined
     * map에서 value만 뽑아서 array로 만듦
     */
    const check = [...isSelectedTopic.values()].some((elem) => {
      return elem.isSelected === false;
    });
    if (check) {
      setIsSelectedAll(false);
    } else {
      setIsSelectedAll(true);
    }
  }, [isSelectedTopic]);

  /** 일일 목표 */
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  React.useEffect(() => {
    if (!isSuccess || !isSuccessQuiz) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isSuccess, isSuccessQuiz]);

  return (
    <>
      {topic_id !== null ? (
        /** url에 topic_id 있으면 주제별 문제들 페이지로 */
        <TopicSolveQuizContainer />
      ) : (
        ((!isSuccess || !isSuccessQuiz) && <MyLoading />) ||
        (isSuccess && isSuccessQuiz && (
          <QuizList
            quizPage={quizPage}
            topicQuizPage={topicQuizPage}
            isSelectedAll={isSelectedAll}
            handleAllTopic={handleAllTopic}
            representQuiz={representQuiz}
            isSelectedTopic={isSelectedTopic}
            handleTopicClick={handleTopicClick}
            handleMoreClick={handleMoreClick}
            isTopicPage={false}
            isFavorites={true}
          />
        ))
      )}
    </>
  );
}
export default SolveQuizContainer;
