/**
 * 랜덤 단어 생성
 * @param {int} word : 정답 단어
 */
function makeRandomWord(word) {
  /**
   * 정답 단어 길이가 4글자 이하면, 정답 단어 길이 포함 8글자 생성
   * 초과면 정답 단어 길이 포함 x2 만큼 생성하기 위해, 정답 단어 길이만큼만 생성
   */
  const wordLength = word.length;
  const newWordLength = wordLength <= 4 ? 8 - wordLength : wordLength;

  // /**
  //  * 한글은 유니코드 AC00(44032) ~ D7AF(55215)
  //  * ㄱ : 12593, ㄲ : 12594 ... 가 : 44032 라서 44031이 초기값
  //  * 유사한 단어를 생성하기 위해, word의 각 단어를 유니코드로 변환 후, 앞 뒤로 - 10 + 인 단어 생성해서 붙이기
  //  */
  // const newWord = word;
  // for (let i = 0; i < newWordLength; i++) {
  //   console.log("글자는?", word[i]);
  //   // const unicode = word.charCodeAt(i); // 각 단어의 유니코드 변환 결과

  //   const ga = 44032;
  //   let uni = word.charCodeAt(0);

  //   uni = uni - ga;

  //   let fn = parseInt(uni / 588);
  //   let sn = parseInt((uni - fn * 588) / 28);
  //   let tn = parseInt(uni % 28);

  //   console.log(
  //     String.fromCharCode(
  //       unicode - 10 /** 최소 */ + Math.ceil(Math.random() * 10 /** 최대 */)
  //     )
  //   );
  // }

  const newWord =
    word +
    new Array(newWordLength)
      .fill("")
      .map(() => String.fromCharCode(44031 + Math.ceil(Math.random() * 11172)))
      .join("");

  /** 생성된 문자열을 섞어서 리턴 */
  return [...newWord].sort(() => Math.random() - 0.5);
}
export default makeRandomWord;
