// error key
const KEY_WRITE_QUIZ_ERROR_QUIZ_TYPE = "error-quiz-type";

// input id : 변수명
const ID_SELECT_QUIZ_INFO = "selectQuizInfo"; // 퀴즈 정보 입력 항목 선택 : 문제/해설/관련자료
const ID_INPUT_QUIZ_TYPE = "inputQuizType"; // 유형
const ID_INPUT_QUESTION_TEXT = "inputQuestionText"; // 질문
const ID_INPUT_ANSWER_TEXT = "inputAnswerText"; // 정답 (퍼즐)
const ID_INPUT_ANSWER = "inputAnswer"; // 정답 (ox)

const VALUE_QUIZ_TYPE_OX = "OX"; //OX형
const VALUE_QUIZ_TYPE_PUZZLE = "PUZZLE"; // 퍼즐형
const VALUE_QUIZ_TYPE_MULTIPLE = "MULTIPLE"; // 다지선다형

// default value object
// 퀴즈 유형에 맞는 보기 및 정답 필드만 오기 때문에,
// undefined 예외처리 해줘야 함 (default값으로 채워주기)
const DEFAULT_VALUE_OX = { inputAnswer: "O" };
const DEFAULT_VALUE_PUZZLE = { inputAnswerText: "" };
const DEFAULT_VALUE_MULTIPLE = [
  {
    // <<보기>> (default)
    // 상수로 선언한 시간은 업데이트가 안되기 때문에 id 중복 에러나기 때문에
    // 아이템 추가할 땐 따로 선언해주기..
    exampleId: new Date().toISOString(),
    exampleText: "",
    isAnswer: false,
  },
];

export {
  KEY_WRITE_QUIZ_ERROR_QUIZ_TYPE,
  ID_SELECT_QUIZ_INFO,
  ID_INPUT_QUIZ_TYPE,
  ID_INPUT_QUESTION_TEXT,
  ID_INPUT_ANSWER_TEXT,
  ID_INPUT_ANSWER,
  VALUE_QUIZ_TYPE_OX,
  VALUE_QUIZ_TYPE_PUZZLE,
  VALUE_QUIZ_TYPE_MULTIPLE,
  DEFAULT_VALUE_OX,
  DEFAULT_VALUE_PUZZLE,
  DEFAULT_VALUE_MULTIPLE,
};
