import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import LevelProgressBar from "./LevelProgressBar";
import URL from "../pageUrls";
import usePage from "../../hooks/usePage";
import LoginUserDrawer from "./LoginUserDrawer";
import getUrlParam from "../../utils/getUrlParam";
import { Masonry } from "@mui/lab";

export const DRAWERWIDTH = 264;
function MyHistoryDrawer() {
  /** 페이지 이동 hook */
  const { handleChangePage } = usePage();

  const { historyId } = getUrlParam();

  const { open, userIdToken } = useSelector((state) => ({
    open: state.drawer.open,
    userIdToken: state.auth.userIdToken,
  }));

  /** 내 이력 */
  const myHistoryData = [
    {
      index: 0,
      name: "틀린 문제",
      icon: <PlaylistRemoveIcon />,
      url: `${URL.MAIN}/?${URL.HISTORY_ID}=${URL.WRONG_ID}`,
    },
    {
      index: 1,
      name: "풀었던 문제",
      icon: <PlaylistAddCheckIcon />,
      url: `${URL.MAIN}/?${URL.HISTORY_ID}=${URL.SOLVE_ID}`,
    },
  ];
  const [isMyHistoryData, setisMyHistoryData] = React.useState(-1);

  function handleOnClick(event, url) {
    event.preventDefault();
    handleChangePage(url);
  }

  /** 현재 틀린 문제 || 풀이한 문제 페이지에 들어왔을 시, 호버됨 */
  React.useEffect(() => {
    if (historyId === URL.WRONG_ID && isMyHistoryData !== 0)
      setisMyHistoryData(0);
    else if (historyId === URL.SOLVE_ID && isMyHistoryData !== 1)
      setisMyHistoryData(1);
  }, [isMyHistoryData, open]);

  /** 일일 목표 */
  const dailyGoal = (
    <>
      <Grid container sx={{ alignItems: "center", mb: "1.5rem" }}>
        <Grid item xs>
          <Typography variant="h5" color="grey.700">
            일일 목표
          </Typography>
        </Grid>
        {/* <Grid item>
          <Link component="button" variant="subtitle1" underline="none">
            목표 수정
          </Link>
        </Grid> */}
      </Grid>
      <LevelProgressBar />
    </>
  );

  /** 내 이력 */
  const myHistory = (
    <Typography variant="h5" color="grey.700">
      내 이력
    </Typography>
  );
  /** 내 이력 버튼 */
  const myHistoryLink = (
    <>
      {myHistoryData.map((data, index) => (
        <div key={data.name}>
          <Divider />
          <ListItem disablePadding key={data.name}>
            <ListItemButton
              onClick={(e) => handleOnClick(e, data.url)}
              selected={data.index === isMyHistoryData}
            >
              {data.icon}
              <ListItemText primary={data.name} sx={{ ml: "0.5rem" }} />
            </ListItemButton>
          </ListItem>
        </div>
      ))}
    </>
  );

  /** sm 페이지에 보일 부분 */
  const smPageDrawer = (
    <div style={{ width: "100%" }}>
      {userIdToken === "Guest" ? (
        <Masonry columns={1}>
          <Card variant="outlined">
            <CardContent>{dailyGoal}</CardContent>
          </Card>
        </Masonry>
      ) : (
        <Masonry columns={{ xs: 1, sm: 2 }} spacing={5}>
          <LoginUserDrawer />
          <Card variant="outlined">
            <CardContent>{dailyGoal}</CardContent>
          </Card>
        </Masonry>
      )}
    </div>
  );

  /** md */
  const mdDrawer = (
    <>
      <LoginUserDrawer />
      <Card variant="outlined">
        <CardContent>{dailyGoal}</CardContent>
        <CardContent>{myHistory}</CardContent>
        <List
          sx={{
            "&:last-child": { pb: 0 },
          }}
        >
          {myHistoryLink}
        </List>
      </Card>
    </>
  );

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: { xs: "block", sm: "block", md: "none" },
        }}
      >
        {smPageDrawer}
      </Box>
      <Box
        sx={{
          width: DRAWERWIDTH,
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        {mdDrawer}
      </Box>
    </>
  );
}
export default MyHistoryDrawer;
