import React from "react";
import useMySnackbar from "../../hooks/useMySnackbar";
import getWriteAccount from "../../modules/_actions/request/getWriteAccount";
import postWriteAccount from "../../modules/_actions/request/postWriteAccount";
import handleRequestHeader from "../../utils/handleRequestHeader";
import isUserLogin from "../../utils/isUserLogin";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVerySatisfiedOutlinedIcon from "@mui/icons-material/SentimentVerySatisfiedOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import { amber } from "@mui/material/colors";
import { useDispatch } from "react-redux";
import { onLoadingFalse, onLoadingTrue } from "../../modules/header";
import RequestWrite from "../../components/setting/RequestWrite";

/**
 * 저작 권한 요청 컨테이너
 */
function RequestWriteContainer() {
  /** Redux State */
  const dispatch = useDispatch();

  /** 스낵바 */
  const { openSnackbarHere } = useMySnackbar();

  // 요청 사유
  const [requestValue, setRequestValue] = React.useState("");
  // 기타 사유
  const [etcRequestValue, setEtcRequestValue] = React.useState("");

  // 요청 사유 select
  const handleSelectChange = (event) => {
    setRequestValue(event.target.value);
    setEtcRequestValue(""); // 설렉트 선택 시 입력된 기타 사유 초기화
  };

  // 요청 사유 리스트
  const NO_QUIZ = "내게 필요한 퀴즈가 없음";
  const ETC = "기타";
  const requestValueList = [NO_QUIZ, ETC];

  // 기타 사유 입력
  const handleTextFieldChange = (event) => {
    setEtcRequestValue(event.target.value);
  };

  // 작성 완료 - 서버로 요청
  async function onClickRequestSubmit() {
    let isSuccess = false;
    if (isUserLogin()) {
      /** 리퀘스트 헤더에 토큰 담기 */
      await handleRequestHeader().then((res) => {
        if (res) isSuccess = true;
      });
    }
    if (!isSuccess) return;

    /** body : 요청 사유 || 요청 사유 : 기타 선택 시, 기타 사유 */
    let body = {
      requestText:
        (requestValue !== ETC && requestValue) ||
        (requestValue === ETC && etcRequestValue),
    };
    await postWriteAccount(body).then((res) => {
      if (res["message"] !== undefined) {
        // 처음 요청
        openSnackbarHere("success", res.message);
      } else if (res["detail"] !== undefined) {
        // 요청 검토 중
        openSnackbarHere("info", res.detail);
      }
    });
  }

  // 작성 완료 버튼, 작성 상태에 따라 비활성화 활성화
  const [isDisabled, setIsDisabled] = React.useState(true);
  React.useEffect(() => {
    if (
      requestValue === "" ||
      (requestValue === ETC && etcRequestValue === "")
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [requestValue, etcRequestValue]);

  //저작 권한 상태
  const [userRequestWrite, setUserRequestWrite] = React.useState({
    requestDate: "",
    status: "",
  });

  const [isLoading, setIsLoading] = React.useState(true);
  // 저작 권한 상태
  async function loadUserRequestWriteState() {
    await setIsLoading(true);

    let isSuccess = false;
    if (isUserLogin()) {
      /** 리퀘스트 헤더에 토큰 담기 */
      await handleRequestHeader().then((res) => {
        if (res) isSuccess = true;
      });
    }
    if (!isSuccess) return;
    await getWriteAccount().then((res) => {
      setUserRequestWrite(res);
      setIsLoading(false);
    });
  }

  // 저작 권한 상태 정의
  const REQUSET_NO = "저작권한을 요청하지 않은 사용자입니다.";
  const REQUEST_REVIEW = "요청 검토중";
  const REQUSET_REFUSE = "요청 반려";
  const REQUEST_PERMIT = "요청 승인";

  const stateIconSx = { fontSize: { xs: 36, sm: 40, md: 48 } };

  // 저작 권한 상태 정의 with.아이콘
  const requestStateList = [
    {
      state: REQUSET_NO,
      icon: <SentimentDissatisfiedIcon color="action" sx={stateIconSx} />,
    },
    {
      state: REQUEST_REVIEW,
      icon: (
        <SentimentVerySatisfiedOutlinedIcon
          sx={{ color: amber[500], ...stateIconSx }}
        />
      ),
    },
    {
      state: REQUSET_REFUSE,
      icon: (
        <SentimentDissatisfiedOutlinedIcon color="error" sx={stateIconSx} />
      ),
    },
    {
      state: REQUEST_PERMIT,
      icon: <SentimentSatisfiedOutlinedIcon color="success" sx={stateIconSx} />,
    },
  ];

  React.useEffect(() => {
    const l = loadUserRequestWriteState();
  }, []);

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  React.useEffect(() => {
    if (isLoading) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isLoading]);

  return (
    <RequestWrite
      isLoading={isLoading}
      requestStateList={requestStateList}
      userRequestWrite={userRequestWrite}
      requestValue={requestValue}
      handleSelectChange={handleSelectChange}
      requestValueList={requestValueList}
      ETC={ETC}
      etcRequestValue={etcRequestValue}
      handleTextFieldChange={handleTextFieldChange}
      onClickRequestSubmit={onClickRequestSubmit}
      isDisabled={isDisabled}
    />
  );
}
export default RequestWriteContainer;
