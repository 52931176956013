const IS_ERROR = "error/IS_ERROR";
const ERROR_INVALID_PASSWORD = "error/ERROR_INVALID_PASSWORD";
const ERROR_EMAIL_ALREADY_IN_USE = "error/ERROR_EMAIL_ALREADY_IN_USE";
const ERROR_INVALID_EMAIL = "error/ERROR_INVALID_EMAIL";
const ERROR_WEAK_PASSWORD = "error/ERROR_WEAK_PASSWORD";
const ERROR_OPERATION_NOT_ALLOWED = "error/ERROR_OPERATION_NOT_ALLOWED";
const ERROR_USER_NOT_FOUND = "error/ERROR_USER_NOT_FOUND";
const ERROR_WRONG_PASSWORD = "error/ERROR_WRONG_PASSWORD";
const ERROR_TOO_MANY_REQUESTS = "error/ERROR_TOO_MANY_REQUESTS";
const ERROR_PROVIDER_ALREADY_LINKED = "error/ERROR_PROVIDER_ALREADY_LINKED";

const WRITE_ERROR_INIT = "error/WRITE_ERROR_INIT"; // 저작 에러 초기화
const WRITE_ERROR_TOPIC = "error/WRITE_ERROR_TOPIC"; // 주제
const WRITE_ERROR_QUESTION = "error/WRITE_ERROR_QUESTION"; // 질문
const WRITE_ERROR_DESCRIPTION = "error/WRITE_ERROR_DESCRIPTION"; // 예문
const WRITE_ERROR_COMMENTARY = "error/WRITE_ERROR_COMMENTARY"; // 해설
const WRITE_ERROR_OX = "error/WRITE_ERROR_OX"; // OX
const WRITE_ERROR_PUZZLE = "error/WRITE_ERROR_PUZZLE"; // PUZZLE
const WRITE_ERROR_MULTIPLE_EXAMPLE_COUNT =
  "error/WRITE_ERROR_MULTIPLE_EXAMPLE_COUNT"; // MULTIPLE 보기 개수
const WRITE_ERROR_MULTIPLE_EXAMPLE_ANSWER =
  "error/WRITE_ERROR_MULTIPLE_EXAMPLE_ANSWER"; // MULTIPLE 정답 1개인지
const WRITE_ERROR_MULTIPLE_EXAMPLE_INPUT =
  "error/WRITE_ERROR_MULTIPLE_EXAMPLE_INPUT"; // MULTIPLE 보기 입력 다 했는지

// init State ( 초기 상태 )
const initialState = {
  isError: false,
  authError: "",
  /**
   * id에 맞는 필드에 메세지 띄우기
   * 주제 : topic
   * 질문 : question
   * 예문(텍스트, 이미지) : description
   * 해설(텍스트, 이미지) : commentary
   * OX, PUZZLE, "MULTIPLE_EXAMPLE_COUNT"
   */
  writeError: { id: "", message: "" },
};

// Action Creator Function ( 액션 생성 함수 )
export const setIsError = (data) => ({
  type: IS_ERROR,
  payload: data,
});
export const setAuthError = (data) => ({
  type:
    (data === "invalid-password" && ERROR_INVALID_PASSWORD) ||
    (data === "auth/email-already-in-use" && ERROR_EMAIL_ALREADY_IN_USE) ||
    (data === "auth/invalid-email" && ERROR_INVALID_EMAIL) ||
    (data === "auth/weak-password" && ERROR_WEAK_PASSWORD) ||
    (data === "auth/operation-not-allowed" && ERROR_OPERATION_NOT_ALLOWED) ||
    (data === "auth/user-not-found" && ERROR_USER_NOT_FOUND) ||
    (data === "auth/wrong-password" && ERROR_WRONG_PASSWORD) ||
    (data === "auth/too-many-requests" && ERROR_TOO_MANY_REQUESTS) ||
    (data === "auth/provider-already-linked" && ERROR_PROVIDER_ALREADY_LINKED),
});
export const setWriteError = (data) => ({
  type:
    (data === "init" && WRITE_ERROR_INIT) ||
    (data === "topic" && WRITE_ERROR_TOPIC) ||
    (data === "question" && WRITE_ERROR_QUESTION) ||
    (data === "description" && WRITE_ERROR_DESCRIPTION) ||
    (data === "commentary" && WRITE_ERROR_COMMENTARY) ||
    (data === "OX" && WRITE_ERROR_OX) ||
    (data === "PUZZLE" && WRITE_ERROR_PUZZLE) ||
    (data === "MULTIPLE_EXAMPLE_COUNT" && WRITE_ERROR_MULTIPLE_EXAMPLE_COUNT) ||
    (data === "MULTIPLE_EXAMPLE_ANSWER" &&
      WRITE_ERROR_MULTIPLE_EXAMPLE_ANSWER) ||
    (data === "MULTIPLE_EXAMPLE_INPUT" && WRITE_ERROR_MULTIPLE_EXAMPLE_INPUT),
  id: data,
});

export default function error(state = initialState, action) {
  switch (action.type) {
    case IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case ERROR_INVALID_PASSWORD:
      return {
        ...state,
        authError: "비밀번호가 일치하지 않습니다.",
      };
    case ERROR_EMAIL_ALREADY_IN_USE:
      return {
        ...state,
        authError: "이미 사용중인 이메일입니다.",
      };
    case ERROR_INVALID_EMAIL:
      return {
        ...state,
        authError: "이메일을 입력해주세요",
      };
    case ERROR_WEAK_PASSWORD:
      return {
        ...state,
        authError:
          "안전하지 않은 비밀번호입니다.\n다른 비밀번호를 사용해 주세요.",
      };
    case ERROR_OPERATION_NOT_ALLOWED:
      return {
        ...state,
        authError: "관리자에게 문의하세요.",
      };
    case ERROR_USER_NOT_FOUND:
      return {
        ...state,
        authError: "해당 이메일로 가입되어 있지 않습니다.",
      };
    case ERROR_WRONG_PASSWORD:
      return {
        ...state,
        authError: "비밀번호가 잘못되었습니다.",
      };
    case ERROR_TOO_MANY_REQUESTS:
      return {
        ...state,
        authError:
          "죄송합니다. 최근에 너무 많은 요청을 보내셨습니다. 나중에 다시 시도하세요.",
      };
    case ERROR_PROVIDER_ALREADY_LINKED:
      return {
        ...state,
        authError: "이미 가입한 계정입니다.",
      };
    case ERROR_PROVIDER_ALREADY_LINKED:
      return {
        ...state,
        authError: "이미 가입한 계정입니다.",
      };

    case WRITE_ERROR_INIT:
      return {
        ...state,
        writeError: {
          id: "",
          message: "",
        },
      };
    case WRITE_ERROR_TOPIC:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "최소 1개 이상, 최대 5개 이하로 선택해주세요.",
        },
      };
    case WRITE_ERROR_QUESTION:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "질문을 입력해주세요.",
        },
      };
    case WRITE_ERROR_DESCRIPTION:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "예문을 입력하시거나 예문 이미지를 추가해주세요.",
        },
      };
    case WRITE_ERROR_COMMENTARY:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "해설을 입력하시거나 해설 이미지를 추가해주세요.",
        },
      };
    case WRITE_ERROR_OX:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "정답을 선택해주세요.",
        },
      };
    case WRITE_ERROR_PUZZLE:
      return {
        ...state,
        writeError: {
          id: action.id,
          message: "정답을 입력해주세요.",
        },
      };
    case WRITE_ERROR_MULTIPLE_EXAMPLE_COUNT:
      return {
        ...state,
        writeError: {
          id: "MULTIPLE",
          message: "보기를 최소 2개 이상, 최대 5개 이하로 추가해주세요.",
        },
      };
    case WRITE_ERROR_MULTIPLE_EXAMPLE_ANSWER:
      return {
        ...state,
        writeError: {
          id: "MULTIPLE",
          message: "보기 중 정답을 1개만 선택해주세요.",
        },
      };
    case WRITE_ERROR_MULTIPLE_EXAMPLE_ANSWER:
      return {
        ...state,
        writeError: {
          id: "MULTIPLE",
          message: "보기 중 정답을 1개만 선택해주세요.",
        },
      };
    case WRITE_ERROR_MULTIPLE_EXAMPLE_INPUT:
      return {
        ...state,
        writeError: {
          id: "MULTIPLE",
          message:
            "모든 보기에 대해 텍스트를 입력하시거나 이미지를 추가해주세요.",
        },
      };

    default:
      return state;
  }
}
