/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const RESULT_QUIZ = "quiz/RESULT_QUIZ";

/** init State ( 초기 상태 ) */
const initialState = {
  resultQuiz: {
    quizId: 0,
    difficultyValue: 0,
    /** 풀이 한 사람 */
    playUserCount: 0,
    /** 정답률 */
    answerRate: 0,
    /** 퀴즈 질문 텍스트 */
    questionText: "",
    /** 퀴즈 예문 텍스트 */
    descriptionText: "",
    /** 퀴즈 예문 이미지들 */
    descriptionImages: [],
    /** 공개 여부 */
    isShow: false,
    /** 검수 여부 */
    isCheck: false,
    /**
     * 퀴즈 유형
     * - MULTIPLE : 객관식 (examples)
     * - OX : OX퀴즈 (answer)
     * - PUZZLE : 퍼즐 (answerText)
     */
    quizType: "",
    createdDate: "",
    /** 퀴즈 주제 */
    quizTopics: [],
    /** 해설 내용 텍스트 */
    commentaryText: "",
    /** 해설 이미지들 */
    commentaryImages: [],
    /** 객관식 보기 */
    examples: [],
    /** OX 정답 (O || X)*/
    answer: "",
    /** 퍼즐 정답 */
    answerText: "",
  },
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onLoadData = ({ id, value }) => ({
  type: id === "resultQuiz" && RESULT_QUIZ,
  [id]: value,
});

/** reducer정의 */
export default function quiz(state = initialState, action) {
  switch (action.type) {
    case RESULT_QUIZ:
      return {
        ...state,
        resultQuiz: action.resultQuiz,
      };
    default:
      return state;
  }
}
