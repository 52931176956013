/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const CREATE_QUIZ_DATA = "createQuiz/CREATE_QUIZ_DATA";

/** init State ( 초기 상태 ) */
const initialState = {
  maxPageCount: 0,
  currentPageNum: 0,
  quizList: [
    // {
    //   quizId: 0,
    //   questionText: "",
    //   quizTopics: [
    //     {
    //       topicId: 0,
    //       topicName: "",
    //     },
    //   ],
    //   quizType: "",
    //   correctAnswerRate: 0,
    //   verifyStatus: "",
    //   playUserCount: 0,
    //   createdDate: "",
    // },
  ],
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onLoadCreateQuizData = (value) => ({
  type: CREATE_QUIZ_DATA,
  value,
});

/** reducer정의 */
export default function createQuiz(state = initialState, action) {
  const data = action.value;

  switch (action.type) {
    case CREATE_QUIZ_DATA:
      return {
        ...state,
        quizList: data.quizList,
        currentPageNum: data.currentPageNum,
        maxPageCount: data.maxPageCount,
      };
    default:
      return state;
  }
}
