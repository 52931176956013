import { Button, Typography, Stack, Grid, Card } from "@mui/material";
import Masonry from "@mui/lab/Masonry";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import QuizCard from "../global-components/QuizCard";
import URL from "../pageUrls";
import { useNavigate } from "react-router-dom";

/** 문제들 */
function QuizCardList({ topicQuizData, handleMoreClick, isFavorites }) {
  /**
   * isMore : 더보기
   * 문제 3개 초과 -> true
   * 문제 3개 이하 -> false
   */
  const isMore = topicQuizData.isMore;
  return (
    <>
      {topicQuizData.quizList.map((quiz) => (
        <div key={quiz.quizId}>
          <QuizCard quiz={quiz} isFavorites={isFavorites} />
        </div>
      ))}
      {isMore && (
        <Card /** 더보기 버튼 */>
          <Button
            fullWidth
            sx={{ p: "1.2rem", backgroundColor: "#ffffff" }}
            onClick={handleMoreClick}
          >
            <Typography variant="subtitle1" color="text.secondary">
              더보기
            </Typography>
          </Button>
        </Card>
      )}
    </>
  );
}

function QuizCards({
  representQuiz,
  isSelectedTopic,
  handleMoreClick,
  isFavorites,
  topicQuizPage,
}) {
  return (
    <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={10}>
      {representQuiz.map(
        (topicQuizData) =>
          isSelectedTopic.get(topicQuizData.topicName).isSelected && (
            <Stack
              /** 한 주제 당 여러 문제 묶음 레이아웃 */
              spacing={4}
              key={topicQuizData.topicId}
            >
              <Button
                /** 주제 랜덤 풀기 버튼 */
                fullWidth
                sx={{ p: "1.2rem", backgroundColor: "#00639b1a" }}
                onClick={() => topicQuizPage(topicQuizData.topicId)}
              >
                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      color="text.primary"
                      sx={{ maxWidth: "fit-content" }}
                    >
                      {topicQuizData.topicName}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="caption">랜덤 풀기</Typography>
                      <ArrowRightIcon />
                    </Stack>
                  </Grid>
                </Grid>
              </Button>
              <QuizCardList
                topicQuizData={topicQuizData}
                handleMoreClick={() => handleMoreClick(topicQuizData.topicId)}
                isFavorites={isFavorites}
              />
            </Stack>
          )
      )}
    </Masonry>
  );
}
export default QuizCards;
