import axios from "axios";
import URL from "../../components/pageUrls";
import APIURL from "../apiUrls";

async function getQuizRepresent() {
  let data;
  try {
    await axios.get(APIURL.api + "quiz/represent").then((response) => {
      // console.log("대표 문제 받음", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("대표 문제 못 받음...", error.response.status);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getQuizRepresent;
