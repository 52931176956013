import { useDispatch } from "react-redux";
import { onChangeInput, onChangeOpen } from "../modules/snackbar";
import usePage from "./usePage";

/**
 * 스낵바 컨트롤
 */
function useMySnackbar() {
  const { backPage } = usePage();
  /** Redux State */
  const dispatch = useDispatch();
  /** 스낵바 */
  const handleOpen = () => dispatch(onChangeOpen());
  const handleInput = (data) => dispatch(onChangeInput(data));

  function openSnackbar(type, message) {
    // console.log(type, message);
    // error, warning, info, success
    handleInput({ id: "severity", value: type });
    handleInput({
      id: "message",
      value: message,
    });
    handleOpen(); // snackbar open
    backPage(); // 뒤로가기
  }
  // 뒤로 가기 안함
  function openSnackbarHere(type, message) {
    handleInput({ id: "severity", value: type });
    handleInput({
      id: "message",
      value: message,
    });
    handleOpen(); // snackbar open
  }

  return { openSnackbar, openSnackbarHere };
}

export default useMySnackbar;
