import React from "react";

/** 스크롤 값을 얻어와서, Y값에 따라 이벤트 리스너 */
function useScrollEventListener() {
  /** 현재 스크롤 위치 */
  const [scrollY, setScrollY] = React.useState(window.pageYOffset);
  /** 스크롤 위치 Y 에 따른 활성화할 컴포넌트 상태 */
  const [isScrollEventActive, setIsScrollEventActive] = React.useState(false);

  /** 스크롤 Y 에 따른 상태 업데이트 */
  const scrollUpdate = () => {
    if (scrollY > 100) {
      setScrollY(window.pageYOffset);
      setIsScrollEventActive(true);
    } else {
      setScrollY(window.pageYOffset);
      setIsScrollEventActive(false);
    }
  };

  /** FAB를 위한 함수(scroll을 맨 위로 올리는 함수) */
  const scrollInit = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  /**
   * 스크롤 위치 감지
   * 이벤트 등록 후, clean up 을 위해 return 에서 이벤트 제거
   */
  React.useEffect(() => {
    const scrollListener = () => {
      window.addEventListener("scroll", scrollUpdate);
    };
    scrollListener();
    return () => {
      window.removeEventListener("scroll", scrollUpdate);
    };
  });

  return { isScrollEventActive, scrollInit };
}
export default useScrollEventListener;
