import { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { handleGetManageReferenceList } from "../../utils/actions/handleUserReference";
import WriteQuizReferenceDraggableList from "./WriteQuizReferenceDraggableList";
import { ResponsiveDialog } from "../atom-components/StyledDialogs";
import DetailReferenceInfo from "../reference/DetailReferenceInfo";
import WriteQuizReferenceList from "./WriteQuizReferenceList";
import ScrollableCard from "./wrapper/ScrollableCard";
import SubTitle from "../global-components/SubTitle";
import EmptyGuide from "./wrapper/EmptyGuide";
import { FlexColumnBox } from "../layout";
import useMySnackbar from "../../hooks/useMySnackbar";
import usePage from "../../hooks/usePage";
import changeReferenceState from "../../utils/changeReferenceState";
import QuizState from "../global-components/QuizState";

function WriteQuizReference({
  selectedReferencesItems,
  handleDragEndSelectedReferencesItems,
  onClickSelectReference,
}) {
  const { loginPage } = usePage();
  const { openSnackbarHere } = useMySnackbar();

  // 관련자료 | 퀴즈 저작하는 동안 새로운 관련자료가 추가될 수 있기 때문에 새로 요청
  const [referenceList, setReferenceList] = useState([]);

  function isSelectedReferenceEmpty() {
    return selectedReferencesItems.length === 0;
  }
  function isReferenceListEmpty() {
    return referenceList.length === 0;
  }

  const [isGetReferencesLoading, setIsGetReferencesLoading] = useState(true);
  // get references
  async function handleGetReferences() {
    const response = await handleGetManageReferenceList(
      setIsGetReferencesLoading,
      setReferenceList
    );
    if (!response) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
    }
  }

  // is Selected reference
  function isSelectedReference(id) {
    return selectedReferencesItems.indexOf(id) !== -1;
  }
  // 관련자료 중 selected 된 것만 가져오기 (선택된 순서 중요하기 때문에 selected->referenceList)
  // is Selected reference
  function selectedReference(id) {
    return referenceList.find((reference) => reference.referenceId === id);
  }
  // 관련자료 중 selected 된 것만 가져오기 (선택된 순서 중요하기 때문에 selected->referenceList)
  function getSelectedReferenceList() {
    return selectedReferencesItems.map((itemId) => {
      return selectedReference(itemId);
    });
  }

  // 상세 보기 모달창
  const [isOpen, setIsOpen] = useState(false);

  // 선택 시, 상세 보기
  const [infoData, setInfoData] = useState({
    referenceId: "",
    referenceType: "",
    referenceData: "",
    referenceCreatedDate: "",
  });
  function onClickDetailReference(event, info) {
    // info : reference info
    setIsOpen(true);
    setInfoData(info);
  }

  // init
  useEffect(() => {
    handleGetReferences();
  }, []);

  return (
    <>
      <FlexColumnBox>
        <SubTitle
          titleName="관련자료"
          body="저작한 퀴즈의 해설에 대해 보충할 자료를 선택하여 추가해주세요."
        />
        <Grid container spacing={4} columns={2}>
          <ScrollableCard subheader="선택한 자료">
            {isSelectedReferenceEmpty() ? (
              <EmptyGuide>
                <Typography variant="body2" sx={{ color: grey[400] }}>
                  오른쪽에 제공된 자료 중에서 선택해주세요.
                </Typography>
              </EmptyGuide>
            ) : (
              <WriteQuizReferenceDraggableList
                referencesItems={getSelectedReferenceList()}
                onClickDetailReference={onClickDetailReference}
                onClickSelectReference={onClickSelectReference}
                handleDragEndSelectedReferencesItems={
                  handleDragEndSelectedReferencesItems
                }
                //   handleSelectProjectBoard={handleSelectProjectBoard}
                //   projectBoardUrl={projectBoardUrl}
              />
            )}
          </ScrollableCard>
          <ScrollableCard subheader="제공자료">
            {isGetReferencesLoading ? (
              <EmptyGuide>
                <CircularProgress sx={{ color: grey[400] }} />
              </EmptyGuide>
            ) : isReferenceListEmpty() ? (
              <EmptyGuide>
                <Typography variant="body2" sx={{ color: grey[400] }}>
                  제공자료가 없습니다.
                </Typography>
              </EmptyGuide>
            ) : (
              <WriteQuizReferenceList
                referenceList={referenceList}
                isSelectedReference={isSelectedReference}
                onClickSelectReference={onClickSelectReference}
                onClickDetailReference={onClickDetailReference}
              />
            )}
          </ScrollableCard>
        </Grid>
      </FlexColumnBox>
      {infoData.referenceId === "" ? (
        <></>
      ) : (
        <ResponsiveDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dialogTitleText="자료 상세보기"
        >
          {changeReferenceState(infoData).map((comp, index) => (
            <QuizState key={index} quizData={comp} />
          ))}
          <DetailReferenceInfo reference={infoData} />
        </ResponsiveDialog>
      )}
    </>
  );
}
export default WriteQuizReference;
