import { AppBar, Toolbar, styled } from "@mui/material";

/**
 * styled header
 */
const FloatingAppBar = styled((props) => {
  return (
    <AppBar
      {...props}
      elevation={0}
      position="fixed"
      sx={{
        backgroundColor: "white",
        opacity: "95%",
        paddingTop: "6.4rem",
        zIndex: (theme) => theme.zIndex.appBar - 1,
      }}
    />
  );
})(({ theme }) => ({}));

// sub header component
const SubHeader = styled(({ children, ...props }) => {
  return (
    <FloatingAppBar {...props}>
      <Toolbar
        disableGutters
        sx={{
          flexDirection: "column",
        }}
      >
        {children}
      </Toolbar>
    </FloatingAppBar>
  );
})(({ theme }) => ({}));

export { FloatingAppBar, SubHeader };
