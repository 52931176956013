import ParseHtml from "../atom-components/ParseHtml";

function DetailReferenceInfo({ reference }) {
  const {
    referenceId,
    referenceCreatedDate,
    referenceData,
    referenceType /*image*/,
  } = reference;

  return <ParseHtml htmlCodes={referenceData} />;
}
export default DetailReferenceInfo;
