import { Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

const TagChip = styled(Chip)(({ theme }) => ({
  margin: "0.4rem 0.4rem 0.4rem 0rem",
  backgroundColor: theme.palette.primary.main,
  color: "white",
  borderRadius: "0.6rem",
  boxShadow: theme.customShadows.tag,
}));

export { TagChip };
