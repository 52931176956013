/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const DRAWER_OPEN = "drawer/DRAWER_OPEN";
const DRAWER_CLOSE = "drawer/DRAWER_CLOSE";

/** init State ( 초기 상태 ) */
const initialState = {
  open: false,
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeOpen = () => ({ type: DRAWER_OPEN });
export const onChangeClose = () => ({ type: DRAWER_CLOSE });

/** reducer정의 */
export default function drawer(state = initialState, action) {
  switch (action.type) {
    case DRAWER_OPEN:
      return {
        ...state,
        open: true,
      };
    case DRAWER_CLOSE:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
