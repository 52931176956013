import timeFormat from "../timeFormat";

/**
 * 사용자 일일 목표 & 내 이력 업데이트
 * @param {string} userId : user ID
 * @param {number} upXp : 획득한 XP
 */
function updateRecordUserDailyMission(userId, upXp) {
  // console.log(upXp);
  /** 현재 한국 날짜와 시간으로 포맷팅 */
  const tmpDate = new Date();
  const date = timeFormat(tmpDate, false);

  /** 현재 일일 목표 XP */
  const userGoal = JSON.parse(localStorage.getItem(userId));

  /** 일일 목표 달성 여부 */
  const totalXp = userGoal.currentXp + upXp;

  /** 일일목표 우선 점수만 갱신 */
  localStorage.setItem(
    userId,
    JSON.stringify({
      ...userGoal,
      currentXp: totalXp,
    })
  );

  /** 이미 달성했다면, 점수만 갱신 해주고 리턴 */
  if (userGoal.isSuccess) return;

  /** 갱신해줘야 한다면, 성공 여부 확인 */
  const isSuccess = totalXp >= userGoal.goalXp;
  if (isSuccess) {
    /** 일일목표 */
    localStorage.setItem(
      userId,
      JSON.stringify({
        ...userGoal,
        currentXp: totalXp,
        todayDate: date,
        isSuccess: isSuccess,
      })
    );
  }
}
export default updateRecordUserDailyMission;
