import { Typography, Grid } from "@mui/material";
import PageScreenBox from "../components/global-components/PageScreenBox";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { styled } from "@mui/material/styles";
/**
 * 점검 중 페이지...
 */
function Error() {
  const GridContainer = styled(Grid)(({ theme }) => ({
    justifyContent: "center",
    paddingBottom: "0.8rem",
  }));
  const sizeSx = { xs: "4.4rem", sm: "5.4rem", md: "6.4rem" };

  return (
    <PageScreenBox>
      <Grid container>
        <GridContainer container>
          <BuildCircleIcon
            sx={{
              width: sizeSx,
              height: sizeSx,
            }}
            color="primary"
          />
        </GridContainer>
        <GridContainer container gap={2}>
          <Grid item>
            <Typography variant="h3" color="primary">
              서비스 점검중
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">입니다.</Typography>
          </Grid>
        </GridContainer>
        <GridContainer container>
          <Typography variant="subtitle1" color="text.secondary">
            이용에 불편을 드려 죄송합니다.
          </Typography>
        </GridContainer>
      </Grid>
    </PageScreenBox>
  );
}
export default Error;
