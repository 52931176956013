import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

async function getQuizWrite(params = {}, isAll) {
  //console.log(params);
  let requestUrl = isAll ? "" : `isCheck=${params.isCheck}&&`;
  requestUrl += `pageNum=${params.pageNum}&&pagingSize=${params.pagingSize}`;

  let data;
  try {
    await axios
      .get(APIURL.api + `quiz/write?${requestUrl}`)
      .then((response) => {
        //console.log("내가 저작한 문제 리스트 받음", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    //console.log("내가 저작한 문제 리스트 못 받음...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getQuizWrite;
