import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import PageScreenBox from "../../components/global-components/PageScreenBox";
import BreadCrumbsTitle from "../../components/global-components/BreadCrumbsTitle";
import usePage from "../../hooks/usePage";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import useSx from "../../hooks/useSx";
import { FlexColumnBox } from "../../components/layout";
import { useEffect, useState } from "react";
import { Masonry } from "@mui/lab";
import BasicPagination from "../../components/global-components/BasicPagination";
import ReferenceCard from "../../components/reference/ReferenceCard";
import { handleGetManageReferenceList } from "../../utils/actions/handleUserReference";
import useMySnackbar from "../../hooks/useMySnackbar";
import ListSkeleton from "../../components/global-components/loading/ListSkeleton";

function Reference({
  isGetReferencesLoading,
  isReferenceListEmpty,
  referenceList,
}) {
  const { referenceWritePage, handleReferenceCreateIdPage } = usePage();
  const { randomSx } = useSx();

  const makeButton = (
    <Grid item>
      <Button
        sx={{
          p: "2rem 2.4rem 2rem 2rem",
          borderRadius: "100rem",
          minWidth: "fit-content",
          // color: "#68587A",
        }}
        variant="contained"
        onClick={referenceWritePage}
        // color="secondary"
        startIcon={<EditOutlinedIcon sx={randomSx} />}
      >
        <Typography variant="h5">관련자료 저작 하기</Typography>
      </Button>
    </Grid>
  );

  return (
    <PageScreenBox /**제일 바깥 스크린 레이아웃  */>
      <Stack spacing={6} sx={{ width: 1 }}>
        <BreadCrumbsTitle />
        <Grid container sx={{ w: 1, justifyContent: "center" }}>
          {/* {!isWindowSmDown && <Box  sx={{ ml: "1.2rem" }}>{makeButton}</Box>} */}
          <Box sx={{ mb: "1.2rem" }}>{makeButton}</Box>
        </Grid>
        <FlexColumnBox
          sx={{
            width: 1,
            alignItems: "center",
            paddingTop: "4rem",
          }}
        >
          {isGetReferencesLoading ? (
            <ListSkeleton />
          ) : isReferenceListEmpty() ? (
            <Typography variant="h4" color="text.secondary">
              저작한 관련자료가 없습니다. ;(
            </Typography>
          ) : (
            <Masonry columns={{ xs: 1, sm: 1, md: 2 }} spacing={10}>
              {referenceList.map((reference) => {
                const { referenceId } = reference;
                console.log(reference);
                return (
                  <ReferenceCard
                    key={referenceId}
                    isHovered
                    reference={reference}
                    onClick={() => handleReferenceCreateIdPage(referenceId)}
                  />
                );
              })}
            </Masonry>
          )}
          <BasicPagination
          // count={maxPageCount}
          // page={currentPageNum}
          // handlePageChange={handlePageChange}
          />
        </FlexColumnBox>
      </Stack>
    </PageScreenBox>
  );
}
export default Reference;
