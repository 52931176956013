import { BrowserRouter, Routes, Route } from "react-router-dom";
import URL from "./components/pageUrls";
import QuizContainer from "./containers/QuizContainer";
import SignUpContainer from "./containers/SignUpContainer";
import LoginContainer from "./containers/LoginContainer";
import HeaderContainer from "./containers/HeaderContainer";
import MainContainer from "./containers/MainContainer";
import TopicQuizContainer from "./containers/TopicQuizContainer";
import WrongQuizContainer from "./containers/WrongQuizContainer";
import TopicWrongQuizContainer from "./containers/TopicWrongQuizContainer";
import SolveQuizContainer from "./containers/SolveQuizContainer";
import TopicSolveQuizContainer from "./containers/TopicSolveQuizContainer";
import Test from "./pages/Test";
import Event from "./pages/Event";
import { useDispatch, useSelector } from "react-redux";
import CreateQuiz from "./pages/CreateQuiz";
import DetailQuiz from "./pages/DetailQuiz";
import CreateQuizContainer from "./containers/create/CreateQuizContainer";
import DetailQuizContainer from "./containers/create/DetailQuizContainer";
import WriteQuizContainer from "./containers/create/WriteQuizContainer";
import ProfileContainer from "./containers/profile/ProfileContainer";
import Error from "./pages/Error";
import SettingContainer from "./containers/setting/SettingContainer";
import ReferenceContainer from "./containers/reference/ReferenceContainer";
import DetailReferenceContainer from "./containers/reference/DetailReferenceContainer";
import WriteReferenceContainer from "./containers/reference/WriteReferenceContainer";

const AppRouter = () => {
  /** ?topic_id=:topic_id */
  const VAR_TOPIC_ID = "?" + URL.TOPIC_ID + "=:" + URL.TOPIC_ID;
  /** ?quiz_id=:quiz_id */
  const VAR_QUIZ_ID = "?" + URL.QUIZ_ID + "=:" + URL.QUIZ_ID;
  /** ?history_id=:history_id */
  const VAR_HISTORY_ID = "?" + URL.HISTORY_ID + "=:" + URL.HISTORY_ID;
  /** ?create_id=:create_id */
  const VAR_CREATE_ID = "?" + URL.CREATE_ID + "=:" + URL.CREATE_ID;

  return (
    <BrowserRouter>
      <HeaderContainer />
      {/* navigate 쓰기 위해선, Router에 있어야 한대서 여기에 선언 */}
      <Event />
      <Routes>
        {/* main/ */}
        <Route
          /** main 대표 문제 */
          path={URL.MAIN}
          element={<MainContainer />}
        >
          <Route
            /** main/topic 주제 문제 */
            path={VAR_TOPIC_ID}
            element={<TopicQuizContainer />}
          />
          <Route
            /** main/wrong 틀린 대표 문제 */
            path={VAR_HISTORY_ID}
            element={<WrongQuizContainer />}
          >
            <Route
              /** main/wrong/topic 틀린 주제 문제 */
              path={VAR_TOPIC_ID}
              element={<TopicWrongQuizContainer />}
            />
          </Route>
          <Route
            /** main/solve 봤던 문제 */
            path={VAR_HISTORY_ID}
            element={<SolveQuizContainer />}
          >
            <Route
              /** main/wrong/topic 틀린 주제 문제 */
              path={VAR_TOPIC_ID}
              element={<TopicSolveQuizContainer />}
            />
          </Route>
        </Route>
        {/* main/ */}

        {/* quiz/ */}
        {/* historyid / topicid / quizid */}
        <Route
          /** 랜덤 퀴즈 */
          path={URL.QUIZ}
          element={<QuizContainer />}
        >
          <Route
            /** quizId 퀴즈 */
            path={VAR_QUIZ_ID}
            element={<QuizContainer />}
          />
          <Route
            /** topicId 퀴즈 */
            path={VAR_TOPIC_ID}
            element={<QuizContainer />}
          >
            <Route
              /** topic + quizId 퀴즈 */
              path={VAR_QUIZ_ID}
              element={<QuizContainer />}
            />
          </Route>
          <Route
            /** historyid 퀴즈 */
            path={VAR_HISTORY_ID}
            element={<QuizContainer />}
          >
            <Route
              /** quizId 퀴즈 */
              path={VAR_QUIZ_ID}
              element={<QuizContainer />}
            />
            <Route
              /** topicId 퀴즈 */
              path={VAR_TOPIC_ID}
              element={<QuizContainer />}
            >
              <Route
                /** topic + quizId 퀴즈 */
                path={VAR_QUIZ_ID}
                element={<QuizContainer />}
              />
            </Route>
          </Route>
        </Route>
        {/* quiz/ */}
        <Route path={URL.LOGIN} element={<LoginContainer />} />
        <Route path={URL.SIGNUP} element={<SignUpContainer />} />
        <Route path="test" element={<Test />} />
        {/* create-quiz */}
        <Route path={URL.CREATE} element={<CreateQuizContainer />}>
          <Route path={VAR_CREATE_ID} element={<DetailQuizContainer />} />
        </Route>
        <Route path={URL.CREATE + URL.WRITE} element={<WriteQuizContainer />}>
          <Route path={VAR_CREATE_ID} element={<WriteQuizContainer />} />
        </Route>

        {/* reference */}
        <Route path={URL.REFERENCE} element={<ReferenceContainer />}>
          <Route path={VAR_CREATE_ID} element={<DetailReferenceContainer />} />
        </Route>
        <Route
          path={URL.REFERENCE + URL.WRITE}
          element={<WriteReferenceContainer />}
        >
          <Route path={VAR_CREATE_ID} element={<WriteReferenceContainer />} />
        </Route>

        {/* 프로필 */}
        <Route path={URL.PROFILE} element={<ProfileContainer />} />

        {/* =======================설정======================= */}
        {/* 설정 - 계정, 저작 권한, 비밀번호 변경 */}
        <Route path={URL.SETTING + "/:menu"} element={<SettingContainer />} />

        {/* 점검 중 페이지 */}
        <Route path={URL.ERROR} element={<Error />} />

        {/* 위 route 들 중 매칭 안 되는 곳 있으면(=="/") main으로 */}
        <Route path="*" element={<MainContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
