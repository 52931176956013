import recordDailyMission from "../../modules/_actions/recordDailyMission";
import handleRequestHeader from "../handleRequestHeader";
import isSameDate from "../isSameDate";

/** 목표 달성 후, 일일 목표 post */
async function postGoalWithInit(userId, userGoal, userGoalInit) {
  /** API 요청을 위해, 리퀘스트 헤더에 토큰 담기 */
  let isReadyPost = false;
  if (await handleRequestHeader()) {
    isReadyPost = true;
  }
  if (!isReadyPost) return;
  /** 3) 목표달성에 성공했으면 API 요청 O */
  /** goalXp, isSuccess, successDate */
  let body = {
    goalXp: userGoal.goalXp,
    isSuccess: userGoal.isSuccess,
    successDate: userGoal.todayDate,
  };
  let isSuccess = false;
  await recordDailyMission(body).then((res) => {
    // console.log("일일목표 API 요청 후!! 잘 post 됐을까? ", res);
    if (res["recordStatus"] !== undefined) isSuccess = true;
  });
  if (!isSuccess) return;

  /** 4) 다 했으면, 로컬 스토리지는 Xp와 날짜 초기화 */
  await localStorage.setItem(userId, JSON.stringify(userGoalInit));
}

/**
 * 사용자 & 익명 일일 목표
 * 리프레시 때마다 확인
 */
async function checkUserDailyMission(userId, userGoal, date) {
  /** 초기화 해줄 일일 목표 데이터 */
  const userGoalInit = {
    goalXp: 30,
    currentXp: 0,
    todayDate: date,
    isSuccess: false,
  };
  /**
   * 일일 목표
   * key : "userID-보안키"
   * value
   * - goalXp : 일일목표 xp값
   * - currentXp : 현재 일일목표 xp값
   * - successDate : 일일 목표 XP에 처음 달성했을 때의 date 20221013-time
   */
  /** 1. 로컬 스토리지에 기록 없다면, Xp와 날짜 초기화 */
  if (userGoal === null) {
    await localStorage.setItem(userId, JSON.stringify(userGoalInit));
    return;
  }

  /** 2. 로컬 스토리지에 기록 있다면 */
  /** 1) 현재 날짜와(날짜만) 비교 */
  /** 1-1) 안 바뀌었다면, 갱신 안 해줘도 되니까 바로 return; */
  if (isSameDate(new Date(date), new Date(userGoal.todayDate))) {
    // console.log("날짜가 같다.");
    return;
  }

  // console.log("날짜가 다르다.");
  /** =========== 익명인 경우 : 그냥 초기화만 진행 ========== */
  if (userId === "Guest") {
    // console.log("익명인 경우는 그냥 초기화만 해주기");
    await localStorage.setItem(userId, JSON.stringify(userGoalInit));
    return;
  }
  /** =========== 회원인 경우 : 목표 달성에 성공했는 지 확인 후 API 요청 ========== */
  // console.log("회원인 경우는, 목표 달성에 성공했는 지 확인 후 API 요청");
  /** 2) 목표달성에 실패했으면 API 요청 X, 초기화만 */
  if (userGoal.goalXp > userGoal.currentXp) {
    // console.log("일일 목표를 못 넘겼다. 그냥 초기화만 해주기");
    await localStorage.setItem(userId, JSON.stringify(userGoalInit));
    return;
  }
  // console.log("목표치를 넘겼다.");

  /** 일일 목표 post */
  await postGoalWithInit(userId, userGoal, userGoalInit);
}
export default checkUserDailyMission;
