import { TextField, styled } from "@mui/material";

/** basic TextField */
const BasicTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "white",
}));

function StyledTextField({
  fullWidth = true,
  id,
  label,
  value,
  onChange,
  maxLength = false,
  error = false,
  helperText = "",
  disabled = false,
  maxRows = false,
  bold = false,
}) {
  const helperTextError = error && !!helperText ? helperText : ""; // 에러 설명
  const helperTextGuide = !error && !!helperText ? helperText : ""; // 설명
  const helperTextLength = !!maxLength ? `${value.length}/${maxLength}` : "";

  return (
    <TextField
      fullWidth={fullWidth}
      disabled={disabled}
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      inputProps={{
        maxLength: maxLength ? maxLength : null,
      }}
      helperText={
        (!!helperTextError || !!helperTextLength || !!helperTextGuide) &&
        `${helperTextError} ${helperTextLength} ${helperTextGuide}`
      }
      error={error}
      multiline={!!maxRows}
      maxRows={maxRows ? maxRows : null}
      sx={{ ".MuiInputBase-input": { fontWeight: bold && "bold" } }}
    />
  );
}

// floating TextField
const FloatingTextField = styled((props) => (
  <TextField {...props} inputProps={{ style: { padding: "1.4rem 1.6rem" } }} />
))(({ theme }) => ({
  "& label.Mui-focused": {},
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    borderColor: theme.palette.divider,

    "& fieldset": {
      borderColor: "inherit",
    },
    "&:hover fieldset": {
      borderColor: "inherit",
    },
    "&.Mui-focused fieldset": {
      borderColor: "inherit",
    },
  },
}));

export { BasicTextField, StyledTextField, FloatingTextField };
