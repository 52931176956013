import {
  Typography,
  Stack,
  Avatar,
  Box,
  Button,
  Badge,
  Grid,
  ButtonBase,
  TextField,
  ClickAwayListener,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Modal,
  CircularProgress,
} from "@mui/material";
import { blueGrey, lightBlue } from "@mui/material/colors";
import PageScreenBox from "../components/global-components/PageScreenBox";
import useSx from "../hooks/useSx";
import EditIcon from "@mui/icons-material/Edit";
import LevelProgressBar from "../components/global-components/LevelProgressBar";
import StatisticsCard from "../components/global-components/StatisticsCard";
import AchievementCard from "../components/global-components/AchievementCard";
import useGameGraphic from "../hooks/useGameGraphic";
import BasicPagination from "../components/global-components/BasicPagination";
import React from "react";
import { LoadingButton } from "@mui/lab";

/** 프로필 */
function Profile({
  // changeImageSrc,
  // changeDefaultProfileImage,
  // isEdit,
  profileImage,
  // imageInput,
  // onAddImageChange,
  // USER_NICK_NAME,
  // NICKNAME_LIMIT,
  userNickname,
  // handleOnSetUserNickName,
  // submitProfileUpdate,
  // handleNickNameIsEdit,
  totalPlayTime,
  totalPlayCount,
  correctAnswerRate,
  maxComboCount,
  achievementsList,
  achievementsListMaxPageNum,
  achievementsListCurrentPageNum,
  handlePageChange,
  // popUpOpen,
  // toggleDrawer,
  // handleClick,
  // handleClickAway,
  // isNicknameActive,
  // isUpdateLoading,
  handleProfileEdit,
}) {
  const profileSize = { xs: "8rem", sm: "10rem", md: "12rem" };
  // const EditSize = { xs: "2.6rem", sm: "3.2rem", md: "3.6rem" };
  const { isWindowMdDown, isWindowSmDown, modalStyle } = useSx();

  /** 그래픽 적용을 위한 함수 */
  const { getAchievmentData } = useGameGraphic();

  // /** 프로필 선택 팝업 스타일 */
  // const popupLayoutStyles = {
  //   position: "absolute",
  //   zIndex: 2000,
  //   bgcolor: "white",
  // };
  // const popupStyles = {
  //   position: "relative",
  // };

  // /** 프로필 사진 선택 목록 */
  // const listData = [
  //   {
  //     name: "사진 선택",
  //     onClickFuntion: changeImageSrc,
  //   },
  //   {
  //     name: "기본 이미지로 변경",
  //     onClickFuntion: changeDefaultProfileImage,
  //   },
  // ];

  // const StyledListItem = ({ data }) => {
  //   return (
  //     <ListItem disablePadding>
  //       <ListItemButton
  //         onClick={data.onClickFuntion}
  //         sx={{
  //           paddingY: "1.2rem",
  //           paddingLeft: "2.0rem",
  //           paddingRight: "8rem",
  //         }}
  //       >
  //         <ListItemText primary={data.name} sx={{ ml: "1.6rem" }} />
  //       </ListItemButton>
  //     </ListItem>
  //   );
  // };
  // //   sx={{ paddingY: "1.2rem", paddingX: "1.6rem" }}
  // const compontList = (
  //   <>
  //     {listData.map((data) => (
  //       <StyledListItem key={data.name} data={data} />
  //     ))}
  //   </>
  // );

  // /** sm down에서의 popup 컴포넌트 */
  // const SmPopup = () => {
  //   return (
  //     <Modal open={popUpOpen} onClose={toggleDrawer(false)}>
  //       <Box sx={modalStyle} noValidate>
  //         <Typography variant="h5" sx={{ fontWeight: "bold", mb: "1.3rem" }}>
  //           프로필 사진
  //         </Typography>
  //         <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
  //           {compontList}
  //         </Box>
  //       </Box>
  //     </Modal>
  //   );
  // };
  // /** md up에서의 popup 컴포넌트 */
  // const MdPopup = () => {
  //   return (
  //     <Paper sx={popupLayoutStyles} elevation={3}>
  //       <Box sx={popupStyles}>
  //         <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
  //           {compontList}
  //         </Box>
  //       </Box>
  //     </Paper>
  //   );
  // };

  return (
    <PageScreenBox sx={{ pt: "8rem" }}>
      <Stack spacing={12} sx={{ width: 1 }}>
        <Grid
          container
          sx={{
            alignItems: "center",
            gap: "2.4rem",
            bgcolor: blueGrey[50],
            p: "1.6rem 2.4rem",
            borderRadius: "0.4rem",
          }}
        >
          {/* <Grid item>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={handleClickAway}
            >
              <Box>
                <ButtonBase
                  onClick={handleClick}
                  disableRipple
                  disabled={!isEdit}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={
                      isEdit && (
                        <Avatar
                          sx={{
                            bgcolor: lightBlue[600],
                            width: EditSize,
                            height: EditSize,
                          }}
                        >
                          <EditIcon
                            fontSize={isWindowMdDown ? "small" : "medium"}
                          />
                        </Avatar>
                      )
                    }
                  >
                    <Avatar
                      src={profileImage.url || ""} // 링크
                      sx={{ width: profileSize, height: profileSize }}
                    />
                  </Badge>
                </ButtonBase>
                {isWindowSmDown ? <SmPopup /> : popUpOpen ? <MdPopup /> : null}
              </Box>
            </ClickAwayListener>

            <input
              type="file" // input 타입을 file로 지정하면 파일을 가져올 수 있는 형태의 input이 생성됨.
              accept="image/*"
              style={{ display: "none" }}
              ref={imageInput}
              onChange={onAddImageChange}
            />
          </Grid>
          <Grid item xs>
            <Grid container sx={{ mb: "0.4rem" }}>
              {isEdit ? (
                <TextField
                  size="small"
                  id={USER_NICK_NAME}
                  label="닉네임"
                  variant="filled"
                  inputProps={{
                    maxLength: NICKNAME_LIMIT,
                  }}
                  value={userNickname}
                  helperText={`${userNickname.length}/${NICKNAME_LIMIT}`}
                  // onChange={handleChange("nickName")}
                  onChange={handleOnSetUserNickName}
                  fullWidth={isWindowSmDown}
                />
              ) : (
                <Typography variant="h5" sx={{ mb: "1.2rem" }}>
                  {userNickname}
                </Typography>
              )}
            </Grid>
            <Grid container>
              {isEdit ? (
                <>
                  {isUpdateLoading && (
                    <LoadingButton loading variant="contained" />
                  )}
                  {!isUpdateLoading && (
                    <Button
                      variant="contained"
                      onClick={submitProfileUpdate}
                      disabled={isNicknameActive}
                    >
                      프로필 저장
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={handleNickNameIsEdit}
                >
                  프로필 수정
                </Button>
              )}
            </Grid>
          </Grid>
          {!isWindowSmDown && (
            <Grid item xs>
              <LevelProgressBar isLevel={true} isProfile={true} />
            </Grid>
          )}
          {isWindowSmDown && (
            <LevelProgressBar isLevel={true} isProfile={true} />
          )} */}
          <Grid item>
            <Avatar
              src={profileImage.url || ""} // 링크
              sx={{ width: profileSize, height: profileSize }}
            />
          </Grid>
          <Grid item xs>
            <Grid container sx={{ mb: "0.4rem" }}>
              <Typography variant="h5" sx={{ mb: "1.2rem" }}>
                {userNickname}
              </Typography>
            </Grid>
            <Grid container>
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                onClick={handleProfileEdit}
              >
                프로필 수정
              </Button>
            </Grid>
          </Grid>

          {!isWindowSmDown && (
            <Grid item xs>
              <LevelProgressBar isLevel={true} isProfile={true} />
            </Grid>
          )}
          {isWindowSmDown && (
            <LevelProgressBar isLevel={true} isProfile={true} />
          )}
        </Grid>

        {/* <Divider /> */}
        <Typography variant="h5" sx={{ mb: "1.2rem", fontWeight: "bold" }}>
          통계
        </Typography>
        <StatisticsCard
          totalPlayTime={totalPlayTime}
          totalPlayCount={totalPlayCount}
          correctAnswerRate={correctAnswerRate}
          maxComboCount={maxComboCount}
          // 통계
        />
        <Typography variant="h5" sx={{ mb: "1.2rem", fontWeight: "bold" }}>
          배지
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={6}
            columns={{ xs: 2, sm: 4, md: 5 }}
            sx={{ alignContent: "stretch" }}
          >
            {achievementsList.map((data, index) => (
              <Grid item xs={1} sm={1} md={1} key={index}>
                <AchievementCard
                  achievementData={getAchievmentData(data.achievementsId)}
                  isAchieved={data.isClear}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* <Masonry columns={{ xs: 3, sm: 4, md: 5 }} spacing={6}>
      {achievementsList.map((data, index) => (
        <AchievementCard
          achievementData={getAchievmentData(data.achievementsId)}
          key={index}
          isAchieved={data.isClear}
        />
      ))} */}
        {/* {
        // 획득한 업적 리스트
        handleAchievementData([]).map((data, index) => (
          <AchievementCard
            achievementData={data}
            key={index}
            isAchieved={true}
          />
        ))
      }
      {
        // 획득 못 한 업적 리스트
        handleNoAchievementData([]).map((data, index) => (
          <AchievementCard achievementData={data} key={index} />
        ))
      } */}
        {/* </Masonry> */}
        <Box
          /** 각 카테고리별 문제 나열한 거 묶음 */
          sx={{
            width: 1,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <BasicPagination
            count={achievementsListMaxPageNum}
            page={achievementsListCurrentPageNum}
            handlePageChange={handlePageChange}
          />
        </Box>
      </Stack>
    </PageScreenBox>
  );
}
export default Profile;
