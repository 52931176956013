import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/** 사용자 업적 조회 API */
async function getAchievementsList(params = {}) {
  let data;
  try {
    await axios
      .get(
        APIURL.api +
          `user/profile/achievements?pageNum=${params.pageNum}&pagingSize=${params.pagingSize}`
      )
      .then((response) => {
        //console.log("업적 리스트 받음", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    // //console.log("업적 리스트 받음...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getAchievementsList;
