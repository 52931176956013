import recordAttendance from "../../modules/_actions/recordAttendance";
import { analytics, logEvent } from "../../service/firebase";
import handleRequestHeader from "../handleRequestHeader";
import isSameDate from "../isSameDate";

async function postAttendanceWithInit(
  userAttendanceId,
  userAttendanceInit,
  date
) {
  let earnAchievementIdList = []; // 달성한 업적 id 리스트

  /** API 요청을 위해, 리퀘스트 헤더에 토큰 담기 */
  let isReadyPost = false;
  if (await handleRequestHeader()) {
    isReadyPost = true;
  }
  if (!isReadyPost) return;

  /** 3) API 요청 */
  await recordAttendance().then((res) => {
    // console.log("출석체크 API 요청 후!! 잘 post 됐을까? ", res);
    if (res["recordStatus"] !== undefined) {
      /** 달성한 출석체크 업적 리스트 */
      earnAchievementIdList = res.earnAchievementIdList;
      // console.log(earnAchievementIdList);
    } else {
      // console.log("출석체크 post 안 됨!!!");
    }
  });

  /** 4. 로컬 스토리지 업적과 함께 초기화 및 갱신 */
  await localStorage.setItem(
    userAttendanceId,
    JSON.stringify({
      ...userAttendanceInit,
      earnAchievementIdList: earnAchievementIdList,
    })
  );

  // 출석체크 애널리틱스 - 출석 시간, 접속 사용자 ID 로그
  await logEvent(analytics, "user_attendance", {
    checkin_user_id: userAttendanceId,
    checkin_time: date,
  });
}
/**
 * 사용자 출석 체크
 * 리프레시 때마다 확인
 */
async function checkUserAttendance(userAttendanceId, userAttendance, date) {
  /** 초기화 해줄 출석 데이터 */
  const userAttendanceInit = { earnAchievementIdList: [], todayDate: date };

  /**
   * 출석
   * key : "userAttendanceId-보안키-attendance"
   * value
   * - earnAchievementIdList : 출석, 업적
   * - todayDate : 마지막 출석 date 20221013-time
   */
  /** 1. 로컬 스토리지에 기록 없다면, API호출해서 출석체크 후, 초기화 */
  if (userAttendance === null) {
    await postAttendanceWithInit(userAttendanceId, userAttendanceInit, date);
    return;
  }

  /** 2. 로컬 스토리지에 기록 있다면 */
  /** 1) 현재 날짜와(날짜만) 비교 */
  /** 1-1) 안 바뀌었다면, 갱신 안 해줘도 되니까 바로 return; */
  if (isSameDate(new Date(date), new Date(userAttendance.todayDate))) {
    // console.log("날짜가 같다.");
    return;
  }
  /** 1-1) 바뀌었다면, API호출해서 출석체크 후, 초기화 */
  // console.log("날짜가 다르다.");
  await postAttendanceWithInit(userAttendanceId, userAttendanceInit, date);
}
export default checkUserAttendance;
