import axios from "axios";
import URL from "../../components/pageUrls";
import { logout } from "../../utils/logout";
import APIURL from "../apiUrls";

async function getUserStat() {
  let data;
  try {
    await axios.get(APIURL.api +"user/stat").then((response) => {
      // console.log("stat 받았습니다", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    // console.log("stat 못 받음...", error);

    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default: // 바로 로그인 토큰 없애버리고 로그아웃
        logout();
        return;
    }
  }
}

export default getUserStat;
