import {
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Stack,
  Box,
  CircularProgress,
  ButtonBase,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import useGameGraphic from "../../hooks/useGameGraphic";
import { blueGrey } from "@mui/material/colors";
import usePage from "../../hooks/usePage";

/**
 * 로그인한 유저의 정보 drawer
 * 닉네임, 레벨
 */
function LoginUserDrawer() {
  const { profilePage } = usePage();
  const { getLevelBadge } = useGameGraphic();
  const { userIdToken, userLevel, userLevelXp, userNickname, profileImage } =
    useSelector((state) => ({
      userIdToken: state.auth.userIdToken,
      userLevel: state.auth.userLevel, // 레벨
      userLevelXp: state.auth.userLevelXp, // 현재 레벨 XP
      userNickname: state.auth.userNickname, // 닉네임
      profileImage: state.auth.profileImage, // 이미지
    }));

  /** 레벨에 맞는 배지 */
  const [levelBadge, setLevelBadge] = React.useState("");
  /** 현재 Xp를 퍼센테이지 */
  const [userLevelXpPercent, setUserLevelXpPercent] = React.useState(0);
  React.useEffect(() => {
    // 익명인 경우는 렌더 안 함
    if (userIdToken === "Guest") return;
    setLevelBadge(getLevelBadge(userLevel));

    const LEVEL_XP = 50; // 한 레벨 당 50
    setUserLevelXpPercent(
      Math.ceil(userLevelXp * (100 / (userLevel * LEVEL_XP)))
    );
  }, [userNickname, userLevel, userLevelXp]);

  function handleProfileComponent() {
    profilePage();
  }

  return (
    <>
      {userIdToken !== "Guest" && (
        <Card
          variant="outlined"
          sx={{
            mb: "1rem",
            "&:hover": { background: blueGrey[50], cursor: "pointer" },
          }}
          onClick={handleProfileComponent}
        >
          <CardContent
            sx={{
              p: "1.6rem 1.6rem 0 2.4rem",
              "&:last-child": { pb: "1.6rem" },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                sx={{
                  mr: "2rem",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  /** 레벨 바 겹침(뒤에 회색을 위해) */
                  sx={{
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    /** 레벨 바 */
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      size={64}
                      thickness={4.6}
                      value={100}
                      variant="determinate"
                      sx={{
                        position: "absolute",
                        color: (theme) => theme.palette.grey[200],
                      }}
                    />
                    <CircularProgress
                      size={64}
                      thickness={4.6}
                      variant="determinate"
                      sx={{ position: "absolute", color: "#fdd835" }}
                      value={userLevelXpPercent} // 이걸로 xp 조절
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Avatar src={profileImage.url || ""} />
                </Box>
              </Grid>
              <Grid item xs>
                <Stack>
                  <Typography variant="h6">{userNickname}</Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    Lv. {userLevel}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item>
                <img src={levelBadge} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </>
  );
}
export default LoginUserDrawer;
