import { combineReducers } from "redux";
import counter from "./counter";
import login from "./login";
import signUp from "./signUp";
import error from "./error";
import auth from "./auth";
import quiz from "./quiz";
import snackbar from "./snackbar";
import drawer from "./drawer";
import main from "./main";
import topicQuiz from "./topicQuiz";
import wrongQuiz from "./wrongQuiz";
import topicWrongQuiz from "./topicWrongQuiz";
import solveQuiz from "./solveQuiz";
import topicSolveQuiz from "./topicSolveQuiz";
import event from "./event";
import createQuiz from "./createQuiz";
import detailQuiz from "./detailQuiz";
import writeQuiz from "./writeQuiz";
import header from "./header";
const rootReducer = combineReducers({
  counter,
  login,
  signUp,
  error,
  auth,
  quiz,
  snackbar,
  drawer,
  main,
  topicQuiz,
  wrongQuiz,
  topicWrongQuiz,
  solveQuiz,
  topicSolveQuiz,
  event,
  createQuiz,
  detailQuiz,
  writeQuiz,
  header,
});

export default rootReducer;
