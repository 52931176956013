import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useSx from "../../hooks/useSx";

function ResponsiveDialog({
  isOpen,
  setIsOpen,
  dialogTitleText = "",
  children,
}) {
  const { isWindowMdDown } = useSx();

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Dialog fullScreen={isWindowMdDown} open={isOpen} onClose={handleClose}>
      <DialogTitle>{dialogTitleText}</DialogTitle>
      <DialogContent dividers sx={{ minWidth: 600 }}>
        {children}
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleClose}>
          취소
        </Button> */}
        <Button variant="contained" onClick={handleClose} autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export { ResponsiveDialog };
