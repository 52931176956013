import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import PageScreenBox from "../components/global-components/PageScreenBox";

/** 설정 */
function Setting({ value, handleChange, tabList }) {
  return (
    <PageScreenBox>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: "2.4rem" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabList.map((list) => (
              <Tab
                key={list.label}
                label={list.label}
                onClick={list.pageFunction}
                sx={{ minWidth: { xs: "12rem", sm: "14rem", md: "16rem" } }}
              />
            ))}
          </Tabs>
        </Box>
        {tabList.map(
          (list, index) =>
            index === value && <div key={list.label}>{list.panel}</div>
        )}
      </Box>
    </PageScreenBox>
  );
}
export default Setting;
