import axios from "axios";
import APIURL from "../../apiUrls";

/**
 * 관련자료 수정
 * @param {object} dataToSubmit : 서버에 보낼 정보
 * @param {array} exampleImageBody : 서버에 보낼 보기 이미지 정보
 */
async function putReferenceWrite(userId, dataToSubmit = {}) {
  try {
    const response = await axios.put(
      APIURL.api + `quiz/write/references`,
      JSON.stringify(dataToSubmit),
      {
        headers: {
          Authorization: `Bearer ${userId}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.log("등록 실패...", error);
    // switch (error.response.status) {
    //   case 502:
    //     window.location.href = URL.ERROR;
    //     return;
    //   case 503:
    //     window.location.href = URL.ERROR;
    //     return;
    //   default:
    //     return;
    // }
  }
}

export default putReferenceWrite;
