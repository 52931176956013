import {
  Box,
  Typography,
  Stack,
  Grid,
  Card,
  CardContent,
  ButtonBase,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import CategoryTag from "../atom-components/CategoryTag";
import usePage from "../../hooks/usePage";
import getUrlParam from "../../utils/getUrlParam";

//***** 한 문제 */
function QuizCard({ quiz, isFavorites }) {
  /**
   * 현재 url에서 id 뽑아 내기
   * - historyId : 페이지 이름( null(main) | wrong-quiz | solve-quiz)
   * - topicId : 주제 id
   * - quizId : 퀴즈 id
   */
  const { historyId, topicId } = getUrlParam();
  const { handleQuizIdPage } = usePage();
  return (
    <ButtonBase
      type="button"
      sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      onClick={() => handleQuizIdPage(historyId, topicId, quiz.quizId)}
    >
      <Card /** 한 문제 레이아웃 */ elevation={2} sx={{ width: "100%" }}>
        {/* {quiz.descriptionImages !== "" && (
          <CardMedia
            component="img"
            height="201.5rem"
            image={BaseUrl + quiz.descriptionImages}
            alt="quiz image"
          />
        )} */}
        <CardContent sx={{ width: "100%" }}>
          <Grid container>
            <Grid
              item
              xs
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                mb: "0.8rem",
                whiteSpace: "nowrap",
              }}
              /** 퀴즈 질문 , 즐겨찾기(있으면) */
            >
              <Typography
                sx={{
                  // height: "4.4rem",
                  mb: "1.5rem",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                variant="subtitle1"
              >
                {quiz.questionText}
              </Typography>
            </Grid>
            {/* 
            즐겨찾기
            <Grid item>{isFavorites && <BasicRating />}</Grid> */}
            {/* <Grid item>{<BasicRating />}</Grid> */}
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              mb: "0.8rem",
            }}
          >
            <Typography variant="caption" color="text.secondary">
              정답률 {quiz.answerRate} %
            </Typography>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              mb: "0.8rem",
            }}
          >
            {quiz.quizTopics.map((topic, index) => (
              <CategoryTag key={topic.topicId} text={topic.topicName} />
            ))}
          </Box>

          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs>
              <Stack
                direction="row"
                alignItems="center"
                color="text.secondary"
                spacing={2}
              >
                <GroupIcon fontSize="small" />
                <Typography variant="caption">{quiz.playUserCount}</Typography>
              </Stack>
            </Grid>
            {/* <Grid item>
              <Button
                variant="outlined"
                sx={{ pl: "0.7rem" }}
                onClick={() =>
                  handleQuizIdPage(historyId, topicId, quiz.quizId)
                }
              >
                <ArrowRightIcon />
                풀기
              </Button>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </ButtonBase>
  );
}
export default QuizCard;
