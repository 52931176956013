import { Box, Divider, Tooltip } from "@mui/material";
import BreadCrumbsTitle from "../components/global-components/BreadCrumbsTitle";
import QuizState from "../components/global-components/QuizState";
import { FlexBox, FlexColumnBox } from "../components/layout";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalWarning from "../components/global-components/ModalWarning";
import Quiz from "./Quiz";
import { SubHeader } from "../components/atom-components/StyledHeaders";
import PageTitle from "../components/global-components/PageTitle";
import { OutlinedIconButton } from "../components/atom-components/StyledButtons";
import changeQuizState from "../utils/changeQuizState";

/** 문제 상세보기 */
function DetailQuiz({
  resultQuiz,
  isSubmit,
  selectedOX,
  handleOXItemClick,
  handleIsSubmit,
  handleOXIsCorrect,
  onClickQuizAgain,
  isCorrect,
  commentary,
  randomWord,
  randomWordIndex,
  selectedWord,
  handleSelectedWordErase,
  handleSelectedWordInsert,
  handlePuzzleIsCorrect,
  handleMakeResultWord,
  handleListIsCorrect,
  selectedIndex,
  handleListItemClick,
  warningModal,
  onClickDeleteQuiz,
  onClickCheckDeleteQuiz,
  onClickCancle,
  onClickUpdateQuiz,
  onClickBackPage,
}) {
  return (
    <>
      <SubHeader>
        <FlexColumnBox
          sx={{
            width: 1,
            maxWidth: 1128,
            gap: "0.4rem",
            p: "1.6rem 0.75rem",
          }}
        >
          <BreadCrumbsTitle />
          <FlexBox
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageTitle
              titleName="상세 보기"
              pageBack
              onClick={onClickBackPage}
            />
            <FlexBox gap="1.2rem">
              <Tooltip title="수정 하기">
                <div>
                  <OutlinedIconButton onClick={onClickUpdateQuiz}>
                    <EditIcon />
                  </OutlinedIconButton>
                </div>
              </Tooltip>
              <Tooltip title="삭제 하기">
                <div>
                  <OutlinedIconButton
                    onClick={onClickDeleteQuiz}
                    isError
                    color="error"
                  >
                    <DeleteIcon />
                  </OutlinedIconButton>
                </div>
              </Tooltip>
            </FlexBox>
          </FlexBox>
          <FlexBox
            sx={{
              mt: "1.2rem",
              flexWrap: "wrap",
            }}
          >
            {
              // 점검 완료된 카드 내용 : 문제 유형, 풀이수, 정답률, 생성일
              // 점검 중인 카드 내용 : 문제 유형, 생성일, 상태
              changeQuizState(resultQuiz.isCheck, resultQuiz).map(
                (comp, index) => (
                  <QuizState key={index} quizData={comp} />
                )
              )
            }
            {!resultQuiz.isCheck && (
              <QuizState quizData={{ type: "상태", data: "점검중" }} isError />
            )}
          </FlexBox>
        </FlexColumnBox>
        <Box sx={{ width: 1 }}>
          <Divider />
        </Box>
      </SubHeader>
      {/* ==================== 퀴즈 미리보기 ==================== */}
      <Box sx={{ pt: "16rem", width: "100%" }}>
        <Quiz
          resultQuiz={resultQuiz}
          isSubmit={isSubmit}
          isCorrect={isCorrect}
          isSuccessQuiz
          selectedOX={selectedOX}
          randomWord={randomWord}
          randomWordIndex={randomWordIndex}
          selectedWord={selectedWord}
          selectedIndex={selectedIndex}
          handleOXItemClick={handleOXItemClick}
          handleListItemClick={handleListItemClick}
          handleSelectedWordErase={handleSelectedWordErase}
          handleSelectedWordInsert={handleSelectedWordInsert}
          handleMakeResultWord={handleMakeResultWord}
          handleOXIsCorrect={handleOXIsCorrect}
          handleListIsCorrect={handleListIsCorrect}
          handlePuzzleIsCorrect={handlePuzzleIsCorrect}
          commentary={commentary}
          isPreview
          handleIsSubmit={handleIsSubmit}
          onClickQuizAgain={onClickQuizAgain}
        />
      </Box>
      {/* 문제 삭제하기 주의 모달창 */}
      <ModalWarning
        title="삭제하시겠습니까?"
        content="저작한 퀴즈를 삭제하시면 다시 복구할 수 없습니다. 그래도 정말 삭제하시겠습니까?"
        buttonHandleName="삭제"
        modalWarningOpen={warningModal}
        onClickModalCancle={onClickCancle}
        onClickModalHandle={onClickCheckDeleteQuiz}
      />
    </>
  );
}
export default DetailQuiz;
