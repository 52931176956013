import { IconButton, Tooltip, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { FlexBox } from "../layout";
import { OutlinedIconButton } from "../atom-components/StyledButtons";

/**
 *
 * @param {*} titleName : 타이틀 이름
 * @param {*} pageBack : 뒤로가기 버튼 유무
 * @returns
 */
function PageTitle({ titleName, pageBack = false, onClick = () => {} }) {
  return (
    <FlexBox gap="1.2rem" sx={{ alignItems: "center" }}>
      {pageBack && (
        <Tooltip title="뒤로 가기">
          <div>
            <OutlinedIconButton onClick={onClick}>
              <KeyboardArrowLeftIcon />
            </OutlinedIconButton>
          </div>
        </Tooltip>
      )}
      <Typography variant="h6" color="black" sx={{ fontWeight: "bold" }}>
        {titleName}
      </Typography>
    </FlexBox>
  );
}
export default PageTitle;
