/** 현재 로그인 상태인지? */
function isUserLogin() {
  let isSuccess = false;

  const loginToken = localStorage.getItem("login-token");
  if (loginToken !== null) {
    isSuccess = true;
  }
  // console.log("현재 로그인 중?", isSuccess);
  return isSuccess;
}
export default isUserLogin;
