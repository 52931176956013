import { Typography, Grid, Card, CardContent } from "@mui/material";
import Pin from "./Pin";
import { grey, yellow } from "@mui/material/colors";
import useGameGraphic from "../../hooks/useGameGraphic";
/** 배지 카드 컴포넌트 */
/**
 *
 * @param {bool} isAchieved : 획득한 배지인지 (기본값 false : 획득 안 함)
 * @returns
 */
function AchievementCard({ achievementData, isAchieved = false }) {
  const { getAchievmentIcon } = useGameGraphic();

  const iconSx = {
    // 아이콘 sx
    width: "7rem",
    height: "7rem",
    color: !isAchieved ? "text.disabled" : yellow[600],
  };

  return (
    <Card
      /** 한 문제 레이아웃 */ elevation={1}
      sx={{ bgcolor: !isAchieved ? grey[100] : "white", height: "100%" }}
    >
      <CardContent>
        <Grid container sx={{ mb: "1.0rem" }}>
          <Grid item xs>
            {/* {getAchievmentIcon("TOT_PLAY_COUNT", iconSx)} */}
            {getAchievmentIcon(achievementData.target, iconSx)}
          </Grid>
          {/*
          운선순위
          <Grid item>
            <Pin isDisabled={!isAchieved} />
          </Grid> */}
        </Grid>
        <Grid container>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: !isAchieved ? "text.disabled" : "black",
            }}
            variant="subtitle1"
          >
            {/* 개근 배지 */}
            {achievementData.name}
          </Typography>
        </Grid>
        <Grid container>
          <Typography
            sx={{
              // whiteSpace: "nowrap",
              display: "box",
              lineClamp: 2,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
            variant="subtitle2"
            color={!isAchieved ? "text.disabled" : "text.secondary"}
          >
            {/* 출석 연속 50일 */}
            {achievementData.description}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default AchievementCard;
