/**
 * "OX" => OX
 * "PUZZLE" => 퍼즐형
 * "MULTIPLE" => 다지선다형
 */
function changeQuizTypeInfo(type) {
  switch (type) {
    case "OX":
      return "OX";
    case "PUZZLE":
      return "퍼즐형";
    case "MULTIPLE":
      return "다지선다형";
    default:
      return "문제 유형";
  }
}
export default changeQuizTypeInfo;
