import { useEffect, useState } from "react";
import WriteCKEditor from "../../components/write/WriteCKEditor";
import WriteTextField from "../../components/write/WriteTextField";
import {
  ID_INPUT_REFERENCE_STRING_URL,
  ID_INPUT_REFERENCE_TYPE,
} from "../../constants/writeReference";
import useEditorInput from "../../hooks/useEditorInput";
import useInputs from "../../hooks/useInputs";
import useMySnackbar from "../../hooks/useMySnackbar";
import usePage from "../../hooks/usePage";
import useTabs from "../../hooks/useTabs";
import WriteReference from "../../pages/reference/WriteReference";
import {
  handleGetReferenceWrite,
  handlePostReferenceWrite,
  handleUpdateReferenceWrite,
} from "../../utils/actions/handleUserReference";
import getUrlParam from "../../utils/getUrlParam";
import { InputAdornment } from "@mui/material";
import REFERENCE_INFO from "../../constants/referenceInfo";
import changeIconComponent from "../../utils/changeIconComponent";

function WriteReferenceContainer() {
  const { loginPage, referencePage, referenceCreateIdPage } = usePage();
  const { openSnackbarHere } = useMySnackbar();

  /** tabs로 관리되는 값들 */
  const {
    values: { inputReferenceType },
    onChange: onChangeWriteReferenceTabs,
    onSetValue: onSetWriteReferenceTab,
  } = useTabs({
    // 관련자료 유형
    inputReferenceType: REFERENCE_INFO.TEXT.type,
  });

  const {
    values: { inputReferenceStringURL },
    onChange: onChangeWriteReferenceInputs,
    onSetValue: onSetWriteReferenceInput,
  } = useInputs({
    // 관련자료 URL 주소
    inputReferenceStringURL: "",
  });

  // 관련자료 Text
  const {
    value: inputReferenceText,
    onChange: onChangeInputReferenceText,

    setValue: setInputReferenceText,
  } = useEditorInput("");

  // 입력 error, guide helpText
  const [inputError, setInputError] = useState(false);
  const [inputHelpText, setInputHelpText] = useState("");
  // tab data
  const tabReferenceTypeData = [
    {
      label: REFERENCE_INFO.TEXT.title,
      value: REFERENCE_INFO.TEXT.type,
      content: (
        <WriteCKEditor
          subTitleName="내용"
          subTitleBody="관련자료로써 참고할 수 있는 내용을 정리하여 입력해주세요."
          inputText={inputReferenceText}
          onChangeInputText={onChangeInputReferenceText}
          placeholder="관련자료 내용을 입력해주세요."
          initHeight="200px"
        />
      ),
    },
    {
      label: REFERENCE_INFO.URL.title,
      value: REFERENCE_INFO.URL.type,
      content: (
        <WriteTextField
          id={ID_INPUT_REFERENCE_STRING_URL}
          subTitleName="URL 주소"
          subTitleBody="관련자료로써 참고할 내용이 있는 URL 주소를 입력해주세요."
          value={inputReferenceStringURL}
          onChange={onChangeWriteReferenceInputs}
          placeholder="관련자료 URL 주소를 입력해주세요."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {changeIconComponent(REFERENCE_INFO.URL.icon)}
              </InputAdornment>
            ),
          }}
          error={inputError}
          helperText={inputHelpText}
        />
      ),
    },
  ];

  function checkSubmitCondition() {
    let result = true;

    const urlRegex =
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

    if (inputReferenceType === REFERENCE_INFO.URL.type) {
      result = urlRegex.test(inputReferenceStringURL);
      if (!result) {
        setInputError(true);
        setInputHelpText("URL 주소에 맞게 작성해주세요.");
      }
    }
    return result;
  }

  // 관련자료 생성 | 수정
  async function onClickSubmitReference() {
    // check submit input condition
    if (await !checkSubmitCondition()) {
      return;
    }

    // // get login token
    const loginToken = await localStorage.getItem("login-token");
    if (loginToken === null) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
      return;
    }

    let body = {
      referenceType: inputReferenceType,
      referenceData:
        inputReferenceType === REFERENCE_INFO.TEXT.type
          ? inputReferenceText
          : inputReferenceType === REFERENCE_INFO.URL.type
          ? inputReferenceStringURL
          : "",
    };

    let response = false;
    /** 관련자료 저작 : 관련자료 저작 완성하기 */
    if (!isEditMode()) {
      response = await handlePostReferenceWrite(loginToken, body);
    } else {
      /** 관련자료 수정 : 관련자료 수정하기 */
      body = {
        ...body,
        referenceId: createId,
      };
      response = await handleUpdateReferenceWrite(loginToken, body);
    }

    if (!response) {
      // 실패
      openSnackbarHere(
        "error",
        `${
          isEditMode() ? "관련자료를 수정하는" : "저작한 관련자료를 등록하는"
        } 데에 실패했습니다. 다시 시도해주세요.`
      );
      return;
    }

    const { referenceId } = response;
    openSnackbarHere(
      "success",
      isEditMode()
        ? "해당 관련자료가 수정되었습니다."
        : "저작한 관련자료가 등록되었습니다."
    );
    referenceCreateIdPage(referenceId);
  }

  // input progress value
  const [writeProgressValue, setWriteProgressValue] = useState(0);
  function isCanSubmit() {
    return writeProgressValue === 100;
  }
  useEffect(() => {
    let value = 0;
    if (
      (inputReferenceType === REFERENCE_INFO.TEXT.type &&
        inputReferenceText !== "") ||
      (inputReferenceType === REFERENCE_INFO.URL.type &&
        inputReferenceStringURL !== "")
    ) {
      value += 100;
    }
    setWriteProgressValue(value);
  }, [inputReferenceType, inputReferenceText, inputReferenceStringURL]);

  /**
   * 현재 url에서 저작한 관련자료 id 뽑아 내기 => 관련자료 저작 || 수정 구분을 위해
   */
  const { createId } = getUrlParam();
  function isEditMode() {
    // 수정 하기 모드인지
    return createId !== null;
  }
  function getBackPage() {
    // 저작 or 수정 뒤로 가기
    return isEditMode()
      ? () => referenceCreateIdPage(createId)
      : () => referencePage();
  }
  function getWriteReferencePageTitle() {
    // 저작 or 수정 페이지 제목
    return isEditMode() ? "수정" : "저작";
  }

  //-------------- (2) 수정하기 : id를 통해 reference info 받아오고 값 채워넣기 ----------------
  const [isLoading, setIsLoading] = useState(false);
  async function loadDataWithId() {
    await setIsLoading(true);

    // get login token
    const loginToken = await localStorage.getItem("login-token");
    if (loginToken === null) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
      return;
    }

    /** get param */
    let params = {
      referenceId: createId,
    };
    const response = await handleGetReferenceWrite(loginToken, params);
    const { referenceType, referenceData } = response;

    await onSetWriteReferenceTab({
      id: ID_INPUT_REFERENCE_TYPE,
      value: referenceType,
    });
    // url
    if (referenceType === REFERENCE_INFO.URL.type) {
      await onSetWriteReferenceInput({
        id: ID_INPUT_REFERENCE_STRING_URL,
        value: referenceData,
      });
    }
    // text
    else if (referenceType === REFERENCE_INFO.TEXT.type) {
      await setInputReferenceText(referenceData);
    }

    await setIsLoading(false);
  }
  // console.log(writeQuizInputs);
  useEffect(() => {
    // quizId 있으면 update, 없으면 write
    if (createId !== null) {
      loadDataWithId();
    }
  }, [createId]);

  return (
    <WriteReference
      onClickSubmitReference={onClickSubmitReference}
      isCanSubmit={isCanSubmit}
      writeProgressValue={writeProgressValue}
      inputReferenceType={inputReferenceType}
      onChangeWriteReferenceTabs={onChangeWriteReferenceTabs}
      tabReferenceTypeData={tabReferenceTypeData}
      onClickBackPage={getBackPage()}
      writeReferencePageTitle={getWriteReferencePageTitle()}
      isEditMode={isEditMode()}
      isLoading={isLoading}
    />
  );
}
export default WriteReferenceContainer;
