import * as React from "react";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ff6d75",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

/** 핀 (업적 카드에 있는 핀) */
function Pin({ isDisabled = false }) {
  const [value, setValue] = React.useState(0);
  function handleValue() {
    setValue(value === 0 ? 1 : 0);
  }

  return (
    <StyledRating
      disabled={isDisabled}
      name="customized-color"
      value={value}
      max={1}
      getLabelText={(value) => `${value} Heart${value !== 1 ? "s" : ""}`}
      icon={<PushPinIcon fontSize="inherit" />}
      emptyIcon={<PushPinOutlinedIcon fontSize="inherit" />}
      onChange={handleValue}
    />
  );
}
export default Pin;
