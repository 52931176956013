import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/** 주제 리스트 가져오기 (문제 저작 시, 주제 선택을 위함) */
async function getTopicList(params = {}) {
  let data;
  try {
    await axios
      .get(
        APIURL.api +
          `topic?pageNum=${params.pageNum}&&pagingSize=${params.pagingSize}`
      )
      .then((response) => {
        //console.log("주제 리스트 받음", response);
        data = response.data;
      });
    return data;
  } catch (error) {
    //console.log("주제 리스트 못 받음...", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}

export default getTopicList;
