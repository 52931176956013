import axios from "axios";
import URL from "../../../components/pageUrls";
import APIURL from "../../apiUrls";

/**
 * 프로필 이미지 삭제
 */
async function deleteProfileImage() {
  let data;
  try {
    await axios.delete(APIURL.api + "user/profile/image").then((response) => {
      //console.log("프로필 사진 삭제 성공!", response);
      data = response.data;
    });
    return data;
  } catch (error) {
    //console.log("프로필 사진 삭제 실패..!!!", error);
    switch (error.response.status) {
      case 502:
        window.location.href = URL.ERROR;
        return;
      case 503:
        window.location.href = URL.ERROR;
        return;
      default:
        return;
    }
  }
}
export default deleteProfileImage;
