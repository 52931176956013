import { Button, Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

/** Footer */
/** Footer 위치 */
const FooterPosition = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
}));
/** Footer 크기 */
const FooterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  maxWidth: "1128px",
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(4, 12), // py : 4, px : 12
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8, 12), // py : 4, px : 12
  },
  gap: theme.spacing(8),
}));
/**
 * Footer 버튼
 */
const FooterButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    size: "small",
    width: "fit-content",
  },
  [theme.breakpoints.up("sm")]: {
    width: "128px",
  },
  [theme.breakpoints.up("md")]: {
    size: "large",
    typography: "h6",
    width: "168px",
  },
}));

export { FooterPosition, FooterBox, FooterButton };
