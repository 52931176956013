import { CardContent, Card } from "@mui/material";
import StyledScrollbar from "../atom-components/StyledScrollbar";
import { FlexBox } from "../layout";
import ParseHtml from "../atom-components/ParseHtml";

/**
 * 퀴즈 예문
 * @param {object} resultQuiz : 퀴즈 데이터
 * @param {bool} isPreviewImage : 미리보기 이미지인지 여부 ( true : 미리보기 이미지 => media 안붙여도 됨 , false : (기본값) 서버에서 받아온 이미지)
 */
function QuizDescription({ resultQuiz, isPreviewImage }) {
  return (
    <>
      {!!resultQuiz.descriptionText && (
        <Card variant="outlined">
          <CardContent>
            <FlexBox sx={{ maxHeight: 640 }}>
              <StyledScrollbar>
                <ParseHtml htmlCodes={resultQuiz.descriptionText} />
              </StyledScrollbar>
            </FlexBox>
          </CardContent>
        </Card>
      )}
    </>
  );
}
export default QuizDescription;
