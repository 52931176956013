// 로고

import { Box, Link } from "@mui/material";
import useSx from "../../hooks/useSx";

/**
 * 헤더에 있는 로고
 * -> 클릭 시, pageUrl 함수 수행
 * @param {*} param0
 */
function LogoHeader({ pageUrl }) {
  /** 헤더 로고 크기 sm down? 58 rem : 112 rem */
  const { isWindowSmDown } = useSx();
  const logoSizeSx = isWindowSmDown ? "88rem" : "112rem";

  return (
    <Box sx={{ width: "fit-content" }}>
      <Link component="button" variant="body2" onClick={pageUrl}>
        <img src="/assets/StartupQT003_logo.png" width={logoSizeSx} />
      </Link>
    </Box>
  );
}
export default LogoHeader;
