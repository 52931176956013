import * as React from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Link,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Grid,
  Divider,
  Modal,
  IconButton,
} from "@mui/material";
import { FlexColumnBox } from "../components/layout";
import ErrorIcon from "@mui/icons-material/Error";
import useSx from "../hooks/useSx";
import ClearIcon from "@mui/icons-material/Clear";
import PasswordInput from "../components/global-components/PasswordInput";
/**
 * 로그인 페이지
 */
function Login({
  email,
  password,
  authError,
  emailLog,
  passwordLog,
  isDisabled,
  handleOnChangeInput,
  handleLoginSubmit,
  handleGoogleAuthProvider,
  signUpPageRePlace,
  findPasswordValue,
  handleOnChangeFindPasswordValue,
  handleFindPassword,
  findPasswordButton,
  handleOpenFindPasswordButton,
  handleCloseFindPasswordButton,
}) {
  const { modalStyle } = useSx();

  return (
    <Container maxWidth="xs" sx={{ pt: "8rem" }}>
      <Card variant="outlined">
        <CardContent>
          <FlexColumnBox
            sx={{
              mt: 8,
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              로그인
            </Typography>
            <Box
              /** 이메일 & 패스워드 감싸는 레이아웃 */
              // component="form"
              // onSubmit={handleLoginSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                error={emailLog}
                margin="normal"
                required
                fullWidth
                label="이메일"
                value={email}
                onChange={handleOnChangeInput}
                /** <input> 태그의 autocomplete 속성 : 자동 완성 기능 */
                autoComplete="email"
                autoFocus
                id="email"
              />
              {/* 비밀번호 입력 */}
              <PasswordInput
                passwordLog={passwordLog}
                password={password}
                handleOnChangeInput={handleOnChangeInput}
              />
              {authError !== "" && (
                <Grid item>
                  <Typography variant="body2" color="error">
                    <ErrorIcon fontSize="small" /> {authError}
                  </Typography>
                </Grid>
              )}
              <Grid container sx={{ alignItems: "center", m: "0.8rem 0" }}>
                <Grid item xs>
                  {/* <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="로그인 상태 유지"
                  /> */}
                </Grid>
                <Grid item>
                  <Link
                    component="button"
                    onClick={handleOpenFindPasswordButton}
                    variant="body2"
                  >
                    비밀번호 찾기
                  </Link>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 10 }}
                disabled={isDisabled}
                onClick={handleLoginSubmit}
              >
                로그인 하기
              </Button>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="body2" sx={{ mr: 4 }}>
                    계정이 없으신가요?
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    component="button"
                    onClick={signUpPageRePlace}
                    variant="body2"
                  >
                    회원가입 하기
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </FlexColumnBox>
        </CardContent>
      </Card>
      <Grid container alignItems="center" sx={{ my: 8 }}>
        <Grid item xs>
          <Divider />
        </Grid>
        <Typography variant="body2" sx={{ mx: 8 }}>
          또는
        </Typography>
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
      <Button
        fullWidth
        variant="outlined"
        sx={{ color: "text.secondary", borderColor: "text.disabled" }}
        onClick={handleGoogleAuthProvider}
      >
        <img src="svgs/btn_google_light_normal_ios.svg" />
        Google 계정으로 로그인
      </Button>
      <Modal
        /** 이메일 작성 */
        open={findPasswordButton}
      >
        <Box
          sx={modalStyle}
          component="form"
          onSubmit={handleFindPassword}
          noValidate
        >
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                비밀번호 찾기
              </Typography>
            </Grid>
            <Grid item /** X 버튼 */>
              <IconButton onClick={handleCloseFindPasswordButton}>
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
          <p id="parent-modal-description">이메일을 입력해주세요.</p>
          <TextField
            margin="normal"
            required
            fullWidth
            label="이메일"
            value={findPasswordValue}
            onChange={handleOnChangeFindPasswordValue}
            type="text"
          />
          <Button
            disabled={!findPasswordValue}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 10 }}
          >
            입력 완료
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}
export default Login;
