import { Skeleton, Stack } from "@mui/material";

function ListSkeleton() {
  return (
    <Stack spacing={4} sx={{ width: 1 }}>
      <Skeleton variant="rounded" height={40} />
      <Skeleton variant="rounded" height={40} />
      <Skeleton variant="rounded" height={40} />
    </Stack>
  );
}
export default ListSkeleton;
