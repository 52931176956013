import { Alert, IconButton, Slide, Snackbar, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

/**
 * styled snackbar with icon
 */
const SnackbarWithIcon = styled(
  ({ icon, message, onClose, alertProps, ...props }) => {
    return (
      <Snackbar
        {...props}
        sx={{ zIndex: (theme) => theme.zIndex.snackbar + 1000 }}
        action={
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        onClose={onClose}
        TransitionComponent={Slide}
      >
        <Alert
          {...alertProps}
          sx={{ backgroundColor: "#000000CC", color: "white" }}
          elevation={6}
          onClose={onClose}
        >
          {message}
        </Alert>
      </Snackbar>
    );
  }
)(({ theme }) => ({}));

/**
 * guide
 */
const GuideSnackbar = styled(
  ({ icon, message, onClose, alertProps, ...props }) => {
    return (
      <Snackbar
        {...props}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        onClose={onClose}
        TransitionComponent={Slide}
        message={message}
      />
    );
  }
)(({ theme }) => ({}));

export { SnackbarWithIcon, GuideSnackbar };
