/**
 * change topic form (get id)
 * @param {*} quizTopics
 * @returns
 */
function changeTopicsForm(quizTopics) {
  let result = [];
  let quizTopicsTemp = [...quizTopics]; // quizTopics copy

  quizTopicsTemp.map((item) => {
    result.push(item.topicId);
  });

  return result;
}
export default changeTopicsForm;
