import { useNavigate } from "react-router-dom";
import URL from "../components/pageUrls";

function usePage() {
  /** 페이지 이동 hook */
  const navigate = useNavigate();

  /** history=wrong-quiz */
  const wrongQuizUrl = `${URL.HISTORY_ID}=${URL.WRONG_ID}`;
  /** history=solve-quiz */
  const solveQuizUrl = `${URL.HISTORY_ID}=${URL.SOLVE_ID}`;

  //======= handleMoreClick : QuizList, QuizList 함수만 표기, QuizCards에서 인자 보냄 =================
  /** main > 더보기 > 주제별 퀴즈 문제들 페이지로 */
  const handleMainMoreClick = (topicId) => {
    navigate(`${URL.MAIN}/?${URL.TOPIC_ID}=${topicId}`);
  };
  /** wrong > 더보기 > 주제별 퀴즈 문제들 페이지로 */
  function handleWrongMoreClick(topicId) {
    navigate(`${URL.MAIN}/?${wrongQuizUrl}&&${URL.TOPIC_ID}=${topicId}`);
  }
  /** wrong > 더보기 > 주제별 퀴즈 문제들 페이지로 */
  function handleSolveMoreClick(topicId) {
    navigate(`${URL.MAIN}/?${solveQuizUrl}&&${URL.TOPIC_ID}=${topicId}`);
  }
  //=====================틀린 문제, 풀었던 문제====================
  /** 틀린 문제 페이지로 */
  function wrongPage() {
    navigate(`${URL.MAIN}/?${wrongQuizUrl}`);
  }
  /** 풀었던 문제 페이지로 */
  function solvePage() {
    navigate(`${URL.MAIN}/?${solveQuizUrl}`);
  }

  //======= quizPage(랜덤으로 풀기) : QuizList, QuizList 함수만 표기 | Topic Page에서는 QuizList에서 인자 보냄 ===============
  //======= topicQuizPage(랜덤 풀기) : QuizList, QuizList 함수만 표기, QuizCards에서 인자 보냄 =============================
  /** main > 퀴즈 페이지로 */
  const mainQuizPage = () => {
    navigate(URL.QUIZ);
  };
  /** main > 각 주제 페이지 > 퀴즈 페이지로 */
  const mainTopicQuizPage = (topicId) => {
    navigate(`${URL.QUIZ}/?${URL.TOPIC_ID}=${topicId}`);
  };
  /** wrong > 퀴즈 페이지로 */
  const wrongQuizPage = () => {
    navigate(`${URL.QUIZ}/?${wrongQuizUrl}`);
  };
  /** wrong > 각 주제 페이지 > 퀴즈 페이지로(quiz/?history_id=wrong-quiz&&topic_id=1)) */
  const wrongTopicQuizPage = (topicId) => {
    navigate(`${URL.QUIZ}/?${wrongQuizUrl}&&${URL.TOPIC_ID}=${topicId}`);
  };
  /** solve > 퀴즈 페이지로 */
  const solveQuizPage = () => {
    navigate(`${URL.QUIZ}/?${solveQuizUrl}`);
  };
  /** solve > 각 주제 페이지 > 퀴즈 페이지로 */
  const solveTopicQuizPage = (topicId) => {
    navigate(`${URL.QUIZ}/?${solveQuizUrl}&&${URL.TOPIC_ID}=${topicId}`);
  };

  /**
   * history Id : 방금 전에 어떤history에서 왔는지 모르니까, url에 있으면 넣고 없으면 안 넣기
   * topic Id : 방금 전에 topic에서 왔는지, 어떤topic에서 왔는지 모르니까, url에 있으면 넣고 없으면 안 넣기
   * quiz Id : 현재 퀴즈 페이지의 quiz id로
   */
  /** historyId, topicId로 url 만들기 */
  function makeQuizUrl(historyId, topicId) {
    let quizUrl = `${URL.QUIZ}/?`; // "quiz/?" 가 기본값

    /** historyId : null 아니면, 틀린&봤던 문제 */
    quizUrl += historyId !== null ? `${URL.HISTORY_ID}=${historyId}&&` : "";
    /** topicId : null 아니면, 주제 문제 */
    quizUrl += topicId !== null ? `${URL.TOPIC_ID}=${topicId}&&` : "";

    return quizUrl;
  }
  //======= 퀴즈 id로 문제 풀기 : QuizCard 에서 history_id, topic_id, quiz_id 받아서 인자로 전달 ====================
  const handleQuizIdPage = (historyId, topicId, quizId) => {
    let quizUrl = makeQuizUrl(historyId, topicId);
    quizUrl += quizId !== null ? `${URL.QUIZ_ID}=${quizId}` : ""; // quiz_id는 있으면 붙이기
    navigate(quizUrl);
  };
  //======= 만든 퀴즈 보기 : CreateQuizCard 에서 create_id 받아서 인자로 전달 ====================
  const handleCreateIdPage = (createId) => {
    let createUrl = `${URL.CREATE}/?`; // "create/?" 가 기본값
    createUrl += `${URL.CREATE_ID}=${createId}`;
    navigate(createUrl);
    window.location.reload();
  };

  //======= 다시 풀기(historyId, topicId, quizId 다 받아놓기) : QuizContainer 에서 인자 보내기
  const quizAgain = (historyId, topicId, quizId) => {
    let quizUrl = makeQuizUrl(historyId, topicId);
    quizUrl += quizId !== null ? `${URL.QUIZ_ID}=${quizId}` : ""; // quiz_id는 있으면 붙이기
    navigate(quizUrl, { replace: true });
    window.location.reload();
  };

  //======= 다음 문제(historyId, topicId 만 받아놓기) =====================
  const quizNext = (historyId, topicId) => {
    let quizUrl = makeQuizUrl(historyId, topicId);
    navigate(quizUrl, { replace: true });
    window.location.reload();
  };

  //======= 메인으로 가기 ===========
  /** 메인으로 가기, 새로고침 */
  const mainPage = () => {
    navigate(URL.MAIN, { replace: true });
    window.location.reload();
  };
  /** 메인으로 가기 */
  const mainPageImme = () => {
    navigate(URL.MAIN, { replace: true });
  };
  /** 메인으로 가기 */
  const mainBread = () => {
    navigate(URL.MAIN);
  };

  //==================login================
  /** 로그인 하러 가기 */
  const loginPage = () => {
    navigate(URL.LOGIN);
  };
  const loginPageRePlace = () => {
    navigate(URL.LOGIN, { replace: true });
  };
  //==================signUp================
  /** 회원가입 하러 가기 */
  const signUpPage = () => {
    navigate(URL.SIGNUP);
  };
  const signUpPageRePlace = () => {
    navigate(URL.SIGNUP, { replace: true });
  };
  //=================뒤로가기===============
  const backPage = () => {
    navigate(-1, { replace: true });
  };
  const backPageRefresh = () => {
    navigate(URL.MAIN, { replace: true });
    window.location.reload();
  };
  //============인자로 넘어온 url로 가기=====
  const handleChangePage = (url) => {
    navigate(url);
  };
  // /** breadcrumbs url 만들기 */
  // function makeBreadcrumbsUrl(historyId, topicId) {
  //   let quizUrl = `${URL.QUIZ}/?`; // "quiz/?" 가 기본값

  //   /** historyId : null 아니면, 틀린&봤던 문제 */
  //   quizUrl += historyId !== null ? `${URL.HISTORY_ID}=${historyId}&&` : "";
  //   /** topicId : null 아니면, 주제 문제 */
  //   quizUrl += topicId !== null ? `${URL.TOPIC_ID}=${topicId}&&` : "";

  //   return quizUrl;
  // }
  //========== 문제 저작 메인 ========
  const createPage = () => {
    navigate(URL.CREATE);
  };
  const createPageRefresh = () => {
    navigate(URL.CREATE);
    window.location.reload();
  };
  //========= 저작한 문제 상세 보기 ======
  /** 문제 저작 > 상세 보기 페이지로 */
  function createIdPage(createId) {
    navigate(`${URL.CREATE}/?${URL.CREATE_ID}=${createId}`);
  }
  //========= 문제 저작 하기======
  /** 문제 저작 > 저작하기 페이지로 */
  function writePage() {
    navigate(`${URL.CREATE}${URL.WRITE}`);
  }
  //========= 문제 수정 하기======
  /** 문제 저작 > 수정하기 페이지로 */
  function updatePage(createId) {
    navigate(`${URL.CREATE}${URL.WRITE}/?${URL.CREATE_ID}=${createId}`);
    // window.location.reload();
  }

  //========= 관련 자료 =========
  function referencePage() {
    navigate(URL.REFERENCE);
  }
  const referencePageRefresh = () => {
    navigate(URL.REFERENCE);
    window.location.reload();
  };
  function referenceCreateIdPage(createId) {
    navigate(`${URL.REFERENCE}/?${URL.CREATE_ID}=${createId}`);
  }
  function referenceWritePage() {
    navigate(`${URL.REFERENCE}${URL.WRITE}`);
  }
  function referenceUpdatePage(createId) {
    navigate(`${URL.REFERENCE}${URL.WRITE}/?${URL.CREATE_ID}=${createId}`);
  }
  const handleReferenceCreateIdPage = (createId) => {
    let createUrl = `${URL.REFERENCE}/?`; // "reference/?" 가 기본값
    createUrl += `${URL.CREATE_ID}=${createId}`;
    navigate(createUrl);
    window.location.reload();
  };

  //========= 프로필 ======
  function profilePage() {
    navigate(URL.PROFILE);
  }
  //========= 설정 - 계정 ======
  function settingAccountPage() {
    navigate(`${URL.SETTING}/${URL.ACCOUNT_ID}`);
  }
  //========= 설정 - 저작 권한 ======
  function settingWriteAuthorityPage() {
    navigate(`${URL.SETTING}/${URL.WRITE_AUTHORITY_ID}`);
  }
  //========= 설정 - 비밀번호 변경 ======
  function settingPasswordPage() {
    navigate(`${URL.SETTING}/${URL.PASSWORD_ID}`);
  }

  //============새로 고침======
  function refresh() {
    window.location.reload();
  }
  //============503점검 중=======
  function errorPage() {
    navigate(`${URL.ERROR}`);
  }

  return {
    handleMainMoreClick,
    handleWrongMoreClick,
    handleSolveMoreClick,
    wrongPage,
    solvePage,
    mainQuizPage,
    mainTopicQuizPage,
    wrongQuizPage,
    wrongTopicQuizPage,
    solveQuizPage,
    solveTopicQuizPage,
    handleQuizIdPage,
    handleCreateIdPage,
    loginPage,
    loginPageRePlace,
    backPage,
    mainPage,
    handleChangePage,
    quizAgain,
    quizNext,
    signUpPage,
    signUpPageRePlace,
    mainPageImme,
    backPageRefresh,
    mainBread,
    createPage,
    createPageRefresh,
    createIdPage,
    refresh,
    writePage,
    updatePage,
    profilePage,
    errorPage,
    settingAccountPage,
    settingWriteAuthorityPage,
    settingPasswordPage,
    referenceCreateIdPage,
    referencePage,
    referencePageRefresh,
    referenceWritePage,
    referenceUpdatePage,
    handleReferenceCreateIdPage,
  };
}
export default usePage;
