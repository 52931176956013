import React from "react";
import { useSelector, useDispatch } from "react-redux";
import MyLoading from "../../components/global-components/MyLoading";
import URL from "../../components/pageUrls";
import useMySnackbar from "../../hooks/useMySnackbar";
import usePage from "../../hooks/usePage";
import { onLoadCreateQuizData } from "../../modules/createQuiz";
import { onLoadingFalse, onLoadingTrue } from "../../modules/header";
import getQuizWrite from "../../modules/_actions/create/getQuizWrite";
import CreateQuiz from "../../pages/CreateQuiz";
import getUrlParam from "../../utils/getUrlParam";
import handleRequestHeader from "../../utils/handleRequestHeader";
import DetailQuizContainer from "./DetailQuizContainer";
import WriteQuizContainer from "./WriteQuizContainer";

export default function CreateQuizContainer() {
  const { loginPage, writePage, backPage } = usePage();
  const { openSnackbar } = useMySnackbar();
  /** Redux State */
  const dispatch = useDispatch();

  const { quizList, currentPageNum, maxPageCount, isWritable, userIdToken } =
    useSelector((state) => ({
      quizList: state.createQuiz.quizList,
      currentPageNum: state.createQuiz.currentPageNum,
      maxPageCount: state.createQuiz.maxPageCount,
      isWritable: state.auth.isWritable, // 저작권한
      userIdToken: state.auth.userIdToken, // 저작권한
    }));

  const handleLoadCreateQuizData = (data) =>
    dispatch(onLoadCreateQuizData(data));
  // /** 스낵바 */
  // const handleOpen = () => dispatch(onChangeOpen());
  // const handleInput = (data) => dispatch(onChangeInput(data));

  /** 퀴즈 리스트 잘 불러왔는지 */
  const [isSuccessList, setIsSuccessList] = React.useState(false);
  /**
   * 현재 url에서 id 뽑아 내기
   * - createId : 만든 문제 id
   */
  const { createId } = getUrlParam();

  /** 현재 pagination 페이지 */
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  /**
   * 검증 전, 검증 완료 선택 여부
   * { state: 검증 상태 string, isSelected : 선택 여부 bool }
   */
  const [isSelectedAll, setIsSelectedAll] = React.useState(true);
  const [isSelectedFalse, setIsSelectedFalse] = React.useState(false); // 모든 게 다 취소되었는지
  const [isSelectedState, setIsSelectedState] = React.useState([
    { state: "검증 전", isSelected: true },
    { state: "검증 완료", isSelected: true },
  ]);

  /** 전체 선택 */
  const handleChangeAll = () => {
    const result = isSelectedState.map((comp) => {
      return { state: comp.state, isSelected: !isSelectedAll };
    });
    setIsSelectedState(result);
  };
  /** 검증 업데이트 */
  const handleChangeIsSelected = (state) => {
    setIsSelectedState(
      isSelectedState.map((comp) =>
        comp.state === state ? { ...comp, isSelected: !comp.isSelected } : comp
      )
    );
  };
  React.useEffect(() => {
    if (isSelectedState[0].isSelected && isSelectedState[1].isSelected)
      setIsSelectedAll(true);
    else setIsSelectedAll(false);

    if (!isSelectedState[0].isSelected && !isSelectedState[1].isSelected)
      setIsSelectedFalse(true);
    else setIsSelectedFalse(false);
  }, [isSelectedState]);

  //================================================
  /** 저작한 문제 리스트 제공 API */
  async function loadCreateQuizData() {
    /** get param */
    let params = {
      //   topicId: topic_id,(선택사항)
      isCheck:
        (isSelectedState[0].isSelected && false) ||
        (isSelectedState[1].isSelected && true), // false : 검증 전, true : 검증 완료, null : 전체 (선택사항)
      pageNum: page,
      pagingSize: 10,
    };

    const loginToken = await localStorage.getItem("login-token");

    /** 로그인 퀴즈 문제 제공 */
    if (loginToken !== null) {
      let isSuccess = false;
      /** 리퀘스트 헤더에 토큰 담기 */
      if (!(await handleRequestHeader())) {
        isSuccess = false;
        return; // 못 담으면, 바로 리턴
      }
      isSuccess = true;
      // //console.log("실행", isSelectedAll, isSelectedState);
      /** 완전 처음 로드 */
      if (isSuccess) {
        await getQuizWrite(params, isSelectedAll).then((res) => {
          // //console.log("우선 여기까지 잘 왔음", res);
          if (res === undefined) {
            // 서버에서 못 가져왔다면
            handleLoadCreateQuizData({
              maxPageCount: 0,
              currentPageNum: 0,
              quizList: [],
            });
          } else {
            handleLoadCreateQuizData(res);
          }
          setIsSuccessList(true);
        });
      }
    } else {
      /** 로그인 하러 가기 */
      loginPage();
    }
  }

  React.useEffect(() => {
    const l = loadCreateQuizData();
  }, [page, isSelectedAll, isSelectedState]);

  React.useEffect(() => {
    //console.log(userIdToken, isWritable);
    if (
      userIdToken !== "Guest" &&
      !isWritable
      // || userIdToken === "Guest"
    )
      openSnackbar(
        "warning",
        "저작 권한이 없습니다. 설정에서 저작 권한을 요청해주세요."
      );
  }, [userIdToken, isWritable]);

  /** 헤더 로그인, 로그아웃 */
  const handleLoadingFalse = () => dispatch(onLoadingFalse());
  const handleLoadingTrue = () => dispatch(onLoadingTrue());
  React.useEffect(() => {
    if (!isSuccessList) handleLoadingTrue();
    else handleLoadingFalse();
  }, [isSuccessList]);
  return (
    <>
      {window.location.pathname.includes(URL.WRITE) ? (
        <WriteQuizContainer />
      ) : createId !== null ? (
        <DetailQuizContainer />
      ) : (
        <>
          {!isSuccessList && <MyLoading />}
          {isSuccessList && (
            <CreateQuiz
              createId={createId}
              isSelectedAll={isSelectedAll}
              handleChangeAll={handleChangeAll}
              isSelectedFalse={isSelectedFalse}
              isSelectedState={isSelectedState}
              handleChangeIsSelected={handleChangeIsSelected}
              handlePageChange={handlePageChange}
              quizList={quizList}
              currentPageNum={currentPageNum}
              maxPageCount={maxPageCount}
              writePage={writePage}
            />
          )}
        </>
      )}
    </>
  );
}
