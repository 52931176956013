/**
 * Action type
 * Ducks 패턴을 사용할 때는 액션 타입을 선언할 때 문자열 앞에 접두사 붙임.
 * 다른 모듈과 이름이 중복되지 않게 하기 위함.
 */
const INPUT_EMAIL = "login/INPUT_EMAIL";
const INPUT_PASSWORD = "login/INPUT_PASSWORD";

/** init State ( 초기 상태 ) */
const initialState = {
  email: "",
  password: "",
};

/** Action Creator Function ( 액션 생성 함수 ) */
export const onChangeInput = ({ id, value }) => ({
  type:
    (id === "email" && INPUT_EMAIL) || (id === "password" && INPUT_PASSWORD),
  [id]: value,
});

/** reducer정의 */
export default function login(state = initialState, action) {
  switch (action.type) {
    case INPUT_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case INPUT_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    default:
      return state;
  }
}
