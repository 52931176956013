import SubTitle from "../global-components/SubTitle";
import { FloatingTextField } from "../atom-components/StyledTextFields";
import { ID_INPUT_ANSWER_TEXT } from "../../constants/writeQuiz";

/**
 * 퍼즐
 * @param {} param0
 * @returns
 */
function WriteQuizQuestionPuzzle({ inputAnswerText, onChangeWriteQuizInputs }) {
  return (
    <>
      <SubTitle titleName="정답" body="정답을 단답형으로 입력해주세요." />
      <FloatingTextField
        id={ID_INPUT_ANSWER_TEXT}
        value={inputAnswerText}
        onChange={onChangeWriteQuizInputs}
        placeholder="정답을 입력해주세요."
      />
    </>
  );
}
export default WriteQuizQuestionPuzzle;
