import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usePage from "../../hooks/usePage";
import WriteQuiz from "../../pages/WriteQuiz";
import getUrlParam from "../../utils/getUrlParam";
import loadQuizId from "../../modules/_actions/loadQuizId";
import useMySnackbar from "../../hooks/useMySnackbar";
import MyLoading from "../../components/global-components/MyLoading";
import useTabs from "../../hooks/useTabs";
import useInputs from "../../hooks/useInputs";
import useDragList from "../../hooks/useDragList";
import useSelects from "../../hooks/useSelects";
import {
  DEFAULT_VALUE_MULTIPLE,
  DEFAULT_VALUE_OX,
  DEFAULT_VALUE_PUZZLE,
  ID_INPUT_ANSWER,
  ID_INPUT_ANSWER_TEXT,
  ID_INPUT_QUESTION_TEXT,
  ID_INPUT_QUIZ_TYPE,
  VALUE_QUIZ_TYPE_MULTIPLE,
} from "../../constants/writeQuiz";
import {
  checkSubmitCondition,
  checkWriteProgressValue,
} from "../../utils/write/checkSubmitCondition";
import changeExamplesForm from "../../utils/write/changeExamplesForm";
import {
  handlePostQuizWrite,
  handlePutQuizWrite,
} from "../../utils/handleUserActions";
import changeTopicsForm from "../../utils/write/changeTopicsForm";
import timeFormat from "../../utils/timeFormat";
import changeAnswer from "../../utils/write/changeAnswer";

/**
 * 문제 저작하기 write & 문제 수정하기 update
 */
function WriteQuizContainer() {
  const { loginPage, createIdPage, createPage } = usePage();
  const { openSnackbar, openSnackbarHere } = useMySnackbar();

  /**
   * 현재 url에서 저작한 퀴즈 id 뽑아 내기 => 문제 저작 || 수정 구분을 위해
   */
  const { createId } = getUrlParam();
  function isEditMode() {
    // 수정 하기 모드인지
    return createId !== null;
  }
  function getWriteQuizPageTitle() {
    // 저작 or 수정 페이지 제목
    return isEditMode() ? "수정" : "저작";
  }
  function getBackPage() {
    // 저작 or 수정 뒤로 가기
    return isEditMode() ? () => createIdPage(createId) : () => createPage();
  }

  const { isWritable, userIdToken } = useSelector((state) => ({
    isWritable: state.auth.isWritable, // 저작권한
    userIdToken: state.auth.userIdToken, // 저작권한
  }));

  // <<퀴즈 유형>>
  // topic
  // const [topicsToggle, setTopicsToggle] = useState(topicsTempData);
  //  const [topicsToggle, setTopicsToggle] = useState([]);

  // // 대분류 toggle 값을 반전시키는 버튼 이벤트 함수
  // function handleToggle(mainIndex) {
  //   const newTopicsToggle = [...topicsToggle];
  //   newTopicsToggle[mainIndex].toggle = !topicsToggle[mainIndex].toggle;
  //   setTopicsToggle(newTopicsToggle);
  // }

  // <<선택한 주제들>>
  const [selectedTopics, setSelectedTopics] = useState([]);
  // 주제 선택 여부 handler
  function handleSubTopicToggle(value) {
    const { topicId } = value;
    const currentIndex = selectedTopics.findIndex(
      (topic) => topic.topicId === topicId
    );
    const newSelectedTopics = [...selectedTopics];

    if (currentIndex === -1) {
      newSelectedTopics.push(value);
    } else {
      newSelectedTopics.splice(currentIndex, 1);
    }
    setSelectedTopics(newSelectedTopics);
  }

  // <<예문>>
  const [inputDescriptionText, setInputDescriptionText] = useState("");
  // set inputDescriptionText
  function handleChangeInputDescriptionText(event, editor) {
    const data = editor.getData();
    setInputDescriptionText(data);
  }
  // <<해설>>
  const [inputCommentaryText, setInputCommentaryText] = useState("");
  // set inputCommentaryText
  function handleChangeInputCommentaryText(event, editor) {
    const data = editor.getData();
    setInputCommentaryText(data);
  }

  /** tabs로 관리되는 값들 */
  const {
    values: { selectQuizInfo, inputQuizType },
    onChange: onChangeWriteQuizTabs,
    onSetValue: onSetWriteQuizTab,
  } = useTabs({
    // 문제, 해설, 관련 자료
    selectQuizInfo: 0,
    // <<퀴즈 유형>>
    inputQuizType: VALUE_QUIZ_TYPE_MULTIPLE,
  });

  /** input : text 로 관리되는 값들 */
  const {
    values: { inputQuestionText, inputAnswerText },
    onChange: onChangeWriteQuizInputs,
    onSetValues: onSetWriteQuizInputs,
  } = useInputs({
    // <<질문>>
    inputQuestionText: "",
    // <<(퍼즐) 정답>>
    ...DEFAULT_VALUE_PUZZLE,
  });

  /** select : text 로 관리되는 값들 */
  const {
    values: { inputAnswer },
    onChange: onChangeWriteQuizSelects,
    onSetValue: onSetWriteQuizSelect,
  } = useSelects({
    // <<(OX) 정답>> : (default : "O")
    ...DEFAULT_VALUE_OX,
  });

  // drag example item
  const {
    items: exampleItems,
    setItems: setExampleItems,
    onDragEnd: handleDragEndExampleItems,
    onAddItem,
    onRemoveItem: handleClickRemoveExampleItem,
  } = useDragList(DEFAULT_VALUE_MULTIPLE);

  // set example item isAnswer
  function handleClickSetExampleItemIsAnswer(event, id) {
    const selectedIndex = exampleItems.findIndex(
      (item) => item.exampleId === id
    ); // get selected item index

    let newItems = [...exampleItems];
    if (selectedIndex !== -1) {
      newItems[selectedIndex] = {
        ...newItems[selectedIndex],
        isAnswer: !newItems[selectedIndex].isAnswer,
      };
    }
    setExampleItems(newItems);
  }

  // set example item text
  function handleChangeExampleItemText(event, editor, id) {
    const data = editor.getData();
    const selectedIndex = exampleItems.findIndex(
      (item) => item.exampleId === id
    ); // get selected item index

    let newItems = [...exampleItems];
    if (selectedIndex !== -1) {
      newItems[selectedIndex] = {
        ...newItems[selectedIndex],
        exampleText: data,
      };
    }
    setExampleItems(newItems);
  }

  // click event add example item
  function handleClickAddExampleItem() {
    onAddItem({
      exampleId: new Date().toISOString(),
      exampleText: "",
      isAnswer: false,
    });
  }

  // <<선택한 관련자료>>
  const {
    items: selectedReferencesItems,
    setItems: setSelectedReferencesItems,
    onDragEnd: handleDragEndSelectedReferencesItems,
  } = useDragList([]);
  // 하나씩 선택 handling
  function onClickSelectReference(event, id) {
    const selectedIndex = selectedReferencesItems.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedReferencesItems, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedReferencesItems.slice(1));
    } else if (selectedIndex === selectedReferencesItems.length - 1) {
      newSelected = newSelected.concat(selectedReferencesItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedReferencesItems.slice(0, selectedIndex),
        selectedReferencesItems.slice(selectedIndex + 1)
      );
    }
    setSelectedReferencesItems(newSelected);
  }

  // 퀴즈 미리보기에 quiz info 감싸서 보내기 위함
  const [resultQuiz, setResultQuiz] = useState({});
  // 퀴즈 미리보기 모드인지
  const [previewResultQuizState, setPreviewResultQuizState] = useState(false);

  // submit control
  async function handleClickSubmitWrite() {
    // await setIsSubmitLoading(true);

    // check submit input condition
    if (
      await !checkSubmitCondition(
        selectedTopics,
        inputQuestionText,
        inputQuizType,
        inputAnswer,
        inputAnswerText,
        exampleItems,
        inputCommentaryText
      )
    ) {
      // await setIsSubmitLoading(false);
      openSnackbarHere("warning", "제출 형식에 맞게 작성해주세요.");
      return;
    }

    /** 문제 body */
    let body = {
      quizType: inputQuizType,
      difficultyValue: 1,
      questionText: inputQuestionText,
      descriptionText: inputDescriptionText,
      quizTopics: changeTopicsForm(selectedTopics), // ID만 추출해오기
      commentaryText: inputCommentaryText,
      examples: changeExamplesForm(exampleItems), // delete id
      answerText: inputAnswerText,
      answer: changeAnswer(inputAnswer), // string -> bool
    };
    // console.log(body);

    // // get login token
    const loginToken = await localStorage.getItem("login-token");
    if (loginToken === null) {
      /** 로그인 하러 가기 */
      loginPage();
      openSnackbarHere("basic", "로그인을 진행해주세요.");
      return;
    }

    let response = false;
    /** 문제 저작 : 문제 저작 완성하기 */
    if (!isEditMode()) {
      response = await handlePostQuizWrite(loginToken, body);
    } else {
      /** 문제 수정 : 문제 수정하기 */
      let params = {
        quizId: createId,
      };
      response = await handlePutQuizWrite(loginToken, body, params);
    }
    // console.log("response", response);
    if (!response) {
      // 실패
      openSnackbarHere(
        "error",
        `${
          isEditMode() ? "퀴즈를 수정하는" : "저작한 퀴즈를 등록하는"
        } 데에 실패했습니다. 다시 시도해주세요.`
      );
      return;
    }

    const { quizId } = response;

    // console.log("write quiz submit success", quizId);
    openSnackbarHere(
      "success",
      isEditMode() ? "퀴즈가 수정되었습니다." : "저작한 퀴즈가 등록되었습니다."
    );
    createIdPage(quizId);
  }

  // 미리보기
  async function onClickPreview() {
    // handleWriteError("init");
    await setPreviewResultQuizState(false);
    await setResultQuiz({
      quizType: inputQuizType,
      difficultyValue: 1,
      questionText: inputQuestionText,
      descriptionText: inputDescriptionText,
      quizTopics: selectedTopics, // ID만 추출해오기
      commentaryText: inputCommentaryText,
      examples: exampleItems,
      answer: inputAnswer,
      answerText: inputAnswerText,
      quizId: 0,
      isShow: true,
      isCheck: true,
      createdDate: timeFormat(new Date(), false),
      answerRate: 0,
      playUserCount: 0,
    });
    await setPreviewResultQuizState(true);
  }

  // 편집하기
  async function onClickEdit() {
    await setPreviewResultQuizState(false);
  }
  // progress bar value
  const [writeProgressValue, setWriteProgressValue] = useState(0);
  const [isCanSubmit, setIsCanSubmit] = useState(false); // is can submit -> submit button active
  useEffect(() => {
    const value = checkWriteProgressValue(
      selectedTopics,
      inputQuestionText,
      inputQuizType,
      inputAnswer,
      inputAnswerText,
      exampleItems,
      inputCommentaryText
    );
    setWriteProgressValue(value);
    setIsCanSubmit(value === 100);
  }, [
    selectedTopics,
    inputQuestionText,
    inputQuizType,
    inputAnswer,
    inputAnswerText,
    exampleItems,
    inputCommentaryText,
  ]);

  //-------------- (2) 수정하기 : id를 통해 quiz info 받아오고 값 채워넣기 ----------------
  /** 퀴즈 잘 로딩 해왔는지 */
  const [isLoading, setIsLoading] = useState(true);
  async function loadDataWithId() {
    await setIsLoading(true);
    /** get param */
    let params = {
      quizId: createId,
    };
    const { resultQuiz } = await loadQuizId(params);
    const {
      answer,
      commentaryText,
      descriptionText,
      questionText,
      quizTopics,
      quizType,
      answerText,
      examples,
    } = resultQuiz;

    // 퀴즈 유형에 맞는 보기 및 정답 필드만 오기 때문에,
    // undefined 예외처리 해줘야 함 (default값으로 채워주기)

    // 퀴즈 유형
    await onSetWriteQuizTab({
      id: ID_INPUT_QUIZ_TYPE,
      value: quizType,
    });
    // 퀴즈 주제
    await setSelectedTopics(quizTopics);
    // 퀴즈 질문 & 퀴즈 퍼즐 정답
    await onSetWriteQuizInputs([
      {
        id: ID_INPUT_QUESTION_TEXT,
        value: questionText,
      },
      {
        id: ID_INPUT_ANSWER_TEXT,
        value:
          answerText === undefined
            ? DEFAULT_VALUE_PUZZLE.inputAnswerText
            : answerText,
      },
    ]);
    // 퀴즈 예문
    await setInputDescriptionText(descriptionText);
    // 퀴즈 다지선다 보기
    await setExampleItems(
      examples === undefined ? DEFAULT_VALUE_MULTIPLE : examples
    );
    // 퀴즈 OX 정답
    await onSetWriteQuizSelect({
      name: ID_INPUT_ANSWER,
      value: answer === undefined ? DEFAULT_VALUE_OX.inputAnswer : answer,
    });
    // 퀴즈 해설
    await setInputCommentaryText(commentaryText);

    await setIsLoading(false);
  }
  // console.log(writeQuizInputs);
  useEffect(() => {
    // quizId 있으면 update, 없으면 write
    if (createId !== null) {
      loadDataWithId();
    }
  }, [createId]);

  // 저작 권한 확인
  useEffect(() => {
    if (userIdToken !== "Guest" && !isWritable) {
      openSnackbar(
        "warning",
        "저작 권한이 없습니다. 설정에서 저작 권한을 요청해주세요."
      );
    }
  }, [userIdToken, isWritable]);

  return (
    <>
      {
        // 저작 하기 : loading 없이 바로 렌더
        // 수정 하기 : loading 후 렌더
        isEditMode() && isLoading && <MyLoading />
      }
      {((isEditMode() && !isLoading) || !isEditMode()) && (
        <WriteQuiz
          isEditMode={isEditMode()}
          resultQuiz={resultQuiz}
          previewResultQuizState={previewResultQuizState}
          onClickPreview={onClickPreview}
          onClickEdit={onClickEdit}
          inputQuizType={inputQuizType}
          inputQuestionText={inputQuestionText}
          inputDescriptionText={inputDescriptionText}
          inputAnswer={inputAnswer}
          inputAnswerText={inputAnswerText}
          inputCommentaryText={inputCommentaryText}
          exampleItems={exampleItems}
          onChangeWriteQuizInputs={onChangeWriteQuizInputs}
          onChangeWriteQuizSelects={onChangeWriteQuizSelects}
          onChangeWriteQuizTabs={onChangeWriteQuizTabs}
          selectQuizInfo={selectQuizInfo}
          handleDragEndExampleItems={handleDragEndExampleItems}
          handleClickAddExampleItem={handleClickAddExampleItem}
          handleClickRemoveExampleItem={handleClickRemoveExampleItem}
          handleClickSetExampleItemIsAnswer={handleClickSetExampleItemIsAnswer}
          handleChangeExampleItemText={handleChangeExampleItemText}
          handleChangeInputDescriptionText={handleChangeInputDescriptionText}
          handleChangeInputCommentaryText={handleChangeInputCommentaryText}
          handleClickSubmitWrite={handleClickSubmitWrite}
          selectedTopics={selectedTopics}
          handleSubTopicToggle={handleSubTopicToggle}
          writeProgressValue={writeProgressValue}
          isCanSubmit={isCanSubmit}
          writeQuizPageTitle={getWriteQuizPageTitle()}
          onClickBackPage={getBackPage()}
          // 관련자료
          selectedReferencesItems={selectedReferencesItems}
          handleDragEndSelectedReferencesItems={
            handleDragEndSelectedReferencesItems
          }
          onClickSelectReference={onClickSelectReference}
        />
      )}
    </>
  );
}

export default WriteQuizContainer;
